import styled from 'styled-components';

const headerGroupBoxStyles = `
    position: absolute;
    border: 1px solid #ccc;
    borderBottom: none;
    height: 55px;
    top: 10px;
    width: 241px;
    padding: 2px 5px;
    fontWeight: bold;
`;

const absolutePos = `
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

// TODO this is dangerous and will brake of dimsum change their implementation
export const StyledContainer = styled.div`
  div[data-testid='data-table-cell'] > div > div > span {
    display: none !important;
  }

  .provider-cell .em-ds-form-element-radio {
    margin-top: 15px;
  }

  .border-right-cell {
    border-right: 1px solid rgb(204, 204, 204);
  }
`;

export const StyledHeaderGroupBox = styled.div`
  left: ${({ leftPos }) => leftPos || 0};
  ${headerGroupBoxStyles}
`;

export const StyledScenarioCell = styled.div`
  ${absolutePos}
  border-top: 1px solid #1e79c2;
  background-color: #ebf6ff;
  left: 40px;
  display: flex;
  align-items: center;
`;

export const StyledProviderCell = styled.div`
  ${absolutePos}
  display: flex;
  padding-left: 4px;
  align-items: center;
  border-right: 1px solid rgb(204, 204, 204);
`;

export const StyledDataGroupCell = styled.div`
  ${absolutePos}
  border-top: 1px solid #1E79C2;
  background-color: #ebf6ff;
  border-right: ${({ showBorder }) => (showBorder ? `1px solid #ccc` : `none`)};
`;

export const StyledDataCell = styled.div`
  ${absolutePos}
  padding-right: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-right: ${({ showBorder }) => (showBorder ? `1px solid #ccc` : `none`)};
`;
