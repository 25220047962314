import AppError from './errors/appError';

const parseValidResponse = async (
  response: any,
  ignoreErrorStatuses: number[] = [],
) => {
  const responseCode = response.status as number;
  if (response.ok || ignoreErrorStatuses.includes(responseCode)) {
    const contentType = response.headers.get('content-type');
    let responseBody;
    if (contentType && contentType.includes('application/json')) {
      responseBody = await response.json();
    } else {
      responseBody = await response.text();
    }
    return responseBody;
  }
  throw new AppError('API Error');
};
/**
 * This will handle api response and return json object if success
 * if anything not between response status 200 - 299 (see response.ok) it will throw AppError exception
 *
 * @param {number[]} filters statuses to ignore
 * @returns {Function} response handler
 */
export const apiResponseHandler =
  (...filters) =>
  async (response) =>
    parseValidResponse(response, filters);
