import moment from 'moment-timezone';
import { getAppConfigValue } from '@elliemae/pui-app-sdk';
import Common from '../services/Common';
import Session, {
  IS_LO,
  JWT_TOKEN,
  ORIGIN_ID,
  OLD_ORIGIN_ID,
} from '../services/Session';
import { formatDate } from '../services/helpers';
import { apiResponseHandler } from './apiResponseFilter';
import createFetch from './createFetch';
import { runFetch, runFetchJson } from './runFetch';

const widgetServerAPI = getAppConfigValue<string>(
  'serviceEndpoints.partnerApi',
);
const eppsAPI = getAppConfigValue<string>('serviceEndpoints.eppsApi');
export const getApiUrl = () => (Session.get(IS_LO) ? widgetServerAPI : eppsAPI);

export const getLoancatcherEnvTarget = () => {
  let env = eppsAPI.match(/https?:\/\/([^.]+)/)?.[1] as string;

  if (eppsAPI.includes('localhost')) {
    env = 'local';
  }

  return env;
};

export const getLoancatcherAPIUrl = () => {
  return getAppConfigValue<string>('serviceEndpoints.loanCatcherApi');
};

// TODO(ngen): all of these methods with createFetch had an extra boolean arg passed that wasn't used in the createFetch function
// probably harmless, since the method signature was just too confusing to eyeball without types
export default {
  getLockPageSettings: () =>
    runFetchJson(async () => {
      const originId = await Common.getOriginId();

      return createFetch(
        `${getApiUrl()}/encompassSettings/secondary/locks?OriginId=${originId}`,
        {
          headers: {
            'X-Elli-PAT': await Common.getPATToken(),
          },
        },
        {},
        {
          method: 'GET',
        },
        [],
        false,
      );
    }),

  calculateRateLockExtension: (opts) =>
    runFetchJson(async () => {
      const { loanUuid, lockDate, numberOfDays, parentLockUuid } = opts;
      const originId = await Common.getOriginId();

      return createFetch(
        `${getApiUrl()}/loan/${loanUuid}/calculators/rateLock?OriginId=${originId}`,
        {
          headers: {
            'X-Elli-PAT': await Common.getPATToken(),
          },
          body: JSON.stringify({
            lockRequestType: 'Extension',
            lockDate: formatDate(new Date(lockDate), 'ISODateOnly'),
            numberOfDays,
            parentLock: {
              entityId: parentLockUuid,
              EntityType: 'RateLock',
            },
          }),
        },
        {},
        {
          method: 'POST',
        },
        [],
        true,
      );
    }),

  pollAdjustmentsApproval: async (opts) => {
    const response = await runFetchJson(async () => {
      const { loanNumber, groupUuid } = opts;
      return createFetch(
        `${getLoancatcherAPIUrl()}/ice-pps/loan/${loanNumber}/polling`,
        {
          headers: {
            'X-EPC-Transaction-Uuid': await Common.getOriginId(),
            'X-Elli-PAT': await Common.getPATToken(),
            'X-PPE-Env': getLoancatcherEnvTarget(),
          },
          body: JSON.stringify({
            groupUuid,
          }),
        },
        {},
        {
          method: 'POST',
        },
        [],
        true,
      );
    });

    const sorted = {
      // interface AdjustmentSubmissionStatus {
      // Requested
      // Approved
      // Denied
      // Removed
      // Submitted
      // Submission_Failed
      // }

      successful: (response?.item?.adjustments ?? []).filter(
        (adj) => adj.status === 'Submitted',
      ) as any[],
      failed: (response?.item?.adjustments ?? []).filter(
        // Until we build concessions with full workflow, we can use simplified status logic here
        (adj) => adj.status !== 'Submitted',
      ) as any[],
    };

    return sorted;
  },

  createAdjustments: async (opts) => {
    const adjustments = opts.adjustments ?? [];

    if (!adjustments?.length) {
      return null;
    }

    const response = await runFetchJson(async () => {
      return createFetch(
        `${getLoancatcherAPIUrl()}/ice-pps/loan/${
          opts.loanNumber
        }/adjustments/${opts.adjustmentType}`,
        {
          headers: {
            'X-EPC-Transaction-Uuid': await Common.getOriginId(),
            'X-Elli-PAT': await Common.getPATToken(),
            'X-PPE-Env': getLoancatcherEnvTarget(),
          },
          body: JSON.stringify(adjustments),
        },
        {},
        {
          method: 'POST',
        },
        [],
        true,
      );
    });
    return response?.item?.uuid;
  },
  deleteAdjustments: async (opts) => {
    const adjustments = opts.adjustments ?? [];
    if (!adjustments.length) {
      return;
    }

    await Promise.all(
      adjustments.map((i) =>
        runFetch(async () =>
          createFetch(
            `${getLoancatcherAPIUrl()}/ice-pps/adjustment/${i.uuid}`,
            {
              headers: {
                'X-EPC-Transaction-Uuid': await Common.getOriginId(),
                'X-Elli-PAT': await Common.getPATToken(),
                'X-PPE-Env': getLoancatcherEnvTarget(),
              },
            },
            {},
            {
              method: 'DELETE',
            },
            [],
            true,
          ),
        ),
      ),
    );
  },

  getAdjustments: async (opts) => {
    const loanNumber = opts?.loanNumber;
    if (!loanNumber) {
      return [];
    }
    const response = await runFetchJson(async () => {
      return createFetch(
        `${getLoancatcherAPIUrl()}/query/drill-down-sources/adjustment`,
        {
          headers: {
            'X-EPC-Transaction-Uuid': await Common.getOriginId(),
            'X-Elli-PAT': await Common.getPATToken(),
            'X-PPE-Env': getLoancatcherEnvTarget(),
          },
          body: JSON.stringify({
            filters: {
              ppeLoanNumber: [loanNumber],
            },
            facets: {},
            dimensionSelectors: {},
            indexName: 'ADJUSTMENT',
            dateKeyField: 'createdDate',
            detailLevel: 'KeyFactTuples',
            rows: -1,
          }),
        },
        {},
        {
          method: 'POST',
        },
        [],
        true,
      );
    });

    const results = response?.item?.detailResults ?? [];

    return results
      .filter((result) => result.status === 'Submitted')
      .map((result) => ({
        uuid: result.adjustmentUuid,
        description: result.description,
        price: result.points,
        adjustmentType: result.adjustmentType,
        status: result.status,
        approvedBy: result.approvedBy,
        approvedReason: result.approvedReason,
        approvalDate: result.approvalDate
          ? formatDate(new Date(result.approvalDate), 'Calendar')
          : null,
        rate: result?.rate,
      }));
  },
  getRates: (opts) =>
    createFetch(
      `${getApiUrl()}/loanQualifier`,
      opts,
      {},
      {
        method: 'POST',
      },
      [apiResponseHandler(400, 401, 412)],
      true,
    ),
  setUserEPPSMapping: (opts, username) =>
    createFetch(
      `${widgetServerAPI}/users/${username}`,
      opts,
      {},
      {
        method: 'PUT',
      },
      [apiResponseHandler(404, 401, 409)],
      false,
    ),
  login: (opts, username) =>
    createFetch(
      `${widgetServerAPI}/users/${username}`,
      opts,
      {},
      {
        method: 'POST',
      },
      [apiResponseHandler(401)],
      false,
    ),
  validateCredentials: (opts, username) =>
    createFetch(
      `${widgetServerAPI}/users/${username}`,
      opts,
      {},
      {
        method: 'POST',
      },
      [apiResponseHandler(400, 401)],
      false,
    ),
  refreshJWT: (opts, username) =>
    createFetch(
      `${widgetServerAPI}/users/${username}/refreshtoken`,
      opts,
      {},
      {
        method: 'GET',
      },
      [apiResponseHandler(401)],
      false,
    ),
  getUserRoles: (opts, tokens?: any) =>
    createFetch(
      `${eppsAPI}/user/role/`,
      opts,
      tokens,
      {
        method: 'GET',
      },
      [apiResponseHandler(401)],
      true,
    ),
  getUserRolesV1: async () => {
    const token = await Common.getPATToken();
    return createFetch(
      `${getApiUrl()}/user/role`,
      {
        headers: {
          'X-Elli-PAT': token,
        },
        body: JSON.stringify({
          originId: await Common.getOriginId(),
        }),
      },
      {},
      {
        method: 'POST',
      },
      [apiResponseHandler(401)],
      true,
    );
  },
  getLockDeskHours: async (originId, loanChannelId) => {
    const token = await Common.getPATToken();
    return createFetch(
      `${getApiUrl()}/lockdesk/hours`,
      {
        headers: {
          'X-Elli-PAT': token,
        },
        body: JSON.stringify({
          originId,
          loanChannelId,
        }),
      },
      {},
      {
        method: 'POST',
      },
      [apiResponseHandler(401)],
      true,
    );
  },
  getLockDeskValidateStatus: async (validateData) =>
    createFetch(
      `${getApiUrl()}/lockdesk/validate/lockRequest`,
      {
        headers: { 'X-Elli-PAT': await Common.getPATToken() },
        body: JSON.stringify({
          originId: await Common.getOriginId(),
          ...validateData,
        }),
      },
      {},
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
      true,
    ),
  setOnrpAccruedAmount: async (loanChannelId, loanAmount) =>
    createFetch(
      `${getApiUrl()}/lockdesk/onrp/accrued-amount`,
      {
        headers: { 'X-Elli-PAT': await Common.getPATToken() },
        body: JSON.stringify({
          originId: await Common.getOriginId(),
          loanAmount,
          loanChannelId,
          rollback: false,
        }),
      },
      {},
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
      true,
    ),
  selectProgramRate: (opts, tokens?: any) =>
    createFetch(
      `${getApiUrl()}/loans/${opts.loanId}/rateSelector`,
      opts,
      tokens,
      {
        method: 'POST',
      },
      [apiResponseHandler(401)],
      true,
    ),

  // PartnerAPI/WidgetAPI Lookup APIs
  getWebhookLookups: async (lookupName) =>
    createFetch(
      `${widgetServerAPI}/lookups/`,
      {
        headers: { 'X-Elli-PAT': await Common.getPATToken() },
        body: JSON.stringify({
          lookupName,
          originId: await Common.getOriginId(),
        }),
      },
      {},
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
      true,
    ),

  getLockSummaryDocument: async (payload) => {
    const originId = await Common.getOriginId();
    return createFetch(
      `${getApiUrl()}/documents/locksummary`,
      {
        body: JSON.stringify({
          originId,
          comments: payload.comments,
          rateSelectorResponse: payload.rateSelectorResponse,
        }),
      },
      {},
      { method: 'POST' },
      [apiResponseHandler(401)],
      true,
    );
  },

  createRateAlert: async (opts) => {
    const response = await runFetchJson(async () => {
      try {
        const originId = await Common.getOriginId();
        return await createFetch(
          `${getApiUrl()}/ratealert/${opts.loanId}?OriginId=${originId}`,
          {
            headers: {
              'X-EPC-Transaction-Uuid': originId,
              'X-Elli-PAT': await Common.getPATToken(),
            },
            body: JSON.stringify(opts.payload),
          },
          {},
          {
            method: 'POST',
          },
          [],
          true,
        );
      } catch (err) {
        throw new Error(err as string);
      }
    });
    return response;
  },

  getWebhookZipLocations: async (zip) =>
    createFetch(
      `${widgetServerAPI}/lookups/zipcodes/${zip}/locations`,
      {
        headers: { 'X-Elli-PAT': await Common.getPATToken() },
        body: JSON.stringify({
          originId: await Common.getOriginId(),
        }),
      },
      {},
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
      true,
    ),

  getWebhookStateCounties: async (stateId) =>
    createFetch(
      `${widgetServerAPI}/lookups/states/${stateId}/counties`,
      {
        headers: { 'X-Elli-PAT': await Common.getPATToken() },
        body: JSON.stringify({
          originId: await Common.getOriginId(),
        }),
      },
      {},
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
      true,
    ),

  // EPPS's Lookup APIS

  getZipLocations: (zip, opts, tokens?: any) =>
    createFetch(
      `${eppsAPI}/lookups/zipcodes/${zip}/locations`,
      opts,
      tokens,
      {
        method: 'GET',
      },
      [apiResponseHandler(401)],
      true,
    ),

  getStateCounties: (stateId, opts, tokens?: any) =>
    createFetch(
      `${eppsAPI}/lookups/state/${stateId}/counties`,
      opts,
      tokens,
      {
        method: 'GET',
      },
      [apiResponseHandler(401)],
      true,
    ),

  getAdjustmentDetails: (programId, opts, tokens?: any) =>
    createFetch(
      `${eppsAPI}/loans/programs/${programId}/adjustments`,
      opts,
      tokens,
      {
        method: 'POST',
      },
      [apiResponseHandler(401)],
      true,
    ),

  getProgramGuidelines: (programID, opts, tokens?: any) =>
    createFetch(
      `${eppsAPI}/programs/${programID}/guidelines`,
      opts,
      tokens,
      { method: 'GET' },
      [apiResponseHandler(401)],
      true,
    ),

  // invalidate token for logout
  getOrigin: (opts, originId, source, type, lockId) => {
    let urlParameters = '';
    if (source) {
      urlParameters = `?SourceApplicationForm=${source}`;
      if (type) {
        urlParameters = `${urlParameters}&TransactionType=${type}`;
      }
      if (lockId) {
        urlParameters = `${urlParameters}&LockId=${lockId}`;
      }
    }
    return createFetch(
      `${widgetServerAPI}/origins/${originId}${urlParameters}`,
      opts,
      {},
      {
        method: 'GET',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(400, 404, 401, 409)],
      false,
    );
  },
  getUserDataV1: async () =>
    createFetch(
      `${getApiUrl()}/user`,
      {
        headers: { 'X-Elli-PAT': await Common.getPATToken() },
        body: JSON.stringify({
          originId: await Common.getOriginId(),
        }),
      },
      {},
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
      true,
    ),
  getUserDataV2: (opts, tokens?: any) =>
    createFetch(
      `${getApiUrl()}/user`,
      {
        customToken: { value: opts.jwtToken },
        tokenType: 'Bearer',
      },
      tokens,
      {
        method: 'GET',
      },
      [apiResponseHandler(401)],
      true,
    ),

  getWebhookData: (opts, OriginId, action) =>
    createFetch(
      `${widgetServerAPI}/Webhooks/${OriginId}/${action}`,

      opts,
      {},
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(400, 401)],
      true,
    ),
  getEpcTransaction: (opts, transactionId) =>
    createFetch(
      `${widgetServerAPI}/epc/transactions/${transactionId}/response/status`,

      opts,
      {},
      {
        method: 'GET',
      },
      [apiResponseHandler(400, 401)],
      true,
    ),
  getUpdatedfield: (opts, originId) =>
    createFetch(
      `${widgetServerAPI}/encompassFieldUpdates/${originId}`,
      opts,
      {},
      {
        method: 'POST',
      },
      [apiResponseHandler(400, 401)],
      true,
    ),
  getEpcWebhookData: (opts, transactionId, action) =>
    createFetch(
      `${widgetServerAPI}/Webhooks/${transactionId}/${action}`,
      opts,
      {},
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(400, 401)],
      true,
    ),
  completeTransaction: (opts, transactionId) =>
    createFetch(
      `${widgetServerAPI}/response/${transactionId}`,
      opts,
      {},
      {
        method: 'POST',
      },
      [apiResponseHandler()],
      false,
    ),

  getGeocodesV1: (opts, loanId) =>
    createFetch(
      `${getApiUrl()}/lookups/loans/${loanId}/geocodes/`,
      {
        headers: { 'X-Elli-PAT': opts.patToken },
        body: JSON.stringify({
          originId: opts.originId,
        }),
      },
      {},
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
      true,
    ),
  getGeocodesV2: (opts, loanId, tokens?: any) =>
    createFetch(
      `${getApiUrl()}/loans/${loanId}/geocodes/`,
      {
        customToken: { value: opts.jwtToken },
        tokenType: 'Bearer',
      },
      tokens,
      {
        method: 'GET',
      },
      [apiResponseHandler(401)],
      true,
    ),
  getProgramGuidelinesV1: (opts, programID) =>
    createFetch(
      `${getApiUrl()}/guidelines/${programID}`,
      {
        headers: { 'X-Elli-PAT': opts.patToken },
        body: JSON.stringify({
          originId: opts.originId,
        }),
      },
      {},
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
      true,
    ),
  getProgramGuidelinesV2: (opts, programID, tokens?: any) =>
    createFetch(
      `${getApiUrl()}/programs/${programID}/guidelines`,
      {
        customToken: { value: opts.jwtToken },
        tokenType: 'Bearer',
      },
      tokens,
      {
        method: 'GET',
      },
      [apiResponseHandler(401)],
      true,
    ),
  getProgramAdjustedAndRawPricesV1: (opts, loanID) =>
    createFetch(
      `${getApiUrl()}/pricedata/${loanID}`,
      {
        body: JSON.stringify({
          originId: opts.originId,
        }),
      },
      {},
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
    ),
  getProgramAdjustedAndRawPricesV2: (opts, loanID, tokens?: any) =>
    createFetch(
      `${getApiUrl()}/${loanID}/pricedata`,
      {
        customToken: { value: opts.jwtToken },
        tokenType: 'Bearer',
      },
      tokens,
      {
        method: 'POST',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
      true,
    ),
  getRateDocInstanceV1: (opts, DocumentId) =>
    createFetch(
      `${getApiUrl()}/ratesheet/Validate/${DocumentId}`,
      {
        headers: { 'X-Elli-PAT': opts.patToken },
        body: JSON.stringify({
          originId: opts.originId,
        }),
      },
      {},
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
    ),
  getRateDocInstanceV2: (opts, documentId, tokens?: any) =>
    createFetch(
      `${getApiUrl()}/ratesheet/Validate/${documentId}`,
      {
        customToken: { value: opts.jwtToken },
        tokenType: 'Bearer',
      },
      tokens,
      {
        method: 'GET',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
      true,
    ),
  getRateSheetDocumentV1: (opts, DocumentId) =>
    createFetch(
      `${getApiUrl()}/ratesheet/${DocumentId}`,
      {
        headers: { 'X-Elli-PAT': opts.patToken },
        body: JSON.stringify({
          originId: opts.originId,
        }),
      },
      {},
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
      true,
    ),
  getRateSheetDocumentV2: (opts, documentId, tokens?: any) =>
    createFetch(
      `${getApiUrl()}/ratesheet/${documentId}`,
      {
        customToken: { value: opts.jwtToken },
        tokenType: 'Bearer',
      },
      tokens,
      {
        method: 'GET',
      },
      [apiResponseHandler(401)],
      true,
    ),
  logEvent: async (payload) =>
    createFetch(
      `${getApiUrl()}/event`,
      {
        headers: { 'X-Elli-PAT': await Common.getPATToken() },
        body: JSON.stringify({
          ...payload,
          originId: await Common.getOriginId(),
        }),
      },
      {},
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
      true,
    ),
  getMe: (opts) =>
    createFetch(
      '/iam/v1/users/me',
      opts,
      {},
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      },
      [apiResponseHandler()],
    ),
  getEncompassSettingsV1: async () =>
    createFetch(
      `${getApiUrl()}/encompassSettings`,
      {
        headers: { 'X-Elli-PAT': await Common.getPATToken() },
        body: JSON.stringify({
          originId: await Common.getOriginId(),
        }),
      },
      {},
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
      true,
    ),
  getLoanLimitsV1: (opts, loanData) =>
    createFetch(
      `${getApiUrl()}/lookups/loanlimits/`,
      {
        headers: { 'X-Elli-PAT': opts.patToken },
        body: JSON.stringify({
          originId: opts.originId,
          state: loanData.property.state,
          county: loanData.property.county,
          zip: loanData.property.zip,
          effectiveDate: loanData.relockRequest.effectiveDate,
        }),
      },
      {},
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
      true,
    ),
  getLoanLimitsV2: (opts, loanId, tokens?: any) => {
    return createFetch(
      `${getApiUrl()}/lookups/loans/${loanId}/loanlimits/`,
      {
        customToken: { value: opts.jwtToken },
        tokenType: 'Bearer',
      },
      tokens,
      {
        method: 'GET',
      },
      [apiResponseHandler(401)],
      true,
    );
  },
  getTransactionDetails: (transactionId) =>
    createFetch(
      `${getApiUrl()}/transaction/${transactionId}`,
      {},
      {},
      {
        method: 'GET',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
    ),
  getClientRole: async () =>
    createFetch(
      `${getApiUrl()}/user/clientrole`,
      {
        body: JSON.stringify({
          originId: await Common.getOriginId(),
        }),
      },
      {},
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
      true,
    ),
  getClientRoleV2: async () =>
    createFetch(
      `${getApiUrl()}/user/clientRole/`,
      {
        customToken: { value: await Session.get(JWT_TOKEN) },
        tokenType: 'Bearer',
      },
      {},
      {
        method: 'GET',
      },
      [apiResponseHandler(401)],
      true,
    ),
  getAllLookups: (opts, tokens?: any) =>
    createFetch(
      `${getApiUrl()}/lookups/all/`,
      {
        ...opts,
        effectiveDate: `${moment()
          .tz('America/New_York')
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`,
      },
      tokens,
      {
        method: 'GET',
      },
      [apiResponseHandler(401)],
      true,
    ),
  refreshOrigin: async () =>
    createFetch(
      `${getApiUrl()}/origins`,
      {
        body: JSON.stringify({
          NewOriginId: Session.get(ORIGIN_ID),
          originId: Session.get(OLD_ORIGIN_ID),
        }),
      },
      {},
      {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
      },
      [apiResponseHandler(401)],
      true,
    ),
};
