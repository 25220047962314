import React from 'react';
import { DSHeader } from '@elliemae/ds-header';
import { DSSeparator } from '@elliemae/ds-separator';

export const HeaderForm = () => (
  <div style={{ height: '50px' }} data-testid="search-form-header">
    <DSHeader text="Search For Products" fontSize="23px" mt="xxs" />
    <DSSeparator
      margin="s"
      orientation="horizontal"
      position="initial"
      type="non-form"
    />
  </div>
);
