import Common from '../../common/services/Common';
import { epcService } from '../../common/services/epcService';
import { closeLoader, openLoader } from '../screenLoader/actions';
import { openGlobalErrorModalAction } from '../globalErrorModal/actionCreators';

export const lockExtentionAction = (options) => async (dispatch) => {
  dispatch(openLoader('Extending Lock'));
  try {
    const resultFormat = 'application/vnd.productpricing-extendlock-1.0.0.json';
    const type = 'EXTEND';

    // TODO: are we allowed to pass any price adjustment here?
    // That will be privledged, will need to do a lookup for that, send it API side
    const { comments, daysToExtend, lockExtendPriceAdjustment } = options;
    if (!daysToExtend) {
      throw new Error('Missing days to extend');
    }
    if (!Number.isFinite(lockExtendPriceAdjustment)) {
      throw new Error('Missing lock extend price adjustment');
    }

    const originId = await Common.getOriginId();

    const parsedOptions = {
      resultFormat,
      action: type,
      originId,
      details: {
        comments,
        daysToExtend,
        lockExtendPriceAdjustment,
      },
    };

    const transactionId = await epcService.createTransaction({
      request: {
        type,
        options: parsedOptions,
      },
    });

    if (transactionId) {
      await epcService.updatePollTransaction({
        transactionID: transactionId,
        options: parsedOptions,
      });

      Common.closeApplication();
    } else {
      throw new Error('Missing transaction ID');
    }
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(e);

    dispatch(
      openGlobalErrorModalAction({
        title: 'Unexpected error completing transaction',
        errorMessage: e.message,
        onCloseCallback: () => Common.closeApplication(),
      }),
    );
  } finally {
    dispatch(closeLoader());
  }
};
