export const lookupKeyFromValue = (enumeratedType, value) => {
  return Object.entries(enumeratedType).find(([, v]) => v === value)?.[0];
};

export const formatDate = (date, formatType) => {
  const config = {
    Calendar: (d: Date) => {
      return [
        (d.getMonth() + 1).toString().padStart(2, '0'),
        d.getDate().toString().padStart(2, '0'),
        d.getFullYear(),
      ].join('/');
    },
    ISODateOnly: (d: Date) => {
      return d.toISOString().split('T')[0];
    },
  };

  let parsed;

  if (date instanceof Date) {
    parsed = date;
  } else if (typeof date === 'string' && date?.length >= 10) {
    const dateParts = date.substring(0, 10).replace(/[-/]/g, '/').split('/');
    const isYearFirst = dateParts[0].length === 4;
    // if isYearFirst = true, then incoming date = "2024-10-30"
    // if isYearFirst = false, then incoming date = "10/30/2024"
    const americanCalendarStyle = (
      isYearFirst
        ? [dateParts[1], dateParts[2], dateParts[0]]
        : [dateParts[0], dateParts[1], dateParts[2]]
    ).join('/');
    parsed = new Date(americanCalendarStyle);
  } else {
    return null;
  }

  if (!parsed || !Number.isFinite(parsed?.getTime())) {
    return null;
  }
  return config[formatType]?.(parsed) ?? null;
};
