import React from 'react';
import { bool, func } from 'prop-types';
import {
  DSDialog,
  DSDialogBody,
  DSDialogFooter,
  DSDialogSeparator,
  DSDialogDefaultLayout,
  DSDialogPrimaryMessage,
  DSDialogSecondaryMessage,
} from '@elliemae/ds-dialog';
import { DSButtonV2 } from '@elliemae/ds-button';
import { WarningTriangle } from '@elliemae/ds-icons';

export const LockRequestedModal = ({
  isModalOpen,
  handleRequest,
  handleCancel,
}) => (
  <DSDialog isOpen={isModalOpen} size="small" onClickOutside={handleCancel}>
    <DSDialogBody>
      <DSDialogDefaultLayout>
        <WarningTriangle size="xxl" color={['danger', '900']} />
        <DSDialogPrimaryMessage>Request new lock?</DSDialogPrimaryMessage>
        <DSDialogSecondaryMessage>
          This will overwrite your existing lock.
        </DSDialogSecondaryMessage>
      </DSDialogDefaultLayout>
    </DSDialogBody>
    <DSDialogSeparator />
    <DSDialogFooter>
      <DSButtonV2 buttonType="outline" onClick={handleCancel}>
        Cancel
      </DSButtonV2>
      <DSButtonV2 onClick={handleRequest}>Request New Lock</DSButtonV2>
    </DSDialogFooter>
  </DSDialog>
);

LockRequestedModal.defaultProps = {
  isModalOpen: false,
};

LockRequestedModal.propTypes = {
  isModalOpen: bool,
  handleRequest: func.isRequired,
  handleCancel: func.isRequired,
};
