/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import Common from '../../common/services/Common';
import Session, { IS_LO, JWT_TOKEN } from '../../common/services/Session';
import api from '../../common/utils/api';
import { useSelectedProductSelector } from '../../data/useSelectors';

const getProgramGuidelines = async (programID) => {
  let result;
  if (Session.get(IS_LO)) {
    const patToken = await Common.getPATToken();
    const originId = await Common.getOriginId();
    result = await api.getProgramGuidelinesV1(
      { patToken, originId },
      programID,
    );
  } else {
    const jwtToken = await Session.get(JWT_TOKEN);
    result = await api.getProgramGuidelinesV2({ jwtToken }, programID);
  }
  return result;
};

export const useProgramDetails = (programInformation) => {
  const [allregsFormAction, setAllregsFormAction] = useState(null);
  const [allregsFormAppID, setAllregsFormAppID] = useState(null);
  const [allregsPassKey, setAllregsPassKey] = useState(null);
  const [productNotes, setProductNotes] = useState(null);

  /* TODO programInformation comes from LockRates call and selectedProduct from Rates
   * and we need one or the other depending if is buy-side or not.
   * We should figure if we can use only one source of information and remove the param
   * (notes, activeGuidelineLink)
   */
  const { program, programID, notes } =
    programInformation || useSelectedProductSelector();

  const { activeGuidelineLink } = useSelectedProductSelector();

  // Parse product notes for modal
  const selectedProductNotes = () => {
    if (!notes || notes.length === 0) {
      setProductNotes(null);
      return;
    }
    let parsedNotes = notes;
    parsedNotes = parsedNotes.replace(/{/g, '<');
    parsedNotes = parsedNotes.replace(/}/g, '>');
    parsedNotes = parsedNotes.replace(/\|/g, '<br/><li>');
    parsedNotes = `<ul><li>${parsedNotes}</ul>`;
    setProductNotes(parsedNotes);
  };

  // First step - GET to the guidelines API.
  const selectedProgramGuidelines = async () => {
    const guidelines = await getProgramGuidelines(programID);
    const { allRegsUrl, applicationIdentifier, passKey } = guidelines;
    setAllregsFormAction(allRegsUrl);
    setAllregsFormAppID(applicationIdentifier);
    setAllregsPassKey(passKey);
  };

  // Second step - FORM POST to allregs url.
  const formPostAllRegs = () => {
    const allregsForm = document.getElementById('allregs') as HTMLFormElement;
    allregsForm.action = allregsFormAction as any;
    allregsForm.appId.value = allregsFormAppID;
    allregsForm.passkey.value = allregsPassKey;
    allregsForm.submit();
  };

  // Update the notes and the guidelines every time product changes.
  useEffect(() => {
    selectedProductNotes();
    if (programID) {
      selectedProgramGuidelines();
    }
  }, [programID]);

  return {
    program,
    productNotes,
    formPostAllRegs,
    activeGuidelineLink,
  };
};
