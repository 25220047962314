import { parseNumber } from '@frontend/utils';
import { getLoanPurposeType, getPropertyUsageType } from '@frontend/epc';

export const convertLoanInfoToEPCModel = (loanInfo) => {
  const ltv = loanInfo.loanInformation.ltv
    ? Number(loanInfo.loanInformation.ltv)
    : null;
  const loanPurposeType = getLoanPurposeType(
    loanInfo.loanInformation.loanPurpose,
  );
  const propertyUsage = getPropertyUsageType(loanInfo.property.use);

  const monthlyPropertyTaxes = parseNumber(loanInfo.property.tax / 12, 0);
  const monthlyInsuranceAmount = parseNumber(
    loanInfo.property.insuranceAmount / 12,
    0,
  );
  const epcLoanInformation = {
    baseLoanAmount: loanInfo.loanInformation.firstMortgageAmount,
    property: {
      streetAddress: loanInfo.property.streetAddress,
      city: loanInfo.property.city,
      state: loanInfo.property.state,
      postalCode: loanInfo.property.zip,
      loanPurposeType: loanPurposeType as string,
      county: loanInfo.property.county,
      propertyUsageType: propertyUsage,
      loanPurposeTypeUrla: loanPurposeType,
    },
    applications: loanInfo.borrowers.map((borrower) => ({
      borrower: {
        firstNameWithMiddleName: borrower.firstName,
        lastNameWithSuffix: borrower.lastName,
        firstName: borrower.firstName,
        lastName: borrower.lastName,
      },
      propertyUsageType: propertyUsage,
    })),
    propertyAppraisedValueAmount: loanInfo.property.value,
    firstSubordinateLienAmount: loanInfo.loanInformation.firstMortgageAmount,
    subjectPropertyGrossRentalIncomeAmount: loanInfo.property.rentalIncome,
    proposedRealEstateTaxesAmount: monthlyPropertyTaxes.toFixed(2),
    proposedHazardInsuranceAmount: monthlyInsuranceAmount.toFixed(2),
    proposedDuesAmount: loanInfo.property.associationFee.toFixed(2),
    mortgageType: loanInfo.loanType,
    subjectPropertyOccupancyPercent: loanInfo.property.occupancyRate,
    borrowerRequestedLoanAmount: loanInfo.loanInformation.firstMortgageAmount,
    rateLock: {
      propertyUsageType: propertyUsage,
      mortgageType: loanInfo.loanType,
      baseLoanAmount: loanInfo.loanInformation.firstMortgageAmount,
      borrowerRequestedLoanAmount: loanInfo.loanInformation.firstMortgageAmount,
      purchasePriceAmount: loanInfo.property.value,
      propertyEstimatedValueAmount: loanInfo.property.value,
      helocCreditLimit: loanInfo.loanInformation.helocCreditLimit,
      subjectPropertyCity: loanInfo.property.city,
      subjectPropertyState: loanInfo.property.state,
      subjectPropertyPostalCode: loanInfo.property.zip,
      subjectPropertyStreetAddress: loanInfo.property.streetAddress,
      subjectPropertyCounty: loanInfo.property.county,
      propertyAppraisedValueAmount: loanInfo.property.value,
      subjectPropertyPropertyStreetAddress: loanInfo.property.streetAddress,
      subjectPropertyUnitNumber: loanInfo.property.unitNumber,
    },
    hmda: {
      msaNumber: loanInfo.property.msa,
      stateCode: loanInfo.property.stateCode,
      countyCode: loanInfo.property.countyCode,
    },
    creditScoreToUse: loanInfo?.borrowers[0]?.borrowerFinanancial?.creditScore,
    propertyEstimatedValueAmount: loanInfo.property.value,
    purchasePriceAmount: loanInfo.property.value,
    ltv: ltv as number | null,
  };
  return epcLoanInformation;
};
