import { DSButton } from '@elliemae/ds-button';
import { ChevronLeft } from '@elliemae/ds-icons';
import { DSPageHeaderV2, PageHeaderTitle } from '@elliemae/ds-page-header';
import { DSSeparator } from '@elliemae/ds-separator';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DataProvider } from '../../../../common/utils/customHooks/useAppData';
import { resetRatesData } from '../../../../data/lockConfirm/actionCreators';
import { getRateSelectorAction } from '../../../../data/lockSummary/actionCreators';
import { closeLoader, openLoader } from '../../../../data/screenLoader/actions';
import {
  useLoanInformationSelector,
  useRateSelectorLien,
} from '../../../../data/useSelectors';
import { FooterButtons } from '../../../components/FooterButtons/FooterButtons';
import {
  StyledEppsContainerBody,
  StyledEppsParentView,
  StyledEppsWrapper,
} from '../../../global/styles/styled';
import { LockConfirmWrapperStyled } from '../../styled';
import { priceUpdate } from '../../utils';
import { LoanDetails } from '../LoanSummaryDetails';
import { RatesContainer } from './components/RatesContainer';

export const CompareRates = ({ handleBack }) => {
  const dispatch = useDispatch();
  const loanInfo = useLoanInformationSelector();
  const [selectedRateData, setSelectedRateData] = useState(null);
  const [selectedPriceId, setSelectedPriceId] = useState('');
  const [disableNewPrice, setdisableNewPrice] = useState(true);
  const { userLender, programName } = useRateSelectorLien(
    ['userLender', 'programName'],
    '',
  ) as any;
  const program = `${userLender} ${programName}`;

  useEffect(() => {
    return () => dispatch(resetRatesData() as any) as any;
  }, [dispatch, resetRatesData]);

  const title = `Compare Rates: ${program}`;

  const handleSelectRate = (rateData) => {
    const { id, disablePrice, ...newPriceData } = rateData;
    setSelectedRateData(newPriceData);
    setSelectedPriceId(id);
    setdisableNewPrice(disablePrice);
  };

  const onBack = () => {
    setSelectedRateData(null);
    setSelectedPriceId('');
    handleBack();
  };

  const onApply = () => {
    dispatch(openLoader('Loading'));
    priceUpdate(selectedRateData, loanInfo);
    dispatch(getRateSelectorAction() as any);
    dispatch(closeLoader());
    onBack();
  };

  const backarrow = (
    <DSButton
      buttonType="link"
      containerProps={{ 'data-testid': 'back-id' }}
      icon={<ChevronLeft />}
      onClick={onBack}
    />
  );
  return (
    <StyledEppsParentView>
      <StyledEppsContainerBody>
        <StyledEppsWrapper>
          <LockConfirmWrapperStyled>
            <DSPageHeaderV2
              backarrow={backarrow}
              breadcrumbs={<div>Lock and Confirm Pricing</div>}
              pageTitle={<PageHeaderTitle>{title}</PageHeaderTitle>}
              containerProps={{ style: { marginTop: '5px' } }}
            />
            <LoanDetails isCompareRatesView />
            <DSSeparator
              position="initial"
              type="non-form"
              style={{ marginTop: 0 }}
            />
            <DataProvider value={selectedPriceId}>
              <RatesContainer onSelect={handleSelectRate} />
            </DataProvider>
          </LockConfirmWrapperStyled>
        </StyledEppsWrapper>
      </StyledEppsContainerBody>
      <FooterButtons
        buttons={[
          { type: `secondary`, text: `Back`, onClick: onBack },
          {
            type: `primary`,
            text: `Apply New Price`,
            onClick: onApply,
            disabled: disableNewPrice,
          },
        ]}
      />
    </StyledEppsParentView>
  );
};

CompareRates.propTypes = {
  handleBack: PropTypes.func.isRequired,
};
