import { DSPageHeaderV2, PageHeaderTitle } from '@elliemae/ds-page-header';
import { useMemo, useState } from 'react';
import Session, {
  ALL_SUMMARY_PARAMS,
  IS_VALIDATE,
  ORIGIN_TRANSACTION_TYPE,
} from '../../../../common/services/Session';
import { isEmpty } from '../../../../common/utils/shared';
import { useRateSelectorLien } from '../../../../data/useSelectors';
import { ProgramDetailsModal } from '../../../LockSummary/components/ProgramDetailsModal';
import { LoanDetails } from '../LoanSummaryDetails';
import { ProgramDetailsButton } from './ProgramDetailsButton';
import Styled from './styled';

export const SummaryHeader = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { programName, userLender, note, programId } = useRateSelectorLien(
    ['programName', 'userLender', 'note', 'programId'],
    '',
  ) as any;
  const loanProgramName = `${userLender || ''} ${programName || ''}`;
  const programInfo = {
    notes: note?.join('<br/><li>'),
    program: loanProgramName,
    programID: programId,
  };
  const isValidate = Session.get(IS_VALIDATE);
  const transactionType = Session.get(ORIGIN_TRANSACTION_TYPE);

  const statusTitle = useMemo(() => {
    const userWentToSearchResult = !isEmpty(Session.get(ALL_SUMMARY_PARAMS));
    let title = 'New Lock Request';
    if (isValidate) {
      if (transactionType === 'validatelock') title = 'Validate';
      else title = 'Accept Lock Update';
      if (userWentToSearchResult) {
        title = 'Lock Update Request';
      }
    }
    return title;
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  const title = loanProgramName && (
    <PageHeaderTitle>{`${statusTitle}: ${loanProgramName}`}</PageHeaderTitle>
  );
  const toolbar = <ProgramDetailsButton onClick={openModal} />;

  return (
    <Styled>
      <DSPageHeaderV2 pageTitle={title} toolbar={toolbar} />
      <LoanDetails />
      {isModalOpen && (
        <ProgramDetailsModal
          isOpen
          toggleModalSlide={toggleModal}
          programInformation={programInfo}
        />
      )}
    </Styled>
  );
};
