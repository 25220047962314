import { CellWithAddons } from '@elliemae/ds-datagrids';
import { safeSum } from '@frontend/pricing-search';
import Common from '../../../../common/services/Common';
import Rights from '../../../../common/services/Rights';
import { formatAprValue } from '../../../../common/utils/shared';
import { generateAdjustments } from '../../../components/PricingGrid/helpers';

const customStyles = {
  textAlign: 'right',
  maxHeight: '36px',
  height: '100%',
  width: 'calc(100% + 1.23076rem)',
  marginLeft: '-0.61538rem',
  marginRight: '-0.61538rem',
  paddingLeft: '0.61538rem',
  paddingRight: '0.61538rem',
  background: '#EBF6FF',
  fontWeight: 700,
  fontSize: 14,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  borderTop: 'solid 1px #1394E5',
};

const DivTextUpper = (name) => (
  <div
    style={{
      fontWeight: 700,
      fontSize: 13,
    }}
  >
    {name}
  </div>
);

const DivTextRight = (data) => (
  <div style={{ width: '100%', textAlign: 'right' }}>{data}</div>
);

export const adjustmentColumns = [
  {
    property: 'name',
    label: 'Name',
    visible: true,
    width: '70%',
    midWidth: 0,

    customRenderer: ({ metaData: { rowData, rowIndex } }) => {
      if (rowIndex === 0) {
        return (
          <CellWithAddons
            badgeAddon={false}
            value={DivTextUpper(rowData.name)}
          />
        );
      }
      if (rowData.title) {
        return DivTextUpper(rowData.name);
      }
      return <div style={{ paddingLeft: `16px` }}>{rowData.name}</div>;
    },
  },
  {
    property: 'margin',
    label: 'Margin',
    visible: true,
    width: '30%',
    midWidth: 0,
    header: {
      customRenderer: () => DivTextRight('Margin'),
    },
    customRenderer: ({ metaData: { rowData, rowIndex } }) => {
      if (rowIndex === 0) {
        return (
          <div
            style={{
              ...customStyles,
              background: '#fff',
              borderBottom: `.07692rem solid #ebedf0`,
              borderTop: `0`,
            }}
          >
            {rowData.margin}
          </div>
        );
      }
      if (rowData.title) {
        return DivTextUpper(rowData.margin);
      }
      return DivTextRight(rowData.margin);
    },
  },
];

export const transformRateSelectorDataToSlide = (
  adjustments,
  armIndexValue,
) => {
  const roles = Common.getUserRoles() || [];
  const viewLOCompRole = 20;
  const hasLOCompViewRight =
    Array.isArray(roles) &&
    roles.some(({ roleId }) => Number(roleId) === viewLOCompRole);

  let parseAdjustmentsRows = adjustments.map((adj) => ({
    ...adj,
    adjustmentType: adj.adjustmentType || adj.typeDescription,
    typeDescription: adj.adjustmentType || adj.typeDescription,
  }));
  const pricingRows = generateAdjustments(parseAdjustmentsRows);

  if (!Rights.viewProfitMargin) {
    parseAdjustmentsRows =
      adjustments &&
      adjustments.filter((adjustment) => {
        return adjustment.priceAdjustmentType !== 'ProfitMargin';
      });
  }
  if (!hasLOCompViewRight) {
    parseAdjustmentsRows =
      parseAdjustmentsRows &&
      parseAdjustmentsRows.filter(
        (adjustment) => adjustment.description !== 'L.O. Compensation',
      );
  }
  let newIdAdjustment = 3;

  const finalPrice = parseAdjustmentsRows.find(
    ({ adjustmentType }) => adjustmentType === 'Final',
  );
  const basePrice = parseAdjustmentsRows.find(
    ({ adjustmentType }) => adjustmentType === 'Base',
  );
  const adjustmentRows = [
    {
      id: 1,
      name: 'Final Margin Adjustment',
      margin: formatAprValue(safeSum(finalPrice?.margin, armIndexValue)),
    },
    {
      id: 2,
      name: 'Index',
      margin: `${Number(armIndexValue || 0).toFixed(3)}%`,
    },
    {
      id: 3,
      name: 'Margin',
      margin: formatAprValue(basePrice?.margin),
    },
  ];
  parseAdjustmentsRows.forEach((item) => {
    const { margin, description, adjustmentType } = item;
    if (
      typeof margin === 'number' &&
      !['Base', 'Final', 'AllIn'].includes(adjustmentType)
    ) {
      newIdAdjustment += 1;
      const percentMargin = formatAprValue(margin);
      const newMarginRow = {
        id: newIdAdjustment,
        name: description,
        margin: percentMargin,
      };
      adjustmentRows.push(newMarginRow);
    }
  });

  return {
    pricingRows,
    adjustmentRows,
  };
};
