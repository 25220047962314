import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DSDataGrid } from '@elliemae/ds-datagrids';
import { SIZE_SMALL, SIZE_LARGE } from './helpers';
import { StyledRateColumn } from '../styled';

const columns = [
  {
    property: 'rate',
    label: 'Rate',
    width: 80,
    customRenderer: (data) => {
      const { rate } = data.metaData.rowData;
      const value = !rate || Number.isNaN(rate) ? '--' : `${rate.toFixed(3)}%`;
      return <strong>{value}</strong>;
    },
  },
];

export const allRatesSelector = ({ epps }) =>
  (epps && epps.lockConfirm.ratesData) || [];

export const RateColumn = ({ isExpanded }) => {
  const allRates = useSelector(allRatesSelector);

  return (
    <StyledRateColumn
      style={{ paddingTop: isExpanded ? SIZE_LARGE : SIZE_SMALL }}
    >
      <DSDataGrid
        columns={columns}
        rows={allRates.map(({ rate, rateDataID }) => ({
          id: rateDataID,
          rate,
        }))}
        noResultsPlaceholder=""
      />
    </StyledRateColumn>
  );
};

RateColumn.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
};
