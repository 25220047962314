import { DSButton } from '@elliemae/ds-button';
import { DSFilterBar } from '@elliemae/ds-filterbar';
import { DSToolbar } from '@elliemae/ds-toolbar';
import { useLookupOptions } from '@frontend/pricing-search';
import { func, number, shape } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Session, {
  ALL_SUMMARY_PARAMS,
} from '../../../../../../common/services/Session';
import { PATH_LOCK_SUMMARY } from '../../../../../../route/constants';
import { QuoteInsuranceButtonWrapper } from '../../../QuoteInsuranceButton';
import { getLockPeriod } from './helpers';
import { StyledAllRatesToolbar } from './styled';

export const AllRatesToolBar = ({
  lockPeriodValue,
  onLockPeriodChange,
  selectedProduct,
  ratesData,
  loanId,
}) => {
  const navigate = useNavigate();
  const lockdays = useLookupOptions('lockdays');
  const dispatch = useDispatch();

  const lockPeriod = getLockPeriod(
    lockPeriodValue,
    lockdays,
    onLockPeriodChange,
    ratesData.locksAvailable,
  ) as any;

  const handleSelectProduct = () => {
    Session.set(ALL_SUMMARY_PARAMS, {
      loanId,
      programId: ratesData.programID,
      rateDataId: selectedProduct.rateDataID,
    });
    navigate(PATH_LOCK_SUMMARY);
  };

  return (
    <StyledAllRatesToolbar>
      <DSToolbar
        withDepth={false}
        style={{ justifyContent: 'space-between', marginTop: '16px' }}
        containerProps={{ id: 'toolbar-all-rates-view' }}
      >
        <DSFilterBar filters={[lockPeriod]} renderToolbar={() => null} />
        <div style={{ display: 'flex' }}>
          <QuoteInsuranceButtonWrapper />
          <DSButton
            buttonType="primary"
            labelText="Select Product"
            onClick={handleSelectProduct}
            disabled={!selectedProduct}
          />
        </div>
      </DSToolbar>
    </StyledAllRatesToolbar>
  );
};

AllRatesToolBar.propTypes = {
  lockPeriodValue: number.isRequired,
  onLockPeriodChange: func.isRequired,
  selectedProduct: shape({}).isRequired,
  ratesData: shape({
    programId: number,
    rateDateId: number,
  }).isRequired,
  loanId: number.isRequired,
};
