import { number } from 'prop-types';
import React, { useState, useEffect } from 'react';

export const Countdown = ({ milliseconds }) => {
  const [messageNumber, setNum] = useState(milliseconds);

  useEffect(() => {
    const myInterval = setInterval(() => setNum((prev) => prev - 1000), 1000);
    return () => clearInterval(myInterval);
  }, []);

  return (
    <div>
      Lock Desk Closing in{' '}
      {Math.floor((messageNumber % (1000 * 60 * 60)) / (1000 * 60))}:
      {Math.floor((messageNumber % (1000 * 60)) / 1000) <= 9 ? 0 : null}
      {Math.floor((messageNumber % (1000 * 60)) / 1000)}
    </div>
  );
};

Countdown.propTypes = {
  milliseconds: number.isRequired,
};
