import { BANNER_TYPES, DSBanner } from '@elliemae/ds-banner';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setRequestLockButtonStatus } from '../../../../../../../data/lockDeskStatus/actionCreators';
import {
  useLoanInformationField,
  useLockDeskStatusActionsSelector,
} from '../../../../../../../data/useSelectors';
import { WARNING_TEXT } from '../../utils/constants';
import { addThousandsSeparator } from '../../utils/helpers';

export const OnrpLimitNotificationHeader = () => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const { onrpNotificationInfo = {} } = useLockDeskStatusActionsSelector();
  const firstMortgageAmount = useLoanInformationField(
    'firstMortgageAmount',
    0,
  ) as number;

  useEffect(() => {
    if (onrpNotificationInfo.isUnderOnrp) {
      const isGreaterLoanAmount =
        firstMortgageAmount > onrpNotificationInfo?.remainingAmountUnderOnrp;
      setOpen(isGreaterLoanAmount);
      dispatch(setRequestLockButtonStatus(isGreaterLoanAmount) as any);
    }
  }, [onrpNotificationInfo, firstMortgageAmount]);

  return (
    <DSBanner
      body={`The amount for this loan is $${addThousandsSeparator(
        firstMortgageAmount,
        ',',
      )}, which is greater than the remaining Overnight Rate Protection amount of $${addThousandsSeparator(
        onrpNotificationInfo?.remainingAmountUnderOnrp,
        ',',
      )}.`}
      isOpen={isOpen}
      label={WARNING_TEXT}
      onClose={() => setOpen(false)}
      type={BANNER_TYPES.WARNING}
      data-testid="OnrpLimitNotificationHeader-all-components"
    />
  );
};
