import { getObject } from '@elliemae/em-ssf-guest';
import { pause } from '@frontend/utils';
import { getApiUrl, getLoancatcherEnvTarget } from '../utils/api';
import createFetch from '../utils/createFetch';
import { runFetch, runFetchJson } from '../utils/runFetch';
import Common from './Common';
import Session from './Session';

// Keep this in sync with the types allowed from
// docs/product_manifest.json
export type TransactionType =
  | 'SUBMIT_LOAN'
  | 'FLOAT'
  | 'LOCK'
  | 'RELOCK'
  | 'CANCELLOCK'
  | 'DENYLOCK'
  | 'UPDATE_CONFIRM'
  | 'LOCK_CONFIRM'
  | 'PRICING_RATE_SEARCH'
  | 'PRODUCT_RATE_SELECTOR'
  | 'PRODUCT_ELIGIBILITY'
  | 'PRODUCT_ADJUSTMENTS'
  | 'EXTEND'
  | 'RELOCK_CONFIRM';

// TODO: wire up this create request with auto-gen code from EPC schema API
// Then we can get full hard types on all the individual request params
// Keep it as a hard requirement, before transitioning partner ui to EPC schema API
export interface CreateTransactionRequest {
  request: {
    type: TransactionType;
    options: object;
  };
}

export interface UpdateTransactionRequest {
  transactionID: string;
  options: object;
}

const createTransaction = async (
  transactionRequest: CreateTransactionRequest,
) => {
  let result: string | null = null;
  try {
    const transactionObject = await getObject('transaction');
    const transactionData = await transactionObject.create(transactionRequest);
    result = transactionData.id;
  } catch (err) {
    console.error(err);
  }
  return result as string;
};

const getTransactionStatus = async (transactionId: string) => {
  const response = await runFetchJson<{
    status: 'processing' | 'completed';
    summary?: 'Conflict';
    details?: string;
  }>(async () =>
    createFetch(
      `${getApiUrl()}/epc/transactions/${transactionId}`,
      {
        headers: {
          'X-EPC-Transaction-Uuid': await Common.getOriginId(),
          'X-Elli-PAT': await Common.getPATToken(),
          'X-PPE-Env': getLoancatcherEnvTarget(),
        },
      },
      {},
      {
        method: 'GET',
      },
      [],
      true,
    ),
  );

  return response;
};

const updateTransaction = async (request: UpdateTransactionRequest) => {
  return runFetch(async () =>
    createFetch(
      `${getApiUrl()}/epc/transactions/${request.transactionID}`,
      {
        headers: {
          'X-EPC-Transaction-Uuid': await Common.getOriginId(),
          'X-Elli-PAT': await Common.getPATToken(),
          'X-PPE-Env': getLoancatcherEnvTarget(),
        },
        body: JSON.stringify(request),
      },
      {},
      { method: 'PATCH' },
      [],
      true,
    ),
  );
};

const updatePollTransaction = async (request: UpdateTransactionRequest) => {
  // TODO: set default for all non-hosted environments to 'Webooks and Application Patch'
  // As soon as we fix the env file config thing
  const updateMode =
    (Session.get('EPC_UPDATE_MODE') as
      | null
      | 'Webooks Only'
      | 'Webhooks and Application Patch') ?? 'Webooks Only';

  if (updateMode === 'Webhooks and Application Patch') {
    await updateTransaction(request);
  }

  const { transactionID } = request;

  try {
    const timeoutInSeconds = 30;
    for (let i = 0; i < timeoutInSeconds; i += 1) {
      /* eslint-disable no-await-in-loop */
      await pause(2000);

      const response = await getTransactionStatus(transactionID);
      if (response.summary === 'Conflict') {
        throw new Error(`${response.summary}: ${response.details}`);
      }
      if (response.status === 'completed') {
        return;
      }
    }

    throw new Error('Unable to verify transaction status');
  } catch (err) {
    throw new Error(err?.toString());
  }
};

export const epcService = {
  createTransaction,
  updatePollTransaction,
};
