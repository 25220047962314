import { DSInputText } from '@elliemae/ds-form-input-text';
import { DSFormLayoutBlockItem } from '@elliemae/ds-form-layout-blocks';
import { Grid } from '@elliemae/ds-grid';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { formatDate } from '../../common/services/helpers';

export const CurrentLockDetails = ({ lockDetails }) => {
  const lockCards = useMemo(() => {
    const allProperties = {
      rateSheetId: {
        label: 'Rate Sheet ID',
        value: lockDetails?.rateSheetID || ' ',
      },
      originalLockRequest: {
        lockDate: {
          label: 'Lock Date',
          value:
            formatDate(
              lockDetails?.originalLockRequest?.requestedDate,
              'Calendar',
            ) || '',
        },
        numDays: {
          label: 'Lock # Days',
          value: lockDetails?.originalLockRequest?.numberOfDays,
        },
        expiresDate: {
          label: 'Lock Expiration Date',
          value:
            formatDate(
              lockDetails?.originalLockRequest?.expiresDate,
              'Calendar',
            ) || '',
        },
      },
      currentLockRequest: {
        lockDate: {
          label: 'Lock Date',
          value:
            formatDate(
              lockDetails?.currentLockRequest?.requestedDate,
              'Calendar',
            ) || '',
        },
        numDays: {
          label: 'Lock # Days',
          value: lockDetails?.currentLockRequest?.numberOfDays,
        },
        expiresDate: {
          label: 'Lock Expiration Date',
          value:
            formatDate(
              lockDetails?.currentLockRequest?.expiresDate,
              'Calendar',
            ) || '',
        },
      },
    };

    const properties: any[] = [];

    properties.push({
      description: lockDetails.currentLockRequest
        ? 'Original Lock'
        : 'Rate Lock',
      properties: [
        allProperties.rateSheetId,
        allProperties.originalLockRequest.lockDate,
        allProperties.originalLockRequest.numDays,
        allProperties.originalLockRequest.expiresDate,
      ],
    });

    if (lockDetails.currentLockRequest) {
      properties.push({
        description: 'Current Lock',
        properties: [
          {},
          allProperties.currentLockRequest.lockDate,
          allProperties.currentLockRequest.numDays,
          allProperties.currentLockRequest.expiresDate,
        ],
      });
    }

    return properties;
  }, [lockDetails]);

  return (
    lockCards && (
      <>
        <div>
          <h3>Lock Details</h3>
        </div>

        {lockCards.map(({ description, properties }) => {
          return (
            <Grid>
              <div>
                <h4>{description}</h4>
              </div>

              <Grid
                cols={{
                  small: ['auto'],
                  medium: ['auto', 'auto'],
                  large: [`repeat(${properties?.length}, auto)`],
                }}
                justifyContent="start"
              >
                {properties?.map(({ label, value }) => (
                  <Grid mr="s" visibility={label ? 'visible' : 'hidden'}>
                    <DSFormLayoutBlockItem
                      key={(label as string) + (value as string)}
                      fitContent
                      label={label}
                      inputID="readonly"
                    >
                      <DSInputText id="readonly" value={value} readOnly />
                    </DSFormLayoutBlockItem>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          );
        })}
      </>
    )
  );
};

CurrentLockDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lockDetails: PropTypes.object.isRequired,
};
