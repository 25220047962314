export const SPECIAL_PRODUCTS = [
  {
    path: '',
    values: ['specialProducts', 'standardProducts'],
  },
];

export const STANDAR_PRODUCTS = [
  {
    path: '',
    values: ['standardProducts', 'specialProducts'],
  },
];

export const TARGET_LABEL = [
  {
    path: 'loanInformation',
    values: ['targetLabel'],
  },
];

export const TARGET_VALUE = [
  {
    path: 'loanInformation.',
    values: ['target'],
  },
];

export const BUYDOWN_TYPE = [
  {
    path: '',
    values: ['buydownType'],
  },
];

export const CUSTOM_TERM = [
  {
    path: 'loanInformation',
    values: ['term'],
  },
];

export const LOAN_TERM = [
  {
    path: '',
    values: ['productType', 'termRange'],
  },
];
