import { openGlobalErrorModalAction } from '../../data/globalErrorModal/actionCreators';
import browserHistory from '../../common/utils/browserHistory';
import { PATH_LOGIN } from '../../route/constants';
import { openLoader, closeLoader } from '../../data/screenLoader/actions';
import Session, { USER_TYPE } from '../../common/services/Session';
import Common from '../../common/services/Common';
import AdjustmentDetailsService from './AdjustmentDetailsService';

export const getAdjustmentDetails =
  ({ loanId, programId, rateDataId, lienPosition, eppsUserName }) =>
  async (dispatch) => {
    dispatch(openLoader('Loading'));
    try {
      const results = (await AdjustmentDetailsService.getAdjustmentDetails(
        loanId,
        lienPosition,
        programId,
        rateDataId,
        eppsUserName,
      )) as any;
      if (results && results.code !== undefined) {
        // if JWT is expired refresh the jwt
        if (results.code === 'EPPS2002') {
          const user = Session.get(USER_TYPE);
          if (user && user.userType === 'StandAlone') {
            browserHistory.push(PATH_LOGIN);
          } else {
            dispatch(
              openGlobalErrorModalAction({
                onCloseCallback: () => Common.closeApplication(),
              }),
            );
          }
        } else if (results.code === 'EPPS1000') {
          dispatch(
            openGlobalErrorModalAction({
              errorMessage: results.summary,
            }),
          );
        }
      } else return results;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      dispatch(
        openGlobalErrorModalAction({
          onCloseCallback: () => Common.closeApplication(),
        }),
      );
    } finally {
      dispatch(closeLoader());
    }
    return null;
  };
