import styled from 'styled-components';

export const StyledFooterButtonsContainer = styled.div`
  flex: 0 0 auto;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid rgb(204, 204, 204);
  right: 0.625rem;
  left: 0;
  bottom: 0;
  position: fixed;
  background-color: white;
  }
`;

export const StyledFooterExtraButton = styled.div`
height: 28.8px;
opacity: 0.5;
}
`;
