/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import {
  isVAValidation,
  validateErrors,
  isGovieValidation,
  isHELOCValidation,
  isIRRRLValidation,
  isRefinanceTypeValidation,
} from './utils/helpers';

const key = 'searchForm';

export const initialState = {
  initialValues: {},
  errorForm: {
    errorFields: [],
    errorFilters: [],
    bannerIsOpen: false,
  },
  formValidations: {
    isVA: false,
    isIRRRL: false,
    isGovie: false,
    isHELOC: false,
    isRefinance: false,
  },
};

const slice = createSlice({
  name: key,
  initialState,
  reducers: {
    setBannerIsOpen: (state, { payload }) => {
      state.errorForm.bannerIsOpen = payload;
    },
    setErrorFilters: (state, { payload }) => {
      state.errorForm.errorFilters = payload;
    },
    setInitialValues: (state, { payload }) => {
      state.initialValues = payload;
    },
    setFormValidations: (state, { payload }) => {
      state.formValidations = payload;
    },
    setIsVA: (state, { payload }) => {
      state.formValidations.isVA = isVAValidation(payload);
    },
    setIsIRRRL: (state, { payload }) => {
      state.formValidations.isIRRRL = isIRRRLValidation(payload);
    },
    setIsGovie: (state, { payload }) => {
      state.formValidations.isGovie = isGovieValidation(payload);
    },
    setIsHELOC: (state, { payload }) => {
      state.formValidations.isHELOC = isHELOCValidation(payload);
    },
    setIsRefinance: (state, { payload }) => {
      state.formValidations.isRefinance = isRefinanceTypeValidation(payload);
    },
    setFormHasError: (state, { payload }) => {
      const { name, hasError } = payload;
      state.errorForm.errorFields = validateErrors(
        state.errorForm.errorFields,
        name,
        hasError,
      );
    },
  },
});

export const searchFormActions = {
  ...slice.actions,
  getFormValidationsAction: createAction(`${key}/getFormValidationsAction`),
};

export default slice.reducer;
