import { DSButton } from '@elliemae/ds-button';
import { ModalFooter, ModalHeader } from '@elliemae/ds-modal-slide';
import cloneDeep from 'lodash/cloneDeep';
import { func, string } from 'prop-types';
import { Fragment, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Session, { IS_VALIDATE } from '../../../../common/services/Session';
import { setValidateSubmitButtonEnabledStatus } from '../../../../data/lockConfirm/actions';
import { updateBuySideAdjustmentsAction } from '../../../../data/origin/actionCreators';
import { ModalSlide } from '../../ModalSlide';
import { loanBuySideAdjustmentsSelector } from '../selectors';
import { AdjustmentCard } from './AdjustmentCard';
import { ConcessionCard } from './ConcessionCard';
import { EmptyCard } from './EmptyCard';
import { CARDS_LIMIT, CARDS_TEMPLATES, CARDS_TITLES } from './constants';
import {
  checkCardsType,
  normalizeAdjustmentsData,
  normalizeCardsData,
  validateRequiredInputs,
} from './helpers';

export const CARDS_COMPONENTS = {
  adjustments: AdjustmentCard,
  concessions: ConcessionCard,
};

export const SlidePriceCards = memo<any>(({ setOpen, open: type }) => {
  const dispatch = useDispatch();
  const cardSettings = useSelector(
    ({ epps: { lockSummary } }) => lockSummary.adjustmentsSettings,
  );

  const cardsType = checkCardsType(type);
  // TODO:talk with backend about matching encompassSettings properties with adjustments types
  const cardSetting =
    cardsType === 'concessions'
      ? cardSettings.priceConcessions
      : cardSettings[`${type[0].toLowerCase()}${type.slice(1)}`];

  const emptyTemplate = {
    ...CARDS_TEMPLATES[cardsType],
    adjustmentType: type,
    persist: true,
    persistDisabled: !cardSetting,
  };

  const variations = useSelector(loanBuySideAdjustmentsSelector(type));
  const initialState =
    variations.length > 0
      ? normalizeAdjustmentsData(variations)
      : [emptyTemplate];
  const [rowCards, setRowCards] = useState(initialState);
  const [validationErrors, setValidationErrors] = useState<any[] | any>([]);

  const reachedLimit = rowCards?.length === CARDS_LIMIT[cardsType];

  const handleAddCard = () => {
    setRowCards((prevCards) => [...prevCards, { ...emptyTemplate }]);
  };

  const closeModal = () => {
    setOpen('');
  };

  const handleDeleteRow = (index) => {
    const newRows = cloneDeep(rowCards);
    newRows.splice(index, 1);
    setRowCards(newRows);
    const errors = validationErrors.filter((el, idx) => idx !== index) as any[];
    setValidationErrors(errors);
  };

  const handleChange = (value, field, index) => {
    const newRows = cloneDeep(rowCards);
    newRows[index][field] = value;
    setRowCards(newRows);
  };

  const handleConfirm = () => {
    let newRowCards;
    const isValidate = Session.get(IS_VALIDATE);
    const hasChanges = rowCards.length !== 0;
    let dispatchBuySideAdjFlag = true;
    if (hasChanges) {
      const { hasError, errors } = validateRequiredInputs(rowCards, cardsType);
      if (hasError) {
        dispatchBuySideAdjFlag = false;
        setValidationErrors(errors);
      } else newRowCards = normalizeCardsData(rowCards);
    } else newRowCards = rowCards;

    if (dispatchBuySideAdjFlag) {
      dispatch(updateBuySideAdjustmentsAction(newRowCards, type));
      if (isValidate) dispatch(setValidateSubmitButtonEnabledStatus(true));
      closeModal();
    }
  };

  const CardComponent = CARDS_COMPONENTS[cardsType];

  return (
    <ModalSlide
      header={<ModalHeader title={CARDS_TITLES[type]} onClose={closeModal} />}
      footer={
        <ModalFooter
          onConfirm={handleConfirm}
          onReject={closeModal}
          confirmLabel="Apply"
        />
      }
      data-testid="SlidePriceCards-all-components"
      isOpen
    >
      {rowCards?.length === 0 ? (
        <EmptyCard handleAddCard={handleAddCard} />
      ) : (
        <>
          {rowCards?.map((row, index) => (
            <CardComponent
              errors={validationErrors[index]}
              rowCard={row}
              index={index}
              title={CARDS_TITLES[type]}
              onDeleteRow={handleDeleteRow}
              onChange={handleChange}
            />
          ))}
          <div style={{ margin: '16px' }}>
            <DSButton
              buttonType="text"
              labelText="Add Another"
              disabled={reachedLimit}
              onClick={handleAddCard}
            />
          </div>
        </>
      )}
    </ModalSlide>
  );
});

(SlidePriceCards as any).defaultProps = {
  open: '',
};

(SlidePriceCards as any).propTypes = {
  setOpen: func.isRequired,
  open: string,
};
