import React, { Fragment } from 'react';
import { DSTextWrapper } from '@elliemae/ds-text-wrapper';
import { useRateSelectorLien } from '../../../../data/useSelectors';
import { BonaFideDiscountWrapper } from './styled';

export const BonaFideDiscountContainer = () => {
  const apor = useRateSelectorLien('apor');
  const startingAdjustedRate = useRateSelectorLien(
    'startingAdjustedRate',
  ) as number;
  const startingAdjustedPrice = useRateSelectorLien(
    'startingAdjustedPrice',
  ) as number;

  return (
    <BonaFideDiscountWrapper>
      {typeof apor === 'number' && (
        <>
          {' '}
          <DSTextWrapper
            text="Bona Fide Discount Point Assessment"
            className="bona-fide-heading"
          />
          <div data-testid="BonaFideDiscountContainer-all-components">
            <ul>
              <li>The APOR is {apor.toFixed(3)}%.</li>
              <li>
                {`The Starting Adjusted Rate is ${startingAdjustedRate.toFixed(
                  3,
                )}
            %. The Starting Adjusted Price is ${(
              100 - startingAdjustedPrice
            ).toFixed(3)}.`}
              </li>
              <li>
                The APOR Discount Point Exclusion Test was not run as pricing is
                equal to or above Par.
              </li>
              <li>
                The Rate Reduction Discount Point Exclusion Test was not run as
                pricing is equal to or above Par.
              </li>
            </ul>
          </div>
        </>
      )}
    </BonaFideDiscountWrapper>
  );
};
