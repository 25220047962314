/* eslint-disable react-hooks/rules-of-hooks */
import { BUTTON_VARIANT } from '@elliemae/ds-button';
import { useLoanDataFieldSelectorHook } from '@frontend/pricing-search';
import Session, {
  ALL_SUMMARY_PARAMS,
  IS_VALIDATE,
  ORIGIN_TRANSACTION_TYPE,
} from '../../../../common/services/Session';
import { isEmpty } from '../../../../common/utils/shared';

export const getPrimaryButtonText = () => {
  const noNewProduct = isEmpty(Session.get(ALL_SUMMARY_PARAMS));
  const loanData = useLoanDataFieldSelectorHook() as any;
  const { requestAction, requestLockStatus, requestLockType } = loanData;
  const isRevise =
    requestAction === 4 && requestLockStatus === 2 && requestLockType === 1;
  if (Session.get(IS_VALIDATE)) {
    return Session.get(ORIGIN_TRANSACTION_TYPE) === 'validatelock' &&
      noNewProduct
      ? 'Update Lock'
      : 'Accept Lock Update';
  }
  if (isRevise) {
    return 'Update Lock';
  }
  return 'Accept New Lock';
};

export const getFooterButtons = (buttonsProps) => {
  const buttons = [
    {
      id: 'deny',
      type: 'secondary',
      text: 'Deny Lock Request',
      variant: BUTTON_VARIANT.DEFAULT,
      disabled: false,
    },
    {
      id: 'denyActiveLock',
      type: 'secondary',
      text: 'Deny Lock',
      variant: BUTTON_VARIANT.DEFAULT,
      disabled: false,
    },
    {
      id: 'cancel',
      type: 'secondary',
      text: 'Cancel Lock',
      variant: BUTTON_VARIANT.DEFAULT,
      disabled: false,
    },
    {
      id: 'lockConfirm',
      type: 'primary',
      text: getPrimaryButtonText(),
      variant: BUTTON_VARIANT.DEFAULT,
      disabled: buttonsProps.lockConfirm?.disabled,
    },
  ];

  const result = buttons.reduce((acum, elem) => {
    const elemButton = { ...elem, onClick: buttonsProps[elem.id].handler };
    return [...acum, ...(buttonsProps[elem.id].show ? [elemButton] : [])];
  }, [] as any[]);
  return result;
};
