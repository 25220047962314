function sortDelta(a, b) {
  // first check error state
  if (a.deleted !== 0 && b.deleted !== 0) return a.deleted - b.deleted;
  if (a.deleted !== 0) return 1;
  if (b.deleted !== 0) return -1;
  // both programs have pricing at or above target
  if (a.delta < 50 && b.delta < 50) {
    if (a.tSecondary === b.tSecondary) return a.tPrimary - b.tPrimary;
    return a.tSecondary - b.tSecondary;
  }
  // only program a has target pricing
  if (a.delta < 50) return -1;
  // only program b has target pricing
  if (b.delta < 50) return 1;
  // both programs are below target
  if (a.tPrimary === b.tPrimary) return a.tSecondary - b.tSecondary;
  return a.tPrimary - b.tPrimary;
}

function calcDeltas(aProgs, target) {
  // get the best delta for each rate
  const hRate = {};

  const t = target.value;
  let od;
  let r;
  let prim: number;
  let sec;
  aProgs.forEach((prog) => {
    const aRates = prog.rateDetails;
    if (aRates) {
      const l = aRates.length;
      for (let i = 0; i < l; i += 1) {
        r = aRates[i];
        od = hRate[prog.programID];
        od = od === undefined ? 999 : od.delta;
        prim = target.isTargetPrice ? r.price : r.rate;
        sec = target.isTargetPrice ? r.rate : r.price;
        if (t - prim >= 0) r.delta = sec;
        else r.delta = prim + 100;
        if (r.delta < od) {
          // hDelta.set(r.ProgramID, r.delta);
          // hDelta.set(r.ProgramID, r.delta);
          hRate[prog.programID] = r;
        }
      }
    }
  });

  // update the programs w/ deltas
  const l = aProgs.length;
  let p;
  for (let i = 0; i < l; i += 1) {
    p = aProgs[i];
    const d = hRate[p.programID];
    if (d) {
      p.delta = d.delta;
      p.target = d;
      d.discountPoints = d.price * -1;
      Object.assign(p, d);
      if (target.isTargetPrice) {
        p.tPrimary = d.price;
        p.tSecondary = d.rate;
      } else {
        p.tPrimary = d.rate;
        p.tSecondary = d.price;
      }
    } else p.delta = 999;
  }

  // sort programs array
  return aProgs.sort(sortDelta);
}

function calcDeltasBestEx(aGroups, aRatesBX, target) {
  // get the best delta for each rate
  const hRate = {};
  const l = aRatesBX.length;
  const t = target.value;
  let od;
  let r;
  let prim: number;
  let sec;
  for (let i = 0; i < l; i += 1) {
    r = aRatesBX[i];
    od = hRate[r.productGroupID];
    od = od === undefined ? 999 : od.delta;
    prim = target.isTargetPrice ? r.price : r.rate;
    sec = target.isTargetPrice ? r.rate : r.price;
    if (t - prim >= 0) r.delta = sec;
    else r.delta = prim + 100;
    if (r.delta < od) {
      hRate[r.productGroupID] = r;
    }
  }
  // update the product groups w/ deltas
  aGroups.forEach((g) => {
    const group = g; // Reference to g
    r = hRate[g.productGroupID];
    if (r) {
      group.delta = r.delta;
      group.target = r;
      if (target.isTargetPrice) {
        group.tPrimary = r.price;
        group.tSecondary = r.rate;
      } else {
        group.tPrimary = r.rate;
        group.tSecondary = r.price;
      }
    } else group.delta = 999;
    // index the rates for expanding/collapsing
    if (group.target !== undefined) {
      for (let i = 0; i < group.rateDetails.length; i += 1) {
        group.rateDetails[i].indexRow = i;
      }
    }
    // if (group.target !== undefined) {
    //   for (let i = 0; i < group.rateDetails.length; i += 1) {
    //     if (group.rateDetails[i].rate === group.target.Rate) {
    //       group.targetIndex = i;
    //       break;
    //     }
    //   }
    //   for (let i = 0; i < group.rateDetails.length; i += 1) {
    //     if (i === group.targetIndex) group.rateDetails[i].expandLevel = 1;
    //     else if (Math.abs(group.targetIndex - i) <= 3)
    //       group.rateDetails[i].expandLevel = 2;
    //     else group.rateDetails[i].expandLevel = 3;
    //   }
    // }
  });
  return aGroups;
}

export { sortDelta, calcDeltas, calcDeltasBestEx };
