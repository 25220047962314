export enum EpcLoanActionTypes {
  Register = 'TpoRegister',
  ImportAdditionalData = 'TpoImportAdditionalData',
  Submit = 'TpoSubmit',
  Resubmit = 'TpoResubmit',
  RequestTitleFees = 'TpoRequestTitleFees',
  OrderAus = 'TpoOrderAus',
  OrderReIssueCredit = 'TpoOrderReissueCredit',
  Disclosures = 'TpoDisclosures',
  LockRequest = 'TpoLockRequest',
  FloatLock = 'TpoFloatLock',
  ChangedCircumstance = 'TpoChangedCircumstance',
  RunLPUnderwriting = 'TpoRunLP Underwriting',
  ViewPurchaseAdvice = 'TpoViewPurchaseAdvice',
  SubmitPurchase = 'TpoSubmitPurchase',
  LockExtension = 'TpoLockExtension',
  CancelLock = 'TpoCancelLock',
  Relock = 'TpoReLock',
  RePrice = 'TpoRePrice',
  VoidLock = 'TpoVoidLock',
  Withdraw = 'TpoWithdrawal',
  RequestLoanEstimate = 'TpoRequestLE',
  GenerateLestimateDisclosure = 'TpoGenerateLEDisclosure',
  OrderAppraisal = 'TpoOrderAppraisal',
}
