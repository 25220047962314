export const getPropertyUsageType = (propertyUsageId: number) => {
  const propertyUsageType: Record<number, string> = {
    1: 'PrimaryResidence',
    2: 'SecondHome',
    3: 'InvestmentProperty',
  };
  return propertyUsageType[propertyUsageId] ?? null;
};

export const getLoanPurposeType = (
  purposeId: number,
  gseRefinancePurposeType = '',
) => {
  const cashOutRefiSubTypes = [
    'CashOutDebtConsolidation',
    'CashOutHomeImprovement',
    'CashOutLimited',
    'CashOutOther',
  ];

  const noCashOutRefiSubTypes = [
    'NoCashOutFHAStreamlinedRefinance',
    'NoCashOutFREOwnedRefinance',
    'NoCashOutOther',
    'NoCashOutStreamlinedRefinance',
    'ChangeInRateTerm',
  ];

  let purposeType: string | null = null;

  switch (purposeId) {
    case 1:
      purposeType = 'Purchase';
      break;
    case 2:
    case 3:
      if (cashOutRefiSubTypes.includes(gseRefinancePurposeType)) {
        purposeType = 'Cash-Out Refinance';
      } else if (noCashOutRefiSubTypes.includes(gseRefinancePurposeType)) {
        purposeType = 'NoCash-Out Refinance';
      }
      break;
    case 4:
      purposeType = 'ConstructionOnly';
      break;
    case 5:
      purposeType = 'ConstructionToPermanent';
      break;
    case 6:
      purposeType = 'Other';
      break;
    default:
      purposeType = null;
      break;
  }

  return purposeType;
};

export const getPropertyType = (typeId = 1) => {
  const propTypeId = typeId;

  const propTypeEnumConfig = {
    5: 'Condominium',
    6: 'Condominium',
    7: 'HighRiseCondominium',
    8: 'Attached',
    9: 'Cooperative',
    10: 'ManufacturedHousing',
    11: 'ManufacturedHousing',
    12: 'DetachedCondo',
    13: 'PUD',
    14: 'MHAdvandtage',
    16: 'MH CHOICEHome',
  };

  return propTypeEnumConfig[propTypeId] ?? 'Detached';
};
