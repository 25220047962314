/* eslint-disable react-hooks/rules-of-hooks */
import { EpcLoanActionTypes, PersonaAccessResponse } from '@frontend/epc';
import Session, { ORIGIN_SOURCE } from '../../../../common/services/Session';
import { isEmpty } from '../../../../common/utils/shared';
import { useOriginalLoanInformationSelector } from '../../../../data/useSelectors';
import { get } from 'lodash';

const epcLoanActionMap = {
    "NewLock": EpcLoanActionTypes.LockRequest,
    "ActiveNewLock": EpcLoanActionTypes.LockRequest,
    "ActiveLockUpdate": EpcLoanActionTypes.RePrice,
    "ExpiredReLock": EpcLoanActionTypes.Relock,
    "CancelledReLock": EpcLoanActionTypes.Relock,
};

export const getTPOActionAccessibility = (
  allowedActions,
  requestActionType,
) => {
  const defaultValue = 'ENABLED';
  let filterItem: any[] = [];
  if (allowedActions) {
    filterItem = allowedActions.filter(
      (item) => item.action === requestActionType,
    );
  }
  return filterItem ? filterItem[0]?.accessibility : defaultValue;
};

export const getPersonaActionAccessibility = (
  allowedActions: PersonaAccessResponse[],
  requestActionType: EpcLoanActionTypes,
): string => {
  const defaultValue = 'ENABLE';
  let filterItem: PersonaAccessResponse[] = [];
  if (allowedActions) {
    filterItem = allowedActions.filter(
      (item) => item.loanAction == requestActionType,
    );
  }
  return filterItem ? filterItem[0]?.restrictionType : defaultValue;
};

export const isRequestLockButtonVisible = (applicationName,loanData,allowedActions) => {
  let isVisible = true;
  if (
    applicationName &&
    applicationName === 'TPO' &&
    getTPOActionAccessibility(allowedActions, 'LockRequest') === 'HIDDEN'
  ) {
    isVisible = false;
  }

  const requestLockActionType = getRequestLockActionType(loanData);
  /**
   * unfortunately the request lock button is used for multiple operations
   * so based on the request lock action type we need to determine if the permission applies to lock action type
   * TODO check in with product for better messaging
   */
  if(requestLockActionType) {
    const epcActionType: EpcLoanActionTypes = epcLoanActionMap[requestLockActionType];
    if (
      epcActionType &&
      getPersonaActionAccessibility(
        allowedActions,
        epcActionType,
      ) === 'Hide'
    ) {
      isVisible = false;
    }
  }

  return isVisible;
};

export const isSaveToLoanButtonVisible = (
  applicationName,
  allowedActions,
  tpo,
  clientSettingsData,
) => {
  const hideLockRequest = clientSettingsData?.hideLockRequest;
  if (hideLockRequest) {
    return false;
  }

  if (applicationName && applicationName === 'TPO') {
    const isHidden =
      getTPOActionAccessibility(allowedActions, 'FloatLock') === 'HIDDEN';
    if (isHidden || tpo?.underwritingDelegated || hideLockRequest) return false;
  }
  return true;
};


export const isRequestLockButtonDisabled = (
  rateStale,
  loanData,
  applicationName,
  allowedActions,
  isDisableRequestLockButton,
  shutDownAllowLockRequestValidation,
  disableLock,
) => {
  if (disableLock) {
    return true;
  }

  const { requestLockStatus, requestAction } = loanData;
  const isExpiredPricing = rateStale > 0; // 1 or 999
  const isLoanOfficer = Session.get(ORIGIN_SOURCE) !== 'secondarylock';
  const isAllowLockRequest = shutDownAllowLockRequestValidation();
  if (
    applicationName &&
    applicationName === 'TPO' &&
    getTPOActionAccessibility(allowedActions, 'LockRequest') === 'DISABLED'
  ) {
    return true;
  }
  const requestLockActionType = getRequestLockActionType(loanData);
  const epcActionType = requestLockActionType ? epcLoanActionMap[requestLockActionType] : null;
  /**
   * unfortunately the request lock button is used for multiple operations
   * so based on the request lock action type we need to determine if the permission applies to lock action type
   * TODO check in with product for better messaging
   */
  const isPersonaDisabled =  epcActionType && getPersonaActionAccessibility(
        allowedActions,
        epcActionType,
      ) === 'Disable'

  const isDisabled =
    (requestLockStatus === 0 && requestAction === 2) ||
    (requestLockStatus === 1 && requestAction === 2) ||
    (requestLockStatus === 3 && requestAction === 1) ||
    (requestLockStatus === 4 && requestAction === 1) ||
    (isLoanOfficer && isExpiredPricing) ||
    isDisableRequestLockButton ||
    isAllowLockRequest || isPersonaDisabled;

  return isDisabled;
};

export const isFloatButtonVisible = (
  allowedActions: PersonaAccessResponse[],
  clientSettingsData,
) => {
  let isFloatButtonVisible = clientSettingsData?.icePpeFloat;
  if (
    getPersonaActionAccessibility(
      allowedActions,
      EpcLoanActionTypes.FloatLock,
    ) === 'Hide'
  ) {
    isFloatButtonVisible = false;
  }

  return isFloatButtonVisible;
};

export const isFloatButtonDisabled = (
  allowedActions: PersonaAccessResponse[],
) => {
  const { lockRequests } = useOriginalLoanInformationSelector() as any;

  const lockRequestIsDenied = lockRequests?.every(
    (req) => req.requestedStatus === 'Denied',
  );

  const isDisabledFromLock = lockRequests?.length && !lockRequestIsDenied;
  const isDisabledFromPersona =
    getPersonaActionAccessibility(
      allowedActions,
      EpcLoanActionTypes.FloatLock,
    ) === 'Disable';

  return isDisabledFromLock || isDisabledFromPersona;
};

export const isSaveToLoanButtonDisabled = (
  loanData,
  applicationName,
  allowedActions,
  disableLock,
) => {
  if (disableLock) {
    return true;
  }
  if (applicationName && applicationName === 'TPO') {
    const isDisabled =
      getTPOActionAccessibility(allowedActions, 'FloatLock') === 'DISABLED';
    if (isDisabled) return true;
    const { requestLockStatus, requestAction } = loanData;
    return requestAction === 2 && [3, 4].includes(requestLockStatus);
  }
  return false;
};

export const getRequestLockActionType = (loanData) => {
  let type: string | null = null;
  if (!isEmpty(loanData)) {
    const { requestLockStatus, requestAction } = loanData;
    if (requestLockStatus === 0 || requestLockStatus === 1) {
      if (requestAction === 1) type = 'NewLock';
    }
    if (requestLockStatus === 2) {
      if (requestAction === 1) type = 'ActiveNewLock';
      if (requestAction === 2) type = 'ActiveLockUpdate';
    }
    if (requestLockStatus === 3) {
      if (requestAction === 2) type = 'ExpiredReLock';
    }
    if (requestLockStatus === 4) {
      if (requestAction === 2) type = 'CancelledReLock';
    }
  }
  return type;
};

export const getEPCActionType = (requestLockActionType, autolocked) => {
  const { buyside, sellSideBestPrice, sellSideSameProduct } = autolocked;
  let EPCActionType;
  if (
    requestLockActionType === 'NewLock' ||
    requestLockActionType === 'ActiveNewLock'
  ) {
    EPCActionType = 'LOCK';
  } else {
    EPCActionType = 'RELOCK';
  }
  if (buyside || sellSideBestPrice || sellSideSameProduct) {
    EPCActionType = 'LOCK_CONFIRM';
  }
  return EPCActionType;
};

export const checkLockRequested = (loanData, lockRequests) => {
  const { requestAction, requestLockStatus } = loanData;
  const hasLockRequest = lockRequests?.some(
    (request) => request.requestedStatus === 'Requested',
  );
  return hasLockRequest && requestAction === 1 && requestLockStatus === 1;
};
