export const ERROR_MESSAGES = {
  EPPS1044: {
    title: 'This loan has no borrowers',
    errorMessage: 'Please return to Encompass and add at least one borrower.',
  },
  PS095: {
    errorMessage:
      'Your ICE PPE session has expired. Please close ICE PPE and log back in.',
  },
};
