import { setAppConfig } from '@elliemae/pui-app-sdk';
import { getDefaultTheme } from '@elliemae/pui-theme';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'; // Import Provider
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import '../webroot/components/themes/vanilla/styles/base.css';
import appConfig from './app.config.json';
import App from './route/index';
import { configureStore } from './store'; // Import your configured Redux store

setAppConfig(appConfig);

const root = createRoot(document.getElementById('root')!);

root.render(
  <Provider store={configureStore()}>
    <ThemeProvider theme={getDefaultTheme()}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
);
