import styled from 'styled-components';

const gridMinWidth = `70px`;

export const boxStyles = {
  borderStyle: 'solid solid solid none',
  borderWidth: '1px',
  borderColor: '#A9B1BE',
};

const StyledGridColumn = `
  .em-ds-data-grid-wrapper {
    min-width: ${gridMinWidth};
  }
`;

export const StyledRateColumn = styled.div`
  min-width: 10%;
  ${StyledGridColumn}
`;

export const StyledDateGridColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 30%;
  ${StyledGridColumn}

  .custom-date-picker-wrapper {
    .em-ds-label {
      display: none;
    }

    [data-testid='ds-time-input__ampm'] {
      text-transform: uppercase;
    }
  }
`;

export const StyledDataGridWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const NoResultsMask = styled.div`
  align-items: center;
  background: #fff;
  bottom: 0;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 26px;
  width: 100%;
`;

export const NegativeDiscount = styled.span`
  color: #c64252;
`;

export const AlignRight = styled.div`
  width: 100%;
  text-align: right;
`;
