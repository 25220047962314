import { SET_TAB_KEY } from '../../common/constants/ActionConstants';

export const setRedirectTabAction = (pathname) => {
  try {
    throw new Error('Unsupported redirect');
  } catch (err) {
    console.error(
      'Unsupported redirect. Trace it here, and refactor it to use useNavigate hooks',
      err,
    );
  }
  return {
    type: SET_TAB_KEY,
    payload: pathname,
  };
};
