import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { GridPriceDetails } from '../../../SearchResult/components/GridPriceDetails/GridPriceDetails';
import { transformRateSelectorDataToSlide } from '../../../SearchResult/components/GridPriceDetails/helpers';
import PricingTableStyled from './styled';

export const PricingTable = () => {
  const {
    lien: { adjustments = [], armIndexValue = null, arm = false } = {},
    loan: { mortgageType = '' } = {},
  } = useSelector(({ epps }) => epps.lockSummary.rateSelector || {});
  const buysideAdjustments = useSelector(({ epps }) =>
    Object.values(epps.origin.buySideAdjustments ?? {}).flat(1),
  ) as any[];

  const filteredAdjustments = useMemo(
    () =>
      adjustments.concat(
        buysideAdjustments.filter((adj) => adj.adjustmentType !== 'Adjustment'),
      ),
    [buysideAdjustments, adjustments],
  );
  const heloc = useMemo(() => mortgageType === 'HELOC', [mortgageType]);
  return (
    <PricingTableStyled
      fullWidth={arm || heloc}
      data-testid="PricingTable-all-components"
    >
      <GridPriceDetails
        data={{
          ...transformRateSelectorDataToSlide(
            filteredAdjustments,
            armIndexValue,
          ),
          showMarginTable: arm || heloc,
        }}
        showText={false}
      />
    </PricingTableStyled>
  );
};
