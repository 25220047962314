import React from 'react';
import PropTypes from 'prop-types';
import { DSButton } from '@elliemae/ds-button';
import { DSButtonGroup } from '@elliemae/ds-button-group';
import { DSSeparator } from '@elliemae/ds-separator';
import { DSTooltipV2 } from '@elliemae/ds-tooltip';
import {
  StyledFooterButtonsContainer,
  StyledFooterExtraButton,
} from './styled';

export const FooterButtons = ({
  extraButton = {},
  buttons = [],
  tooltip = null,
}: {
  extraButton: any;
  buttons: any[];
  tooltip?: any;
}) => {
  return (
    <div style={{ marginTop: `50px` }}>
      <StyledFooterButtonsContainer>
        {extraButton.isVisible && (
          <DSButton
            key={`${extraButton.text}-${extraButton}`}
            buttonType={extraButton.type}
            labelText={extraButton.text}
            size={extraButton.size}
            onClick={extraButton.onClick}
            variant={extraButton.variant}
            disabled={extraButton.disabled}
            data-testid="FooterButtons-all-components"
          />
        )}
        {extraButton.isVisible && (
          <StyledFooterExtraButton>
            <DSSeparator
              margin="l"
              orientation="vertical"
              position="initial"
              type="group-level"
            />
          </StyledFooterExtraButton>
        )}

        {buttons.length > 0 && (
          <DSButtonGroup buttonType="primary" spacing="s" size="m">
            {buttons
              .filter(
                (button) =>
                  button.visible === undefined || button.visible === true,
              )
              .map((button) =>
                button.tooltipText ? (
                  <DSTooltipV2
                    title={
                      <div style={{ textAlign: 'left', width: '15em' }}>
                        {button.tooltipText}
                      </div>
                    }
                    spacing="s"
                    triggerComponent={
                      <DSButton
                        style={{ marginRight: '16px' }}
                        containerProps={{
                          'data-testid': button['data-testid'],
                        }}
                        key={button.text}
                        buttonType={button.type}
                        labelText={button.text}
                        size={button.size}
                        onClick={button.onClick}
                        variant={button.variant}
                        disabled={button.disabled}
                      />
                    }
                  />
                ) : (
                  <DSButton
                    containerProps={{ 'data-testid': button['data-testid'] }}
                    key={button.text}
                    buttonType={button.type}
                    labelText={button.text}
                    size={button.size}
                    onClick={button.onClick}
                    variant={button.variant}
                    disabled={button.disabled}
                  />
                ),
              )}
          </DSButtonGroup>
        )}
        {tooltip}
      </StyledFooterButtonsContainer>
    </div>
  );
};

FooterButtons.defaultProps = {
  buttons: [],
  extraButton: {
    isVisible: false,
  },
  tooltip: null,
};

FooterButtons.propTypes = {
  buttons: PropTypes.instanceOf(Array),
  extraButton: PropTypes.shape({
    isVisible: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.string,
    labelText: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.string,
  }),
  tooltip: PropTypes.element,
};
