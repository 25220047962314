import { func, number, string } from 'prop-types';
import { GuidelineLink } from './GuidelineLink';
import { Notes } from './Notes';

export const ProgramDetails = ({
  program,
  notes,
  activeGuidelineLink,
  onLinkClick,
}) => (
  <div>
    <div style={{ display: 'flex', alignItems: 'Center' }}>
      {program && (
        <span
          data-testid="ProgramDetails-all-components"
          style={{ marginLeft: '16px', fontWeight: 'Bold' }}
        >
          {program}
        </span>
      )}
      <GuidelineLink
        activeGuidelineLink={activeGuidelineLink}
        onClick={onLinkClick}
        showSeprator={program}
      />
    </div>
    <Notes notes={notes} showInAccordion={!!program} />
  </div>
);

ProgramDetails.defaultProps = {
  program: '',
  notes: '',
  activeGuidelineLink: 0,
};

ProgramDetails.propTypes = {
  program: string,
  notes: string,
  activeGuidelineLink: number,
  onLinkClick: func.isRequired,
};
