/* eslint-disable @typescript-eslint/default-param-last */
import { PersonaAccessResponse } from '@frontend/epc';
import { SET_PERSONA_ACCESS_DATA } from './actions';

const INITIAL_STATE = {
  allowedActions:[]
};

type Action = { payload: PersonaAccessResponse[]; type: string };

export default (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case SET_PERSONA_ACCESS_DATA:
      return {
        ...state,
        allowedActions: action.payload,
      };

    default:
      return state;
  }
};
