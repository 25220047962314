import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DSFormLayoutBlockItem } from '@elliemae/ds-form-layout-blocks';
import { DSControlledLargeTextInput } from '@elliemae/ds-form-input-textarea';
import { DSSingleComboBox } from '@elliemae/ds-form-single-combobox';
import { Grid } from '@elliemae/ds-grid';
import { DSInputText } from '@elliemae/ds-form-input-text';

export const ExtensionForm = ({
  controlOptions,
  extensionLockDays,
  setExtensionLockDays,
  comments,
  setComments,
  adjustmentOverride,
  setAdjustmentOverride,
}) => {
  const parsedDaysToExtendControlOptions = useMemo(() => {
    return (controlOptions?.daysToExtendControlOptions ?? []).map((i) => ({
      dsId: `optionId${i}`,
      type: 'option',
      value: `${i}`,
      label: `${i} days`,
    }));
  }, [controlOptions]);

  const showEnumeratedOptions = useMemo(
    () => controlOptions?.daysToExtendControlType === 'Enumerated',
    [controlOptions],
  );
  const showTextOptions = useMemo(
    () => controlOptions?.daysToExtendControlType === 'Text',
    [controlOptions],
  );
  const showAdjustmentTextControl = useMemo(
    () => controlOptions?.adjustmentControlType === 'Text',
    [controlOptions],
  );
  const selectedEnumeratedOption = useMemo(
    () =>
      parsedDaysToExtendControlOptions?.find(
        (i) => Number(i.value) === extensionLockDays,
      ) ?? {},
    [parsedDaysToExtendControlOptions, extensionLockDays],
  );
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (controlOptions?.daysToExtendControlOptions?.length === 1) {
      setExtensionLockDays(controlOptions?.daysToExtendControlOptions[0]);
    }
  }, [parsedDaysToExtendControlOptions, controlOptions]);

  return (
    <Grid alignContent="flex-start">
      <Grid mb="s" cols={{ small: ['1fr'], medium: ['300px'] }}>
        {showEnumeratedOptions && (
          <div>
            <DSFormLayoutBlockItem
              label="Days to Extend"
              inputID="DaysToExtendEnum"
            >
              <DSSingleComboBox
                allOptions={(parsedDaysToExtendControlOptions || []).sort(
                  (a, b) => a.value - b.value,
                )}
                onChange={(i) => setExtensionLockDays(Number(i.value))}
                selectedValue={selectedEnumeratedOption}
                id="DaysToExtendEnum"
              />
            </DSFormLayoutBlockItem>
          </div>
        )}
        {showTextOptions && (
          <div>
            <DSFormLayoutBlockItem
              label="Days to Extend"
              inputID="DaysToExtendText"
            >
              <DSInputText
                id="DaysToExtendText"
                type="number"
                value={isEditing ? extensionLockDays || '' : extensionLockDays}
                placeholder="Enter days"
                onFocus={() => {
                  setIsEditing(true); // Set editing state to true when focused
                  if (extensionLockDays === 0) {
                    setExtensionLockDays(''); // Set value to empty if it is 0
                  }
                }}
                onBlur={() => {
                  setIsEditing(false); // Set editing state to false when blurred
                  if (extensionLockDays === '') {
                    setExtensionLockDays(0); // Set value back to 0 if empty
                  }
                }}
                onValueChange={(i) => setExtensionLockDays(Number(i))}
                max={controlOptions?.maxNumberOfDays}
                min={0}
              />
            </DSFormLayoutBlockItem>
          </div>
        )}
        {showAdjustmentTextControl && (
          <div>
            <DSFormLayoutBlockItem
              label="Price Adjustment"
              inputID="PriceAdjustment"
            >
              <DSInputText
                id="readonly"
                type="number"
                value={Math.abs(Number(adjustmentOverride))}
                onValueChange={(i) => setAdjustmentOverride(Number(i))}
              />
            </DSFormLayoutBlockItem>
          </div>
        )}
      </Grid>

      <div>
        <DSFormLayoutBlockItem
          inputID="comments"
          maxCharCounter={200}
          currentChar={comments?.length}
          label="Comments"
          hasError={comments?.length >= 200}
          validationMessage="Max characters reached."
        >
          <DSControlledLargeTextInput
            onChange={setComments}
            maxHeight={100}
            value={comments}
            id="commentsInput"
            hasError={comments?.length >= 200}
          />
        </DSFormLayoutBlockItem>
      </div>
    </Grid>
  );
};

ExtensionForm.propTypes = {
  controlOptions: PropTypes.shape({
    daysToExtendControlType: PropTypes.string.isRequired,
    daysToExtendControlOptions: PropTypes.arrayOf(PropTypes.number.isRequired)
      .isRequired,
    adjustmentControlType: PropTypes.string,
    maxNumberOfDays: PropTypes.number.isRequired,
  }).isRequired,
  setExtensionLockDays: PropTypes.func.isRequired,
  setComments: PropTypes.func.isRequired,
  extensionLockDays: PropTypes.number.isRequired,
  comments: PropTypes.string.isRequired,
  adjustmentOverride: PropTypes.number.isRequired,
  setAdjustmentOverride: PropTypes.func.isRequired,
};
