import { useLoanDataFieldSelectorHook } from '@frontend/pricing-search';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAdjustmentDetailsCache } from '../../../../data/useSelectors';

export const useEligibleProductsGrid = ({
  dispatch,
  loanId,
  relockRequest,
  lienPosition,
  productOptions,
  armIndexValue,
  mortgageType,
  getEligibleGridColumns,
  transformRateSelectorDataToSlide,
  getAdjustmentDetails,
  filterPrograms,
  selectProduct,
  clientsettings,
  activeFilters,
  rows,
  arm,
  expandedRows,
  windowWidth,
  handleSelectProduct,
  handleRefreshButton,
  updateAdjustmentDetailsCache,
  disableLockLinks,
  isBuySideHistorical,
}) => {
  const [selectedRow, setSelectedRow] = useState<any>(false);
  const changeAdditionalLoanProg =
    clientsettings[0]?.encompassSettings?.changeAdditionalLoanProg || false;
  const [slide, setSlide] = useState<any>({
    open: false,
    data: null,
    rowData: null,
    rateData: null,
  });
  const [programSlide, setProgramSlide] = useState(false);
  const heloc = useMemo(() => mortgageType === 'HELOC', [mortgageType]);

  const handleSelectRow = (selection) => {
    setSelectedRow(selection);
    dispatch(selectProduct(selection));
  };

  const adjustmentDetailsCache = useAdjustmentDetailsCache() || [];

  const loanData = useLoanDataFieldSelectorHook() as any;
  const buysideAdjustments = useSelector(
    ({ epps }) =>
      Object.values(epps.origin.buySideAdjustments ?? {}).flat(1) as any[],
  );
  const loanCatcherAdjustmentTypes = [
    'LockExtensionAdjustment',
    'ReLockFeeAdjustment',
    'CustomPriceAdjustment',
    'CorporatePriceConcession',
    'BranchPriceConcession',
  ];
  const filteredLoanCatcherAdjustments = buysideAdjustments.filter((i) =>
    loanCatcherAdjustmentTypes.includes(i.adjustmentType),
  );
  const eppsUserName = loanData?.eppsUserName || null;
  useEffect(() => {
    if (slide.rowData && !slide.open) {
      const { programID: programId, program } = slide.rowData;
      const rateDataId = slide.rateData
        ? slide.rateData
        : slide.rowData.rateDataID;
      const searchParams = {
        loanId,
        programId,
        rateDataId,
        lienPosition,
        eppsUserName,
      };
      const existingDetail = adjustmentDetailsCache.find(
        (detail) =>
          detail.programId === programId && detail.rateDataId === rateDataId,
      );
      if (existingDetail) {
        setSlide({
          open: true,
          data: existingDetail.dataSlide,
          rowData: null,
          rateData: null,
        });
      } else {
        dispatch(getAdjustmentDetails(searchParams)).then((response) => {
          if (response && response.adjustments) {
            const dataSlide = transformRateSelectorDataToSlide(
              [...response.adjustments, ...filteredLoanCatcherAdjustments],
              armIndexValue,
            );
            dataSlide.showMarginTable = arm || heloc;
            dataSlide.bonaFideTestResults = response.bonaFideTestResults;
            dataSlide.programName = program;
            const updatedAdjustmentDetailsCache = [
              ...adjustmentDetailsCache,
              {
                programId,
                rateDataId,
                dataSlide,
              },
            ];
            dispatch(
              updateAdjustmentDetailsCache(updatedAdjustmentDetailsCache),
            );
            setSlide({
              open: true,
              data: dataSlide,
              rowData: null,
              rateData: null,
            });
          }
        });
      }
    }
  }, [slide]);

  const changeProgramOnReLock = get(
    clientsettings,
    '[0].encompassSettings.changeProgramOnReLock',
    true,
  );

  let filteredRows = filterPrograms(
    rows,
    activeFilters,
    setSlide,
    handleSelectRow,
    relockRequest,
    isBuySideHistorical,
  );
  const selectedProgram = filteredRows.find(
    (x) => x.programID === relockRequest?.specificProgramID,
  );

  if (relockRequest?.specificProgramID && !changeProgramOnReLock) {
    filteredRows = filteredRows.filter(
      (x) => x.programID === relockRequest?.specificProgramID,
    );
  }

  if (
    relockRequest?.specificProgramID &&
    changeAdditionalLoanProg &&
    !selectedProgram
  ) {
    filteredRows = filterPrograms(
      rows,
      activeFilters,
      setSlide,
      handleSelectRow,
      relockRequest,
      isBuySideHistorical,
    );
  }

  const columns = useMemo(() => {
    return getEligibleGridColumns(
      relockRequest,
      productOptions,
      filteredRows,
      selectedRow,
      loanData,
      expandedRows,
      windowWidth,
      handleSelectProduct,
      handleRefreshButton,
      handleSelectRow,
      disableLockLinks,
      isBuySideHistorical,
    );
  }, [selectedRow.programID, rows, filteredRows, productOptions]);

  return {
    columns,
    filteredRows,
    selectedRow,
    handleSelectRow,
    slide,
    setSlide,
    programSlide,
    setProgramSlide,
    expandedRows,
    windowWidth,
    handleSelectProduct,
    handleRefreshButton,
  };
};
