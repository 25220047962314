import styled from 'styled-components';

export const StyledCell = styled.div`
  ${({ theme, textXl }) =>
    textXl && `font-size: ${theme.fontSizes.value[500]};`}
  ${({ theme, textBold }) =>
    textBold && `font-weight: ${theme.fontWeights.semibold};`}
  ${({ rightAlign }) => rightAlign && 'text-align: right;'}
  ${({ padLeft }) => padLeft && 'padding-left: 8px;'}
`;

export const StyledJustifyContentItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  cursor: pointer;
`;

export const StyledCellHeader = styled.div`
  width: 100%;
  text-align: right;
`;
