/* eslint-disable react/prop-types */

import { get } from 'lodash';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import validate from '../../../../common/utils/validator/index';
import validators from '../../../../common/utils/validator/validations/index';

export const isString = (property) => typeof property === 'string';
export const isObject = (property) => typeof property === 'object';
export const isFunction = (property) => typeof property === 'function';

// function to........

export const getLabel = (label, customDependencies) => {
  return typeof label === 'function' ? label({ customDependencies }) : label;
};

// function to......

export const getValue = (property, data) => {
  if (property && typeof property === 'function') {
    return property(data);
  }
  return data;
};

// function to......

export const getInitialValue = (property, data) => {
  if (property && typeof property === 'function') {
    return property(data);
  }
  return data;
};

// function to......

export const getParseValue = (property, data) => {
  if (property && typeof property === 'function') {
    return property(data);
  }
  return data;
};

// function to......

export const getCustomProperty = (property, data) => {
  if (isFunction(property)) {
    return property(data);
  }
  return isObject(data) ? data.value : data;
};

// function to........

export const replaceValue = (value, replace) => {
  return value
    ? value.replace(`${replace}`, '').replace('%', '').replace(/,/g, '')
    : value;
};

// function to........

export const getMaxLength = (value = '', defaultLength = 0) => {
  if (typeof value === 'string') {
    if (value.split('.').length > 1) return defaultLength + 1;
  }
  return defaultLength;
};

// function to......

export const getFeedbackMessage = (isFeedbackMessage, condition) => {
  if (isFeedbackMessage && typeof isFeedbackMessage === 'function') {
    return isFeedbackMessage(condition);
  }
  return isFeedbackMessage;
};

// function to.......

export const parseValue = (value, comboBox?: any) => {
  const val = comboBox && (value === null || value === undefined) ? 'none' : '';
  return ![undefined, null, '', '-'].includes(value) ? parseFloat(value) : val;
};

// Parses the given value to be used inside a combobox.
export const parseComboboxValue = (value, isMulti) => {
  let newValue = value;
  const forbiddenValues = [undefined, null, '', '-'];

  // Default empty value for non multi
  if (!isMulti && forbiddenValues.includes(value)) {
    newValue = { value: 'none', label: 'none', dsId: 'none', type: 'option' };
  }

  // Default empty value for multi
  if (!isMulti && forbiddenValues.includes(value)) {
    newValue = [];
  }

  // Put the multi value inside an array if the
  // given value is not an array.
  if (isMulti && !Array.isArray(value)) {
    newValue = [value];
  }

  return newValue;
};

// function to........

export const getReplace = (value, replace) => {
  const selectReplace = replace ? replace(value) : replaceValue(value, '$');
  return parseValue(selectReplace);
};

// function to........

export const getValidator = (values): any => {
  const { value, required, name, customDependencies } = values;
  const getName = String(name).replace(/[0-9]/g, '').replace('-', '');
  const isValidator = validators[getName];
  return isValidator
    ? validate({ [getName]: value }, [isValidator], {
        dependencies: customDependencies,
        required,
      })
    : {};
};

// function to......

export const customOnChange = ({ event, replace, customEvent, ...rest }) =>
  customEvent
    ? {
        [event]: ({ target, value }) =>
          customEvent({
            value: getReplace(value || target?.value, replace),
            ...rest,
          }),
      }
    : {};

// function to......
export const customOnTextBoxChange = ({ event, customEvent, ...rest }) =>
  customEvent
    ? {
        [event]: ({ target, value }) =>
          customEvent({
            value: value || target.value,
            ...rest,
          }),
      }
    : {};

// function to......
export const customOnDateChange = ({
  event,
  dateFormat,
  customEvent,
  ...rest
}) =>
  customEvent
    ? {
        [event]: (newDate) => {
          const strDate = moment(newDate).format(dateFormat);
          return customEvent({
            value: strDate,
            ...rest,
          });
        },
      }
    : {};

// function to.......

export const recursivePropAccessCheckbox = (obj, propAccess, propValue) => {
  let objRef = { ...obj };
  propAccess.split('.').forEach((prop, i, arr) => {
    if (i < arr.length - 1) objRef = objRef[prop];
    else objRef[prop] = propValue;
  });
  return obj;
};

export const recursivePropAccess = (obj, propAccess, propValue) => {
  const copyObject = JSON.parse(JSON.stringify(obj));
  let objRef = copyObject;
  propAccess
    .replace('[', '.')
    .replace(']', '')
    .split('.')
    .forEach((prop, i, arr) => {
      if (i < arr.length - 1) {
        objRef = objRef[prop];
      } else {
        objRef[prop] = propValue;
      }
    });
  return copyObject;
};

// function to......

export const getContainerProps = (name, hasError) => ({
  id: `epps-field-id-${name}`,
  'data-field-haserror': !!hasError,
  'data-testid': `epps-field-testid-${name}`,
});

// TODO(ngen): many of these utils were copied over into individual component files. I'm putting them all back here, and hopefully choosing the best version of each method.

// Gets a value from loanInfo
const fieldValueSelector =
  (nameOrPath, defaultValue = null) =>
  ({ EPPS: { loanInfo = {} } }) => {
    if (typeof nameOrPath === 'string') {
      return recursivePropAccessCheckbox(loanInfo, nameOrPath, defaultValue);
    }
    if (Array.isArray(nameOrPath)) {
      const valuesSelector = {};
      nameOrPath.forEach((group) => {
        const { path, values } = group;
        if (!Array.isArray(values)) return;
        values.forEach((value) => {
          valuesSelector[value] = loanInfo[path]?.[value] || null;
        });
      });
      return valuesSelector;
    }
    return null;
  };

// Returns a value from loanInfo using fieldValueSelector
export const useFieldValueSelector = (pathValue) =>
  useSelector(fieldValueSelector(pathValue), shallowEqual);

// Updates a loanInfo value
export const setLoanDataValueCheckbox = (value, route) => (dispatch, state) => {
  const loanData = get(state(), 'EPPS.loanInfo') || {};
  dispatch({
    type: 'UPDATE_LOANINFO',
    payload: recursivePropAccessCheckbox(loanData, route, value),
  });
};

export const setLoanDataValue = (value, route) => (dispatch, state) => {
  const loanData = get(state(), 'EPPS.loanInfo') || {};
  dispatch({
    type: 'UPDATE_LOANINFO',
    payload: recursivePropAccess(loanData, route, value),
  });
};

// Updates all of loanInfo
export const setLoanInfo = (value) => (dispatch, state) => {
  dispatch({
    type: 'UPDATE_LOANINFO',
    payload: value,
  });
};
