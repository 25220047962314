/* eslint-disable @typescript-eslint/default-param-last */
import {
  SET_LOCK_DESK_STATUS_DATA,
  SET_LOCK_DESK_REQUEST_LOCK_BUTTON_STATUS,
  SHOW_ONRP_LIMIT_NOTIFICATION,
  SET_LOCK_DESK_MODAL,
  SET_ALLOW_LOCK_UPDATE,
  SET_LOCK_UNDER_ONRP,
} from './actions';

const INITIAL_STATE = {
  isDisableRequestLockButton: false,
  lockDeskData: {},
  onrpNotificationInfo: {
    isUnderOnrp: false,
    remainingAmountUnderOnrp: 0,
  },
  isLockDeskModal: false,
  isAllowLockUpdate: false,
  isLockRequestUnderOnrp: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOCK_DESK_STATUS_DATA:
      return {
        ...state,
        lockDeskData: action.payload,
      };
    case SET_LOCK_DESK_REQUEST_LOCK_BUTTON_STATUS:
      return {
        ...state,
        isDisableRequestLockButton: action.payload,
      };
    case SHOW_ONRP_LIMIT_NOTIFICATION:
      return {
        ...state,
        onrpNotificationInfo: action.payload,
      };
    case SET_LOCK_DESK_MODAL:
      return {
        ...state,
        isLockDeskModal: action.payload,
      };
    case SET_ALLOW_LOCK_UPDATE:
      return {
        ...state,
        isAllowLockUpdate: action.payload,
      };
    case SET_LOCK_UNDER_ONRP:
      return {
        ...state,
        isLockRequestUnderOnrp: action.payload,
      };
    default:
      return state;
  }
};
