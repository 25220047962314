import React from 'react';
import { StyledCellHeader } from '../styled';
import {
  NameCell,
  StandardCell,
  EditIconCell,
  PriceChangeSelectCell,
  CellArrowUpDown,
} from './cells';

export const defaultColumns = [
  {
    Header: 'Name',
    Cell: NameCell,
    width: '50%',
  },
  {
    Header: () => <StyledCellHeader> Rate </StyledCellHeader>,
    accesor: 'rate',
    Cell: StandardCell,
  },
  {
    Header: () => <StyledCellHeader> Price </StyledCellHeader>,
    accesor: 'price',
    Cell: StandardCell,
  },
];

export const getBuysideColumns = ({ onEditClick }) => [
  ...defaultColumns,
  {
    Header: '',
    Cell: EditIconCell,
    onEditClick,
    width: 30,
  },
];

export const getPricingChangedColumns = ({
  onEditClick,
  onSelectPrice,
  selectedPrice,
}) => [
  {
    Header: 'Name',
    Cell: NameCell,
    width: '50%',
  },
  {
    Header: () => <StyledCellHeader> Requested Price </StyledCellHeader>,
    columns: [
      {
        Header: () => <StyledCellHeader> Rate </StyledCellHeader>,
        accesor: 'rate',
        Cell: StandardCell,
      },
      {
        Header: () => <StyledCellHeader> Price </StyledCellHeader>,
        accesor: 'price',
        Cell: PriceChangeSelectCell,
        onSelectPrice,
        selectedPrice,
      },
    ],
  },
  {
    Header: () => <StyledCellHeader> Current Pricing </StyledCellHeader>,
    columns: [
      {
        Header: () => <StyledCellHeader> Rate </StyledCellHeader>,
        accesor: 'currentRate',
        Cell: StandardCell,
      },
      {
        Header: () => <StyledCellHeader> Price </StyledCellHeader>,
        accesor: 'currentPrice',
        Cell: PriceChangeSelectCell,
        onSelectPrice,
        selectedPrice,
      },
    ],
  },

  {
    Header: '',
    Cell: EditIconCell,
    onEditClick,
    width: 30,
  },
];

export const getValidateColumns = ({ onEditClick }) => [
  {
    Header: '',
    columns: [
      {
        Header: 'Name',
        Cell: NameCell,
        width: '50%',
      },
    ],
  },
  {
    Header: () => <StyledCellHeader> Active Lock </StyledCellHeader>,
    columns: [
      {
        Header: () => <StyledCellHeader> Rate </StyledCellHeader>,
        accesor: 'rate',
        Cell: StandardCell,
      },
      {
        Header: () => <StyledCellHeader> Price </StyledCellHeader>,
        accesor: 'price',
        Cell: StandardCell,
      },
    ],
  },
  {
    Header: () => <StyledCellHeader> Proposed Pricing </StyledCellHeader>,
    columns: [
      {
        Header: () => <StyledCellHeader> Rate </StyledCellHeader>,
        accesor: 'currentRate',
        Cell: CellArrowUpDown,
      },
      {
        Header: () => <StyledCellHeader> Price </StyledCellHeader>,
        accesor: 'currentPrice',
        Cell: CellArrowUpDown,
      },
    ],
  },
  {
    Header: '',
    columns: [
      {
        Header: '',
        Cell: EditIconCell,
        onEditClick,
        width: 30,
      },
    ],
  },
];
