import styled from 'styled-components';

export default styled.div`
  .em-ds-labelValue__wrapper {
    margin: 8px 16px 8px 0px;
  }
  .em-ds-labelValue__value {
    font-weight: bold;
  }
`;
