import { DSButtonV2 } from '@elliemae/ds-button';
import {
  DSDialog,
  DSDialogAddon,
  DSDialogBody,
  DSDialogDefaultLayout,
  DSDialogFooter,
  DSDialogHeader,
  DSDialogPrimaryMessage,
  DSDialogSecondaryMessage,
  DSDialogSeparator,
} from '@elliemae/ds-dialog';
import { Close, WarningTriangle } from '@elliemae/ds-icons';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeLoader } from '../../../data/screenLoader/actions';

export const GlobalErrorModal = memo<any>(() => {
  const {
    isOpen,
    title,
    errorMessage,
    onClose,
    showClose = true,
    confirmLabel = 'Ok',
    discardLabel = '',
    size = 'small',
    icon = <WarningTriangle size="xxl" color={['danger', '900']} />,
  } = useSelector(({ globalErrorModal }) => globalErrorModal ?? {});

  const handleOnClick = () => onClose();
  const dispatch = useDispatch();

  const loadingIsOpen = useSelector(({ screenLoader }) => screenLoader?.show);

  useEffect(() => {
    if (isOpen && loadingIsOpen) {
      dispatch(closeLoader());
    }
  }, [isOpen, loadingIsOpen]);

  return (
    <DSDialog isOpen={isOpen} onClose={handleOnClick} size={size}>
      {showClose && (
        <DSDialogHeader>
          <DSDialogAddon>
            <DSButtonV2 buttonType="icon" onClick={handleOnClick}>
              <Close color={['neutral', '900']} />
            </DSButtonV2>
          </DSDialogAddon>
        </DSDialogHeader>
      )}

      <DSDialogBody p={24}>
        <DSDialogDefaultLayout>
          {icon}
          {title && (
            <DSDialogPrimaryMessage style={{ textAlign: 'center' }}>
              {title}
            </DSDialogPrimaryMessage>
          )}
          {errorMessage && (
            <DSDialogSecondaryMessage>{errorMessage}</DSDialogSecondaryMessage>
          )}
        </DSDialogDefaultLayout>
      </DSDialogBody>

      <DSDialogSeparator />

      <DSDialogFooter>
        {discardLabel && (
          <DSButtonV2 onClick={handleOnClick} buttonType="outline">
            Discard
          </DSButtonV2>
        )}
        {confirmLabel && (
          <DSButtonV2 onClick={handleOnClick}>{confirmLabel}</DSButtonV2>
        )}
      </DSDialogFooter>
    </DSDialog>
  );
});
