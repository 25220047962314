import { getBuySideQualification } from '../../common/Qualification/QualificationActions';
import { getRateSelector } from '../lockSummary/actionCreators';

export const getUpdatedSummary = async (selectedPrice, loanData, dispatch) => {
  const newLoanData =
    selectedPrice !== 'currentPrice'
      ? loanData
      : {
          ...loanData,
          relockRequest: {
            ...loanData?.relockRequest,
            effectiveDate: '',
          },
        };
  await getBuySideQualification('', newLoanData, dispatch);
  return getRateSelector(dispatch, newLoanData, null);
};
