import React, { useState, useEffect } from 'react';
import { DataTable } from '@elliemae/ds-data-table';
import { Grid } from '@elliemae/ds-grid';
import { DSRadio } from '@elliemae/ds-form';
import { InfoCircle } from '@elliemae/ds-icons';
import { v4 as uuidv4 } from 'uuid';
import {
  StyledContainer,
  StyledHeaderGroupBox,
  StyledScenarioCell,
  StyledProviderCell,
  StyledDataGroupCell,
  StyledDataCell,
} from './styled';

const renderDataGroupCell = (border, isExpanded) => (
  <StyledDataGroupCell showBorder={border && isExpanded} />
);

const renderDataCell = (content, border) => (
  <StyledDataCell showBorder={border}>{content}</StyledDataCell>
);

const getColumn = (n?: any, border?: any) => {
  return {
    id: `custom-cr-${n}`,
    Header: () => (
      <div style={{ width: '100%', textAlign: 'right' }}>{`Header ${n}`}</div>
    ),
    Cell: ({ row }) => {
      const { provider } = row.original;
      return provider.includes('Scenario')
        ? renderDataGroupCell(border, row.isExpanded)
        : renderDataCell(row.original[`col${n}`], border);
    },
    width: 80,
  };
};

const renderScenarioCell = (content, isExpanded) => (
  <StyledScenarioCell className={isExpanded ? 'border-right-cell' : ''}>
    <span style={{ marginRight: '5px', fontWeight: 'bold' }}>{content}</span>
    <InfoCircle color={['brand-primary', '600']} />
  </StyledScenarioCell>
);

const renderProviderCell = (content) => (
  <StyledProviderCell className="provider-cell">
    <DSRadio value="1" />
    <InfoCircle color={['brand-primary', '600']} />
    <span style={{ marginLeft: '5px' }}>{content}</span>
  </StyledProviderCell>
);

const colProvider = {
  id: `custom-provider-col`,
  Header: 'Provider',
  Cell: ({ row }) => {
    const { provider } = row.original;
    return provider.includes('Scenario')
      ? renderScenarioCell(provider, row.isExpanded)
      : renderProviderCell(provider);
  },
  width: 150,
};

const columns = [
  colProvider,
  getColumn(1),
  getColumn(2),
  getColumn(3, true),
  getColumn(4),
  getColumn(5),
  getColumn(6, true),
  getColumn(7),
  getColumn(8),
  getColumn(9, true),
];

const generateRecord = (id: number, level = 0) => {
  const record = {
    provider: level === 0 ? `Scenario ${id + 1}` : `Provider ${id + 1}`,
    col1: level === 0 ? `` : `${id + 1} - col 1`,
    col2: level === 0 ? `` : `${id + 1} - col 2`,
    col3: level === 0 ? `` : `${id + 1} - col 3`,
    col4: level === 0 ? `` : `${id + 1} - col 4`,
    col5: level === 0 ? `` : `${id + 1} - col 5`,
    col6: level === 0 ? `` : `${id + 1} - col 6`,
    col7: level === 0 ? `` : `${id + 1} - col 7`,
    col8: level === 0 ? `` : `${id + 1} - col 8`,
    col9: level === 0 ? `` : `${id + 1} - col 9`,
    myUniqueRowAccessor: uuidv4(),
  } as any;

  if (level === 0)
    record.subRows = [
      generateRecord(0, level + 1),
      generateRecord(1, level + 1),
      generateRecord(2, level + 1),
      generateRecord(3, level + 1),
      generateRecord(4, level + 1),
    ];
  return record;
};

const genRows = (n: number) =>
  new Array(n).fill({}).map((_, id) => generateRecord(id));

export const CustomSelectionDataTable = () => {
  const mockColumns = React.useMemo(() => columns, []);
  const [expandedRows, setExpandedRows] = React.useState({});
  const [mockData, setMockData] = useState([]);

  useEffect(() => {
    setMockData(genRows(3) as any);
  }, []);

  const uniqueRowAccessor = 'myUniqueRowAccessor';

  return (
    <StyledContainer>
      <Grid
        cols={['1100px', 'auto']}
        style={{ paddingTop: '40px', position: 'relative' }}
      >
        <DataTable
          columns={mockColumns}
          data={mockData}
          height={500}
          isExpandable
          uniqueRowAccessor={uniqueRowAccessor}
          expandedRows={expandedRows}
          onRowExpand={(newExpandedRows) => {
            setExpandedRows(newExpandedRows);
          }}
        />
        <StyledHeaderGroupBox leftPos="174px">
          Initial Premium at Closing
        </StyledHeaderGroupBox>
        <StyledHeaderGroupBox leftPos="414px">
          First Renewal
        </StyledHeaderGroupBox>
        <StyledHeaderGroupBox leftPos="654px">
          Second Renewal
        </StyledHeaderGroupBox>
      </Grid>
    </StyledContainer>
  );
};
