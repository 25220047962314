export const REJECT_LABEL = 'Stay';
export const CONFIRM_LABEL = 'Leave';
export const PRICING_TEXT = 'Pricing ID ';
export const TITLE_TEXT = 'ICE PPE';
export const HELP_TEXT = 'Online Help';
export const MODAL_TEXT = 'Leave ICE PPE?';
export const MODAL_SUMMARY = 'If you have unsaved changes they will be lost.';
export const HISTORICAL_PRICING_TEXT = 'Currently Showing Historical Pricing';
export const LO_PAGE_TITLES = {
  '/search-form': 'Search',
  '/search-results': 'Results',
  '/lock-summary': 'Summary',
};
export const SEC_BUYSIDE_PAGE_TITLES = {
  '/search-form': 'Search',
  '/search-results': 'Results',
  '/lock-summary': 'Buy Side Pricing',
};
export const SEC_SELLSIDE_PAGE_TITLES = {
  '/search-form': 'Search',
  '/search-results': 'Results',
  '/lock-summary': 'Sell Side Pricing',
};
export const LOCK_EXTENSION_TITLES = {
  '/lock-extension': 'Lock Extension',
};
