import { applyMiddleware, createStore, AnyAction } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { rootReducer } from '../common/reducers/index';
import { rootSaga } from './rootSagas';

const logger = createLogger({
  // ...options
});
// TODO(ngen): merge from other files here
export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = ThunkDispatch<AppState, unknown, AnyAction>;
export const configureStore = (preloadedState?) => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [thunk, sagaMiddleware, logger];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  if (
    process.env.NODE_ENV !== 'prod' &&
    process.env.NODE_ENV !== 'uat' &&
    module.hot
  ) {
    module.hot.accept('common/reducers', () =>
      store.replaceReducer(rootReducer),
    );
  }
  sagaMiddleware.run(rootSaga);
  return store;
};
