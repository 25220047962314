export default (search) => {
  const params = search
    .replace(/^\?/, '')
    .split('&')
    .map((v) => {
      const [key, value] = v.split('=');
      return { [key]: value };
    })
    .reduce((a, b) => Object.assign(a, b), {});

  return params;
};
