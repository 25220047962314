import { DSButton } from '@elliemae/ds-button';
import { Grid } from '@elliemae/ds-grid';
import {
  useLoanDataFieldSelectorHook,
  useLookupOptions,
} from '@frontend/pricing-search';
import { func } from 'prop-types';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openGlobalErrorModalAction } from '../../../../data/globalErrorModal/actionCreators';
import { ComboBoxForm } from '../../../components/Form';
import { setLoanDataValue } from '../../../components/Form/utils/common';
import { useSearchFormSelector } from '../../../components/Form/utils/customHooks';
import * as dependencies from '../../../SearchForm/components/ProductInformationForm/utils/dependencies';
import { productTypeValidation } from '../../../SearchForm/components/ProductInformationForm/utils/handlers';

export const QuickSearch = ({ onSearch }: any = {}) => {
  const dispatch = useDispatch();
  const loanData = useLoanDataFieldSelectorHook() as any;
  const errorForm = (useSearchFormSelector('errorForm.errorFields') as any)
    .value;
  const [state, setState] = useState<any>({});
  const lockdays = useLookupOptions('lockdays') as any[];

  const handleOnchange = ({ value, pathValue }) => {
    setState((prev) => ({ ...prev, [pathValue]: value }));
  };

  const handleSetLoanDataValue = useCallback(({ value, pathValue }) => {
    const parseValue = ['', undefined, 'none'].includes(value) ? null : value;
    dispatch(setLoanDataValue(parseValue, pathValue) as any);
  }, []);

  // Reconcile lock day from loan with lock days from settings
  const reconLockDays = (value) => {
    let initialValue = value[0];
    const lockdaysArr: any[] = [];
    for (let i = 0; i < lockdays.length; i += 1) {
      lockdaysArr.push(lockdays[i].value);
    }
    while (lockdaysArr.indexOf(initialValue) === -1) {
      initialValue += 1;
    }
    return [initialValue];
  };

  const handOnChangeForLoanTerm = (fieldData) => {
    const { value, options, pathValue } = fieldData;
    const helocValidation = { productType: value, lookupsLoanTerms: options };
    const { errorMessage, ...rest } = productTypeValidation(helocValidation);
    if (rest.hasError) {
      dispatch(openGlobalErrorModalAction({ errorMessage }) as any);
      value.pop();
    }
    handleOnchange({ value, pathValue });
  };

  const handleRefreshButton = () => {
    if (!errorForm.length) {
      const refreshLoanData = { ...loanData, ...state };
      onSearch(refreshLoanData, false);
    }
  };

  return (
    <div>
      <span>
        <b>Simple Search</b>
      </span>
      <Grid
        cols={['auto', '80px']}
        gutter="xs"
        marginBottom="15px"
        marginTop="15px"
        data-testid="search-results-quick-search"
      >
        <Grid cols={[1, 2, 2, 2, 2]} gutter="xs">
          <ComboBoxForm
            name="lockdays"
            customDisabled={() => {
              const { requestAction, requestLockStatus } = loanData;
              if (requestAction === 2 && requestLockStatus === 2) {
                return true;
              }
              return false;
            }}
            isNonClearable
            label="Lock Period"
            pathValue="lockDays"
            customValue={({ value }) => {
              let initialValue = value;
              if (!value || !value[0]) {
                initialValue = [30];
              } else {
                initialValue = reconLockDays(initialValue);
              }
              handleOnchange({ value: initialValue, pathValue: 'lockDays' });
              return initialValue;
            }}
            onChangeV2={({ value, ...rest }) => {
              const parseValue = Array.isArray(value) ? value : [value];
              handleOnchange({ value: parseValue, ...rest } as any);
            }}
          />
          <ComboBoxForm
            isMulti
            required
            maxOptions={8}
            name="loanTerms"
            label="Loan Terms"
            pathValue="productType"
            onChangeV2={handOnChangeForLoanTerm}
            dependencies={dependencies.CUSTOM_TERM}
          />
          <ComboBoxForm
            isMulti
            name="standardProducts"
            label="Standard Products"
            pathValue="standardProducts"
            onChangeV2={(data) => {
              handleSetLoanDataValue(data);
            }}
            dependencies={dependencies.STANDAR_PRODUCTS}
          />
          <ComboBoxForm
            isMulti
            name="specialProducts"
            label="Special Products"
            pathValue="specialProducts"
            onChangeV2={(data) => {
              handleSetLoanDataValue(data);
            }}
            dependencies={dependencies.SPECIAL_PRODUCTS}
          />
          <ComboBoxForm
            isMulti
            name="productOptions"
            label="Product Options"
            pathValue="productOptions"
            onChangeV2={handleOnchange}
          />
        </Grid>
        <Grid paddingTop="15px">
          {/* TODO this component need to be changed for the new version V2 */}
          <DSButton
            data-testid="quick-search-search-button"
            buttonType="secondary"
            labelText="Qualify"
            style={{ width: '100%' }}
            disabled={errorForm.length}
            onClick={handleRefreshButton}
          />
        </Grid>
      </Grid>
    </div>
  );
};

QuickSearch.propTypes = {
  onSearch: func.isRequired,
};
