import { getAppConfigValue } from '@elliemae/pui-app-sdk';
import Session, { TRANS_ID } from '../../common/services/Session';
import api from '../../common/utils/api';

// TODO this is not a component nor a view, we should move it to a better place
class AuthenticationService {
  async setUserEPPSMapping(username, password) {
    const body = {
      transactionId: Session.get(TRANS_ID),
      eppsUserPassword: password,
      eppsUserName: username,
    };
    const data = await api.setUserEPPSMapping(
      { body: JSON.stringify(body) },
      username,
    );
    return data;
  }

  async setUserEPPSMappingWebhook(username, password) {
    const body = {
      ssoUserId: 'me',
      eppsUserPassword: password,
    };
    const data = await api.setUserEPPSMapping(
      {
        body: JSON.stringify(body),
        customToken: {
          value: getAppConfigValue<string>('partner-ui.JWT_TOKEN_KEY'),
        },
        tokenType: '',
      },
      username,
    );
    return data;
  }

  async refreshJWT(username, token) {
    const data = await api.refreshJWT(
      { customToken: { value: token }, tokenType: 'Bearer' },
      username,
    );
    return data;
  }

  async login(username, password) {
    const body = { userPassword: password };
    const data = await api.login({ body: JSON.stringify(body) }, username);
    return data;
  }
}
export default new AuthenticationService();
