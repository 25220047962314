import { getAppConfigValue } from '@elliemae/pui-app-sdk';
import { errorMessages } from '../../common/constants/Messages';
import Common from '../../common/services/Common';
import Session, { JWT_TOKEN, USER_TYPE } from '../../common/services/Session';
import { setRedirectTabAction } from '../../data/landing/actions';
import { closeLoader, openLoader } from '../../data/screenLoader/actions';
import { PATH_LOCK_SUMMARY, PATH_SEARCH_RESULTS } from '../../route/constants';
import LoginService from './LoginService';

export const getLogin =
  ({ userName, password }) =>
  async (dispatch) => {
    const { INVALID_USER, GENERAL_ERROR_MODAL } = errorMessages;
    dispatch(openLoader('Authenticating User'));
    let result: any = '';
    try {
      result = await LoginService.login(userName, password);
      if (result.token) {
        Session.set(JWT_TOKEN, result.token);
        Session.set(USER_TYPE, { userName, userType: 'StandAlone' });
        await Promise.all([Common.setUserRoles(), Common.setClientRoles()]);

        if (getAppConfigValue<boolean>('partner-ui.debugBuyside')) {
          dispatch(setRedirectTabAction(PATH_LOCK_SUMMARY));
        } else {
          dispatch(setRedirectTabAction(PATH_SEARCH_RESULTS));
        }
      } else {
        result = new Error(INVALID_USER).message;
        dispatch(closeLoader());
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      result = new Error(GENERAL_ERROR_MODAL).message;
      dispatch(closeLoader());
    }
    return result;
  };
