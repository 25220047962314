import { BANNER_TYPES, DSBanner } from '@elliemae/ds-banner';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setLockedProgramData } from '../../../../data/origin/actions';
import { useLockedProgramSelector } from '../../../../data/useSelectors';
import { PATH_SEARCH_RESULTS } from '../../../../route/constants';

const bodyText = {
  unavailable: ' is no longer available. Please select a new product.',
  ineligible: ' is ineligible.',
  ineligibleRate:
    ': The interest rate that you selected for this program is no longer available.',
};

export const WarningProgramStatus = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status = '', programName = '' } = useLockedProgramSelector();
  const location = useLocation();
  const tabId = location.pathname;

  const viewDetails = (event) => {
    event.preventDefault();
    dispatch(setLockedProgramData({ focusIneligible: true }));
    if (tabId !== PATH_SEARCH_RESULTS) {
      navigate(PATH_SEARCH_RESULTS);
    }
  };

  return (
    <DSBanner
      body={`${programName}${bodyText[status]}`}
      showCloseButton={false}
      isOpen={bodyText[status] !== undefined}
      label="Warning"
      data-testid="WarningProgramStatus-all-components"
      type={BANNER_TYPES.WARNING}
      actionLink={
        status === 'ineligible'
          ? {
              label: 'View Details',
              onClick: viewDetails,
            }
          : ({} as any)
      }
    />
  );
};
