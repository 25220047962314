import { InferType, object, string, mixed } from 'yup';

/* eslint-disable @typescript-eslint/restrict-plus-operands */
export const StringEnumObjectDto = object({
  displayText: string().required(),
  value: mixed((input): input is any => input),
});

export type StringEnumObject = InferType<typeof StringEnumObjectDto>;

export const isBrowser = () => typeof window !== 'undefined';

export function delay(ms: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((res) => setTimeout(res, ms));
}

export function parseNumber(
  x: string | number | undefined | null,
  defaultValue: number | null = NaN,
): number {
  if (typeof x === 'number' && !Number.isNaN(x)) {
    return x;
  }
  if (typeof x === 'string') {
    const parsedString = x.replace(/[^0-9.-]/gi, '');
    const parsedNumber =
      parsedString.length === 0 ? defaultValue : Number(parsedString);
    return (Number.isNaN(parsedNumber) ? defaultValue : parsedNumber) as any;
  }

  return defaultValue as any;
}

export function compareItems<T>(a: T, b: T, whitelist: string[]) {
  const mapper = (item: T) =>
    (whitelist || Object.keys(item as any)).map((key) =>
      getStringIdentifier(item, key),
    );
  const mappedA = mapper(a);
  const mappedB = mapper(b);
  return (
    mappedA.length === mappedB.length &&
    mappedA.every((value, index) => compareItem(value, mappedB[index]))
  );
}

export function getStringIdentifier(item: any, key: string | number) {
  const value = item?.[key];
  let stringValue: string;
  if (typeof value === 'undefined' || value === null) {
    return `${key}:null`;
  }

  if (typeof value === 'string') {
    stringValue = value;
  } else if (typeof value === 'number') {
    stringValue = value.toString();
  } else if (typeof value === 'boolean') {
    stringValue = value.toString();
  } else if (value instanceof Date) {
    stringValue = value.toISOString();
  } else if (Array.isArray(value)) {
    if (!value.length) {
      stringValue = 'null';
    } else {
      stringValue = value
        .map((val, index) => getStringIdentifier(value, index))
        .join(',');
    }
  } else if (typeof value === 'object') {
    try {
      stringValue = JSON.stringify(value);
    } catch (err) {
      console.error(err);
      console.log('key', key);
      console.log('value', value);
      stringValue = 'null';
    }
  } else {
    throw new Error(`Unsupported type ${value}`);
  }

  return `${key}:${stringValue}`;
}

export function compareItem<T extends number | Array<T> | string | object>(
  a: T,
  b: T,
): boolean {
  if (Array.isArray(a) || Array.isArray(b)) {
    if (
      (Array.isArray(a) &&
        !a.length &&
        (!b || (Array.isArray(b) && !b.length))) ||
      (Array.isArray(b) && !b.length && (!a || (Array.isArray(a) && !a.length)))
    ) {
      return true;
    }

    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((value, index) => compareItem(value, b[index]))
    );
  }

  if (typeof a === 'object') {
    return (
      typeof b === 'object' &&
      compareItem(
        Object.keys(a).map((key) => key + (a as any)[key]),
        Object.keys(b).map((key) => key + (b as any)[key]),
      )
    );
  }
  if (typeof a === 'undefined' || a === null) {
    return typeof b === 'undefined' || b === null;
  }

  return a === b;
}

export function randomUUID(): string {
  // if ((window ?? globalThis)?.crypto?.randomUUID) {
  return (window ?? globalThis)?.crypto?.randomUUID();
  // }

  // eslint-disable-next-line no-restricted-properties, no-bitwise, prefer-exponentiation-operator
  // return `0000${((Math.random() * Math.pow(36, 4)) << 0).toString(36)}`.slice(
  //   -4,
  // );
}
export function waitForCondition(
  fn: () => any,
  options: { timerInterval?: number; timeout?: number } = {},
) {
  const startTime = new Date().getTime();

  return new Promise((res, rej) => {
    // eslint-disable-next-line consistent-return
    const intervalID = setInterval(() => {
      const result = fn();
      if (result) {
        clearInterval(intervalID);
        return res(true);
      }
      const timeout = options.timeout || 15000;
      if (startTime + timeout < new Date().getTime()) {
        clearInterval(intervalID);
        return rej(new Error(`timeout. Task took longer than ${timeout}ms`));
      }
    }, options.timerInterval || 100);
  });
}

export function pause(time: number) {
  return new Promise((res) => {
    setTimeout(() => {
      res(true);
    }, time);
  });
}

export const convertMSToDateString = (
  miliseconds: string | number | undefined,
  locale = 'en-US',
  options: { [key: string]: string } = {},
) => {
  if (!miliseconds) {
    console.log('Invalid input');
    return miliseconds;
  }
  return `${new Date(
    typeof miliseconds === 'string' ? parseInt(miliseconds, 10) : miliseconds,
  ).toLocaleString(locale, options)}`;
};
