import { DSButton } from '@elliemae/ds-button';
import { DSDataGrid } from '@elliemae/ds-datagrids';
import Grid from '@elliemae/ds-grid';
import { DSTextWrapper } from '@elliemae/ds-text-wrapper';
import { bool, shape } from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppData } from '../../../../common/utils/customHooks/useAppData';
import { getValidTextValue } from '../../../../common/utils/mappingData';
import { closeLoader, openLoader } from '../../../../data/screenLoader/actions';
import {
  useLoanInformationField,
  useSummaryDetailsField,
} from '../../../../data/useSelectors';
import { PricingGrid } from '../../../components/PricingGrid';
import { PricingDetailsModal } from '../../../LockSummary/components/PricingDetailsModal';
import {
  getProgramAdjustedAndRawPricesServices,
  getTableDataWithIDs,
} from '../../../LockSummary/components/PricingDetailsModal/helpers';
import { CompensationFields } from './components/CompensationFields';
import { adjustmentColumns } from './helpers';
import {
  ProgramNameStyled,
  StyledTileGrids,
  StyledTileMarginGrids,
} from './styled';

const defaultTableData = {
  rawPrice: [],
  adjustedPrice: [],
};

export const GridPriceDetails = ({ isSlide, data, showText }) => {
  const tableDataContext = useAppData('dataTableContext');
  const summary = useSummaryDetailsField();
  if (isSlide) {
    adjustmentColumns[0].label = 'MARGIN ADJUSTMENT';
    adjustmentColumns[1].label = 'MARGIN';
  }

  const dispatch = useDispatch();
  const { loanId } = useSelector(({ EPPS }) => EPPS.loanPrograms || {});
  const loanIdLoanData = useLoanInformationField('loanId');
  const displayedLoanId = loanId || loanIdLoanData;

  const [isOpenPricingDetails, setOpenPricingDetails] = useState(false);
  const [tableData, setTableData] = useState<any>(defaultTableData);

  const fetchAndStorePricingDetails = () => {
    dispatch(openLoader('Calculating Adjusted Prices and Raw Prices'));
    getProgramAdjustedAndRawPricesServices(displayedLoanId)
      .then((apiData) => {
        setTableData(getTableDataWithIDs(apiData));
        dispatch(closeLoader());
      })
      .catch(() => dispatch(closeLoader()));
  };

  const togglePricingDetails = () => {
    setOpenPricingDetails(!isOpenPricingDetails);
    if (tableData.adjustedPrice.length === 0) {
      fetchAndStorePricingDetails();
    }
  };

  useEffect(() => {
    if (tableDataContext) {
      const { adjustedPrice, rawPrice } = tableDataContext;
      if (adjustedPrice?.length > 0 || rawPrice?.length > 0) {
        setTableData(tableDataContext);
      }
    }
  }, [tableDataContext]);

  return (
    <div>
      {!isSlide && (
        <PricingDetailsModal
          togglePricingDetails={togglePricingDetails}
          isOpenPricingDetails={isOpenPricingDetails}
          tableData={tableData}
        />
      )}
      <Grid cols={isSlide ? [1] : [1, 1]} gutter="s">
        <Grid>
          {!isSlide && (
            <StyledTileGrids>
              <span>Pricing</span>
              <DSButton
                onClick={togglePricingDetails}
                buttonType="secondary"
                labelText="Pricing Details"
                size="m"
              />
            </StyledTileGrids>
          )}
          <ProgramNameStyled>{data.programName}</ProgramNameStyled>
          {isSlide && <CompensationFields />}
          <PricingGrid adjustmentRows={data.pricingRows} />
        </Grid>
        {data.showMarginTable && (
          <Grid height="245px">
            {!isSlide && (
              <StyledTileMarginGrids>
                <span>Margin Adjustment</span>
              </StyledTileMarginGrids>
            )}
            <DSDataGrid
              columns={adjustmentColumns}
              rows={data.adjustmentRows}
            />
          </Grid>
        )}
      </Grid>

      {showText && (
        <>
          <DSTextWrapper
            className="section-title"
            style={{ paddingTop: 20, paddingBottom: 10 }}
            text="Bona Fide Discount Point Assessment"
          />
          {data.bonaFideTestResults ? (
            <div
              dangerouslySetInnerHTML={{ __html: data.bonaFideTestResults }} // eslint-disable-line react/no-danger
            />
          ) : (
            <>
              <div>{`The APOR is ${Number(
                getValidTextValue(summary, 'lien', 'apor'),
              ).toFixed(3)}%.`}</div>
              <div>
                {`The Starting Adjusted Rate is ${Number(
                  getValidTextValue(summary, 'lien', 'startingAdjustedRate'),
                ).toFixed(3)}%. The
              Starting Adjusted Price is ${(
                100 -
                Number(
                  getValidTextValue(summary, 'lien', 'startingAdjustedPrice'),
                )
              ).toFixed(3)}.`}
              </div>
              <div>
                The APOR Discount Point Exclusion Test was not run as pricing is
                equal to or above Par.
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

GridPriceDetails.defaultProps = { isSlide: false, data: {}, showText: true };

GridPriceDetails.propTypes = { isSlide: bool, data: shape({}), showText: bool };
