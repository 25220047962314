import { DataTable } from '@elliemae/ds-data-table';
import { ModalHeader } from '@elliemae/ds-modal-slide';
import { useLoanDataFieldSelectorHook, useLookup } from '@frontend/pricing-search';
import { chain, cloneDeep, get } from 'lodash';
import { arrayOf, func, objectOf, shape } from 'prop-types';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Rights from '../../../../common/services/Rights';
import Session, {
  ALL_SUMMARY_PARAMS,
  IS_BUYSIDE,
  IS_SELLSIDE,
  IS_VALIDATE,
} from '../../../../common/services/Session';
import { calcDeltasBestEx } from '../../../../common/utils/calculateTarget';
import { useAppData } from '../../../../common/utils/customHooks/useAppData';
import { selectProduct } from '../../../../data/lockSummary/actions';
import {
  useAdjustmentDetailsCache,
  useLoanInformationField,
  useSummaryDetailsField,
} from '../../../../data/useSelectors';
import { PATH_LOCK_SUMMARY } from '../../../../route/constants';
import { getAdjustmentDetails } from '../../../AdjustmentDetails/AdjustmentDetailsAction';
import { ModalSlide } from '../../../components/ModalSlide';
import { StyledDataTableWrapper } from '../../../global/styles/styled';
import { GridPriceDetails } from '../GridPriceDetails/GridPriceDetails';
import { transformRateSelectorDataToSlide } from '../GridPriceDetails/helpers';
import { BestExToolbar } from './bestExToolbar';
import { DropdownDetails } from './DropdownDetails';
import { getBestExColumns } from './helpers';
import { StyledTableTitle } from './styled';

const TitleRows = ({ row }) => (
  <StyledTableTitle height={36} pl="xxs">
    {row.original.dimsumHeaderValue}
  </StyledTableTitle>
);

export const BestExGrid = memo<any>(
  ({
    productGroups,
    loanPrograms,
    target,
    ratesBestEx,
    lockDaysBestEx,
    onSearch,
    updateAdjustmentDetailsCache,
  }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const refBestExecution = useAppData('refBestExecution');
    const setBestExecution = useAppData('setBestExecution');
    const { loanId } = useSelector(({ EPPS }) => EPPS.loanPrograms || {});
    const loanData = useLoanDataFieldSelectorHook() as any;
    const { requestAction, requestLockStatus } = loanData;
    const disableLockLinks = requestAction === 2 && requestLockStatus === 2;
    const eppsUserName = loanData.eppsUserName || null;
    const lienPosition = useLoanInformationField('lienPosition', 1);
    const {
      lien: { armIndexValue = 0, arm = false } = {},
      loan: { mortgageType = '' } = {},
    } = useSummaryDetailsField() as any;
    const heloc = useMemo(() => mortgageType === 'HELOC', [mortgageType]);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const { relockRequest } = useSelector(({ EPPS }) => EPPS.loanInfo || {});
    const [slide, setSlide] = useState<any>({
      open: false,
      data: null,
      rowData: null,
    });
    const [expandedRows, setExpandedRows] = useState({});
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isBuyside = Session.get(IS_BUYSIDE);
    const isSellside = Session.get(IS_SELLSIDE);
    const isValidate = Session.get(IS_VALIDATE);
    const clientsettings = useLookup('clientSettings', '');
    const isBuySideHistorical = get(
      clientsettings,
      '[0].options.isBuySideHistorical',
    );
    const showLoanOfficer =
      Rights.AssignLoanOfficer && (isBuyside || isSellside || isValidate);
    const handleSelectProduct = (programID, rateDataID) => {
      Session.set(ALL_SUMMARY_PARAMS, {
        loanId,
        programId: programID,
        rateDataId: rateDataID,
      });
      navigate(PATH_LOCK_SUMMARY);
    };

    const handleRefreshButton = useCallback((lock) => {
      // TODO(ngen): this was missing dep array
      const lockData = { lockDays: [Number(lock)] };
      const refreshLoanData = { ...loanData, ...lockData };
      onSearch(refreshLoanData, false);
    }, []);

    const handleSelectRow = (selection) => {
      setSelectedRow(selection);
      dispatch(selectProduct(selection));
    };

    const adjustmentDetailsCache = useAdjustmentDetailsCache() || [];

    useEffect(() => {
      if (slide.rowData && !slide.open) {
        const { programID: programId } = slide.rowData;
        const rateDataId = slide.rateData
          ? slide.rateData
          : slide.rowData.rateDataID;
        const searchParams: any = showLoanOfficer
          ? { loanId, programId, rateDataId, lienPosition, eppsUserName }
          : { loanId, programId, rateDataId, lienPosition };
        const existingDetail = adjustmentDetailsCache.find(
          (detail) =>
            detail.programId === programId && detail.rateDataId === rateDataId,
        );

        /**
         * row data does not have a program field or program Name so to add the program Name
         * we find the program from the loanPrograms and attach the program name to the slide data
         */
        if (existingDetail && !existingDetail.programName) {
          const prog = loanPrograms.find(
            (program: any) => program.programID === programId,
          );
          existingDetail.programName = prog.program;
        }

        if (existingDetail) {
          setSlide({
            open: true,
            data: existingDetail.dataSlide,
            rowData: null,
            rateData: null,
          });
        } else {
          dispatch(getAdjustmentDetails(searchParams) as any).then(
            (response) => {
              if (response && response.adjustments) {
                const dataSlide = transformRateSelectorDataToSlide(
                  response.adjustments,
                  armIndexValue,
                ) as any;
                dataSlide.showMarginTable = arm || heloc;
                dataSlide.bonaFideTestResults = response.bonaFideTestResults;

                const prog = loanPrograms.find(
                  (program: any) => program.programID === programId,
                );
                if (prog) {
                  dataSlide.programName = prog.program;
                }
                const updatedAdjustmentDetailsCache = [
                  ...adjustmentDetailsCache,
                  {
                    programId,
                    rateDataId,
                    dataSlide,
                  },
                ];
                dispatch(
                  updateAdjustmentDetailsCache(updatedAdjustmentDetailsCache),
                );
                setSlide({
                  open: true,
                  data: dataSlide,
                  rowData: null,
                  rateData: null,
                });
              }
            },
          );
        }
      }
    }, [slide]);

    useEffect(() => {
      setExpandedRows({});
    }, [ratesBestEx]);

    // Handle window resize for program row width calculation
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const columns = getBestExColumns(
      relockRequest,
      selectedRow?.id || '',
      handleSelectProduct,
      handleRefreshButton,
      handleSelectRow,
      disableLockLinks,
      windowWidth,
      isBuyside,
      isBuySideHistorical,
    );

    const ratesByProductGroup = useMemo(() => {
      const cloneBestEx = cloneDeep(ratesBestEx);
      return chain(cloneBestEx)
        .groupBy('productGroupID')
        .map((v) => {
          return {
            productGroupID: v[0].productGroupID,
            rateDetails: v,
          };
        })
        .value();
    }, [ratesBestEx, target]);

    const mappedProductGroups = Array.isArray(productGroups)
      ? productGroups.map((productGroup, index) => ({
          ...productGroup,
          positionGroup: index,
          rateDetails: ratesByProductGroup.find(
            (r) => r.productGroupID === productGroup.productGroupID,
          )?.rateDetails,
        }))
      : [];

    const addTableRowDetails = (programs: any[]) => {
      return programs.map((program, index) => ({
        ...program,
        id: index + 1,
        setSlide,
        handleSelectRow,
        tableRowDetails: DropdownDetails,
      }));
    };

    const addGroupedLockDays = (programs) => {
      const lockDaysMap = {};
      if (Array.isArray(lockDaysBestEx))
        lockDaysBestEx.forEach((lock) => {
          if (!lockDaysMap[lock.productGroupID]) {
            lockDaysMap[lock.productGroupID] = [];
          }
          lockDaysMap[lock.productGroupID].push(lock.lockDays.toString());
        });
      programs.forEach((program) => {
        if (lockDaysMap[program.productGroupID]) {
          program.locksAvailable =
            lockDaysMap[program.productGroupID].join(',');
        }
      });
      return programs;
    };

    const generateGroupedRows = useMemo(() => {
      let calcDeltas =
        calcDeltasBestEx(mappedProductGroups, ratesBestEx, target) || [];
      calcDeltas = addTableRowDetails(calcDeltas);
      calcDeltas = addGroupedLockDays(calcDeltas);
      return calcDeltas;
    }, [ratesBestEx, target]);

    useEffect(() => {
      if (generateGroupedRows.length > 0 && !refBestExecution.current) {
        setBestExecution(generateGroupedRows.length);
        refBestExecution.current = true;
      } else {
        setBestExecution(generateGroupedRows.length);
      }
      return () => {
        refBestExecution.current = false;
      };
    }, [generateGroupedRows]);

    return (
      <div
        style={{
          height: '100%',
          flexDirection: 'column',
          display: 'flex',
          paddingTop: 8,
        }}
      >
        <ModalSlide
          isOpen={slide.open}
          header={
            <ModalHeader
              title="Pricing Details"
              onClose={() =>
                setSlide({
                  open: false,
                  data: null,
                  rowData: null,
                  rateDataID: null,
                })
              }
            />
          }
        >
          {slide.data && (
            <div style={{ padding: 16 }}>
              <GridPriceDetails isSlide data={slide.data} />
            </div>
          )}
        </ModalSlide>
        <BestExToolbar />
        <StyledDataTableWrapper>
          <DataTable
            colsLayoutStyle="auto"
            columns={columns}
            data={generateGroupedRows}
            isExpandable
            uniqueRowAccessor="id"
            expandedRows={expandedRows}
            onRowExpand={(newExpandedRows) => {
              setExpandedRows(newExpandedRows);
            }}
            cellRendererProps={{
              onClickPrice: (rowData) => {
                setSlide({
                  rowData,
                  rateDataID: null,
                  open: false,
                  data: null,
                });
              },
            }}
            onRowClick={(rowData) => {
              handleSelectRow(rowData.target);
            }}
            getRowVariant={(row) => {
              if (row.original.Component) return row.original.Component;
              return 'ds-primary-row';
            }}
            noResultsMessage="No Products matched your request"
          />
        </StyledDataTableWrapper>
      </div>
    );
  },
);

TitleRows.defaultProps = {
  row: {},
};

TitleRows.propTypes = {
  row: objectOf(shape({})),
};

(BestExGrid as any).defaultProps = {
  productGroups: [],
  loanPrograms: [],
  ratesBestEx: [],
  lockDaysBestEx: [],
  target: [],
  onSearch: () => null,
  updateAdjustmentDetailsCache: () => null,
};

(BestExGrid as any).propTypes = {
  productGroups: arrayOf(shape({})),
  loanPrograms: arrayOf(shape({})),
  ratesBestEx: arrayOf(shape({})),
  lockDaysBestEx: arrayOf(shape({})),
  target: arrayOf(shape({})),
  onSearch: func,
  updateAdjustmentDetailsCache: func,
};
