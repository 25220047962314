/* eslint-disable @typescript-eslint/default-param-last */
import {
  calculateTotalLoanAmount,
  SchemaAndRules,
} from '@frontend/pricing-search';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  GET_COUNT_PAGEDATA,
  GET_ENCOMPASS_ELEMENTS,
  GET_FILTERS_SUCCESS,
  GET_RATES_SUCCESS,
  SET_ALL_RATES_ROW_DATA,
  SET_LOANPROPERTIES,
  SET_LOCK_EXTENSION_DATA,
  SET_ORIGINALLOANINFORMATION,
  SET_SEARCH_CONFIGURATION,
  SET_TAB_KEY,
  SET_USERDATA,
  SWITCH_ERROR_MODAL,
  UPDATE_ADJUSTMENT_DETAILS_CACHE,
  UPDATE_ERRORS_LIST,
  UPDATE_LOAN_ID,
  UPDATE_LOAN_INFO_RELOCK_REQUEST,
  UPDATE_LOANINFO,
  UPDATE_ORIGINAL_LOAN_LOCKREQUEST,
  UPDATE_ORIGINAL_LOANINFO,
  UPDATE_PROPERTY_DETAILS_CACHE,
} from '../constants/ActionConstants';
import rates from '../Qualification/QualificationReducer';
import getSavedState from '../utils/getSavedState';

export interface LoanInformation {
  loanId?: string;
  target?: number;
  targetLabel?: string;
  totalMortgageAmount?: number;
  firstMortgageAmount?: number;
  financedAmount?: number;
  feeAmountFinanced?: number;
}

export interface Borrower {
  id?: string;
  [key: string]: any;
}

export interface Property {
  otherRealEstateOwned?: Array<{ id?: string; [key: string]: any }>;
  [key: string]: any;
}

export interface LoanInfo {
  loanInformation?: LoanInformation;
  borrowers?: Borrower[];
  property?: Property;
  relockRequest?: { [key: string]: any };
  [key: string]: any;
}

export interface EPPSState {
  loanPrograms?: any;
  loanInfo?: LoanInfo;
  encompassElements?: any;
  errorPageData?: any;
  filters?: any;
  loanProperties?: any;
  extensionData?: any;
  originalLoanInformation?: any;
  originalLoanInfo?: any;
  errors?: any[];
  showGlobalErrorModal?: boolean;
  globalErrorModalTitle?: string;
  globalErrorModalMessage?: string;
  globalErrorModalOnClose?: () => void;
  tabs?: any;
  allRatesRowData?: any;
  originalLoanLockRequest?: any;
  adjustmentDetailsCache?: any;
  propertyDetailsCache?: any;
  userData?: any;
  searchConfiguration: SchemaAndRules | null;
}
const INITIAL_STATE = getSavedState('EPPS', {}) as EPPSState;

export default function EPPS(state: EPPSState = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_RATES_SUCCESS:
      return {
        ...state,
        loanPrograms: rates(state.loanPrograms, action),
      };
    case UPDATE_LOAN_ID:
      return {
        ...state,
        loanInfo: {
          ...state.loanInfo,
          loanInformation: {
            ...state?.loanInfo?.loanInformation,
            loanId: rates(state?.loanInfo?.loanInformation, action),
          },
        },
      };
    case GET_ENCOMPASS_ELEMENTS:
      return {
        ...state,
        // encompassElements: summary(state.encompassElements, action),
      };
    case GET_COUNT_PAGEDATA:
      return {
        ...state,
        errorPageData: action.payload,
      };
    case GET_FILTERS_SUCCESS:
      return {
        ...state,
        filters: rates(state.filters, action),
      };
    case UPDATE_LOANINFO: {
      // TODO all these changes should go in the action, not the reducer
      let newBorrowers = action.payload.borrowers;
      const { target, targetLabel } = action.payload.loanInformation;
      const { loanInformation } = action.payload;

      if (!isEmpty(newBorrowers)) {
        newBorrowers = newBorrowers.map((borrower) => {
          const newBorrower = { ...borrower };
          if (!newBorrower.id) newBorrower.id = uuidv4();
          return newBorrower;
        });
      }
      let newOtherRealEstate = action.payload.property.otherRealEstateOwned;
      if (!isEmpty(newOtherRealEstate)) {
        newOtherRealEstate = newOtherRealEstate.map((estate) => {
          const newEstate = { ...estate };
          if (!newEstate.id) newEstate.id = uuidv4();
          return newEstate;
        });
      }

      loanInformation.totalMortgageAmount = calculateTotalLoanAmount(
        loanInformation.firstMortgageAmount,
        loanInformation.financedAmount,
        loanInformation.feeAmountFinanced,
      );
      if (!targetLabel) {
        if (target > 80) {
          loanInformation.targetLabel = 'Price';
        } else {
          loanInformation.targetLabel = 'Rate';
        }
      }

      return {
        ...state,
        loanInfo: {
          ...action.payload,
          loanInformation: {
            ...loanInformation,
          },
          borrowers: newBorrowers,
          property: {
            ...action.payload.property,
            otherRealEstateOwned: newOtherRealEstate,
          },
        },
      };
    }
    case 'UPDATE_TARGET_LOAN_DATA':
      return {
        ...state,
        loanInfo: {
          ...state.loanInfo,
          loanInformation: {
            ...state?.loanInfo?.loanInformation,
            target: action.payload.value,
            targetLabel: action.payload.label,
          },
        },
      };
    case SET_LOANPROPERTIES:
      return {
        ...state,
        loanProperties: action.payload,
      };
    case SET_LOCK_EXTENSION_DATA:
      return {
        ...state,
        extensionData: action.payload,
      };
    case SET_ORIGINALLOANINFORMATION:
      return {
        ...state,
        originalLoanInformation: action.payload,
      };
    case UPDATE_ORIGINAL_LOANINFO:
      return {
        ...state,
        originalLoanInfo: action.payload,
      };
    case UPDATE_ERRORS_LIST:
      return {
        ...state,
        errors: action.payload || [],
      };
    case SWITCH_ERROR_MODAL:
      return {
        ...state,
        showGlobalErrorModal: action.payload.showErrorModal,
        globalErrorModalTitle: action.payload.title,
        globalErrorModalMessage: action.payload.errorMessage,
        globalErrorModalOnClose: action.payload.onClose,
      };
    case SET_TAB_KEY:
      return {
        ...state,
        tabs: action.payload,
      };
    case SET_ALL_RATES_ROW_DATA:
      return { ...state, allRatesRowData: action.payload };
    case UPDATE_ORIGINAL_LOAN_LOCKREQUEST:
      return {
        ...state,
        originalLoanLockRequest: action.payload,
      };
    case UPDATE_ADJUSTMENT_DETAILS_CACHE:
      return {
        ...state,
        adjustmentDetailsCache: action.payload,
      };
    case UPDATE_PROPERTY_DETAILS_CACHE:
      return {
        ...state,
        propertyDetailsCache: action.payload,
      };
    case SET_USERDATA:
      return {
        ...state,
        userData: action.payload,
      };
    case UPDATE_LOAN_INFO_RELOCK_REQUEST:
      return {
        ...state,
        loanInfo: {
          ...state.loanInfo,
          relockRequest: {
            ...state?.loanInfo?.relockRequest,
            ...action.payload,
          },
        },
      };
    case SET_SEARCH_CONFIGURATION: {
      return {
        ...state,
        searchConfiguration: action.payload as SchemaAndRules,
      };
    }
    default:
      return state;
  }
}
