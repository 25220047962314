import styled from 'styled-components';
import { DSButton } from '@elliemae/ds-button';
import { Grid } from '@elliemae/ds-grid';

export const StyledColorContainer = styled.div`
  color: white;
  background-image: linear-gradient(
    45deg,
    rgb(19, 71, 100) 0%,
    rgb(31, 102, 150) 54%,
    rgb(26, 74, 111) 100%
  );
  margin-bottom: -8px;
`;

export const StyledDebugInfo = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights[100]};
  margin: -1px ${({ theme }) => theme.space.xs} 0px
    ${({ theme }) => theme.space.xs};
`;

export const StyledSlash = styled.div`
  border-left: 1px solid white;
  height: ${({ theme }) => theme.fontSizes.title[600]};
`;

export const StyledCarouselContent = styled(Grid)`
  overflow-x: hidden;
`;

export const StyledArrowButtons = styled(DSButton)`
  height: 50px;
  width: 24px !important;
  border-radius: 0;

  svg path {
    stroke-width: 1px;
    stroke: #1e79c2;
  }
`;
