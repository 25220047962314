import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { DSInputText } from "@elliemae/ds-form-input-text";
import { Grid } from "@elliemae/ds-grid";
import { useState, useEffect, useMemo } from "react";
import { Controller } from "react-hook-form";
import { DSZipCodeSearch } from "@elliemae/ds-zipcode-search";
import { randomUUID } from "@frontend/utils";
import { DSSingleComboBox } from "@elliemae/ds-form-single-combobox";
import {
  useTextboxFieldUpdateLogic,
  useSingleComboboxFieldUpdateLogic
} from "./utils/fieldUpdateLogic";
import { useLookupOptions } from "./utils/utils";
const Address = ({
  schemaForm,
  fields,
  rest
}) => {
  const textboxUpdateLogic = useTextboxFieldUpdateLogic();
  const singleComboboxFieldUpdateLogic = useSingleComboboxFieldUpdateLogic();
  const getFieldByComponentId = (fields2, componentId) => {
    return fields2.find((field) => field.component_id === componentId);
  };
  const streetAddressField = getFieldByComponentId(fields, "Street Address");
  const unitTypeField = getFieldByComponentId(fields, "Unit type");
  const unitNumberField = getFieldByComponentId(fields, "Unit Number");
  const zipCodeField = getFieldByComponentId(fields, "Zip Code");
  const stateField = getFieldByComponentId(fields, "State");
  const cityField = getFieldByComponentId(fields, "City");
  const countyField = getFieldByComponentId(fields, "County");
  const [options, setOptions] = useState([]);
  const propertyCounty = schemaForm.getValues("property.county");
  const propertyState = schemaForm.getValues("property.state");
  const states = useLookupOptions("states");
  const [countyOptions, setCountyOptions] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [countyPlaceholder, setCountyPlaceholder] = useState("Please select");
  const getZips = async (newZip) => {
    setOptions([{ label: "Loading...", value: "" }]);
    const locations = await rest.getZipLocations(newZip);
    if (locations && Array.isArray(locations) && locations.length > 0) {
      const newOpts = locations.map((loc) => ({
        label: `${loc.city}, ${loc.abbrev} - ${loc.county}`,
        value: newZip,
        state: loc.abbrev,
        county: loc.county,
        city: loc.city
      }));
      setOptions(newOpts);
    } else {
      setOptions([]);
    }
  };
  const getCounties = async (stateId) => {
    const counties = await rest.getStateCounties(stateId);
    if (counties && Array.isArray(counties) && counties.length > 0) {
      const newOpts = counties.map((county) => ({
        dsId: `counties-${randomUUID()}`,
        type: "option",
        label: county.key,
        value: county.value
      }));
      const sortedCounties = newOpts.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
      setCountyOptions(sortedCounties);
    } else {
      setCountyOptions([]);
    }
  };
  const allComboboxOptionsUnitType = useMemo(() => {
    const newOpts = unitTypeField?.options.map((option) => ({
      dsId: `${option.id}`,
      type: "option",
      label: `${option.text}`,
      value: `${option.value}`,
      disable: option.disabled
    }));
    return newOpts || [];
  }, [unitTypeField?.options]);
  const allComboboxOptionsState = useMemo(() => {
    const newOpts = stateField?.options.map((option) => ({
      dsId: `${option.id}`,
      type: "option",
      label: `${option.text}`,
      value: `${option.value}`,
      disable: option.disabled
    }));
    return newOpts || [];
  }, [stateField?.options]);
  useEffect(() => {
    if (propertyState !== null && propertyCounty !== null && Array.isArray(states)) {
      const { stateID } = states.find((state) => state.value === propertyState);
      getCounties(stateID);
    }
    if (propertyState === null) {
      setCountyPlaceholder("Select a State");
    } else {
      setCountyPlaceholder("Please select");
    }
  }, [propertyState, propertyCounty]);
  useEffect(() => {
    setSelectedCounty(
      countyOptions.find((county) => county.label === propertyCounty)
    );
  }, [countyOptions]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Controller,
      {
        name: streetAddressField?.encompass_ids?.[0]?.modelPath,
        render: ({ field: renderedField }) => /* @__PURE__ */ jsx(Grid, { span: streetAddressField?.ui.col_size, children: /* @__PURE__ */ jsx(
          DSFormLayoutBlockItem,
          {
            name: streetAddressField?.component_id,
            label: streetAddressField?.label,
            children: /* @__PURE__ */ jsx(
              DSInputText,
              {
                ...renderedField,
                "data-testid": "textbox",
                value: renderedField?.value ?? "",
                onChange: textboxUpdateLogic(
                  renderedField,
                  schemaForm,
                  streetAddressField?.id
                ),
                id: streetAddressField?.encompass_ids?.[0]?.modelPath
              }
            )
          }
        ) })
      }
    ),
    /* @__PURE__ */ jsx(
      Controller,
      {
        name: unitTypeField?.encompass_ids?.[0]?.modelPath,
        render: ({ field: renderedField }) => /* @__PURE__ */ jsx(Grid, { span: unitTypeField?.ui.col_size, children: /* @__PURE__ */ jsx(
          DSFormLayoutBlockItem,
          {
            name: unitTypeField?.component_id,
            label: unitTypeField?.label,
            inputID: unitTypeField?.component_id,
            children: /* @__PURE__ */ jsx(
              DSSingleComboBox,
              {
                ...renderedField,
                allOptions: allComboboxOptionsUnitType,
                onChange: singleComboboxFieldUpdateLogic(
                  renderedField,
                  schemaForm,
                  unitTypeField?.id
                ),
                selectedValue: allComboboxOptionsUnitType.find(
                  (option) => option.value === renderedField?.value?.toString()
                ) || null,
                id: unitTypeField?.encompass_ids?.[0]?.modelPath
              }
            )
          }
        ) })
      }
    ),
    /* @__PURE__ */ jsx(
      Controller,
      {
        name: unitNumberField?.encompass_ids?.[0]?.modelPath,
        render: ({ field: renderedField }) => /* @__PURE__ */ jsx(Grid, { span: unitNumberField?.ui.col_size, children: /* @__PURE__ */ jsx(
          DSFormLayoutBlockItem,
          {
            name: unitNumberField?.component_id,
            label: unitNumberField?.label,
            children: /* @__PURE__ */ jsx(
              DSInputText,
              {
                ...renderedField,
                "data-testid": "textbox",
                value: renderedField?.value ?? "",
                onChange: textboxUpdateLogic(
                  renderedField,
                  schemaForm,
                  unitNumberField?.id
                ),
                id: unitNumberField?.encompass_ids?.[0]?.modelPath
              }
            )
          }
        ) })
      }
    ),
    /* @__PURE__ */ jsx(
      Controller,
      {
        name: zipCodeField?.encompass_ids?.[0]?.modelPath,
        render: ({ field: renderedField }) => /* @__PURE__ */ jsx(Grid, { span: zipCodeField?.ui?.col_size, children: /* @__PURE__ */ jsx(
          DSFormLayoutBlockItem,
          {
            name: zipCodeField?.component_id,
            label: zipCodeField?.label,
            children: /* @__PURE__ */ jsx(
              DSZipCodeSearch,
              {
                ...renderedField,
                "data-testid": "textbox",
                value: renderedField?.value ?? "",
                onSearch: (v) => {
                  if (v.length === 5 || v.length === 10) {
                    getZips(v);
                  } else {
                    setOptions([]);
                  }
                },
                options,
                onChange: (data) => {
                  renderedField.onChange(data.value);
                  if (data?.value !== void 0) {
                    const { stateID } = states.find(
                      (item) => item.value === data?.state
                    );
                    getCounties(stateID);
                    setSelectedCounty(
                      countyOptions.find(
                        (county) => county.label === data.county
                      )
                    );
                    schemaForm.setValue("property.state", data.state);
                    schemaForm.setValue("property.county", data.county);
                    schemaForm.setValue("property.city", data.city);
                  }
                }
              }
            )
          }
        ) })
      }
    ),
    /* @__PURE__ */ jsx(
      Controller,
      {
        name: stateField?.encompass_ids?.[0]?.modelPath,
        render: ({ field: renderedField }) => /* @__PURE__ */ jsx(Grid, { span: stateField?.ui.col_size, children: /* @__PURE__ */ jsx(
          DSFormLayoutBlockItem,
          {
            name: stateField?.component_id,
            label: stateField?.label,
            inputID: stateField?.component_id,
            children: /* @__PURE__ */ jsx(
              DSSingleComboBox,
              {
                ...renderedField,
                allOptions: allComboboxOptionsState,
                onChange: (data) => {
                  renderedField.onChange(data.value);
                  if (data?.value !== void 0) {
                    const { stateID } = states.find(
                      (item) => item.value === data?.value
                    );
                    getCounties(stateID);
                    setSelectedCounty(null);
                    schemaForm.setValue("property.county", null);
                    schemaForm.setValue("property.state", data?.value);
                  }
                },
                selectedValue: allComboboxOptionsState.find(
                  (option) => option.value === renderedField?.value?.toString()
                ) || null,
                id: stateField?.encompass_ids?.[0]?.modelPath,
                placeholder: "Please select"
              }
            )
          }
        ) })
      }
    ),
    /* @__PURE__ */ jsx(
      Controller,
      {
        name: cityField?.encompass_ids?.[0]?.modelPath,
        render: ({ field: renderedField }) => /* @__PURE__ */ jsx(Grid, { span: cityField?.ui.col_size, children: /* @__PURE__ */ jsx(
          DSFormLayoutBlockItem,
          {
            name: cityField?.component_id,
            label: cityField?.label,
            children: /* @__PURE__ */ jsx(
              DSInputText,
              {
                ...renderedField,
                "data-testid": "textbox",
                value: renderedField?.value ?? "",
                onChange: textboxUpdateLogic(
                  renderedField,
                  schemaForm,
                  cityField?.id
                ),
                id: cityField?.encompass_ids?.[0]?.modelPath
              }
            )
          }
        ) })
      }
    ),
    /* @__PURE__ */ jsx(
      Controller,
      {
        name: countyField?.encompass_ids?.[0]?.modelPath,
        render: ({ field: renderedField }) => /* @__PURE__ */ jsx(Grid, { span: countyField?.ui.col_size, children: /* @__PURE__ */ jsx(
          DSFormLayoutBlockItem,
          {
            name: countyField?.component_id,
            label: countyField?.label,
            inputID: countyField?.component_id,
            children: /* @__PURE__ */ jsx(
              DSSingleComboBox,
              {
                ...renderedField,
                allOptions: countyOptions || [],
                onChange: (data) => {
                  renderedField.onChange(data);
                  schemaForm.setValue("property.county", data?.label);
                  setSelectedCounty(data);
                },
                selectedValue: selectedCounty || null,
                id: countyField?.encompass_ids?.[0]?.modelPath,
                placeholder: countyPlaceholder
              }
            )
          }
        ) })
      }
    )
  ] });
};
export {
  Address
};
