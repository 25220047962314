/* eslint-disable @typescript-eslint/default-param-last */
import {
  SET_BUYSIDE_ADJUSTMENTS,
  SET_FIRST_LOAD,
  SET_LOAN_DATA,
  SET_LOCKED_PROGRAM_DATA,
  SET_ORIGINAL_LOAN_DATA,
  UPDATE_BUYSIDE_ADJUSTMENTS,
} from './actions';
import { INITIAL_STATE } from './constants';

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOAN_DATA:
      return {
        ...state,
        loanData: action.payload,
      };
    case SET_ORIGINAL_LOAN_DATA:
      return {
        ...state,
        originalLoanData: action.payload,
      };
    case SET_FIRST_LOAD:
      return {
        ...state,
        isFirstLoad: action.payload,
      };
    // TODO: May be moved with new initializer flow
    case SET_LOCKED_PROGRAM_DATA:
      return {
        ...state,
        lockedProgram: { ...state.lockedProgram, ...action.payload },
      };
    case SET_BUYSIDE_ADJUSTMENTS:
      return {
        ...state,
        buySideAdjustments: action.payload,
      };
    case UPDATE_BUYSIDE_ADJUSTMENTS: {
      const { type, data } = action.payload;
      return {
        ...state,
        buySideAdjustments: { ...state.buySideAdjustments, [type]: data },
      };
    }
    default:
      return state;
  }
};
