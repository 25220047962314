import React from 'react';
// import { useDispatch } from "react-redux";
import { Grid } from '@elliemae/ds-grid';
import { DSControlledCheckbox } from '@elliemae/ds-controlled-form';
import {
  useLoanInformationField,
  // useHedgeLoanSelector,
} from '../../../../../data/useSelectors';
// import { setIsLoanHedged } from "data/lockSummary/actions";
// import { setLoanDataValue } from "components/Form/formCommons";

// EPPS-77113 - commeting code as we will enable it back again in future

export const HedgeLoanCheckbox = () => {
  // const dispatch = useDispatch();
  const isLoanHedged = useLoanInformationField('hedged', false);
  // const hedgeLoanIndicator = useHedgeLoanSelector();
  // const [hedgeCheckbox, setHedgeCheckbox] = useState(isLoanHedged);

  // useEffect(() => {
  //   if (hedgeLoanIndicator === undefined || hedgeLoanIndicator === "") {
  //     setHedgeCheckbox(isLoanHedged);
  //     dispatch(setIsLoanHedged(isLoanHedged));
  //   } else {
  //     setHedgeCheckbox(hedgeLoanIndicator);
  //   }
  // }, [isLoanHedged, hedgeLoanIndicator]);

  // const handleOnHedgeChange = () => {
  //   dispatch(setIsLoanHedged(!hedgeLoanIndicator));
  //   dispatch(
  //     setLoanDataValue(
  //       !hedgeLoanIndicator ? "Y" : "N",
  //       "loanInformation.hedged"
  //     )
  //   );
  // };

  return (
    <Grid>
      <DSControlledCheckbox
        data-testid="custom-data-testid"
        checked={isLoanHedged === 'True'}
        name="hedgeLoan"
        label="Hedge Loan"
        id="hedgeLoan"
        // onChange={handleOnHedgeChange}
        disabled
      />
    </Grid>
  );
};

export default HedgeLoanCheckbox;
