import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Controller } from "react-hook-form";
import { DSHeader } from "@elliemae/ds-header";
import { Grid } from "@elliemae/ds-grid";
import { Textbox } from "./textbox";
import { Combobox } from "./combobox";
import { Checkbox } from "./checkbox";
import { Date } from "./date";
const Borrowerdetails = ({
  schemaForm,
  fields,
  preview
}) => {
  const fieldComponentMap = {
    textbox: Textbox,
    combobox: Combobox,
    checkbox: Checkbox,
    date: Date
  };
  const borrowers = preview ? [{ id: "preview-borrower" }] : schemaForm.getValues("borrowers");
  const renderField = (field, index) => {
    const {
      type,
      ui,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      component_id,
      id,
      label,
      required,
      helptext,
      options,
      selectMultiple
    } = field;
    const modelPath = field?.modelPath ?? field?.encompass_ids?.[0]?.modelPath;
    const key = type === "enum" ? "combobox" : type === "boolean" ? "checkbox" : type === "string" && ui.widget === "datepicker" ? "date" : "textbox";
    const Component = key ? fieldComponentMap[key] : null;
    const pathValue = modelPath.replace("[0]", `[${index}]`);
    if (!Component) return null;
    return /* @__PURE__ */ jsx(
      Controller,
      {
        name: pathValue,
        render: ({ field: renderedField }) => /* @__PURE__ */ jsx(
          Component,
          {
            renderedField,
            schemaForm,
            rules: null,
            id,
            name: component_id,
            label,
            required,
            pathValue,
            feedbackMessage: helptext,
            type,
            ui,
            options,
            isMulti: selectMultiple,
            visible: true,
            disabled: component_id.includes("Name") ?? false,
            visibleCheck: null,
            disableCheck: null,
            preview: true,
            rest: null
          }
        )
      },
      `${component_id}-${index}`
    );
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Grid, { span: 12, children: /* @__PURE__ */ jsx(
      DSHeader,
      {
        text: "Borrower Details",
        fontSize: 12,
        fontWeight: "bold",
        lineHeight: 1,
        style: { marginTop: "16px" },
        color: void 0,
        fontFamily: void 0,
        height: void 0,
        level: void 0,
        width: void 0
      }
    ) }),
    borrowers.map((_, borrowerIndex) => /* @__PURE__ */ jsx(
      Grid,
      {
        span: 12,
        style: { display: "flex", flexWrap: "wrap", gap: "16px" },
        children: fields.map((field, fieldIndex) => {
          return /* @__PURE__ */ jsx(
            Grid,
            {
              style: { width: "calc(100% / 7 - 16px)", minWidth: "120px" },
              children: renderField(field, borrowerIndex)
            },
            `field-${fieldIndex}`
          );
        })
      },
      `borrower-${borrowerIndex}`
    ))
  ] });
};
export {
  Borrowerdetails
};
