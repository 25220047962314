/* eslint-disable @typescript-eslint/default-param-last */
const SCREEN_LOADER = '@SCREEN_LOADER@';

const INITIAL_STATE = {
  show: false,
  message: '',
  type: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SCREEN_LOADER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
