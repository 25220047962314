import { isEmpty, isObject } from 'lodash';

const getPathValue = (obj, path, defaultValue?: any) => {
  const keys = path.includes('.') ? path.split('.') : [path];
  const [key] = keys;
  if (!obj[key]) return defaultValue || obj[key];
  if (keys.length === 1) return obj[key];
  const newPath = keys.slice(1).join('.');
  return getPathValue(obj[key], newPath, defaultValue);
};

/*
VALIDATOR SCHEMA
{
  fieldName: String,
  path: String // path to the field in the dataObject. can be a root path or a nested path.,
  required: Boolean,
  message: String | function => returns String // message to show if the required field is not specified,
  min: {value: Number, message: returns String | function => String },
  max:{value: Number, message: returns String | function => String },
  minLength: {value: Number, message: returns String | function => String },
  maxLength: {value: Number, message: returns String | function => String },
  match: {regex: RegExp , message: String | function => returns String },
  allowedFalseyValues: Array of allowedFalseyValues. Ex: [null, "", 0], default to [],
  customValidator: function => Object of type {hasError: Boolean, message: String}
 }
 */

const validatorFunction = (data, validatorsArray) => {
  const validation: {
    hasError: boolean;
    errorDetails: Record<string, string>;
    allErrors: Record<string, string[]>;
    firstError: string | null;
  } = {
    hasError: false,
    errorDetails: {},
    allErrors: {},
    firstError: null,
  };

  const setError = (errorMsg: any, fieldName) => {
    validation.hasError = true;
    if (validation.firstError === null) {
      if (isObject(errorMsg)) {
        validation.firstError = `${fieldName}-${(errorMsg as any).firstError}`;
      } else {
        validation.firstError = fieldName;
      }
    }
    if (!validation.errorDetails[fieldName]) {
      validation.errorDetails[fieldName] = errorMsg;
    }
    if (!validation.allErrors[fieldName]) {
      validation.allErrors[fieldName] = [];
    }
    validation.allErrors[fieldName].push(errorMsg);
  };

  validatorsArray.forEach((validator) => {
    const { fieldName, path, message, sanitizer } = validator;
    const { allowedFalseyValues = [] } = validator;

    if (sanitizer && typeof sanitizer !== 'function') {
      throw new Error('VALIDATOR: sanitizer should be a function');
    }
    const pathValue = sanitizer
      ? sanitizer(getPathValue(data, path))
      : getPathValue(data, path);

    // REQUIRED FIELD VALIDATION
    if (
      validator.required &&
      !pathValue &&
      !allowedFalseyValues.includes(pathValue)
    ) {
      const msg =
        typeof message === 'function' ? message(data, pathValue) : message;
      setError(msg, fieldName);
    }

    // EQUALITY VALIDATION
    if (validator.equal) {
      const { value, message: msg } = validator.equal;
      if (value === undefined) {
        throw new Error(
          "VALIDATOR: found 'equal' property without a 'value' field.",
        );
      }
      if (pathValue === value) {
        const equalMsg = typeof msg === 'function' ? msg(data, pathValue) : msg;
        setError(equalMsg, fieldName);
      }
    }

    // NUMBER MIN VALIDATION
    if (typeof +pathValue === 'number' && validator.min) {
      const { value, message: msg } = validator.min;
      if (value === undefined) {
        throw new Error(
          "VALIDATOR: found 'min' property without a 'value' field.",
        );
      }
      if (+pathValue < value || [undefined, null, ''].includes(pathValue)) {
        const minMsg = typeof msg === 'function' ? msg(data, pathValue) : msg;
        setError(minMsg, fieldName);
      }
    }

    // NUMBER MAX VALIDATION
    if (typeof +pathValue === 'number' && validator.max) {
      const { value, message: msg } = validator.max;
      if (value === undefined) {
        throw new Error(
          "VALIDATOR: found 'max' property without a 'value' field.",
        );
      }
      if (+pathValue > value || [undefined, null, ''].includes(pathValue)) {
        const maxMsg = typeof msg === 'function' ? msg(data, pathValue) : msg;
        setError(maxMsg, fieldName);
      }
    }

    // NUMBER IN RANGE VALIDATION
    if (typeof pathValue === 'number' && validator.minmax) {
      const {
        value,
        message: msg,
        includeExtremesInRange = false,
      } = validator.minmax;
      if (value === undefined) {
        throw new Error(
          "VALIDATOR: found 'minmax' property without a 'value' field.",
        );
      } else if (Array.isArray(value) || value.length !== 2) {
        throw new Error(
          "VALIDATOR: 'minmax' property value must be an array with two numbers.",
        );
      }
      if (includeExtremesInRange) {
        if (pathValue >= value[0] && pathValue <= value[1]) {
          const maxMsg = typeof msg === 'function' ? msg(data, pathValue) : msg;
          setError(maxMsg, fieldName);
        }
      } else if (pathValue > value[0] && pathValue < value[1]) {
        const maxMsg = typeof msg === 'function' ? msg(data, pathValue) : msg;
        setError(maxMsg, fieldName);
      }
    }

    // STRING REGEX MATCH VALIDATION
    if (typeof pathValue === 'string' && validator.match) {
      const { regex, message: msg } = validator.match;
      if (regex === undefined) {
        throw new Error(
          "VALIDATOR: found 'match' property without a 'regex' field.",
        );
      }
      if (!pathValue.match(regex)) {
        const matchMsg = typeof msg === 'function' ? msg(data, pathValue) : msg;
        setError(matchMsg, fieldName);
      }
    }

    // STRING MINLENGTH VALIDATION
    if (typeof pathValue === 'string' && validator.maxLength) {
      const { value, message: msg } = validator.maxLength;
      if (value === undefined) {
        throw new Error(
          "VALIDATOR: found 'maxLength' property without a 'value' field.",
        );
      }
      if (pathValue.length > value) {
        const maxLnMsg = typeof msg === 'function' ? msg(data, pathValue) : msg;
        setError(maxLnMsg, fieldName);
      }
    }

    // STRING MAXLENGTH VALIDATION
    if (typeof pathValue === 'string' && validator.minLength) {
      const { value, message: msg } = validator.minLength;
      if (value === undefined) {
        throw new Error(
          "VALIDATOR: found 'minLength' property without a 'value' field.",
        );
      }
      if (pathValue.length > value) {
        const minLnMsg = typeof msg === 'function' ? msg(data, pathValue) : msg;
        setError(minLnMsg, fieldName);
      }
    }

    // CUSTOM VALIDATOR
    if (validator.customValidator) {
      const { customValidator } = validator;
      if (typeof customValidator !== 'function') {
        throw new Error("VALIDATOR: 'customValidator' should be a 'function'.");
      }
      const result = customValidator(data, pathValue);
      const { hasError, message: msg, messages = {} } = result;
      if (hasError && ![undefined, null].includes(msg)) {
        setError(msg, fieldName);
      }
      if (hasError && !isEmpty(messages)) {
        setError(messages, fieldName);
      }
    }
  });
  return validation;
};

export default validatorFunction;
