export const errorMessages = {
  RESUME_ERROR:
    'Sorry, we are unable to resume your saved application at this time. Please try again later or contact your loan officer for assistance.',
  SUBMIT_ERROR:
    'Sorry, we were unable to accept your loan application. Please try submitting again.',
  SAVE_ERROR:
    'Sorry, we were unable to save your loan application. Please try saving again.',
  GENERAL_ERROR:
    'Sorry, our systems are unavailable at this time. Please try again later.',
  BORROWER_PAIR_ID_ERROR:
    'Sorry, our systems are unavailable at this time. Please contact your loan officer.',
  INVALID_LAR_ERROR:
    "We're sorry! Something went wrong. Please contact your loan officer.",
  GENERAL_ERROR_MODAL:
    'Something went wrong. Please try again or contact your administrator.',
  INVALID_USER: 'Invalid username/password, Please try again',
};
