import ssf from '@elliemae/em-ssf-guest';
import { getAppConfigValue } from '@elliemae/pui-app-sdk';
import {
  EppsCustomField,
  NSSampleSchema,
  PricingSearchConfig,
  SchemaAndRules,
  loanChannelConfiguration,
} from '@frontend/pricing-search';
import { openGlobalErrorModalAction } from '../data/globalErrorModal/actionCreators';
import { setBuySideAdjustmentsAction } from '../data/origin/actionCreators';
import { setOriginalLoanDataAction } from '../data/origin/actions';
import { closeLoader, openLoader } from '../data/screenLoader/actions';
import {
  calculateGovtFeeAmount,
  getUpfrontFeeValue,
  isFirstMortgage,
  isGovtLoan,
} from '../view/SearchForm/components/LoanInformationForm/utils/helpers';
import {
  SET_LOANPROPERTIES,
  SET_LOCK_EXTENSION_DATA,
  SET_ORIGINALLOANINFORMATION,
  SET_SEARCH_CONFIGURATION,
  SET_USERDATA,
  UPDATE_LOANINFO,
  UPDATE_ORIGINAL_LOANINFO,
  UPDATE_ORIGINAL_LOAN_LOCKREQUEST,
} from './constants/ActionConstants';
import {
  mockLoanData,
  loanProperties as mockLoanProperties,
  originalLoanInformation as mockOriginalLoanInf,
} from './constants/SearchSampleData';
import Common from './services/Common';
import Rights from './services/Rights';
import Session, {
  CRED,
  IS_BUYSIDE,
  IS_SELLSIDE,
  IS_VALIDATE,
  OLD_ORIGIN_ID,
  ORIGIN_ID,
  ORIGIN_IS_UPDATE,
  ORIGIN_LOCKID,
  ORIGIN_SOURCE,
  ORIGIN_TRANSACTION_TYPE,
  PAT_TOKEN,
  USER_TYPE,
} from './services/Session';
import api from './utils/api';
import createAction from './utils/createAction';
import {
  mapCustomFieldsToSchema,
  mapEppsCustomFields,
} from './utils/customFieldsHelpers';

export const setSearchConfiguration = (payload: SchemaAndRules) => ({
  // Placing this here following model of other actions on the root reducer
  // Would love to refactor all of it into a more organized system, at another time
  type: SET_SEARCH_CONFIGURATION,
  payload,
});

// (ngen): imported from src/app/helpers.js

export const APPLICATION_ERROR = 'APPLICATION_ERROR';
export const IS_INVALID_TOKEN = 'INVALID_ACCESS_TOKEN';
export const IS_INVALID_CREDENTIAL = 'INVALID_CREDENTIAL';
export const IS_RECONCILE = 'RECONCILE_CREDENTIAL';
export const IS_RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND';

const checkForLoanAmount = ({
  lienPosition,
  firstMortgageAmount,
  secondMortgageAmount,
}) => {
  let hasLoanAmount = false;
  if (lienPosition === 1) {
    hasLoanAmount = !!firstMortgageAmount;
  }
  if (lienPosition === 2) {
    hasLoanAmount = !!secondMortgageAmount;
  }
  return hasLoanAmount;
};

export const parseLoanData = (loanData) => {
  let { secondMortgageAmount } = loanData.loanInformation;
  if (typeof secondMortgageAmount === 'number') {
    secondMortgageAmount = Math.trunc(secondMortgageAmount);
  }
  const result = {
    ...loanData,
    loanInformation: {
      ...loanData.loanInformation,
      secondMortgageAmount,
    },
  };
  return result;
};

export const updateLoanInfoSuccessAction = (data) => {
  const action = createAction(UPDATE_LOANINFO);
  const payload = parseLoanData(data);
  return action(payload);
};

export const setOriginalLoanInformation = createAction(
  SET_ORIGINALLOANINFORMATION,
);
export const setUserData = createAction(SET_USERDATA);
export const setLoanProperties = createAction(SET_LOANPROPERTIES);
export const setLockExtensionData = createAction(SET_LOCK_EXTENSION_DATA);
const updateOriginalLoanInfoAction = createAction(UPDATE_ORIGINAL_LOANINFO);
const updateOriginalLoanLockRequest = createAction(
  UPDATE_ORIGINAL_LOAN_LOCKREQUEST,
);
const showNoPermissionsError = (dispatch) =>
  dispatch(
    openGlobalErrorModalAction({
      title: 'You do not have permission to access this page.',
      errorMessage: 'Please contact your administrator to request changes.',
      onCloseCallback: () => Common.closeApplication(),
      confirmLabel: 'Close ICE PPE',
      showClose: false,
    }),
  );

export const getInitData = async (dispatch, isRefresh, setIsError) => {
  dispatch(openLoader('Loading'));
  Session.set(USER_TYPE, { userType: 'LO' });
  ssf.connect();
  ssf.ready();
  await Common.sourceApplicationName();
  const data = await getOrigin(isRefresh, dispatch, setIsError);
  return data;
};

export const dispatchUserData = async (dispatch) => {
  const userData = await Common.getUserData();
  if (userData && !Object.prototype.hasOwnProperty.call(userData, 'code')) {
    dispatch(setUserData(userData));
  }
};

export const setMockInitData = async (dispatch) => {
  const prvUser = Session.get(USER_TYPE);
  Session.set(USER_TYPE, { userType: !prvUser ? 'StandAlone' : prvUser });
  if (getAppConfigValue<boolean>('partner-ui.debugBuyside')) {
    Session.set(
      ORIGIN_TRANSACTION_TYPE,
      getAppConfigValue('partner-ui.dataSourceBuyside'),
    );
  }
  throw new Error('Setting mock data');
  dispatch(updateLoanInfoSuccessAction(mockLoanData));
  dispatch(setLoanProperties(mockLoanProperties));
  dispatch(setOriginalLoanInformation(mockOriginalLoanInf));
};

export const getSearchFormSchema = (
  loanData,
  searchConfiguration: PricingSearchConfig[],
  customFields,
) => {
  const loanChannelKey = loanData.loanInformation.loanChannel as
    | 0
    | 1
    | 2
    | 3
    | 4;
  if (loanChannelKey) {
    const loanChannel = loanChannelConfiguration[loanChannelKey];
    const channelSchema = searchConfiguration.find(
      (obj) => obj.channel === loanChannel,
    );
    if (channelSchema?.publishedSchema) {
      return channelSchema.publishedSchema;
    }
  }

  if (customFields) {
    const mappedCustomFields = mapEppsCustomFields(
      customFields,
      loanData?.lockRequestAdditionalFields,
    ) as unknown as EppsCustomField[];
    if (mappedCustomFields && mappedCustomFields.length > 0) {
      const customSearchSchema = mapCustomFieldsToSchema(
        mappedCustomFields,
        NSSampleSchema,
      );
      return customSearchSchema;
    }
  }

  return NSSampleSchema;
};

export const dispatchOriginData = (
  dispatch,
  {
    originalLoanInformation,
    originalLoanInformation: { lockRequests, ltv },
    loanProperties,
    loanData,
    lockRequestInformation,
    extensionData,
    searchConfiguration,
  }: {
    originalLoanInformation: any;
    loanProperties: any;
    loanData: any;
    lockRequestInformation: any;
    extensionData: any;
    searchConfiguration: PricingSearchConfig[];
  },
  govtUpfrontFees,
  customFields,
) => {
  const { credentials, rateLock } = originalLoanInformation;
  if (rateLock?.buySideLockDate) {
    loanData.loanInformation.lockDate = rateLock.buySideLockDate;
  }

  const { feeAmount, feeAmountFinanced, feeAmountPaidinCash } =
    calculateGovtUpfrontAmount(
      loanData,
      originalLoanInformation,
      govtUpfrontFees,
      ltv,
    );
  if (feeAmount) {
    loanData.loanInformation.financedAmount = 0;
    loanData.loanInformation.feeAmount = feeAmount || 0;
    loanData.loanInformation.feeAmountFinanced = feeAmountFinanced || 0;
    loanData.loanInformation.feeAmountPaidinCash = feeAmountPaidinCash || 0;
  }

  dispatch(updateLoanInfoSuccessAction(loanData));
  dispatch(setLoanProperties(loanProperties));
  dispatch(setLockExtensionData(extensionData));
  dispatch(setOriginalLoanInformation(originalLoanInformation));
  dispatch(updateOriginalLoanInfoAction(loanData));
  dispatch(setOriginalLoanDataAction(originalLoanInformation));
  dispatch(updateOriginalLoanLockRequest(lockRequestInformation));
  dispatch(
    setBuySideAdjustmentsAction(
      originalLoanInformation?.rateLock?.buySideAdjustments || [],
    ),
  );
  dispatch(
    setSearchConfiguration(
      getSearchFormSchema(loanData, searchConfiguration, customFields),
    ),
  );
  Session.set(CRED, credentials);
  if (Session.get(IS_BUYSIDE)) {
    if (!Rights.accessBuyside && !Session.get(IS_VALIDATE)) {
      dispatch(closeLoader());
      showNoPermissionsError(dispatch);
      return;
    }
    Session.set(ORIGIN_IS_UPDATE, lockRequests || []);
  } else if (Session.get(IS_SELLSIDE)) {
    if (!Rights.accessSellside) {
      dispatch(closeLoader());
      showNoPermissionsError(dispatch);
      return;
    }
    Session.set(ORIGIN_IS_UPDATE, lockRequests || []);
  }
};

/* calculate government upfront fees for loanQualifier */
export const calculateGovtUpfrontAmount = (
  loanData,
  originalLoanInformation,
  govtUpfrontFees,
  ltv,
) => {
  let feeAmount = 0;
  let feeAmountFinanced = 0;
  let feeAmountPaidinCash = 0;
  const { standardProducts, loanInformation } = loanData;
  const {
    loanPurpose,
    vaFirstTimeUse,
    lienPosition,
    firstMortgageAmount,
    secondMortgageAmount,
  } = loanInformation;

  if (isGovtLoan({ customDependencies: { standardProducts } })) {
    const govtFeeFactor = getUpfrontFeeValue(
      { standardProducts },
      { govtUpfrontFees, loanPurpose, vaFirstTimeUse, ltv },
    );
    const baseLoanAmount = isFirstMortgage({
      customDependencies: { lienPosition },
    })
      ? firstMortgageAmount
      : secondMortgageAmount;
    feeAmount = calculateGovtFeeAmount(baseLoanAmount, govtFeeFactor);
    // FHA and VA calculations
    if (standardProducts.includes(2) || standardProducts.includes(3)) {
      if (originalLoanInformation.rateLock.fundingAmount) {
        feeAmountPaidinCash = originalLoanInformation.rateLock.mipPaidInCash;
        feeAmountFinanced =
          originalLoanInformation.rateLock.fundingAmount - feeAmountPaidinCash;
      }
    }
    // USDA calculations
    if (standardProducts.includes(4)) {
      if (originalLoanInformation.rateLock.fundingAmount) {
        feeAmountFinanced =
          originalLoanInformation.rateLock.fundingAmount -
          originalLoanInformation.rateLock.mipPaidInCash;
        feeAmountPaidinCash =
          originalLoanInformation.rateLock.baseLoanAmount *
            (originalLoanInformation.regulationZ
              .financedAllGuaranteeFeePercent /
              100) -
          feeAmountFinanced;
      }
    }

    if (feeAmountFinanced === 0) {
      feeAmountFinanced = feeAmount;
      feeAmountPaidinCash = 0;
    }
  }
  if (!Session.get(IS_BUYSIDE) && !Session.get(IS_SELLSIDE)) {
    feeAmountFinanced = feeAmount;
    feeAmountPaidinCash = 0;
  }
  return { feeAmount, feeAmountFinanced, feeAmountPaidinCash };
};

const validateOrigin = async (originData) => {
  const { loanData, code, summary } = originData;
  const { loanInformation } = loanData || {};
  const { lienPosition } = loanInformation || {};

  const hasLoanAmount = checkForLoanAmount(loanInformation || {});
  const loanAmountBlank =
    code === APPLICATION_ERROR &&
    summary ===
      'Loan Amount is Blank. Please enter Loan Amount and retry pricing';
  const notTokenCredResource = [
    IS_INVALID_TOKEN,
    IS_INVALID_CREDENTIAL,
    IS_RESOURCE_NOT_FOUND,
  ].includes(code);
  const validation: any = {
    notTokenCredResource,
    loanAmountBlank,
    hasLoanAmount,
    lienPosition,
    originData,
    code,
  };
  if (notTokenCredResource) {
    const reconcile = await Common.reconcileInvalidCredentials();
    if (
      reconcile.code === IS_INVALID_TOKEN ||
      reconcile.code === IS_INVALID_CREDENTIAL ||
      reconcile.code === IS_RESOURCE_NOT_FOUND ||
      reconcile.token
    ) {
      validation[IS_RECONCILE] = IS_RECONCILE;
    }
    if (!reconcile) {
      validation.isRedirectToError = true;
    }
  }
  return validation;
};

const getTransactionData = async (isRefresh) => {
  const transactionObj = await ssf.getObject('transaction');
  const data = await (isRefresh
    ? transactionObj.refreshOrigin()
    : transactionObj.getOrigin());
  return data;
};

const setInfoSession = async (
  { source, partnerAccessToken, id, settings },
  dispatch,
) => {
  let newSource = source;
  // TODO: temporary code. This should be replace when reviselock story is going to implement
  if (source) {
    newSource = source.replace('reviselock', 'getbuysidepricing');
  }

  if (getAppConfigValue<boolean>('partner-ui.debugBuyside')) {
    newSource = getAppConfigValue('partner-ui.dataSourceBuyside');
  }

  Session.set({
    [PAT_TOKEN]: partnerAccessToken,
    [ORIGIN_SOURCE]: newSource,
    [ORIGIN_TRANSACTION_TYPE]: newSource,
    [ORIGIN_LOCKID]: newSource,
    [OLD_ORIGIN_ID]: Session.get(ORIGIN_ID),
    [ORIGIN_ID]: id,
  });
};

const openDocumentViewer = async (transactionId) => {
  const response = await api.getTransactionDetails(transactionId);
  if (response && response.resources) {
    try {
      const applicationObject = await ssf.getObject('application');
      const modalStatus = await applicationObject.openModal({
        target: {
          entityId: response.resources.id,
          entityType: 'urn:elli:skydrive',
        },
      });
      if (modalStatus === 'success') {
        await Common.closeApplication();
      }
    } catch (error) {
      // console.log({ error });
    }
  }
};

export const getOrigin = async (isRefresh, dispatch, setIsError) => {
  const redirectToError = () => {
    setIsError(true);
  };
  const data = await getTransactionData(isRefresh);
  setInfoSession(data, dispatch);
  const { transactionId } = data;
  if (transactionId) {
    await openDocumentViewer(transactionId);
  }
  if (!transactionId) {
    try {
      const originData = await Common.getOrigin();
      const result = validateOrigin(originData);
      return await result;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      dispatch(closeLoader());
      dispatch(
        openGlobalErrorModalAction({
          errorMessage:
            'Something went wrong getting Origin. Please contact the administrator.',
          onCloseCallback: () => {
            redirectToError();
          },
        }),
      );
      redirectToError();
    }
  }
  return null;
};
