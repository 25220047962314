import React from 'react';
import Proptypes, { arrayOf, shape } from 'prop-types';
import { Grid, GridContainer, GridItem } from '@elliemae/ds-grid';

export const TooltipCol = ({ items, header }) => (
  <Grid style={{ height: 'fit-content' }}>
    <strong>{header}</strong>
    {items.map(({ abbreviation, key }) => (
      <GridContainer cols={1} alignItems="left">
        <GridItem>
          <div style={{ marginTop: '5px' }}>
            <span style={{ minWidth: '40px', display: 'inline-block' }}>
              {abbreviation}
            </span>
            <span>{key}</span>
          </div>
        </GridItem>
      </GridContainer>
    ))}
  </Grid>
);

export const TooltipGrid = ({ items }) => (
  <>
    <strong style={{ display: 'block', marginBottom: '10px' }}>Key</strong>
    <Grid cols={[1, 1, 1]} gutter="20px">
      <TooltipCol header="Purpose" items={items.purposes} />
      <TooltipCol header="Property Use" items={items.propertyUses} />
      <TooltipCol header="Property Type" items={items.propertyTypes} />
    </Grid>
    <em style={{ display: 'block', margin: '10px 0px' }}>
      <i>
        Some failure reasons indicated here may override general guidelines
        shown below.
      </i>
    </em>
  </>
);

TooltipCol.propTypes = {
  items: arrayOf(
    shape({
      key: Proptypes.string.isRequired,
      abbreviation: Proptypes.string.isRequired,
    }),
  ).isRequired,
  header: Proptypes.string.isRequired,
};

TooltipGrid.propTypes = {
  items: shape({
    purposes: arrayOf(
      shape({
        key: Proptypes.string.isRequired,
        abbreviation: Proptypes.string.isRequired,
      }),
    ),
    propertyUses: arrayOf(
      shape({
        key: Proptypes.string.isRequired,
        abbreviation: Proptypes.string.isRequired,
      }),
    ),
    propertyTypes: arrayOf(
      shape({
        key: Proptypes.string.isRequired,
        abbreviation: Proptypes.string.isRequired,
      }),
    ),
  }).isRequired,
};
