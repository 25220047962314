import { DSDataGrid } from '@elliemae/ds-datagrids';
import { GridContainer, GridItem } from '@elliemae/ds-grid';
import { DSLabelValue } from '@elliemae/ds-label-value';
import { StyledDSLabel } from '../../GeneralLoanInformation/styled';
import { columnsBorrowersGrid } from './constants';
import { useBorrowersSectionData } from './hooks';
import { StyledBorrowerTitles } from './styled';

export const BorrowersSection = () => {
  const { borrowers, sectionFields } = useBorrowersSectionData();
  return (
    <GridContainer cols={6} span={1} style={{ breakInside: 'avoid' }}>
      <GridItem span={6}>
        <DSDataGrid
          autoHeight
          rows={borrowers}
          columns={columnsBorrowersGrid}
          rowKey="id"
        />
      </GridItem>
      <GridItem span={6}>
        <StyledBorrowerTitles>Credit History</StyledBorrowerTitles>
      </GridItem>
      {sectionFields &&
        sectionFields.map(({ label, value }, index) => (
          <StyledDSLabel
            data-testid={`${
              index === 0 ? 'BorrowersSection-all-components' : ''
            }`}
          >
            <DSLabelValue>
              <DSLabelValue.Label>{label}</DSLabelValue.Label>
              <DSLabelValue.Value>{value}</DSLabelValue.Value>
            </DSLabelValue>
          </StyledDSLabel>
        ))}
    </GridContainer>
  );
};
