import { DSButtonV2 } from '@elliemae/ds-button';
import {
  DSDialog,
  DSDialogBody,
  DSDialogDefaultLayout,
  DSDialogFooter,
  DSDialogPrimaryMessage,
  DSDialogSecondaryMessage,
  DSDialogSeparator,
} from '@elliemae/ds-dialog';
import { WarningTriangle } from '@elliemae/ds-icons';
import { useFocusTrap } from '@elliemae/ds-utilities';
import { memo, useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Common from '../../../../common/services/Common';
import { setLockedProgramData } from '../../../../data/origin/actions';
import { useLockedProgramSelector } from '../../../../data/useSelectors';
import { MODAL_TITLES } from './constants';
import { ModalBody } from './ModalBody';

export const IneligibleUnavailableModal = memo<any>(() => {
  const dispatch = useDispatch();
  const firstElementRef = useRef(null);
  const lastElementRef = useRef(null);
  const handleOnKeyDown = useFocusTrap({ firstElementRef, lastElementRef });
  const { modalOpen, status = 'eligible' } = useLockedProgramSelector();

  const exitEppsOnClick = useCallback(() => {
    Common.closeApplication();
  }, []);
  const closeModalOnClick = useCallback(() => {
    dispatch(setLockedProgramData({ modalOpen: false }));
  }, []);

  const closeModalMessage = useMemo(() => {
    if (status === 'ineligibleRate') {
      return 'Select New Rate';
    }
    return 'Select New Product';
  }, [status]);

  return (
    <DSDialog isOpen={modalOpen} size="small" onKeyDown={handleOnKeyDown}>
      <DSDialogBody p={24}>
        <DSDialogDefaultLayout>
          <WarningTriangle size="xxl" color={['warning', '500']} />
          <DSDialogPrimaryMessage style={{ textAlign: 'center' }}>
            {MODAL_TITLES[status]}
          </DSDialogPrimaryMessage>
          <DSDialogSecondaryMessage>
            <ModalBody />
          </DSDialogSecondaryMessage>
        </DSDialogDefaultLayout>
      </DSDialogBody>
      <DSDialogSeparator />
      <DSDialogFooter>
        <DSButtonV2
          buttonType="outline"
          innerRef={firstElementRef}
          onClick={exitEppsOnClick}
          width="125"
        >
          Leave ICE PPE
        </DSButtonV2>
        <DSButtonV2
          width="160"
          innerRef={lastElementRef}
          onClick={closeModalOnClick}
        >
          {closeModalMessage}
        </DSButtonV2>
      </DSDialogFooter>
    </DSDialog>
  );
});
