import { mockLoanData } from '../constants/SearchSampleData';
import Session, { JWT_TOKEN } from '../services/Session';
import api from '../utils/api';

class QualificationService {
  // This is only used in Standalone mode...

  async getRates(loanInfo) {
    const Rates = await api.getRates({
      body: JSON.stringify(loanInfo !== undefined ? loanInfo : mockLoanData),
      customToken: { value: Session.get(JWT_TOKEN) },
      tokenType: 'Bearer',
    });
    return Rates;
  }
}

export default new QualificationService();
