export const loanLimitsGridColumns = [
  {
    accessor: 'limitText',
    Header: 'LIMIT TYPE',
    width: '16.6%',
  },

  {
    accessor: 'effectiveDate',
    Header: 'EFFECTIVE DATE',
    width: '16.6%',
  },
  {
    accessor: 'limit1',
    Header: '1 UNIT',
    width: '16.6%',
  },
  {
    accessor: 'limit2',
    Header: '2 UNIT',
    width: '16.6%',
  },
  {
    accessor: 'limit3',
    Header: '3 UNIT',
    width: '16.6%',
  },
  {
    accessor: 'limit4',
    Header: '4 UNIT',
    width: '16.6%',
  },
];
