/**
 * It takes an object of the initial form values and returns an object with the same values, but with the keys
 * prefixed with the `initial` world.
 *
 * @param {Array} initialValues The initial values of the form
 * @returns {object} An object with the same keys as the initialValues object, but with the first letter of each
 * key capitalized and the word "initial" prepended to each key
 */

export const parseInitialValues = (initialValues) => {
  const values = {};
  Object.entries(initialValues).forEach((entry) => {
    const [key, value] = entry;
    const parseKey = `initial${key.replace(/^./, key[0].toUpperCase())}`;
    values[parseKey] = value;
  });
  return values;
};

/**
 * It takes an array of numbers and returns true if any of the numbers in the array is 3 (VA - Veterans Affairs)
 *
 * @param {Array} standardProducts This is the value of the Standard Products field
 * @returns {boolean} It will include or not VA products in the qualification
 */

export const isVAValidation = (standardProducts) => {
  let isVA = false;
  const VA = [3];
  const newStandardProducts = standardProducts?.value || standardProducts;
  if (Array.isArray(newStandardProducts)) {
    isVA = newStandardProducts.some((elem) => VA.includes(elem));
  }
  return isVA;
};

/**
 * It takes an array of numbers and returns true if any of the numbers in the array is 55
 * (VA IRRR - Veterans Affairs' Interest Rate Reduction Refinance Loan)
 *
 * @param {Array} specialProducts This is the value of the Special Products field
 * @returns {boolean} It will include or not VA IRRRL special products in the qualification
 */

export const isIRRRLValidation = (specialProducts) => {
  let isIRRRL = false;
  const IRRRL = [55];
  const newSpecialProducts = specialProducts?.value || specialProducts;
  if (Array.isArray(newSpecialProducts)) {
    isIRRRL = newSpecialProducts.some((elem) => IRRRL.includes(elem));
  }
  return isIRRRL;
};

/**
 * It takes an array of numbers and returns true if any of the numbers in the array is
 * 2 (FHA - Federal Housing Administration) or 3 (VA - Veterans Affairs)
 *
 * @param {Array} standardProducts This is the value of the Standard Products field
 * @returns {boolean} It will include or not FHA OR / AND VA products in the qualification
 */

export const isGovieValidation = (standardProducts) => {
  let isGovie = false;
  const govies = [2, 3];
  const newStandardProducts = standardProducts?.value || standardProducts;
  if (Array.isArray(newStandardProducts)) {
    isGovie = newStandardProducts.some((elem) => govies.includes(elem));
  }
  return isGovie;
};

/**
 * It takes an array of numbers and returns true if any of the numbers in the array is
 * 2 (Rate/Term Refinance) or 3 (C/O Refinance)
 *
 * @param {number} loanPurpose This is the value of the Loan Purpose field
 * @returns {boolean} This will determine if the loan is refinanced
 */

export const isRefinanceTypeValidation = (loanPurpose) => {
  let isRefinanceType = false;
  const refinanceTypes = [2, 3];
  const newLoanPurpose = loanPurpose?.value || loanPurpose;
  if (newLoanPurpose) {
    isRefinanceType = refinanceTypes.includes(newLoanPurpose);
  }
  return isRefinanceType;
};

/**
 * It Returns a boolean value based on the results of filtering "lookup Loan Term" with the values
 * of "property Type" and then validating if the result has any property "HELOC" as true
 *
 * @param {{ productType: Array, lookupsLoanTerms: Array }} loanTems This is the value and optios of the Loan Term field
 * @returns {boolean} This will determine if the loan is HELOC
 */

export const isHELOCValidation = ({ productType, lookupsLoanTerms }) => {
  let isHELOC = false;
  const newProductType = productType?.value || productType;
  if (Array.isArray(newProductType) && lookupsLoanTerms) {
    const loanIsHELOC = lookupsLoanTerms
      .filter((elem) => newProductType.includes(elem.value))
      .some((elem) => elem.heloc);

    isHELOC = loanIsHELOC;
  }
  return isHELOC;
};

/**
 * This will add the validated field to a list if it has an error.
 * If the validated field is in the list and there is no error, this field will be removed from the list
 * The purpose of this function is for error handling in the form.
 *
 * @param {Array} errorFields An array of field names that have errors
 * @param {string} fieldName The name of the field that is being validated
 * @param {boolean} hasError The field has error or not
 * @returns {Array} It will return a list of the current fields in error
 */

export const validateErrors = (errorFields, fieldName, hasError) => {
  if (fieldName) {
    if (!errorFields.includes(fieldName) && hasError)
      return [...errorFields].concat(fieldName);
    if (errorFields.includes(fieldName) && !hasError)
      return errorFields.filter((e) => e !== fieldName);
  }
  return errorFields;
};

/**
 * It returns an object with keys that are the names of the validations and values that are the results
 * of the search form
 *
 * @param {object} formValues The entire initialValues object
 * @returns  An object with the validations to set a flow in the form and being able to display or hide some fields
 */

export const formValidations = (formValues) => {
  const { productType, lookupsLoanTerms } = formValues;
  return {
    isVA: isVAValidation(formValues.standardProducts),
    isIRRRL: isIRRRLValidation(formValues.specialProducts),
    isGovie: isGovieValidation(formValues.standardProducts),
    isHELOC: isHELOCValidation({ productType, lookupsLoanTerms }),
    isRefinance: isRefinanceTypeValidation(formValues.loanPurpose),
  };
};
