/* eslint-disable prettier/prettier */
import {
  EppsCustomField,
  EppsCustomFieldDecimalTypes,
  EppsCustomFieldTypes,
  licensedStates,
  SchemaAndRules,
  YupIScreenGroupScreens,
} from '@frontend/pricing-search';
import { randomUUID } from '@frontend/utils';
import { cloneDeep, isEmpty } from 'lodash';

export const mapFieldType = (field: EppsCustomField) => {
  if (!field?.type) {
    return {};
  }
  switch (field.type) {
    case EppsCustomFieldTypes.DATE:
    case EppsCustomFieldTypes.MONTHDAY:
      return {
        type: 'string',
        ui: {
          widget: 'datepicker',
          col_size: 4,
        },
      };
    case EppsCustomFieldTypes.DROPDOWN:
    case EppsCustomFieldTypes.DROPDOWNLIST:
      return {
        type: 'enum',
        options: field.masterList.map((i) => ({
          id: i,
          text: i,
          value: i,
          visible: true,
        })),
        ui: { widget: 'dropdown', col_size: 4 },
        selectMultiple: false,
      };
    case EppsCustomFieldTypes.YN:
      return {
        type: 'enum',
        options: [
          { id: 'YES', text: 'Yes', value: 'Y', visible: true },
          { id: 'YES', text: 'No', value: 'N', visible: true },
        ] as any,
        selectMultiple: false,
        ui: { widget: 'dropdown', col_size: 4 },
      };
    case EppsCustomFieldTypes.X:
      return {
        type: 'boolean',
        ui: { col_size: 4 } as any, // needed because boolean doesn not have col_size,
      };
    case EppsCustomFieldTypes.STRING:
    case EppsCustomFieldTypes.SSN:
    case EppsCustomFieldTypes.PHONE:
      return {
        type: 'string',
        ui: { col_size: 4 },
      };
    case EppsCustomFieldTypes.INTEGER:
    case EppsCustomFieldTypes.ZIPCODE:
    case Object.values<string>(EppsCustomFieldDecimalTypes).includes(
      field.type,
    ):
      return {
        type: 'number',
        ui: { col_size: 4 },
      };
    case EppsCustomFieldTypes.STATE:
      return {
        type: 'enum',
        options: licensedStates.map((i) => ({
          text: i.state,
          value: i.stateAbbr,
          visible: true,
        })),
        ui: { col_size: 4 },
        selectMultiple: false,
      };
    default:
      return { type: 'string', ui: { col_size: 4 } };
  }
};

const getCustomFieldModelPath = (
  fieldId: string,
  lockRequestAdditionalFields: any,
) => {
  if (!isEmpty(lockRequestAdditionalFields)) {
    const customFieldIndex = lockRequestAdditionalFields.findIndex(
      (customField) => customField.fieldId === fieldId,
    );
    if (customFieldIndex !== -1) {
      return `lockRequestAdditionalFields[${customFieldIndex}].value`;
    }
  }
  return `lockRequestAdditionalFields.${fieldId}`;
};

export const mapEppsCustomFields = (
  fields: EppsCustomField[],
  lockRequestAdditionalFields: any,
) => {
  if (!fields || fields.length === 0) return [];
  return fields
    .filter((field) => !field.hidden)
    .map((field) => ({
      ...mapFieldType(field),
      label: field.description,
      visible: !field.hidden,
      modelPath: getCustomFieldModelPath(
        field.newFieldId,
        lockRequestAdditionalFields,
      ),
      component_id: `CUSTOM ${field.type}: ${field.originalFieldId}`,
      id: randomUUID(),
      disabled: !field.editable,
    }));
};

export const mapCustomFieldsToSchema = (
  fields: EppsCustomField[],
  NSSampleSchema: SchemaAndRules,
) => {
  const sampleSchemaCopy = cloneDeep(NSSampleSchema);
  const indexOfScreenGroup = sampleSchemaCopy.schema.screens.findIndex(
    (screen) => screen.type === 'screen_group',
  );
  const indexOfGeneralLoanInformation = sampleSchemaCopy.schema.screens
    .filter((screen) => screen.type === 'screen_group')
    .findIndex((screen) =>
      screen.screens.find(
        (s) => s.id === 'c8fc0171-c0c4-46fd-b81e-0c2e315512e2',
      ),
    );
  if (indexOfGeneralLoanInformation === -1) return sampleSchemaCopy;
  const customFieldsSection = {
    id: randomUUID(),
    type: 'screen',
    name: 'Custom Fields',
    fields: [
      {
        type: 'field_group',
        name: 'VISIBLE',
        fields: [...fields],
      },
    ],
  };
  // insert custom fields section into the schema
  (sampleSchemaCopy.schema.screens as YupIScreenGroupScreens)[
    indexOfScreenGroup
  ].screens.splice(indexOfGeneralLoanInformation + 1, 0, {
    id: customFieldsSection.id,
  });
  sampleSchemaCopy.schema.screens.push(customFieldsSection);
  return sampleSchemaCopy;
};
