import {
  currency,
  formatNumberWithCommas,
} from '../../../../common/utils/shared';

const calculatePercent = (value, propertyValue) => {
  if (!value || value < 0) return '$0.00 (0.00 %)';
  const percentage = ((value / propertyValue) * 100).toFixed(2);
  return `$${value} (${percentage})%`;
};

export const getPropertyValueLabel = (lienPosition, loanPurpose) => {
  let label = 'Property Value';
  if (lienPosition === 1 && loanPurpose === 'Purchase') {
    label = 'Purchase Price/Property Value';
  }
  return label;
};

const getLoanInfoFields = ({
  qualificationType,
  qualificationTypeId,
  purpose,
  lienPosition,
  propertyValue,
  firstMortgageAmount,
  secondMortgageAmount,
  giftForDownPayment,
  sellerConcession,
  aprClosingCost,
}) => [
  {
    label: 'Lien Position',
    value: qualificationType,
    dataTestID: 'loanDetails_qualificationType',
    key: 'lienPosition',
  },
  {
    label: 'Purpose',
    value: purpose,
    dataTestID: 'loanDetails_purpose',
    key: 'purpose',
  },
  {
    label: getPropertyValueLabel(lienPosition, purpose),
    value: currency(propertyValue),
    dataTestID: 'loanDetails_propertyValue',
  },
  {
    label: 'Existing First Mortgage',
    value: firstMortgageAmount ? currency(firstMortgageAmount) : 'None',
    hidden: qualificationTypeId === 1,
    dataTestID: 'loanDetails_firstMortgageAmount',
    key: 'existingFirstMortgage',
  },
  {
    label: 'Existing Second Mortgage',
    value: secondMortgageAmount ? currency(secondMortgageAmount) : 'None',
    hidden: qualificationTypeId === 2,
    dataTestID: 'loanDetails_secondMortgageAmount',
    key: 'existingSecondMortgage',
  },
  {
    label: 'Gift Funds',
    value:
      giftForDownPayment === 0
        ? 'None'
        : calculatePercent(giftForDownPayment, propertyValue),
    // TODO(ngen): this used to say !giftForDownPayment === 0
    // That's probably a bug.
    hidden: !giftForDownPayment && giftForDownPayment !== 0,
    dataTestID: 'loanDetails_giftForDownPayment',
    key: 'giftFunds',
  },
  {
    label: 'Seller Concessions',
    value:
      sellerConcession === 0
        ? 'None'
        : calculatePercent(sellerConcession, propertyValue),
    hidden: !sellerConcession && sellerConcession !== 0,
    dataTestID: 'loanDetails_sellerConcession',
    key: 'sellerConcessions',
  },
  {
    label: 'Estimated Closing Costs',
    value: `$${formatNumberWithCommas(aprClosingCost)}`,
    dataTestID: 'estimated_closing_costs',
    key: 'estimatedClosingCosts',
  },
];

export default getLoanInfoFields;
