import { pause } from '@frontend/utils';
import moment from 'moment';
import api from '../utils/api';
import { closeLoader } from '../../data/screenLoader/actions';
import Common from './Common';
import Session, {
  APPLICATION_NAME,
  IS_LO,
  JWT_TOKEN,
  ORIGIN_SOURCE,
  USER_TYPE,
} from './Session';

export const eppsToLNCTAdjustmentType = {
  CustomPriceAdjustment: 'CUSTOM_PRICE_ADJUSTMENT',
  ReLockFeeAdjustment: 'RE_LOCK_FEE_ADJUSTMENT',
  LockExtensionAdjustment: 'LOCK_EXTENSION_ADJUSTMENT',
  CorporatePriceConcession: 'CORPORATE_PRICE_CONCESSION',
  BranchPriceConcession: 'BRANCH_PRICE_CONCESSION',
};

export const convertEPPSAdjustmentsToLNCT = (buysideAdjustments) => {
  // Array<Record<keyof AdjustmentType, {uuid: string, price: number, description: string}[]>>
  const response = (buysideAdjustments ?? []).reduce((sum, curr) => {
    const newAdjustment = {
      uuid: curr.uuid,
      points: curr.price && !Number.isNaN(curr.price) ? curr.price : null,
      rate: curr.rate && !Number.isNaN(curr.rate) ? curr.rate : null,
      description: curr.description,
      approvedBy: curr.approvedBy,
      approvedReason: curr.approvedReason,
      approvalDate: curr.approvalDate
        ? new Date(curr.approvalDate).toISOString()
        : null,
      status: curr.status,
    };
    const newAdjustmentType = Object.values(eppsToLNCTAdjustmentType).includes(
      curr.adjustmentType,
    )
      ? curr.adjustmentType
      : eppsToLNCTAdjustmentType[curr.adjustmentType];
    if (!newAdjustmentType) {
      return sum;
    }
    return {
      ...sum,
      [newAdjustmentType]: sum[newAdjustmentType]
        ? [...sum[newAdjustmentType], newAdjustment]
        : [newAdjustment],
    };
  }, {});

  return response;
};

export const createAdjustments = async (
  loanData,
  adjustments,
  attemptCount = 0,
) => {
  const newAttemptCount = attemptCount || 0;
  const maxAttempts = 5;
  if (newAttemptCount >= maxAttempts) {
    throw new Error(`Max attempts reached. Adjustments could not be accepted.`);
  }
  const loanNumber = loanData?.loanInformation?.loanId;

  const adjustmentResponses = await Promise.all(
    Object.entries(adjustments).map(([key, adjustmentsInGroup]) =>
      api.createAdjustments({
        loanNumber,
        adjustments: adjustmentsInGroup,
        adjustmentType: key,
      }),
    ),
  );

  const timeoutInSeconds = 75;
  /* eslint-disable no-await-in-loop */
  for (let i = 0; i < timeoutInSeconds; i += 1) {
    await pause(1000);

    const responses = await Promise.all<
      Awaited<ReturnType<typeof api.pollAdjustmentsApproval>>
    >(
      adjustmentResponses
        .filter(Boolean)
        .map((response) =>
          api.pollAdjustmentsApproval({ loanNumber, groupUuid: response }),
        ),
    );

    const resultsAreComplete =
      responses.reduce(
        (sum, curr) => sum + curr.failed.length + curr.successful.length,
        0,
      ) === Object.values(adjustments).flat(1).length;
    const failedAdjustmentGroups = Object.keys(adjustments).reduce(
      (sum, adjustmentType) => {
        const failedItems = responses
          .map((response) => response.failed)
          .flat(1)
          .filter(
            (adjustmentGroup) =>
              adjustmentGroup.adjustmentType === adjustmentType,
          )
          .map((adjustmentGroup) => adjustmentGroup.adjustment);

        if (!failedItems.length) {
          return sum;
        }
        return {
          ...sum,
          [adjustmentType]: failedItems,
        };
      },
      {},
    );

    if (resultsAreComplete) {
      if (Object.keys(failedAdjustmentGroups).length) {
        return createAdjustments(
          loanData,
          failedAdjustmentGroups,
          newAttemptCount + 1,
        );
      }

      return null;
    }
  }
  /* eslint-enable no-await-in-loop */

  throw new Error('Timeout. Adjustments could not be accepted. ');
};

const populateOriginDate = (loanData, obj) => {
  const updatedRelockLoanData = {
    effectiveDate: (moment as any)
      .tz(obj.requestedDate, 'America/New_York')
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    specificProgramID: loanData.relockRequest?.specificProgramID,
    specificRateDataID: loanData.relockRequest?.specificRateDataID,
  };
  return {
    ...loanData,
    requestAction: '4',
    relockRequest: updatedRelockLoanData,
  };
};

const getWebhookRelockData = async (loanData, dispatch, action) => {
  let apiResult = null;
  await Common.getWebhookData(
    loanData,
    await Common.getOriginId(),
    dispatch,
    action,
    await Common.getPATToken(),
  )
    .then((result) => {
      if (result && result.code !== undefined) {
        apiResult = null;
      } else {
        apiResult = result;
      }
    })
    .catch(() => {
      dispatch(closeLoader());
    });
  return apiResult;
};

const getRelockData = async (loanData, dispatch, action, lockRequestsEvent) => {
  let fetchRelockData;
  const requests = lockRequestsEvent.map((item) => {
    return getWebhookRelockData(
      populateOriginDate(loanData, item),
      dispatch,
      action,
    );
  });
  await Promise.all(requests).then((results) => {
    fetchRelockData = results;
  });
  return fetchRelockData;
};

export const getRatesData = async (
  loanData,
  date,
  isCustom,
  dispatch,
  action,
) => {
  const effectiveDate = isCustom
    ? moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    : (moment as any)
        .tz(date, 'America/New_York')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  const updatedLoanData = {
    ...loanData,
    requestAction: '4',
    relockRequest: {
      ...loanData.relockRequest,
      effectiveDate,
    },
  };
  const ratesData = await getWebhookRelockData(
    updatedLoanData,
    dispatch,
    action,
  );
  return ratesData;
};

export const rateSelector = async (
  loanData,
  historicalProgram,
  ignoreEffectiveDate = false,
) => {
  const { relockRequest = {} } = loanData;
  const isLOCmode = Session.get(IS_LO);
  const params = {
    loanId: historicalProgram.loanId,
    programId: relockRequest.specificProgramID,
    rateDataId: relockRequest.specificRateDataID,
  };

  const { loanId, rateDataId, programId } = params;
  const applicationName = isLOCmode
    ? Session.get(APPLICATION_NAME) || 'ENCW'
    : 'EPPS';

  const body = {
    loanId,
    sourceApplicationName: applicationName,
    sourceApplicationFormName: Session.get(ORIGIN_SOURCE) || 'secondarylock',
    requestLockStatus: loanData.requestLockStatus,
    requestAction: 4,
    eppsUserName: '',
    programId,
    rateDataID: rateDataId,
    lienPosition:
      loanData && loanData.loanInformation
        ? loanData.loanInformation.lienPosition
        : 1,
    effectiveDate: !ignoreEffectiveDate
      ? loanData.relockRequest.effectiveDate || ''
      : null,
  };
  let details = {};
  const user = Session.get(USER_TYPE);
  if (user && user.userType === 'StandAlone') {
    details = await api.selectProgramRate(
      {
        body: JSON.stringify(body),
        customToken: { value: Session.get(JWT_TOKEN) },
        tokenType: 'Bearer',
        loanId,
      },
      {},
    );
  } else {
    details = await api.getWebhookData(
      {
        headers: { 'X-Elli-PAT': await Common.getPATToken() },
        body: JSON.stringify(body),
      },
      await Common.getOriginId(),
      'LockRates',
    );
  }
  return details;
};

export default getRelockData;
