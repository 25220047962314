import { ModalHeader } from '@elliemae/ds-modal-slide';
import { arrayOf, bool, func, shape } from 'prop-types';
import { ModalSlide } from '../../../components/ModalSlide';
import { BonaFideDiscountContainer } from './BonaFideDiscountContainer';
import { DataGridPricingDetails } from './DataGridPricingDetails';

export const PricingDetailsModal = ({
  togglePricingDetails,
  isOpenPricingDetails,
  tableData,
}) => {
  return (
    <div data-testid="PricingDetailsModal-all-components">
      <ModalSlide
        header={
          <ModalHeader
            breadcrumTitle="Pricing Details"
            onClose={togglePricingDetails}
            title="Pricing Details"
          />
        }
        isOpen={isOpenPricingDetails}
      >
        <div style={{ padding: '0 15px' }}>
          <DataGridPricingDetails tableData={tableData} />
          <BonaFideDiscountContainer />
        </div>
      </ModalSlide>
    </div>
  );
};

PricingDetailsModal.defaultProps = {
  tableData: [],
};

PricingDetailsModal.propTypes = {
  togglePricingDetails: func.isRequired,
  isOpenPricingDetails: bool.isRequired,
  tableData: arrayOf(
    shape({
      rawPrice: arrayOf(shape({})),
      adjustedPrice: arrayOf(shape({})),
    }),
  ),
};
