import React from 'react';
import { bool, string } from 'prop-types';
import { DSLabelValue } from '@elliemae/ds-label-value';
import { GridContainer, GridItem } from '@elliemae/ds-grid';
import {
  useRateSelectorLienInfo,
  useRateSelectorLoanInfo,
  useOriginalLoanInformationSelector,
  useLoanDataSelector,
} from '../../../../data/useSelectors';
import { formatLoanInformation } from './helpers';
import LoanDetailsRibbonColumns from './styled';

const DataItem = ({ label, value }) => (
  <LoanDetailsRibbonColumns>
    <DSLabelValue>
      <DSLabelValue.Label>{label}</DSLabelValue.Label>
      <DSLabelValue.Value size="s">{value}</DSLabelValue.Value>
    </DSLabelValue>
  </LoanDetailsRibbonColumns>
);

DataItem.propTypes = {
  label: string.isRequired,
  value: string.isRequired,
};

export const LoanDetails = ({ isCompareRatesView }) => {
  const loan = useRateSelectorLoanInfo();
  const lien = useRateSelectorLienInfo();
  const { requestLockStatus } = useLoanDataSelector() as any;
  const lockRequests = useOriginalLoanInformationSelector(
    'lockRequests',
    [],
  ) as any[];
  const buySideLockExpires = useOriginalLoanInformationSelector(
    'rateLock.buySideLockExpires',
    '',
  );
  const noOfLockRequests = lockRequests.length;

  const lockRequestedDate =
    (noOfLockRequests && lockRequests[noOfLockRequests - 1].requestedDate) ||
    '';

  const requestedDate = lockRequests?.[0]?.requestedDate || '';

  const loanInfo = formatLoanInformation({
    lien,
    loan,
    lockRequestedDate,
    requestLockStatus,
    requestedDate,
    buySideLockExpires,
    isCompareRatesView,
  });

  return (
    <GridContainer cols={6}>
      {loanInfo.map(({ label, value }, index) => (
        <GridItem
          span={1}
          key={label}
          data-testid={`${index === 0 ? 'LoanDetails-all-components' : ''}`}
        >
          <DataItem label={label} value={value} />
        </GridItem>
      ))}
    </GridContainer>
  );
};

LoanDetails.defaultProps = {
  isCompareRatesView: false,
};

LoanDetails.propTypes = {
  isCompareRatesView: bool,
};
