/* eslint-disable @typescript-eslint/default-param-last */
import React, { useContext } from 'react';
import { get } from 'lodash';

const DataContext = React.createContext(null);

export const DataProvider = DataContext.Provider;

export const useAppData = (key?: any = null, defaultValue?: any) => {
  const appData = useContext(DataContext);
  const finalDefaultValue =
    typeof defaultValue !== 'undefined' ? defaultValue : appData;
  return get(appData, key as any, finalDefaultValue);
};
