import { DSModalSlide } from '@elliemae/ds-modal-slide';
import { bool, element, node, string } from 'prop-types';
import React, { useRef } from 'react';

export const ModalSlide = ({
  isOpen = false,
  header = null,
  footer = null,
  children,
  maxWidth = null,
}) => {
  const rootElement = document.getElementById('root') as HTMLElement;
  const refContainer = useRef(
    document.getElementById('root'),
  ) as React.MutableRefObject<HTMLDivElement>;
  const changeModalWidth = () => {
    const modalContent = rootElement?.querySelector(
      '.em-ds-modal-slide__content',
    ) as HTMLElement;
    if (modalContent) {
      modalContent.style.maxWidth = maxWidth || '552px';
    }
  };

  return (
    <DSModalSlide
      fadeIn={300}
      fadeOut={100}
      fullWidth={false}
      getContainer={() => {
        changeModalWidth();
        return refContainer.current;
      }}
      isOpen={isOpen}
      header={header}
      footer={footer}
      data-testid="ModalSlide-all-components"
    >
      {children}
    </DSModalSlide>
  );
};

ModalSlide.propTypes = {
  isOpen: bool,
  header: element,
  footer: element,
  children: node,
  maxWidth: string,
};
