import { DSDataGrid } from '@elliemae/ds-datagrids';
import { DSModal, MODAL_TYPE_V2 } from '@elliemae/ds-modal';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import Common from '../../../../common/services/Common';
import { updateEncompassElementsColumnsDef } from './constants';

export const DiffElements = () => {
  const getDiffElements =
    useSelector(
      ({
        epps: {
          lockSummary: { diffElements },
        },
      }) => diffElements,
    ) || [];

  return (
    <DSModal
      modalType={MODAL_TYPE_V2.FORM}
      centered
      size="medium"
      minColumnWidth={60}
      shouldCloseOnOverlayClick
      showRejectButton
      modalTitle="Update Lock Request Fields"
      confirmLabel="Close"
      isOpen={!isEmpty(getDiffElements)}
      onClose={() => {
        Common.closeApplication();
      }}
      onConfirm={() => {
        Common.closeApplication();
      }}
    >
      <DSDataGrid
        columns={updateEncompassElementsColumnsDef}
        height={320}
        rows={getDiffElements}
        rowKey="fieldName"
      />
    </DSModal>
  );
};
