const USStateTypes = {
  AL: { displayText: "Alabama", defaultZipCode: "35005" },
  AK: { displayText: "Alaska", defaultZipCode: "99501" },
  AZ: { displayText: "Arizona", defaultZipCode: "85001" },
  AR: { displayText: "Arkansas", defaultZipCode: "72002" },
  CA: { displayText: "California", defaultZipCode: "90210" },
  CO: { displayText: "Colorado", defaultZipCode: "80022" },
  CT: { displayText: "Connecticut", defaultZipCode: "06101" },
  DE: { displayText: "Delaware", defaultZipCode: "19702" },
  DC: { displayText: "District of Columbia", defaultZipCode: "20001" },
  FL: { displayText: "Florida", defaultZipCode: "33018" },
  GA: { displayText: "Georgia", defaultZipCode: "30301" },
  HI: { displayText: "Hawaii", defaultZipCode: "96795" },
  ID: { displayText: "Idaho", defaultZipCode: "83701" },
  IL: { displayText: "Illinois", defaultZipCode: "60007" },
  IN: { displayText: "Indiana", defaultZipCode: "46077" },
  IA: { displayText: "Iowa", defaultZipCode: "50047" },
  KS: { displayText: "Kansas", defaultZipCode: "66103" },
  KY: { displayText: "Kentucky", defaultZipCode: "40601" },
  LA: { displayText: "Louisiana", defaultZipCode: "70112" },
  ME: { displayText: "Maine", defaultZipCode: "04330" },
  MD: { displayText: "Maryland", defaultZipCode: "21224" },
  MA: { displayText: "Massachusetts", defaultZipCode: "02115" },
  MI: { displayText: "Michigan", defaultZipCode: "49455" },
  MN: { displayText: "Minnesota", defaultZipCode: "55101" },
  MS: { displayText: "Mississippi", defaultZipCode: "39200" },
  MO: { displayText: "Missouri", defaultZipCode: "63146" },
  MT: { displayText: "Montana", defaultZipCode: "59601" },
  NE: { displayText: "Nebraska", defaultZipCode: "68501" },
  NV: { displayText: "Nevada", defaultZipCode: "89109" },
  NH: { displayText: "New Hampshire", defaultZipCode: "03301" },
  NJ: { displayText: "New Jersey", defaultZipCode: "08608" },
  NM: { displayText: "New Mexico", defaultZipCode: "87501" },
  NY: { displayText: "New York", defaultZipCode: "12084" },
  NC: { displayText: "North Carolina", defaultZipCode: "27601" },
  ND: { displayText: "North Dakota", defaultZipCode: "58501" },
  OH: { displayText: "Ohio", defaultZipCode: "43004" },
  OK: { displayText: "Oklahoma", defaultZipCode: "73008" },
  OR: { displayText: "Oregon", defaultZipCode: "97045" },
  PA: { displayText: "Pennsylvania", defaultZipCode: "17025" },
  RI: { displayText: "Rhode Island", defaultZipCode: "02902" },
  SC: { displayText: "South Carolina", defaultZipCode: "29401" },
  SD: { displayText: "South Dakota", defaultZipCode: "57501" },
  TN: { displayText: "Tennessee", defaultZipCode: "38301" },
  TX: { displayText: "Texas", defaultZipCode: "73301" },
  UT: { displayText: "Utah", defaultZipCode: "84116" },
  VT: { displayText: "Vermont", defaultZipCode: "05601" },
  VA: { displayText: "Virginia", defaultZipCode: "23269" },
  WA: { displayText: "Washington", defaultZipCode: "98101" },
  WV: { displayText: "West Virginia", defaultZipCode: "25301" },
  WI: { displayText: "Wisconsin", defaultZipCode: "53558" },
  WY: { displayText: "Wyoming", defaultZipCode: "82001" }
};
export {
  USStateTypes
};
