import { DSButton } from '@elliemae/ds-button';
import { DataTable } from '@elliemae/ds-data-table';
import { InfoCircleFill } from '@elliemae/ds-icons';
import { DSTooltipV3, tooltipPositions } from '@elliemae/ds-tooltip';
import { useLookup } from '@frontend/pricing-search';
import { arrayOf, shape, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { TooltipGrid } from './TooltipGrid';
import { ineligibilityGridColumns } from './utils/constants';

export const ExpandableIneligibilityGrid = (props) => {
  const { data, programID } = props;
  const [rowData, setRowData] = useState([]);

  const purposes = useLookup('untransformedLoanUsage', []);
  const propertyTypes = useLookup('untransformedPropertyTypes', []);
  const propertyUses = (
    useLookup('untransformedPropertyUse', []) as any[]
  )?.filter(({ key }) => !key.match('Pending Sale/Sold'));

  useEffect(() => {
    if (data?.length) {
      const mappingData = data.map((row, index) => ({
        ...row,
        id: `${programID}-${index}`,
      }));
      setRowData(mappingData);
    }
  }, []);

  return (
    <div style={{ padding: '33px 16px 44px 16px' }}>
      <span style={{ float: 'left', width: 'auto' }}>
        <DSButton
          buttonType="text"
          labelText="Ineligibility Grid"
          size="s"
          style={{
            textTransform: 'none',
            color: 'black',
            padding: '8px',
            fontSize: '16px',
            marginBottom: '10px',
          }}
        />
      </span>
      <span style={{ padding: 'left', color: '#1e79c2' }}>
        <DSTooltipV3
          withoutArrow
          zIndex={999}
          startPlacementPreference={tooltipPositions.TOP_START}
          text={
            <TooltipGrid items={{ purposes, propertyUses, propertyTypes }} />
          }
        >
          <DSButton
            buttonType="text"
            icon={<InfoCircleFill />}
            size="s"
            style={{
              textTransform: 'none',
              color: 'black',
              padding: '8px',
              fontSize: '16px',
              marginBottom: '10px',
            }}
          />
        </DSTooltipV3>
      </span>
      <DataTable
        autoHeight
        paginated
        data={rowData}
        colsLayoutStyle="auto"
        columns={ineligibilityGridColumns}
        rowKey="id"
      />
    </div>
  );
};

ExpandableIneligibilityGrid.defaultProps = {
  data: [],
  programID: '',
};

ExpandableIneligibilityGrid.propTypes = {
  data: arrayOf(shape({})),
  programID: string,
};
