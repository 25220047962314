import {
  CLEAN_ALL_ERRORS,
  UPDATE_FORM,
  UPDATE_FIELD,
  SET_EXTRA_FIELDS,
} from './actions';

const INITIAL_STATE = {
  fields: {
    // General Loan Information
    cashOut: {
      errorMessage: null,
      hasError: false,
      required: true,
    },
    effectiveDate: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    firstMortgageAmount: {
      errorMessage: null,
      hasError: false,
      required: true,
    },
    loanPurpose: {
      errorMessage: null,
      hasError: false,
      required: true,
    },
    lienPosition: {
      errorMessage: null,
      hasError: false,
      required: true,
    },
    maxLoanAmountMaxLine1stLien: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    maxLoanAmountMaxLine2ndLien: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    propertyValue: {
      errorMessage: null,
      hasError: false,
      required: true,
    },
    secondMortgageAmount: {
      disabled: false,
      errorMessage: null,
      hasError: false,
      required: false,
    },
    secondMortgagePayment: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    otherClosedEnd2ndMortgage: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    otherPayment: {
      errorMessage: null,
      hasError: false,
      required: true,
    },
    financedAmount: {
      errorMessage: null,
      hasError: false,
      required: false,
    },

    // Product Information
    amount: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    buydownType: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    buydownContributor: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    compensation: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    loanTerms: {
      errorMessage: null,
      hasError: false,
      required: true,
    },
    prepaymentPenalty: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    productOptions: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    standardProducts: {
      errorMessage: null,
      hasError: false,
      required: true,
    },
    specialProducts: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    target: {
      errorMessage: null,
      hasError: false,
      required: true,
    },
    targetPrice: {
      errorMessage: null,
      hasError: false,
      required: true,
    },
    targetRate: {
      errorMessage: null,
      hasError: false,
      required: true,
    },

    // Finance Information
    annualIncome: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    asset: {
      errorMessage: null,
      hasError: false,
      required: true,
    },
    borrowers: {
      errorMessage: {},
      disabled: {},
      hasError: false,
      required: false,
    },
    employment: {
      errorMessage: null,
      hasError: false,
      required: true,
    },
    income: {
      errorMessage: null,
      hasError: false,
      required: true,
    },
    lastName: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    liquidAssets: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    monthlyDebt: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    representativeCreditScore: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    retirementAccounts: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    // Supplemental Info
    giftForDownPayment: {
      errorMessage: null,
      hasError: false,
      required: false,
      disabled: false,
    },

    // Subject Property Information
    propertyType: {
      errorMessage: null,
      hasError: false,
      required: true,
    },
    propertyUse: {
      errorMessage: null,
      hasError: false,
      required: true,
    },
    propertyState: {
      errorMessage: null,
      hasError: false,
      required: true,
    },
    propertyZip: {
      errorMessage: null,
      hasError: false,
      required: true,
    },
    propertyTax: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    propertyInsuranceAmount: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    propertyAssociationFee: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    propertyRentalIncome: {
      errorMessage: null,
      hasError: false,
      required: false,
    },
    otherRealEstateOwned: {
      errorMessage: {},
      disabled: {},
      hasError: false,
      required: false,
    },
  },
  hasError: false,
  extraFields: {
    asset: null,
    income: null,
    employment: null,
    ficoScore: 0,
  },
  firstError: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      return {
        firstError:
          action.payload.firstError !== undefined
            ? action.payload.firstError
            : state.firstError,
        extraFields: state.extraFields,
        hasError: action.payload.hasError || state.hasError,
        fields: {
          ...state.fields,
          ...action.payload.fields,
        },
      };
    case UPDATE_FIELD: {
      const fields = { ...state.fields };
      fields[action.payload.field] = {
        ...state.fields[action.payload.field],
        ...action.payload.fieldInfo,
      };
      return {
        firstError: state.firstError,
        extraFields: state.extraFields,
        hasError: state.hasError,
        fields,
      };
    }
    case SET_EXTRA_FIELDS:
      return {
        firstError: state.firstError,
        extraFields: action.payload.extraFields,
        hasError: state.hasError,
        fields: state.fields,
      };
    case CLEAN_ALL_ERRORS: {
      const cleanFields = { ...state.fields };
      Object.keys(state.fields).forEach((field) => {
        cleanFields[field] = {
          hasError: false,
          errorMessage: null,
          required: state.fields[field].required,
        };
      });
      return {
        hasError: false,
        fields: cleanFields,
        extraFields: state.extraFields,
        firstError: null,
      };
    }
    default:
      return state;
  }
};
