import React from 'react';
import { shape, string } from 'prop-types';

const customHeaderStyle = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '100%',
  textAlign: 'right',
  paddingRight: '2px',
};

export const CustomHeader = ({ label, style }) => (
  <div
    style={{ ...customHeaderStyle, ...style }}
    data-testid="CustomColumnHeader-all-components"
  >
    {label.toUpperCase()}
  </div>
);

CustomHeader.defaultProps = {
  label: '',
  style: {},
};

CustomHeader.propTypes = {
  label: string,
  style: shape({}),
};
