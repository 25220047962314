const handlePriceChange = (value) => {
  if (
    Number.isNaN(parseFloat(value)) ||
    parseFloat(value) < 80 ||
    parseFloat(value) > 120
  ) {
    return {
      hasError: true,
      errorMessage: 'Please enter price between 80 and 120',
    };
  }
  return {
    hasError: false,
    errorMessage: '',
  };
};

const handleRateChange = (value) => {
  if (
    Number.isNaN(parseFloat(value)) ||
    parseFloat(value) < 0 ||
    parseFloat(value) > 20
  ) {
    return {
      hasError: true,
      errorMessage: 'Please enter rate between 0 and 20',
    };
  }
  return {
    hasError: false,
    errorMessage: '',
  };
};

const handleDaysChange = (value) => {
  if (
    Number.isNaN(parseFloat(value)) ||
    parseInt(value, 10) < 1 ||
    parseInt(value, 10) > 30
  ) {
    return {
      hasError: true,
      errorMessage: 'Please provide expiration between 1 and 30 days',
    };
  }
  return {
    hasError: false,
    errorMessage: '',
  };
};

const handleEmailChange = (value) => {
  if (
    value !== undefined &&
    value.trim() !== '' &&
    !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
  ) {
    return {
      hasError: true,
      errorMessage: 'Please provide valid email',
    };
  }
  return {
    hasError: false,
    errorMessage: '',
  };
};

export {
  handlePriceChange,
  handleRateChange,
  handleDaysChange,
  handleEmailChange,
};
