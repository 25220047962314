import React, { Fragment } from 'react';
import { arrayOf, shape } from 'prop-types';
import { isEmpty } from 'lodash';
import { EmptyState } from '@elliemae/ds-datagrids';

export const RowsGridEmpty = ({ loanPrograms, rows }) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!isEmpty(loanPrograms) && !rows.length ? (
        <div style={{ margin: 'auto', paddingTop: '48px' }}>
          <EmptyState
            data-testid="RowsGridEmpty-all-components"
            primaryMessage="No Products matched your request"
            secondaryMessage=""
          />
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

RowsGridEmpty.defaultProps = {
  loanPrograms: {},
  rows: [],
};

RowsGridEmpty.propTypes = {
  loanPrograms: shape({}),
  rows: arrayOf(shape({})),
};
