import { isShallowEqual } from '@frontend/pricing-search';
import { shallowEqual, useSelector } from 'react-redux';
import {
  formValidationsSelector,
  originalLoanInfoSelector,
  searchFormSelector,
} from '../../../../store/selectors';

export const useFormValidationsSelector = (pathValue) =>
  useSelector(formValidationsSelector(pathValue), isShallowEqual);

export const useInitialValuesSelector = (pathValue) =>
  useSelector(formValidationsSelector(pathValue), isShallowEqual);

export const useSearchFormSelector = (pathValue) =>
  useSelector(searchFormSelector(pathValue), isShallowEqual);

export const useOriginalLoanInfoSelector = (pathValue, defaultValue?: any) =>
  useSelector(originalLoanInfoSelector(pathValue, defaultValue), shallowEqual);
