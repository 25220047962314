import { OpenWindow } from '@elliemae/ds-icons';
import { DSSeparator } from '@elliemae/ds-separator';
import { bool, func, number } from 'prop-types';
import Rights from '../../../../common/services/Rights';

import { GuidelineLinkStyled, PLinkContainer } from './styled';

const separatorStyle = { display: 'block' };

export const GuidelineLink = ({
  activeGuidelineLink,
  showInAccordion,
  onClick,
  showSeprator,
}) => (
  <div data-testid="GuidelineLink-all-components">
    {activeGuidelineLink === 1 && Rights.viewInvestorGuidelineOverride && (
      <>
        <GuidelineLinkStyled showInAccordion={showInAccordion}>
          <form
            id="allregs"
            method="post"
            target="_blank"
            style={{ display: 'none' }}
          >
            <input type="hidden" name="appId" id="appId" />
            <input type="hidden" name="passkey" id="passkey" />
          </form>
          <div
            data-testid="active-guideline-link-container"
            style={{ width: 'fit-content' }}
            onClick={onClick}
            onKeyPress={null}
            role="button"
            tabIndex="0"
          >
            <PLinkContainer
              data-testid="active-guideline-link"
              showInAccordion={showInAccordion}
            >
              View Guidelines on AllRegs
              <OpenWindow
                size="s"
                color={['neutral', '500']}
                style={{ marginLeft: 10, marginTop: -5 }}
              />
            </PLinkContainer>
          </div>
        </GuidelineLinkStyled>
        {!showSeprator && (
          <DSSeparator
            dashed
            color="neutral"
            position="initial"
            style={
              showInAccordion
                ? { ...separatorStyle, padding: '0 16px' }
                : {
                    ...separatorStyle,
                    padding: '0 20px',
                    margin: 0,
                    marginBottom: 10,
                  }
            }
          />
        )}
      </>
    )}
  </div>
);

GuidelineLink.defaultProps = {
  activeGuidelineLink: 0,
  showInAccordion: false,
  showSeprator: false,
};
GuidelineLink.propTypes = {
  activeGuidelineLink: number,
  showInAccordion: bool,
  onClick: func.isRequired,
  showSeprator: bool,
};
