export const customOptions = {
  targetTypes: [
    { key: 'Price', value: 'Price' },
    { key: 'Rate', value: 'Rate' },
  ],
  compensationTypes: [
    { key: 'Borrower-Paid', value: 'Borrower-Paid' },
    { key: 'Lender-Paid', value: 'Lender-Paid' },
  ],
};

export const docTypesOptions = {
  employment: {
    100: [
      { label: 'Employed or Retired (can Verify)', value: 1 },
      { label: 'Self Employed (can Verify)', value: 2 },
    ],
    0: [{ label: 'Will Not Verify or Unemployed', value: 0 }],
  },
  income: {
    100: [{ label: 'Full Income', value: 100 }],
    70: [{ label: 'Alternative Income', value: 70 }],
    60: [{ label: 'Lite Income', value: 60 }],
    50: [{ label: 'Stated Income', value: 50 }],
    0: [{ label: 'No Income', value: 0 }],
  },
  assets: {
    100: [{ label: 'Full Assets', value: 100 }],
    50: [{ label: 'Stated Assets', value: 50 }],
    0: [{ label: 'No Assets', value: 0 }],
  },
};

export const itemsFormat = {
  agencyapprovals: {
    noneOption: true,
  },
  documentationTypes: {
    nonFormat: true,
  },
  loanTerms: {
    extraProps: {
      heloc: 'heloc',
      term: 'term',
    },
  },
  states: {
    props: {
      label: 'abbreviation',
      value: 'abbreviation',
    },
    extraProps: {
      stateID: 'stateID',
    },
  },
  bankruptcy: {
    extraProps: {
      displayOrder: 'displayOrder',
    },
  },
  foreclosure: {
    extraProps: {
      displayOrder: 'displayOrder',
    },
  },
  bankruptcyType: {
    props: {
      label: 'descriptionText',
      value: 'bankruptcyTypeId',
    },
  },
  nodTypes: {
    props: {
      label: 'description',
      value: 'nodTypeID',
    },
  },
  citizenship: {
    props: {
      label: 'citizenship',
      value: 'citizenshipId',
    },
    noneOption: true,
  },
  nonQMDocLevel: {
    props: {
      label: 'descriptionText',
      value: 'nonQMDocLevelTypeId',
    },
  },
  buydownType: {
    props: {
      label: 'descriptionText',
      value: 'buydownTypeId',
    },
  },
  buydownContributorType: {
    props: {
      label: 'descriptionText',
      value: 'buydownContributorTypeId',
    },
  },
  unitTypes: {
    props: {
      label: 'description',
      value: 'unitTypeID',
    },
  },
  commitmentTypes: {
    props: {
      label: 'description',
      value: 'commitmentTypeID',
    },
  },
  lockdays: {
    props: {
      label: 'lockDays',
      value: 'lockDays',
    },
  },
  clientSettings: {
    nonFormat: true,
  },
  geocodes: {
    nonFormat: true,
  },
  encompassElements: {
    nonFormat: true,
  },
  customFields: {
    nonFormat: true,
  },
  fee: {
    props: {
      label: 'feeId',
      value: 'feeValue',
    },
    extraProps: {
      feeId: 'feeId',
      feeName: 'feeName',
      feeValue: 'feeValue',
      minDown: 'minDown',
      maxDown: 'maxDown',
      firstUse: 'firstUse',
      afterFirstUse: 'afterFirstUse',
    },
  },
};
