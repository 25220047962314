import { BUTTON_TYPES, DSButtonV2 } from '@elliemae/ds-button';
import { DSDropdownMenuV2 } from '@elliemae/ds-dropdownmenu';
import { Grid } from '@elliemae/ds-grid';
import { ChevronSmallDown } from '@elliemae/ds-icons';
import { func } from 'prop-types';
import { useEffect, useState } from 'react';
import Rights from '../../../../../common/services/Rights';
import { PRICING_DROPDOWN_MENU } from '../constants';

const PricingDropdown = ({ priceAnalyzerModal, priceDetailsModal }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [options, setOptions] = useState(PRICING_DROPDOWN_MENU);
  const [selectedOptions, setSelectedOptions] = useState<any>({});

  useEffect(() => {
    // TODO(ngen): optionsCopy is both an array and an object. This probably doesn't work.
    const optionsCopy = [...options] as any;

    if (!Rights.accessPriceAnalyzer) {
      const analyzerIndex = options.findIndex((el) => el.dsId === 'analyzers');
      if (analyzerIndex !== -1) optionsCopy.splice(analyzerIndex, 1);
    }

    setOptions(optionsCopy);
  }, []);

  useEffect(() => {
    switch (selectedOptions.dsId) {
      case 'analyzers':
        priceAnalyzerModal();
        break;
      case 'details':
        priceDetailsModal();
        break;
      default:
        break;
    }
  }, [selectedOptions]);

  return (
    <Grid p="xxs" width="fit-content" alignItems="center" paddingRight="0px">
      <DSDropdownMenuV2
        isOpened={isOpened}
        options={options}
        selectedOptions={selectedOptions}
        onClickOutside={() => setIsOpened(false)}
        onOptionClick={(_, selectedOption) => {
          if (!(selectedOption as any).disabled) {
            setIsOpened(false);
            setSelectedOptions({
              ...selectedOption,
              [selectedOption.dsId]: true,
            });
          }
        }}
        minWidth={100}
      >
        <DSButtonV2
          buttonType={BUTTON_TYPES.OUTLINE}
          onClick={() => setIsOpened((prevOpened) => !prevOpened)}
        >
          Pricing
          <ChevronSmallDown />
        </DSButtonV2>
      </DSDropdownMenuV2>
    </Grid>
  );
};

PricingDropdown.propTypes = {
  priceAnalyzerModal: func.isRequired,
  priceDetailsModal: func.isRequired,
};

export default PricingDropdown;
