export const targetDropdownOptions = [
  { dsId: 'id1', label: 'Target Price', type: 'single' },
  { dsId: 'id2', label: 'Target Rate', type: 'single' },
];

export const rowNumberDropdownOptions = [
  { dsId: 'five', label: '5', type: 'single' },
  { dsId: 'all', label: 'ALL', type: 'single' },
];

export const defaultRowNumberDropdownOption = {
  dsId: 'five',
  label: '5',
  type: 'single',
};

export const targetRateOptions = (label) => ({
  prefix: '',
  integerLimit: 3,
  decimalLimit: 3,
  allowDecimal: true,
  suffix: label === 'Target Rate' ? '%' : '',
});

export const getTargetValue = (value) => {
  let parseValue = String(value);
  if (value >= 0 && value <= 20) parseValue += '%';
  return parseValue;
};

export const getTargetLabel = (target) => {
  const { 0: targetPrice, 1: targetRate } = targetDropdownOptions;
  let label = { ...targetPrice };
  if (target >= 0 && target <= 20) label = { ...targetRate };
  return label;
};
