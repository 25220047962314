/* eslint-disable @typescript-eslint/default-param-last */
import {
  SET_LOAN_ID,
  SET_GEOCODES,
  SET_RATE_SELECTOR,
  SET_CURRENT_RATE_SELECTOR,
  SET_DIFF_ELEMENTS,
  SET_ENCOMPASS_ELEMENTS,
  SET_STATUS_FAIL,
  SET_COMMENTS,
  SET_PRICE_TABLE,
  SET_SETTINGS,
  SELECT_PRODUCT,
  SELECT_PRICE,
  SET_LOAN_LIMITS,
  SET_COMPARE_DATA,
  SET_IS_LOAN_HEDGED,
  SET_CLIENT_SETTINGS_DATA_SELECTOR,
} from './actions';
import { INITIAL_STATE } from './constants';

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_RATE_SELECTOR:
      return {
        ...state,
        rateSelector: action.payload,
      };
    case SET_CLIENT_SETTINGS_DATA_SELECTOR:
      return {
        ...state,
        clientSettingsDataSelector: action.payload,
      };
    case SET_CURRENT_RATE_SELECTOR:
      return {
        ...state,
        currentRateSelector: action.payload,
      };
    case SET_COMPARE_DATA:
      return {
        ...state,
        pricingChanges: action.payload,
      };
    case SET_DIFF_ELEMENTS:
      return {
        ...state,
        diffElements: action.payload,
      };
    case SET_ENCOMPASS_ELEMENTS:
      return {
        ...state,
        encompassElements: action.payload,
      };
    case SET_GEOCODES:
      return {
        ...state,
        geoCodes: action.payload,
      };
    case SET_LOAN_ID:
      return {
        ...state,
        loanId: action.payload,
      };
    case SET_STATUS_FAIL:
      return {
        ...state,
        getStatus: action.payload,
      };
    case SET_COMMENTS:
      return {
        ...state,
        rateSelector: {
          ...state.rateSelector,
          loan: {
            ...(state.rateSelector as any).loan,
            comments: action.payload,
          },
        },
      };
    case SET_PRICE_TABLE:
      return {
        ...state,
        rateSelector: {
          ...state.rateSelector,
          lien: {
            ...(state.rateSelector as any).lien,
            priceTable: action.payload,
          },
        },
      };
    case SET_SETTINGS:
      return {
        ...state,
        adjustmentsSettings: action.payload,
      };
    case SELECT_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload,
      };
    case SELECT_PRICE:
      return {
        ...state,
        selectedPrice: action.payload,
      };
    case SET_LOAN_LIMITS:
      return {
        ...state,
        loanLimits: action.payload,
      };
    case SET_IS_LOAN_HEDGED:
      return {
        ...state,
        isLoanHedged: action.payload,
      };
    default:
      return state;
  }
};
