import React from 'react';
import { DSToolbar } from '@elliemae/ds-toolbar';
import { FilterPills } from '../../FilterPills';

export const BestExToolbar = () => {
  return (
    <DSToolbar
      withDepth={false}
      style={{
        alignItems: 'flex-start',
        height: 'auto',
        justifyContent: 'space-between',
        marginBottom: 10,
        paddingBottom: '10px',
      }}
    >
      <FilterPills />
    </DSToolbar>
  );
};
