import { isEmpty, isString, isUndefined } from 'lodash';

const getChildrenValue = (object, parent, children) => {
  if (!isEmpty(object)) {
    if (!isEmpty(object[parent]) || !isUndefined(object[parent])) {
      return children ? object[parent][children] : object[parent];
    }
  }
  return null;
};

export const getValueTextInput = (object, parentProp, childrenProp) => {
  let value = null;
  if (object) {
    value = getChildrenValue(object, parentProp, childrenProp);
  }
  return value === null || value === undefined ? '' : String(value);
};

export const getValueInput = (object, parentProp, childrenProp) => {
  const value = getChildrenValue(object, parentProp, childrenProp);
  return value === null || value === undefined ? 'none' : value;
};

export const getValueNullableInput = (object, parentProp, childrenProp) => {
  const value = getChildrenValue(object, parentProp, childrenProp);
  return value === null ? 'null' : value;
};

export const getFloredValueTextInput = (object, parentProp, childrenProp) => {
  let value = getChildrenValue(object, parentProp, childrenProp);
  value = value !== '' ? Math.floor(value) : '';
  return value === null || value === undefined ? '' : String(value);
};

export const getTruncValueTextInput = (object, parentProp, childrenProp) => {
  let value = getChildrenValue(object, parentProp, childrenProp);
  value = ![undefined, null, ''].includes(value) ? Math.trunc(value) : '';
  return String(value);
};

export const getValueRequiredInput = (object, parentProp, childrenProp) => {
  return getChildrenValue(object, parentProp, childrenProp);
};

export const setValueInput = (value) => {
  const isNotValidArray = Array.isArray(value) && value.length === 0;
  return ['', 'none', -1, null, undefined].includes(value) || isNotValidArray
    ? null
    : value;
};

export const replaceValue = (e, replace) => {
  const { value } = e.target;
  return value.replace(`${replace}`, '').replace(/,/g, '');
};

export const generateOnChangeValues = (
  object,
  parentProp,
  childrenProp,
  value,
) => {
  const setObject = { ...object };
  return {
    ...setObject,
    [parentProp]: childrenProp
      ? {
          ...setObject[parentProp],
          [childrenProp]: value,
        }
      : value,
  };
};

export const getValidTextValue = (object, parentProp, childrenProp) => {
  const value = getChildrenValue(object, parentProp, childrenProp);
  return value === null || value === undefined ? '' : value;
};

export const getValidNumberValue = (object, parentProp, childrenProp) => {
  const value = getChildrenValue(object, parentProp, childrenProp);
  return parseFloat(value);
};

export const getValidArrayValue = (object, parentProp, childrenProp) => {
  const value = getChildrenValue(object, parentProp, childrenProp);
  return value === null || value === undefined ? [] : value;
};

export const getValueComboBoxMulti = (object, parentProp, childrenProp) => {
  return getValidArrayValue(object, parentProp, childrenProp);
};

export const generateDocumentationTypesCombobox = (
  type,
  typesOptions,
  documentOptions,
) => {
  const setOptions: any[] = [];
  if (!isEmpty(typesOptions)) {
    const elements = [
      ...new Set(typesOptions.map((el) => el[type] || (el[type] === 0 && 0))),
    ];
    if (!isEmpty(elements)) {
      elements.map((el) => {
        const isDocumentOption = documentOptions[el as any];
        return isDocumentOption.map((opt) => setOptions.push(opt));
      });
    }
  }
  return setOptions;
};

export const generateComboBoxOptions = (item, index) => {
  if (index === 3) {
    return {
      value: item.value,
      label: item.key,
      dsId: item.key,
      type: 'option',
      heloc: item.heloc,
    };
  }
  if (index === 6) {
    return {
      value: item.abbreviation,
      label: item.state,
      dsId: item.state,
      type: 'option',
      stateID: item.stateID,
    };
  }
  if (index === 12) {
    return {
      value: item.value === 0 ? 'none' : item.value,
      label: item.key,
      dsId: item.key,
      type: 'option',
    };
  }
  if (index === 15 || index === 17) {
    return {
      value: item.value === null ? 'none' : item.value,
      label: item.key,
      dsId: item.key,
      type: 'option',
      displayOrder: item.displayOrder,
    };
  }

  if (index === 16) {
    return {
      value: item.bankruptcyTypeId === null ? 'none' : item.bankruptcyTypeId,
      label: item.descriptionText,
      dsId: item.descriptionText,
      type: 'option',
    };
  }
  if (index === 18) {
    return {
      value: item.nodTypeID === null ? 'none' : item.nodTypeID,
      label: item.description,
      dsId: item.description,
      type: 'option',
    };
  }

  if (index === 19) {
    return {
      value: item.value,
      label: item.label,
      dsId: item.label,
      type: 'option',
    };
  }
  if (index === 20) {
    return {
      value: item.citizenshipId,
      label: item.citizenship,
      dsId: item.citizenship,
      type: 'option',
    };
  }

  if (index === 23) {
    return {
      value: item.buydownTypeId,
      label: item.descriptionText,
      dsId: item.descriptionText,
      type: 'option',
    };
  }
  if (index === 24) {
    return {
      value: item.buydownContributorTypeId,
      label: item.descriptionText,
      dsId: item.descriptionText,
      type: 'option',
    };
  }
  if (index === 22) {
    return {
      value: item.nonQMDocLevelTypeId,
      label: item.descriptionText,
      dsId: item.descriptionText,
      type: 'option',
    };
  }
  if (index === 25) {
    return {
      value: item.unitTypeID,
      label: item.description,
      dsId: item.description,
      type: 'option',
    };
  }
  if (index === 26) {
    return {
      value: item.commitmentTypeID,
      label: item.description,
      dsId: item.description,
      type: 'option',
    };
  }
  if (index === 'COUNTIES') {
    return {
      value: item.key,
      label: item.key,
      dsId: item.key,
      type: 'option',
    };
  }
  return {
    value: item.value,
    label: item.key,
    dsId: item.key,
    type: 'option',
  };
};

export const generateComboBox = (field, index) => {
  const comboBox: Array<{
    value: string;
    label: string;
  }> =
    field !== undefined &&
    field !== null &&
    field !== '' &&
    Array.isArray(field)
      ? field.map((item) => generateComboBoxOptions(item, index))
      : [];
  if (index === 8 || index === 20) {
    comboBox.unshift({ value: 'none', label: 'none' });
  }
  if (index === 'COUNTIES') {
    comboBox.unshift({ value: 'none', label: 'none' });
  }
  if (index === 21) {
    return field;
  }
  return comboBox;
};

// TODO: This should be replaced to a general function
export const generateSearchResultCombobox = (field) => {
  return field?.map((elem) => ({
    label: elem.key,
    value: elem.value || 'none',
  }));
};

export const changeNoneForZeroCombobox = (array) => {
  if (array && array.length) {
    return array.map((elem) => ({
      value: elem.value === 'none' ? 0 : elem.value,
      label: elem.label,
    }));
  }
  return [];
};

export const maxValueCalculatorP = (stringToCheck = '', defaultMax = 0) => {
  if (isString(stringToCheck)) {
    if (stringToCheck.split('.').length > 1) {
      return defaultMax + 1;
    }
  }
  return defaultMax;
};
