import { jsx } from "react/jsx-runtime";
import {
  getNumberMaskedValue,
  useNumberMask
} from "@elliemae/ds-form-helpers-mask-hooks";
import { useEffect, useState } from "react";
import { DSInputText } from "@elliemae/ds-form-input-text";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { Grid } from "@elliemae/ds-grid";
import { useFormState } from "react-hook-form";
import { useTextboxFieldUpdateLogic } from "./utils/fieldUpdateLogic";
import { useFieldVisibilityAndDisability } from "./utils/fieldVisibilityAndDisability";
const Textbox = ({
  renderedField,
  schemaForm,
  rules,
  id,
  name,
  label,
  required,
  pathValue,
  feedbackMessage,
  type,
  ui,
  visible,
  disabled,
  disableCheck,
  visibleCheck,
  preview
}) => {
  const PERCENT_VALUES = {
    allowNegative: true,
    decimalPlaces: 3,
    suffix: "%",
    includeThousandsSeparator: false
  };
  const NOT_DECIMAL_PLACES = {
    allowNegative: true,
    decimalPlaces: 0,
    prefix: "$"
  };
  const NUMBER_VALUES = {
    allowNegative: false,
    includeThousandsSeparator: false
  };
  const textboxUpdateLogic = useTextboxFieldUpdateLogic();
  const { isVisible, isDisabled } = useFieldVisibilityAndDisability({
    schemaForm,
    rules,
    visible,
    disabled,
    visibleCheck,
    disableCheck,
    preview
  });
  const { errors } = useFormState({ control: schemaForm.control });
  const maskOptionsMap = {
    currency: NOT_DECIMAL_PLACES,
    percent: PERCENT_VALUES,
    number: NUMBER_VALUES
  };
  const opts = maskOptionsMap[ui?.widget] || maskOptionsMap[type] || void 0;
  const stringValue = String(renderedField?.value ?? "");
  const [value, setValue] = useState(
    opts ? getNumberMaskedValue(stringValue, opts) : stringValue
  );
  useEffect(() => {
    setValue(opts ? getNumberMaskedValue(stringValue, opts) : stringValue);
  }, [renderedField?.value]);
  const inputProps = useNumberMask({
    valueSetter: setValue,
    ...opts
  });
  return /* @__PURE__ */ jsx(Grid, { span: ui.col_size, style: { display: isVisible ? "block" : "none" }, children: /* @__PURE__ */ jsx(
    DSFormLayoutBlockItem,
    {
      name,
      label,
      required,
      feedbackMessage,
      hasError: !!errors[id],
      validationMessage: errors[id]?.message?.toString(),
      children: /* @__PURE__ */ jsx(
        DSInputText,
        {
          ...renderedField,
          "data-testid": "textbox",
          value: opts ? value : renderedField?.value ?? "",
          ...opts ? inputProps : {},
          onChange: (e) => {
            if (opts) {
              inputProps.onChange(e);
              const parsedValue = e.target.value.replace(/[^0-9.-]/g, "");
              const numericValue = parsedValue ? parseFloat(parsedValue) : 0;
              textboxUpdateLogic(
                renderedField,
                schemaForm,
                id
              )({
                ...e,
                target: { ...e.target, value: numericValue }
              });
            } else {
              textboxUpdateLogic(renderedField, schemaForm, id)(e);
            }
          },
          disabled: isDisabled,
          id: pathValue
        }
      )
    }
  ) });
};
export {
  Textbox
};
