import loanInformation from './loanInformation';
import productInformation from './productInformation';
import subjectPropertyInformation from './subjectPropertyInformation';
import financeInformation from './financeInformation';

const validations = {
  ...loanInformation,
  ...productInformation,
  ...financeInformation,
  ...subjectPropertyInformation,
};

export default validations;
