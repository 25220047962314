/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
import Grid from '@elliemae/ds-grid';
import { objectOf, shape, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Rights from '../../../../common/services/Rights';
import Session, {
  ALL_SUMMARY_PARAMS,
} from '../../../../common/services/Session';
import { PATH_LOCK_SUMMARY } from '../../../../route/constants';
import { dispApr, dispCurrencyMask, dispPrice, dispRate } from '../../helpers';
import { StyledBestExProgramLinks, StyledBestExRowSelected } from './styled';

export const DropdownDetails = (props) => {
  const navigate = useNavigate();
  const cols = ['52%', '48%'];
  const program = props.row.original;
  const { rateDetails, setSlide, handleSelectRow } = program;
  const { loanId } = useSelector(({ EPPS }) => EPPS.loanPrograms || {});
  const dispatch = useDispatch();
  const handleSelectProduct = (programID, rateDataID) => {
    Session.set(ALL_SUMMARY_PARAMS, {
      loanId,
      programId: programID,
      rateDataId: rateDataID,
    });
    navigate(PATH_LOCK_SUMMARY);
  };
  const rightAlign = { width: '100%', textAlign: 'right', paddingRight: '3px' };
  const CellValue = ({ value }) => {
    return <span style={rightAlign}>{value}</span>;
  };
  CellValue.defaultProps = {
    value: '',
  };
  CellValue.propTypes = {
    value: string,
  };

  return (
    <>
      <Grid cols={cols}>
        <Grid />
        <Grid>
          <Grid cols={Rights.viewAPR ? 6 : 5} tabIndex={0}>
            {rateDetails.map(
              (
                {
                  programID,
                  rateDataID,
                  rate,
                  price,
                  priceVal,
                  pnI,
                  apr,
                  targetRow = program.target.rateDataID === rateDataID &&
                    program.target.programID === programID,
                },
                index,
              ) => (
                <>
                  <Grid
                    height="32px"
                    style={{
                      paddingRight: 8,
                    }}
                  >
                    <StyledBestExRowSelected targetRow={targetRow} gridRow>
                      <Grid
                        width="100%"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <StyledBestExProgramLinks
                          onClick={() => {
                            handleSelectRow(rateDetails[index]);
                            handleSelectProduct(programID, rateDataID);
                          }}
                        >
                          {dispRate(rate, priceVal)}
                        </StyledBestExProgramLinks>
                      </Grid>
                    </StyledBestExRowSelected>
                  </Grid>
                  <Grid
                    height="32px"
                    style={{
                      paddingRight: 8,
                      fontWeight: targetRow ? 'bold' : '',
                    }}
                  >
                    <StyledBestExRowSelected targetRow={targetRow} gridRow>
                      <CellValue value={dispPrice(price, priceVal)} />
                    </StyledBestExRowSelected>
                  </Grid>
                  <Grid
                    height="32px"
                    style={{
                      paddingRight: 8,
                      fontWeight: targetRow ? 'bold' : '',
                    }}
                  >
                    <StyledBestExRowSelected targetRow={targetRow} gridRow>
                      <CellValue value={dispCurrencyMask(priceVal)} />
                    </StyledBestExRowSelected>
                  </Grid>
                  <Grid
                    height="32px"
                    style={{
                      paddingRight: 8,
                      fontWeight: targetRow ? 'bold' : '',
                    }}
                  >
                    <StyledBestExRowSelected targetRow={targetRow} gridRow>
                      <CellValue value={dispCurrencyMask(pnI)} />
                    </StyledBestExRowSelected>
                  </Grid>
                  {Rights.viewAPR && (
                    <Grid
                      height="32px"
                      style={{
                        paddingRight: 8,
                        fontWeight: targetRow ? 'bold' : '',
                      }}
                    >
                      <StyledBestExRowSelected targetRow={targetRow} gridRow>
                        <CellValue value={dispApr(apr)} />
                      </StyledBestExRowSelected>
                    </Grid>
                  )}
                  <Grid
                    height="32px"
                    style={{
                      paddingRight: 8,
                      fontWeight: targetRow ? 'bold' : '',
                    }}
                  >
                    <StyledBestExRowSelected targetRow={targetRow} gridRow>
                      <Grid
                        width="100%"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <StyledBestExProgramLinks
                          onClick={() => {
                            handleSelectRow(rateDetails[index]);
                            setSlide({
                              rowData: rateDetails[index],
                              rateData: rateDataID,
                              open: false,
                              data: null,
                            });
                          }}
                        >
                          details
                        </StyledBestExProgramLinks>
                      </Grid>
                    </StyledBestExRowSelected>
                  </Grid>
                </>
              ),
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

DropdownDetails.defaultProps = {
  row: {},
};

DropdownDetails.propTypes = {
  row: objectOf(shape({})),
};
