import styled from 'styled-components';

export const StyledPricingGridHeader = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
`;

export const StyledBuySidePrice = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;

export const StyledTileGrids = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 10px;
  padding-top: 15px;
  height: 40px;
  font-size: ${({ theme: { fontSizes = {} } = {} }) => fontSizes?.title?.[600]};
  font-weight: ${({ theme: { fontWeights } = {} }) => fontWeights?.semibold};
`;
