import React from 'react';
import {
  DSTruncatedTooltipText,
  TooltipTextProvider,
} from '@elliemae/ds-truncated-tooltip-text';
import { PopperPositions as Positions } from '@elliemae/ds-popper';
import {
  StyledIneligibleProgramLinks,
  StyledIneligibleRow,
  StyledIneligibleReason,
} from '../styled';

export const getIneligibleGridColumns = () => [
  {
    accessor: 'program',
    Header: 'Program Name',
    minWidth: '60px',
    width: '40%',
    Cell: ({ row, onClickProgramName }) => {
      const { program } = row.original;
      return (
        <StyledIneligibleRow>
          <TooltipTextProvider>
            <DSTruncatedTooltipText
              value={
                <StyledIneligibleProgramLinks
                  onClick={() => onClickProgramName(row.original)}
                >
                  {program}
                </StyledIneligibleProgramLinks>
              }
              tooltipPlacement={Positions.TOP}
            />
          </TooltipTextProvider>
        </StyledIneligibleRow>
      );
    },
  },
  {
    Header: 'Ineligibility Reason',
    width: '60%',
    Cell: ({ row }) => {
      return (
        <StyledIneligibleReason>{row.original.notes}</StyledIneligibleReason>
      );
    },
  },
];
