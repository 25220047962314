import {
  currency,
  currencyWithDecimals,
} from '../../../../common/utils/shared';
import {
  useRateSelector,
  useRateSelectorLien,
} from '../../../../data/useSelectors';

const useEstimatesSectionData = () => {
  const rateData = useRateSelector();
  const { lien = {}, loan = {} } = rateData as any;

  const pandI: number = lien.pandI || 0;
  const taxInsurance: number = lien.taxInsurance || 0;
  const interesetOnly: number = lien.interesetOnly || 0;
  const lienPosition: number = lien.lienPosition || 1;

  const monthlyFees: number = loan.property?.monthlyFees || 0;
  const mortgageInsurance: number = loan.property?.monthlyMI || 0;

  const otherPayment: number = loan.otherMtgPayment || 0;
  const { reserves } = useRateSelectorLien(['reserves'], {}) as any;
  const { determinedByAUS = false, reserves: reservesNum } = reserves || {};

  let reservesValue = currency(0);
  if (determinedByAUS) {
    reservesValue = '(Determined by AUS)';
  } else if (reservesNum) {
    reservesValue = currency(reservesNum);
  }

  const fields = [
    {
      label: interesetOnly ? 'Interest' : 'P & I',
      value: currency(pandI),
      key: 'pAndI',
      processedLabel: true,
      order: 1,
    },
    {
      label: 'Total Payments',
      key: 'totalPayments',
      value:
        lienPosition === 1
          ? currencyWithDecimals(
              pandI +
                taxInsurance +
                monthlyFees +
                Number(mortgageInsurance.toString().replace(/[^0-9.]+/g, '')),
              mortgageInsurance.toString().includes('.') ? 2 : 0,
            )
          : currency(pandI),
      order: 7,
    },
    {
      label: 'Reserves',
      value: reservesValue,
      key: 'reserves',
      order: 8,
    },
    {
      label: `Existing ${lienPosition === 1 ? '2nd' : '1st'} Mortgage`,
      value: otherPayment ? currency(otherPayment) || 'None' : 'None',
      key: 'existingMortgage',
      processedLabel: true,
      order: 6,
    },
  ];

  return fields.sort((a, b) => a.order - b.order);
};

const usePropertySectionData = () => {
  const rateData = useRateSelector();
  const { loan = {}, lien = {} } = rateData as any;
  const {
    zip = '',
    county = '',
    usage = '',
    rentalIncome = 0,
    type = '',
  } = loan.property || {};
  const lienPosition = lien.lienPosition || 1;
  const address = `${loan.property?.address},` || '';
  const unitNumber = loan.property?.unitNumber || '';
  const unitNumberValue = unitNumber ? `${unitNumber},` : '';
  const unitType = loan.property?.unitType || '';
  const unitTypeValue = unitType ? `${unitType},` : '';
  const city = `${loan.property?.city}, ` || '';
  const stateAbbreviation = `${loan.property?.stateAbbreviation}, ` || '';
  const annualTax = loan.property?.annualTax || 0;
  const annualInsurance = loan.property?.annualInsurance || 0;
  const monthlyFees = loan.property?.monthlyFees || 0;

  let fields = [
    {
      label: 'Address',
      value: `${
        address ? `${address} ${unitTypeValue}  ${unitNumberValue}` : ''
      } ${city ? `${city}` : ''} ${stateAbbreviation} ${zip}`,
      span: 2,
      key: 'address',
      style: 'width: calc(33.32% - 20px)',
    },
    { label: 'County', value: county, key: 'county' },
    { label: 'Property Type', value: type, key: 'propertyType' },
    { label: 'Property Use', value: usage, key: 'propertyUse' },
  ];
  if (lienPosition === 1) {
    fields = fields.concat([
      {
        label: 'Property Taxes',
        value: currency(annualTax),
        key: 'propertyTaxes',
      },
      {
        label: 'Homeowners Insurance',
        value: currency(annualInsurance),
        key: 'homeownersInsurance',
      },
      {
        label: 'Association Fees',
        value: currency(monthlyFees),
        key: 'associationFees',
      },
      {
        label: 'Rental Income',
        value: currency(rentalIncome),
        key: 'rentalIncome',
      },
    ]);
  }
  return fields;
};

const useArmSectionData = (isArm) => {
  const rateData = useRateSelector();
  const { lien = {} } = rateData as any;
  const {
    armAdjPeriod,
    armFirstCap,
    armPeriodicCap,
    armLifetimeCap,
    armMargin,
  } = lien;

  if (isArm) {
    return [
      {
        label: `Margin`,
        value: `${Number(armMargin || 0).toFixed(3)} %`,
        key: 'armMargin',
      },
      {
        label: `Rate Adjustment Period`,
        value: `${Number(armAdjPeriod || 0).toFixed(0)} mo.`,
        key: 'armRateAdjustmentPeriod',
      },
      {
        label: `1st Rate Change Cap`,
        value: `${Number(armFirstCap || 0).toFixed(3)} %`,
        key: 'armFirstRateChangeCap',
      },
      {
        label: `Period Rate Change Cap`,
        value: `${Number(armPeriodicCap || 0).toFixed(3)} %`,
        key: 'armPeriodRateChangeCap',
      },
      {
        label: `Lifetime Rate Change Cap`,
        value: `${Number(armLifetimeCap || 0).toFixed(3)} %`,
        key: 'armLifetimeRateChangeCap',
      },
    ];
  }
  return [];
};

const useHelocSectionData = (isHeloc) => {
  const rateData = useRateSelector();
  const { lien = {} } = rateData as any;
  const {
    drawPeriodmos,
    interestRateCap,
    armMargin,
    repaymentPeriodmos,
    armLifetimeCap,
  } = lien;

  if (isHeloc) {
    return [
      {
        label: `Margin`,
        value: `${Number(armMargin || 0).toFixed(3)} %`,
        key: 'helocMargin',
      },
      {
        label: `Draw Period`,
        value: `${Number(drawPeriodmos || 0).toFixed(0)} mo.`,
        key: 'helocDrawPeriod',
      },
      {
        label: `Repayment Period`,
        value: `${Number(repaymentPeriodmos || 0).toFixed(0)} mo.`,
        key: 'helocRepaymentPeriod',
      },
      {
        label: `Life Cap`,
        value: `${Number(armLifetimeCap || 0).toFixed(3)} %`,
        key: 'helocLifeCap',
      },
      {
        label: `Interest Rate Cap`,
        value: `${Number(interestRateCap || 0).toFixed(3)} %`,
        key: 'helocInterestRateCap',
      },
    ];
  }
  return [];
};

export {
  useArmSectionData,
  useEstimatesSectionData,
  useHelocSectionData,
  usePropertySectionData,
};
