import { DSFormItemLayout, DSTextBox } from '@elliemae/ds-form';
import { useLoanDataFieldSelectorHook } from '@frontend/pricing-search';
import PropTypes from 'prop-types';
import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { searchFormActions } from '../../../../store/searchForm';
import {
  customOnTextBoxChange,
  getContainerProps,
  getLabel,
  getMaxLength,
  getValidator,
  isFunction,
} from '../utils/common';
import { useSearchFormSelector } from '../utils/customHooks';

export const TextBoxForm = memo<any>(
  ({ name, label, required, pathValue, customValue, maxLength, ...rest }) => {
    const getFielValue = useLoanDataFieldSelectorHook(String(pathValue)) as any;
    const dependencies = useLoanDataFieldSelectorHook(rest.dependencies);
    const extraDependencies = useSearchFormSelector(rest.extraDependencies);
    const [fieldValue, setFieldValue] = useState('');
    const [validator, setValidator] = useState<any>({
      hasError: false,
      errorMessage: '',
      required,
    });
    const [error, setError] = useState(false);

    const dispatch = useDispatch();

    const customDependencies = useMemo(
      () => ({ ...dependencies, ...extraDependencies }),
      [dependencies, extraDependencies],
    );

    const handleValidator = (value) => {
      const validatorValues = { value, required, name, customDependencies };
      const newValidator = getValidator({ ...validatorValues });
      if (newValidator?.errorMessage !== validator?.errorMessage) {
        setValidator(newValidator);
      }
    };

    useEffect(() => {
      if (
        typeof getFielValue === 'object' &&
        getFielValue.value !== fieldValue
      ) {
        setFieldValue(getFielValue.value || '');
        handleValidator(getFielValue.value);
      }
    }, [customDependencies, getFielValue]);

    const containerProps = useMemo(() => {
      return {
        ...getContainerProps(name, validator.hasError),
        ...rest.containerProps,
      };
    }, [validator]);

    useEffect(() => {
      const { hasError } = validator;
      if (hasError !== undefined && error !== hasError) {
        setError((prev) => !prev);
        dispatch(searchFormActions.setFormHasError({ name, hasError }));
      }
    }, [validator]);

    useEffect(() => {
      return () => {
        if (validator.hasError)
          dispatch(
            searchFormActions.setFormHasError({ name, hasError: false }),
          );
      };
    }, [error]);

    const validateEvent = (event) => {
      const { replace } = rest;
      const customEvent = rest[event];
      const eventValues = {
        customEvent,
        replace,
        pathValue,
        customDependencies,
      };
      return customOnTextBoxChange({ event, ...eventValues });
    };

    const handleLabel = () => {
      return getLabel(label, customDependencies);
    };

    const handleFeedbackMessage = () => {
      const { feedbackMessage } = rest;
      return isFunction(feedbackMessage)
        ? feedbackMessage({ initialValue: fieldValue, customDependencies })
        : feedbackMessage;
    };

    const handleReadOnly = () => {
      const { readOnly } = rest;
      return isFunction(readOnly)
        ? readOnly({ initialValue: fieldValue, customDependencies })
        : readOnly;
    };

    const handleOnChange = (value) => {
      const { onChange } = rest;
      setFieldValue(value);
      handleValidator(value);
      if (onChange) {
        onChange({ value, pathValue, customDependencies });
      }
    };

    return (
      <DSFormItemLayout
        {...rest}
        name={name}
        labelText={handleLabel()}
        value={customValue ? String(customValue) : String(fieldValue)}
        readOnly={handleReadOnly()}
        required={validator.required}
        hasError={validator.hasError}
        validationMessage={validator.errorMessage}
        maxLength={getMaxLength(fieldValue, maxLength)}
        clearable={handleReadOnly() ? false : rest.clearable}
        inputComponent={DSTextBox}
        onChange={({ target }) => {
          let { value } = target;

          if (rest.alphanumeric) {
            const regex = /[^0-9a-zA-Z]+/g;
            const formattedValue = (value || '').replace(regex, '');
            value = formattedValue;
          }

          handleOnChange(value);
        }}
        feedbackMessage={handleFeedbackMessage()}
        containerProps={containerProps}
        data-testid="TextBoxForm-all-components"
        // custom handlers
        {...validateEvent('onBlur')}
        {...validateEvent('onClick')}
        {...validateEvent('onFocus')}
      />
    );
  },
);

(TextBoxForm as any).defaultProps = {
  name: '',
  label: '',
  pathValue: '',
  customValue: '',
  maxLength: 50,
  required: false,
  dependencies: [],
  extraDependencies: [],
};

(TextBoxForm as any).propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  pathValue: PropTypes.string,
  customValue: PropTypes.string,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  dependencies: PropTypes.instanceOf(Array),
  extraDependencies: PropTypes.instanceOf(Array),
};
