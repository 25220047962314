import { DSComboBox } from '@elliemae/ds-controlled-form';
import { DSFormLayoutBlockItem } from '@elliemae/ds-form-layout-blocks';
import {
  useLoanDataFieldSelectorHook,
  useLookupOptions,
} from '@frontend/pricing-search';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { searchFormActions } from '../../../../store/searchForm';
import {
  customOnChange,
  getContainerProps,
  getLabel,
  getValidator,
  isFunction,
  parseComboboxValue,
} from '../utils/common';
import { useSearchFormSelector } from '../utils/customHooks';

export const ComboBoxForm = ({
  name,
  label,
  required,
  pathValue,
  optionsFilter,
  maxOptions,
  enableSelectAll,
  isNonClearable,
  containerStyles,
  ...rest
}: any = {}) => {
  const dispatch = useDispatch();
  const options = useLookupOptions(name);
  const filteredOptions = useMemo(
    () =>
      optionsFilter && isFunction(optionsFilter)
        ? optionsFilter(options)
        : null,
    [options, optionsFilter],
  );
  const getFielValue = useLoanDataFieldSelectorHook(
    pathValue,
    rest.isMulti ? [] : rest.initialValue,
  ) as any;
  const dependencies = useLoanDataFieldSelectorHook(rest.dependencies);
  const extraDependencies = useSearchFormSelector(rest.extraDependencies);
  const [fieldValue, setFieldValue] = useState(null);
  const [validator, setValidator] = useState<any>({
    hasError: false,
    errorMessage: '',
    required,
  });
  const [error, setError] = useState(false);

  const customDependencies = useMemo(
    () => ({ ...dependencies, ...extraDependencies }),
    [dependencies, extraDependencies],
  );

  const handleValidator = (value) => {
    const validatorValues = { value, required, name, customDependencies };
    const newValidator = getValidator({ ...validatorValues });
    if (
      newValidator?.errorMessage !== validator?.errorMessage ||
      newValidator?.required !== validator?.required
    ) {
      setValidator(newValidator);
    }
  };

  useEffect(() => {
    if (options) {
      const { isMulti, customValue } = rest;
      const value = isFunction(customValue)
        ? customValue({ value: getFielValue?.value, customDependencies })
        : getFielValue?.value;
      const parseValue = isMulti ? parseComboboxValue(value, isMulti) : value;
      handleValidator(parseValue);
      setFieldValue(value);
    }
  }, [options, customDependencies, rest.initialValue, getFielValue]);

  useEffect(() => {
    const { hasError } = validator;
    if (hasError !== undefined && error !== hasError) {
      setError((prev) => !prev);
      dispatch(searchFormActions.setFormHasError({ name, hasError }));
    }
  }, [validator]);

  useEffect(() => {
    return () => {
      if (validator.hasError)
        dispatch(searchFormActions.setFormHasError({ name, hasError: false }));
    };
  }, [error]);

  const containerProps = useMemo(() => {
    return {
      ...getContainerProps(name, validator.hasError),
      ...rest.containerProps,
    };
  }, [validator]);

  const validateEvent = (event) => {
    const { replace } = rest;
    const customEvent = rest[event];
    const eventValues = { customEvent, replace, pathValue, customDependencies };
    return customOnChange({ event, ...eventValues });
  };

  const handleLabel = () => {
    return getLabel(label, customDependencies);
  };

  const handleOnChangeV2 = (selection) => {
    const parseValue = Array.isArray(selection)
      ? selection.map((option) => option.value)
      : selection?.value;
    handleValidator(parseValue);
    setFieldValue(parseValue);
    const { onChangeV2, isMulti } = rest;
    if (onChangeV2) {
      onChangeV2({
        value:
          Array.isArray(parseValue) && !isMulti ? parseValue[0] : parseValue,
        pathValue,
        customDependencies,
        options,
      });
    }
  };

  const handleDisabled = () => {
    const { customDisabled } = rest;
    if (isFunction(customDisabled)) {
      return customDisabled({ value: fieldValue, customDependencies });
    }
    return customDisabled === true;
  };

  const allComboboxOptions = useMemo(() => {
    return filteredOptions || options || [];
  }, [filteredOptions, options]);

  const [currentOptions, setCurrentOptions] = useState(allComboboxOptions);

  useEffect(() => {
    setCurrentOptions(allComboboxOptions);
  }, [allComboboxOptions]);

  const getFieldValue = () => {
    if (Array.isArray(fieldValue) && typeof fieldValue[0] === 'object')
      return fieldValue || [];
    if (!Array.isArray(fieldValue) && typeof fieldValue === 'object')
      return fieldValue || [];

    const { isMulti } = rest;

    if (!isMulti) {
      const singleValue = Array.isArray(fieldValue)
        ? fieldValue[0]
        : fieldValue;
      return allComboboxOptions.find((option) => option.value === singleValue);
    }

    return (
      (fieldValue as any[])
        .map((value) => {
          return allComboboxOptions.find((option) => option.value === value);
        })
        .filter((elem) => elem !== undefined) || null
    );
  };

  const handleSelectAll = () => {
    if (enableSelectAll) {
      return handleOnChangeV2;
    }

    if (
      typeof maxOptions === 'number' &&
      allComboboxOptions.length > maxOptions
    ) {
      return null;
    }

    return handleOnChangeV2;
  };

  const { isMenuOpen, onMenuChange, ...dsformRest } = rest;

  return (
    <div style={containerStyles} {...containerProps}>
      <DSFormLayoutBlockItem
        {...dsformRest}
        name={name}
        label={handleLabel()}
        inputID={name}
        hasError={validator.hasError}
        validationMessage={validator.errorMessage}
        required={validator.required}
      >
        <DSComboBox
          zIndex={100}
          maxOptions={rest.maxOptions}
          menuMaxHeight={rest.menuMaxHeight}
          allOptions={allComboboxOptions}
          onChange={handleOnChangeV2}
          filteredOptions={currentOptions}
          onFilter={setCurrentOptions}
          onSelectAll={handleSelectAll()}
          selectedValues={getFieldValue() || []}
          disabled={handleDisabled()}
          hasError={validator.hasError}
          validationMessage={validator.errorMessage}
          placeholder={rest.placeholder}
          isNonClearable={isNonClearable}
          {...validateEvent('onCreate')}
          {...validateEvent('onCancel')}
          {...validateEvent('onChange')}
          {...validateEvent('onFilter')}
          {...onMenuChange}
          {...isMenuOpen}
        />
      </DSFormLayoutBlockItem>
    </div>
  );
};
