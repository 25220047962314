/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/default-param-last */
import Grid from '@elliemae/ds-grid';
import { LockLockedRateRequested as LockIcon } from '@elliemae/ds-icons';
import Rights from '../../../../common/services/Rights';
import { CustomHeader } from '../../../components/CustomColumnHeader/index';
import {
  checkLockRateStatus,
  dispApr,
  dispCurrencyMask,
  dispPrice,
} from '../../helpers';
import { StyledBestExProgramLinks, StyledBestExRowSelected } from './styled';

const rightAlign = { width: '100%', textAlign: 'right', paddingRight: '3px' };

const CellValue = ({ value, isBest, styles }) => {
  if (isBest) {
    return <span style={{ fontWeight: 'bold', ...styles }}>{value}</span>;
  }
  return <span style={{ ...styles }}>{value}</span>;
};

const getSelectedLock = (lockRequested, locksAvailable) => {
  const locksArr = locksAvailable.split(',').map(Number);
  const locks = locksArr.filter((lock) => lock >= lockRequested);
  return locks[0];
};

export const getBestExColumns = (
  relockRequest = {},
  selectedRowID = '',
  handleSelectProduct,
  handleRefreshButton,
  handleSelectRow,
  disableLockLinks,
  windowWidth,
  isBuyside,
  isBuySideHistorical,
  columnWidth = Rights.viewAPR
    ? `${(48 / 6).toFixed(2)}%`
    : `${(48 / 5).toFixed(2)}%`,
) => [
  {
    accessor: 'program',
    Header: 'Program Name',
    minWidth: '60px',
    width: '52%',
    Cell: ({ row }) => {
      const rowData = row.original;
      const isSelectedRow = rowData.id === selectedRowID;
      const { productTypeID } = rowData;
      const selectedLock = getSelectedLock(
        rowData.lockRequested,
        rowData.locksAvailable,
      );
      let productIcon;
      let altText;
      if (productTypeID === 2) {
        // TODO(ngen): moving all these files to just URL references from webroot
        productIcon = '/img/fhaGif';
        altText = 'FHA';
      } else if (productTypeID === 3) {
        productIcon = '/img/vaGif';
        altText = 'VA';
      } else if (productTypeID === 4) {
        productIcon = '/img/usdaGif';
        altText = 'USDA';
      }
      return (
        <StyledBestExRowSelected isSelected={isSelectedRow}>
          <CellValue
            value={
              <Grid cols={[1, 1, 1]}>
                <Grid style={{ width: '250px' }}>
                  <span
                    style={{
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {rowData.productGroup}
                  </span>
                </Grid>
                <Grid style={{ width: windowWidth / 4, paddingRight: '20px' }}>
                  <span>
                    ({' '}
                    {rowData.locksAvailable
                      .split(',')
                      .map((lock) =>
                        selectedLock !== Number(lock) ? (
                          <a
                            key={lock}
                            style={{
                              textDecoration: 'none',
                              pointerEvents: disableLockLinks
                                ? 'none'
                                : undefined,
                              color: disableLockLinks ? 'black' : undefined,
                            }}
                            onClick={() => handleRefreshButton(lock)}
                          >
                            {lock}
                          </a>
                        ) : (
                          <span style={{ fontWeight: 'bold' }} key={lock}>
                            {lock}
                          </span>
                        ),
                      )
                      .reduce((prev, curr) => [prev, ' ', curr])}{' '}
                    )
                  </span>
                </Grid>
                <Grid>
                  {productIcon && (
                    <img
                      src={productIcon}
                      alt={altText}
                      style={{ height: '10px' }}
                    />
                  )}
                </Grid>
              </Grid>
            }
          />
        </StyledBestExRowSelected>
      );
    },
  },
  {
    accessor: 'rate',
    Header: () => <CustomHeader label="Rate" />,
    width: columnWidth,
    sortable: true,
    resizable: true,
    Cell: ({ row }) => {
      const rowData = row.original.target;
      const { programID, rateDataID } = rowData;
      const lockRateStatus = checkLockRateStatus(
        relockRequest,
        programID,
        rateDataID,
      );
      const displayLockIcon = isBuyside
        ? isBuySideHistorical && lockRateStatus
        : lockRateStatus;
      const isSelectedRow = rowData.id === selectedRowID;
      return (
        <StyledBestExRowSelected isSelected={isSelectedRow}>
          <Grid width="100%" justifyContent="flex-end" alignItems="center">
            {displayLockIcon && (
              <LockIcon
                color={['warning', '600']}
                title="Rate lock has been requested for this item"
                style={{ marginRight: '8px' }}
              />
            )}
            <StyledBestExProgramLinks
              onClick={() => {
                handleSelectRow(rowData);
                handleSelectProduct(programID, rateDataID);
              }}
            >
              {rowData.rate ? `${rowData.rate.toFixed(3)}` : null}
            </StyledBestExProgramLinks>
          </Grid>
        </StyledBestExRowSelected>
      );
    },
  },
  {
    accessor: 'price',
    Header: () => <CustomHeader label="PRICE" />,
    width: columnWidth,
    searchable: true,
    resizable: true,
    Cell: ({ row }) => {
      const rowData = row.original.target;
      const isSelectedRow = rowData.id === selectedRowID;
      return (
        <StyledBestExRowSelected isSelected={isSelectedRow}>
          <CellValue
            styles={rightAlign}
            value={dispPrice(rowData.price, rowData.priceVal)}
            isBest={rowData.isBest}
          />
        </StyledBestExRowSelected>
      );
    },
  },
  {
    accessor: 'discount',
    Header: () => <CustomHeader label="Price ($)" />,
    width: columnWidth,
    Cell: ({ row }) => {
      const rowData = row.original.target;
      const isSelectedRow = rowData.id === selectedRowID;
      if (!rowData.parent) {
        return (
          <StyledBestExRowSelected isSelected={isSelectedRow}>
            <CellValue
              styles={rightAlign}
              value={dispCurrencyMask(rowData.priceVal)}
              isBest={rowData.isBest}
            />
          </StyledBestExRowSelected>
        );
      }
      return '';
    },
  },
  {
    accessor: 'pnI',
    Header: () => <CustomHeader label="PMT" />,
    width: columnWidth,
    resizable: true,
    Cell: ({ row }) => {
      const rowData = row.original.target;
      const isSelectedRow = rowData.id === selectedRowID;
      return (
        <StyledBestExRowSelected isSelected={isSelectedRow}>
          <CellValue
            styles={rightAlign}
            value={`$${parseInt(rowData.pnI, 10)}`}
            isBest={rowData.isBest}
          />
        </StyledBestExRowSelected>
      );
    },
  },
  ...(Rights.viewAPR
    ? [
        {
          accessor: 'apr',
          Header: () => <CustomHeader label="APR" />,
          width: columnWidth,
          Cell: ({ row }) => {
            const rowData = row.original.target;
            const isSelectedRow = rowData.id === selectedRowID;
            if (!rowData.parent) {
              return (
                <StyledBestExRowSelected isSelected={isSelectedRow}>
                  <CellValue
                    styles={rightAlign}
                    value={dispApr(rowData.apr)}
                    isBest={rowData.isBest}
                  />
                </StyledBestExRowSelected>
              );
            }
            return '';
          },
        },
      ]
    : []),
  {
    accessor: 'details',
    Header: () => <CustomHeader label="QM/DET" />,
    width: columnWidth,
    searchable: true,
    resizable: true,
    Cell: ({ row, onClickPrice }) => {
      const rowData = row.original.target;
      const isSelectedRow = rowData.id === selectedRowID;
      return (
        <StyledBestExRowSelected isSelected={isSelectedRow}>
          <Grid width="100%" justifyContent="flex-end" alignItems="center">
            <StyledBestExProgramLinks onClick={() => onClickPrice(rowData)}>
              details
            </StyledBestExProgramLinks>
          </Grid>
        </StyledBestExRowSelected>
      );
    },
  },
];
