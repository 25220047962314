import { DSLabelValue } from '@elliemae/ds-label-value';
import { uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pricingAnalyzerLaunched } from '../../../../common/utils/constants/ErrorCodes';
import { stopLog } from '../../../../common/utils/logger';
import { getRelockDataAction } from '../../../../data/lockConfirm/actionCreators';
import { LockUpdateBoxesWrapper } from './LockUpdateBoxContainer';
import { RatesHeaderToolbar } from './RatesHeaderToolbar';
import {
  checkDuplicateInObject,
  formatRate,
  generateLockUpdateEvent,
  generateRateFilters,
  getAvailableRates,
  getDefaultRate,
} from './helpers';

const loanEventDataSelector = ({ epps }) =>
  (epps && epps.lockConfirm.loanRelockData) || [];
const loanDataSelector = ({ EPPS }) => (EPPS && EPPS.loanInfo) || [];

export const PriceAnalyzerView = ({ onCompareRates, onApplyChange }) => {
  const relockSnapShotData = useSelector(loanEventDataSelector);
  const relockEventData = relockSnapShotData.relockData || [];
  const relockEventDates = relockSnapShotData.eventList || [];
  const originLoanData = useSelector(loanDataSelector);
  const uniqAvailableRates = uniqBy(getAvailableRates(relockEventData), 'rate');
  const availableRates = getAvailableRates(relockEventData);
  const [defaultRate, setDefaultRate] = useState(
    getDefaultRate(
      availableRates,
      originLoanData.relockRequest.specificRateDataID,
    ),
  );

  const dispatch = useDispatch();
  const [relockLoanData, setRelockLoanData] = useState(null);

  const [relockEventBoxData, setRelockEventBoxData] = useState(null);

  const populateLoanEventData = (data) => {
    setRelockLoanData(data);
  };

  const initPageData = async () => {
    // TODO(ngen): these methods were awaited, but they didn't return a promise (on the outside of dispatch)
    // This likely doesn't work as it was thought it did
    dispatch(getRelockDataAction() as any);
    populateLoanEventData(relockEventData);

    // TODO(ngen): not sure what is log is measuring, but I don't think it actually measures the time it takes to run initPageData
    stopLog(pricingAnalyzerLaunched);
  };

  const flushSavedData = async () => {
    // TODO(ngen): these methods were awaited, but they didn't return a promise (on the outside of dispatch)
    populateLoanEventData(null);
  };

  useEffect(() => {
    initPageData();
  }, []);

  const updateRelockEventBoxex = (selRate) => {
    setRelockEventBoxData(
      generateLockUpdateEvent(relockLoanData, selRate, relockEventDates) as any,
    );
  };

  const updatedefaultRate = () => {
    let startRate = 0;
    if (originLoanData) {
      startRate = getDefaultRate(
        availableRates,
        originLoanData.relockRequest.specificRateDataID,
      );
      setDefaultRate(startRate);
    }
  };

  useEffect(() => {
    if (relockLoanData) {
      setRelockLoanData(relockEventData);
      updateRelockEventBoxex(defaultRate);
      updatedefaultRate();
    }
    return () => {
      flushSavedData();
    };
  }, [relockLoanData]);

  const onChangeRate = (val) => {
    setDefaultRate(val);
  };

  useEffect(() => {
    updateRelockEventBoxex(defaultRate);
  }, [defaultRate]);

  return (
    <div className="grid-x" style={{ overflow: 'hidden' }}>
      <div className="cell large-12">
        <div className="cell large-12">
          <DSLabelValue>
            <DSLabelValue.Label>
              The prices below take your current pricing scenario and applies it
              to your historical rate sheets.
            </DSLabelValue.Label>
          </DSLabelValue>
        </div>
        <div className="cell large-12">
          <RatesHeaderToolbar
            onCompareRates={onCompareRates}
            availableRates={generateRateFilters(uniqAvailableRates)}
            onChangeRate={onChangeRate}
            defaultRate={
              defaultRate != null
                ? { id: defaultRate, label: formatRate(defaultRate) }
                : { id: 0, label: formatRate(0) }
            }
          />
        </div>
        <div className="cell large-12">
          <LockUpdateBoxesWrapper
            relockData={relockEventBoxData}
            relockEventDateList={checkDuplicateInObject(relockEventDates)}
            defaultRate={defaultRate}
            selectedLockBox={onApplyChange}
          />
        </div>
      </div>
    </div>
  );
};

PriceAnalyzerView.propTypes = {
  onCompareRates: PropTypes.func.isRequired,
  onApplyChange: PropTypes.func.isRequired,
};
