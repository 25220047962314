import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { focusField } from '../../../../common/utils/shared';
import { PATH_SEARCH_RESULTS } from '../../../../route/constants';
import { searchFormActions } from '../../../../store/searchForm';
import { FooterButtons } from '../../../components/FooterButtons';
import { useSearchFormSelector } from '../../../components/Form/utils/customHooks';
import { setLoanInfo } from '../../../components/Form/utils/common';

export const FooterForm = ({ schemaForm }: { schemaForm: any }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorForm = (useSearchFormSelector('errorForm') as any).value;

  const updateStore = useCallback(async () => {
    const response = schemaForm.handleSubmit((data) => {
      dispatch(setLoanInfo(data) as any);
    });
    await response();
  }, []);

  useEffect(() => {
    const { errorFields, bannerIsOpen } = errorForm;
    if (errorFields.length > 0 && bannerIsOpen) {
      dispatch(searchFormActions.setBannerIsOpen(true));
    }
  }, [errorForm.errorFields]);

  const handleSearchAction = () => {
    const { errorFields, bannerIsOpen } = errorForm;
    focusField();
    if (errorFields.length > 0 && !bannerIsOpen) {
      dispatch(searchFormActions.setBannerIsOpen(true));
    } else if (errorFields.length === 0) {
      dispatch(searchFormActions.setBannerIsOpen(false));
      updateStore().finally(() => {
        navigate(PATH_SEARCH_RESULTS);
      });
    }
  };

  return (
    <FooterButtons
      buttons={[
        {
          type: 'primary',
          text: 'Search',
          onClick: handleSearchAction,
          'data-testid': 'footer-button-search',
        },
      ]}
    />
  );
};
