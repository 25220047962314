import React from 'react';
import { DSToolbar } from '@elliemae/ds-toolbar';
import { DSButton } from '@elliemae/ds-button';
import { DSFilterBar } from '@elliemae/ds-filterbar';
import { get } from 'lodash';

export const RatesHeaderToolbar = (props) => {
  const onCompareRates = get(props, 'onCompareRates') || '';
  const availableRates = get(props, 'availableRates') || '';
  const onChangeRate = get(props, 'onChangeRate') || '';
  const defaultRate = get(props, 'defaultRate') || '';

  const filters = {
    label: 'Rate',
    value: defaultRate,
    fixed: true,
    options: defaultRate !== '' ? availableRates : [],
    onChange: (val) => {
      onChangeRate(val.id);
    },
  };

  return (
    <DSToolbar
      withDepth={false}
      style={{
        justifyContent: 'space-between',
        alignItems: 'baseline',
      }}
    >
      <DSFilterBar filters={[filters]} renderToolbar={() => null} />
      <DSButton
        style={{ textDecoration: 'underline' }}
        buttonType="link"
        labelText="Compare Rates"
        onClick={() => onCompareRates()}
      />
    </DSToolbar>
  );
};
