import Session, { ALL_SUMMARY_PARAMS } from '../../common/services/Session';

export const priceUpdate = (relockData, loanInfo) => {
  Session.set(ALL_SUMMARY_PARAMS, {
    loanId: loanInfo?.loanInformation?.loanId,
    programId: relockData.specificProgramID,
    rateDataId: relockData.specificRateDataID,
    effectiveDate: relockData.effectiveDate,
  });
};
