import { Grid } from '@elliemae/ds-grid';
import { DSSeparatorV2 } from '@elliemae/ds-separator';
import { parseNumber } from '@frontend/utils';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { AdjustmentTable } from './adjustmentTable';

export const AdjustmentTableSectionWrapper = ({ lockDetails }) => {
  const rateTableData = useMemo(() => {
    const [rate, price] = [
      {
        adjustments: lockDetails?.rateAdjustments || [],
        typeLabel: 'Rate',
        base: lockDetails?.baseRate || 0,
      },
      {
        adjustments: lockDetails?.priceAdjustments || [],
        typeLabel: 'Price',
        base: lockDetails?.basePrice || 0,
      },
    ].map((_data) => {
      const data = _data as {
        adjustments: { description: string; amount: number | undefined }[];
        base: number;
        typeLabel: string;
        totalAdjustments: number;
        total: number;
      };
      data.totalAdjustments =
        data.adjustments?.reduce(
          (sum, curr) => sum + parseNumber(curr?.amount, 0) || 0,
          0,
        ) || 0;
      data.total = Number(data.base + data.totalAdjustments) || 0;
      return data;
    });

    const maxAdjustments = Math.max(
      rate.adjustments.length,
      price.adjustments.length,
    );

    // eslint-disable-next-line no-restricted-syntax
    for (const { adjustments } of [rate, price]) {
      for (let i = adjustments.length; i < maxAdjustments; i += 1) {
        adjustments.push({ description: ' ', amount: undefined });
      }
    }

    return { price, rate };
  }, [lockDetails]);

  return (
    rateTableData && (
      <Grid
        cols={{
          small: [1 / 1, 0, 1 / 1],
          medium: ['1fr', 'auto', '1fr'],
        }}
      >
        <AdjustmentTable {...rateTableData.rate} />
        <DSSeparatorV2 m="0 s" isVertical isDotted />
        <AdjustmentTable {...rateTableData.price} />
      </Grid>
    )
  );
};

AdjustmentTableSectionWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lockDetails: PropTypes.object.isRequired,
};
