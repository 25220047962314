import React, { useState } from 'react';
import { DSButton } from '@elliemae/ds-button';
import { useDispatch } from 'react-redux';
import {
  DSTextBox,
  DSFormItemLayout,
  DSInputProtected,
} from '@elliemae/ds-form';
import { DSBanner, BANNER_TYPES } from '@elliemae/ds-banner';
import { getLogin } from './LoginActions';
import * as Styled from './styled';

interface LoginProps {
  onLoginSuccess: () => void; // Accepts a callback for successful login
}

export const Login: React.FC<LoginProps> = ({ onLoginSuccess }) => {
  const dispatch = useDispatch();
  const [userName, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showBanner, setShowBanner] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userNameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleClick = async () => {
    if (!userName) setUsernameError(true);
    if (!password) setPasswordError(true);

    if (userName && password) {
      try {
        const result = await dispatch(getLogin({ userName, password }) as any);
        if (result.token) {
          onLoginSuccess(); // Trigger the success callback
        } else {
          setShowBanner(true);
          setErrorMessage(result.message || 'Login failed. Please try again.');
        }
      } catch (error) {
        setShowBanner(true);
        setErrorMessage('An unexpected error occurred. Please try again.');
      }
    }
  };

  return (
    <Styled.StyledLoginBody data-testid="Login-all-components">
      <Styled.StyledLoginBannerContainer>
        <DSBanner
          body={errorMessage}
          isOpen={showBanner}
          label="Error"
          onClose={() => setShowBanner(false)}
          type={BANNER_TYPES.DANGER}
        />
      </Styled.StyledLoginBannerContainer>
      <Styled.StyledLoginContainer>
        <Styled.StyledLoginLogo>
          <img
            title="EPPS"
            alt="EPPS"
            src="/components/themes/vanilla/images/login-title.jpg"
          />
        </Styled.StyledLoginLogo>
        <Styled.StyledLoginForm>
          <Styled.StyledLoginFormInput>
            <DSFormItemLayout
              ariaLabel="userName"
              required
              inputComponent={DSTextBox}
              labelText="User Name"
              onChange={(e) => {
                const { value } = e.target;
                setUsername(value);
                if (userNameError) setUsernameError(false);
              }}
              name="userName"
              placeholder="Enter userName"
              value={userName}
              hasError={userNameError}
            />
          </Styled.StyledLoginFormInput>
          <Styled.StyledLoginFormInput>
            <DSFormItemLayout
              required
              inputComponent={DSInputProtected}
              labelText="Password"
              onChange={(e) => {
                const { value } = e.target;
                setPassword(value);
                if (passwordError) setPasswordError(false);
              }}
              placeholder="Enter password"
              value={password}
              hasError={passwordError}
            />
          </Styled.StyledLoginFormInput>
          <Styled.StyledLoginFormBotton>
            <DSButton
              id="login"
              buttonType="primary"
              labelText="Log In"
              size="m"
              onClick={handleClick}
            />
          </Styled.StyledLoginFormBotton>
        </Styled.StyledLoginForm>
      </Styled.StyledLoginContainer>
    </Styled.StyledLoginBody>
  );
};
