import { BUTTON_TYPES, DSButtonV2 } from '@elliemae/ds-button';
import { useLoanDataFieldSelector } from '@frontend/pricing-search';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  useFilters,
  useLoanInformationField,
  useLoanProgramsSelector,
} from '../../../../../data/useSelectors';
import { arrayHasHELOC } from '../../../helpers';
import { MortgageInsurancePrograms } from '../../MortgageInsurancePrograms';
import { QuoteInsuranceButtonDisabled } from './QuoteInsuranceButtonDisabled';
import { QuoteInsuranceDropdown } from './QuoteInsuranceDropdown';

const StyledDSButtonV2 = styled(DSButtonV2)`
  margin-right: 8px;
`;

const LABEL = 'Quote Mortgage Insurance';

export const QuoteInsuranceButton = () => {
  const [tooltip, setTooltip] = useState<any | any[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);
  const [isMIModalOpen, setIsMIModalOpen] = useState(false);
  const [dropdownMenuItems, setDropdownMenuItems] = useState([]);

  const allLoanTerms = useFilters(0);
  const allStandardProducts = useFilters(2);
  // Standard Product type in loanPrograms --> `productTypeID`
  // Loan term type in loanPrograms --> `programTypeID`
  const loanPrograms = useLoanProgramsSelector() as any;
  // Loan terms type list in loanData --> `productType`
  const loanTerms = useLoanDataFieldSelector('productType');
  const standardProducts = useLoanDataFieldSelector(
    'standardProducts',
  ) as unknown as any[];
  const lienPosition = useLoanInformationField('lienPosition');
  const borrowerFinancialHistory = useLoanDataFieldSelector(
    'borrowerFinancialHistory',
    {},
  ) as any;
  const invalidateLtv = (program) => {
    const { ltv } = program;
    return ltv * 100 < 80;
  };

  const getStandardProductNames = () =>
    allStandardProducts
      .filter((elem) => standardProducts.includes(elem.value))
      .map((elem) => elem.key)
      .join(', ');

  const validateButton = () => {
    const { loanPrograms: programs } = loanPrograms;
    if (!programs || !programs.length) {
      setTooltip(['No programs available!']);
      setIsDisabled(true);
      return;
    }
    const isHeloc = arrayHasHELOC(allLoanTerms, loanTerms);
    const isSecondMortgage = lienPosition !== 1;
    const isLowLtv = invalidateLtv(programs[0]); // returns true if LTV less than 80
    const isProductTypeInvalid = !(
      // Invalid if at least 1 agency type is not selected
      (standardProducts.includes(1) || standardProducts.includes(5))
    );
    const isNoCreditScores = borrowerFinancialHistory.noCreditScores;

    const toolTipList = [
      isHeloc && 'Loan term is HELOC',
      isSecondMortgage && 'Lien type is 2nd mortgage',
      isLowLtv && 'LTV < 80%',
      isProductTypeInvalid && `Product Type: ${getStandardProductNames()}`,
      isNoCreditScores && 'No credit score available',
    ].filter((item) => !!item);

    setTooltip([...toolTipList]);

    setIsDisabled(
      !!isHeloc ||
        !!isSecondMortgage ||
        !!isLowLtv ||
        !!isProductTypeInvalid ||
        !!isNoCreditScores,
    );
  };

  const buildDropdownOptions = (terms) => {
    const options = terms
      .map((id) => allLoanTerms.find((elem) => elem.value === id) || {})
      .map(({ value, key }) => ({
        dsId: value,
        type: 'single',
        label: key,
      }));
    setDropdownMenuItems([...options] as any);
  };

  const checkComponentType = () => {
    const { loanPrograms: programs } = loanPrograms;
    if (programs && programs.length) {
      const terms = [
        ...new Set(programs.map(({ programTypeID }) => programTypeID)),
      ];
      if (terms.length > 1) {
        buildDropdownOptions(terms);
      }
      setIsDropdown(terms.length > 1);
    }
  };

  useEffect(() => {
    if (loanPrograms) {
      validateButton();
      checkComponentType();
    }
  }, [
    loanPrograms,
    allLoanTerms,
    allStandardProducts,
    loanTerms,
    standardProducts,
    lienPosition,
    borrowerFinancialHistory,
  ]);

  return isDisabled ? (
    <QuoteInsuranceButtonDisabled label={LABEL} tooltip={tooltip} />
  ) : (
    <>
      {isDropdown ? (
        <QuoteInsuranceDropdown
          label={LABEL}
          dropdownMenuItems={dropdownMenuItems}
          onSelect={() => setIsMIModalOpen(true)}
        />
      ) : (
        <StyledDSButtonV2
          buttonType={BUTTON_TYPES.OUTLINE}
          onClick={() => setIsMIModalOpen(true)}
        >
          {LABEL}
        </StyledDSButtonV2>
      )}
      {isMIModalOpen && (
        <MortgageInsurancePrograms
          handleClose={() => setIsMIModalOpen(false)}
        />
      )}
    </>
  );
};
