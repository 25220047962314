export const INITIAL_STATE = {
  rateSelector: {},
  diffElements: [],
  encompassElements: [],
  loanId: null,
  geoCodes: {},
  getStatus: {
    code: '',
    message: '',
    error: false,
  },
  pricingChanges: {
    comparissonArray: [],
    changeCounter: 0,
  },
  selectedProduct: {},
  selectedPrice: 'price',
  adjustmentsSettings: {
    customPriceAdjustment: false,
    lockExtensionAdjustment: false,
    priceConcessions: false,
    reLockFeeAdjustment: false,
  },
  isLoanHedged: undefined,
};
