/* eslint-disable react-hooks/rules-of-hooks */
import { DSRadio } from '@elliemae/ds-form';
import { LockLockedNewLockRequested } from '@elliemae/ds-icons';
import { DSTooltipV2 } from '@elliemae/ds-tooltip';
import styled from 'styled-components';
import { useAppData } from '../../../../../common/utils/customHooks/useAppData';
import { currencyWithDecimals } from '../../../../../common/utils/shared';
import { AlignRight, NegativeDiscount } from '../styled';

export const StyledPriceCellRenderer = styled.div`
  display: flex;
  width: 100%;
`;

const alignRight = (data) => <AlignRight>{data}</AlignRight>;

const formatNumber = (num) =>
  alignRight(!num || Number.isNaN(num) ? '--' : `${(100 - num).toFixed(3)}`);

const RenderAlignRight = (data) => alignRight(data);

const RenderDiscountHeader = (label) => (
  <AlignRight>
    <DSTooltipV2
      title={label}
      placement="top"
      triggerComponent={<span>Disc...</span>}
    />
  </AlignRight>
);

const RenderSelectionCell = ({ row }) => {
  const { id, price, rateDataID, programID, onSelectCallback } =
    row?.original || {};
  return price ? (
    <DSRadio
      value={id}
      id={id}
      checked={useAppData() === id}
      onChange={(e) => {
        const { value } = e.target;
        onSelectCallback({
          id: value,
          rateDataID,
          programID,
          flag: false,
        });
      }}
    />
  ) : null;
};

const RenderPrice = ({ row }) => {
  const { price, isLock } = row?.original || {};
  return (
    <StyledPriceCellRenderer>
      {isLock && (
        <DSTooltipV2
          title="Rate Lock has been requested for this item."
          placement="top"
          triggerComponent={
            <LockLockedNewLockRequested
              size="s"
              color={['brand-primary', '600']}
              style={{ margin: '0 auto' }}
            />
          }
        />
      )}
      {formatNumber(price)}
    </StyledPriceCellRenderer>
  );
};

const RenderBasePrice = ({ row }) => {
  const { base } = row?.original || {};
  return formatNumber(base);
};

const RenderDiscount = ({ row }) => {
  const { discount } = row?.original || {};
  if (!discount || Number.isNaN(discount)) {
    return alignRight('--');
  }
  const result = Math.round(discount);
  if (result < 0) {
    return alignRight(
      <NegativeDiscount>{currencyWithDecimals(result)}</NegativeDiscount>,
    );
  }
  return alignRight(currencyWithDecimals(result));
};

const RenderDiscountPoints = ({ row }) => {
  const { price } = row?.original || {};
  const discountPoints = price && price * -1;
  if (!discountPoints || Number.isNaN(discountPoints)) {
    return alignRight('--');
  }
  const result = discountPoints ? discountPoints.toFixed(3) : '';
  if (result < 0) {
    return alignRight(<NegativeDiscount>{result}</NegativeDiscount>);
  }
  return alignRight(result);
};

export const columns = [
  {
    Header: '',
    accessor: 'id',
    width: 40,
    Cell: RenderSelectionCell,
  },
  {
    Header: () => RenderAlignRight('Price'),
    accessor: 'price',
    width: 80,
    Cell: RenderPrice,
  },
  {
    Header: () => RenderAlignRight('Base'),
    accessor: 'base',
    width: 80,
    Cell: RenderBasePrice,
  },
  {
    Header: () => RenderDiscountHeader('Discount/Rebate Points'),
    accessor: 'discountPoints',
    width: 80,
    Cell: RenderDiscountPoints,
  },
  {
    Header: () => RenderDiscountHeader('Discount/Rebate'),
    accessor: 'discount',
    width: 80,
    Cell: RenderDiscount,
  },
];

// 'placeholderData' is required as datagrids cannot be empty due to
// alignment issues on smart client
export const getPlaceholderData = (len?: number) => {
  const resultSize = len && len > 0 ? len : 3;
  const result: {
    id: number;
    price: number | null;
    base: number | null;
    discountPoints: number | null;
    discount: number | null;
  }[] = [];
  for (let i = 0; i < resultSize; i += 1) {
    result.push({
      id: i + 1,
      price: null,
      base: null,
      discountPoints: null,
      discount: null,
    });
  }
  return result;
};

export const formatter = 'MM/DD/YYYY [at] hh:mm A [ET]';
export const utcFormatter = 'YYYY-MM-DDTHH:mm:ss[Z]';
export const EST = 'America/New_York';
export const SIZE_SMALL = '80px';
export const SIZE_LARGE = '140px';
export const GRID_HEIGHT = '54px';

export const customOptionsInitialState = [
  { name: 'compareRatesGrid2', isCustom: false },
  { name: 'compareRatesGrid3', isCustom: false },
];
