import styled from 'styled-components';

export const StyledDataTableWrapper = styled.div`
  padding-top: 8px;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  justify-items: flex-start;
  [data-testid='data-table-scrollable-container'] {
    height: 100%;
  }
  [data-testid='data-table-wrapper'] {
    height: 100%;
  }
`;

export const StyledIneligibleRow = styled.div`
  height: 36px;
  max-height: 36px;
  width: calc(100% + 1.23076rem);
  margin-left: -0.61538rem;
  margin-right: -0.61538rem;
  padding-left: 0.61538rem;
  padding-right: 0.61538rem;
  display: flex;
  align-items: center;
`;

export const StyledIneligibleProgramLinks = styled.p`
  color: #3989ca;
  justify-content: flex-start;
  cursor: pointer;
`;

export const StyledIneligibleReason = styled.div`
  padding-top: 0.61538rem;
  padding-bottom: 0.61538rem;
`;
