/* eslint-disable @typescript-eslint/default-param-last */
export const createOptionObject = (id) => ({ id, label: `${id} Days` });

export const createLockPeriodOptions = (
  filters: any[] = [],
  lockPeriodValue,
  locksAvailable,
) =>
  filters
    .filter(
      (lockDays) =>
        lockDays !== lockPeriodValue &&
        locksAvailable.includes(lockDays.toString()),
    )
    .map(({ lockDays }) => createOptionObject(lockDays));

export const getLockPeriod = (
  lockPeriodValue,
  filters = [],
  onLockPeriodChange,
  locksAvailable,
) => {
  const currentLockPeriod = createOptionObject(lockPeriodValue);
  const locksAvailableArr = locksAvailable.split(',');
  const lockPeriodOptions = createLockPeriodOptions(
    filters,
    lockPeriodValue,
    locksAvailableArr,
  );
  return {
    group: 'lockPeriod',
    label: 'Lock Period',
    options: [currentLockPeriod, ...lockPeriodOptions],
    onChange: onLockPeriodChange,
  };
};
