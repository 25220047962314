import { useState, useEffect } from "react";
import { evaluateRuleById } from "./utils";
const useFieldVisibilityAndDisability = ({
  schemaForm,
  rules,
  visible,
  disabled,
  visibleCheck,
  disableCheck,
  preview
}) => {
  const [isVisible, setIsVisible] = useState(visible);
  const [isDisabled, setIsDisabled] = useState(disabled);
  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);
  useEffect(() => {
    const updateVisibility = () => {
      if (visibleCheck?.handler) {
        setIsVisible(
          evaluateRuleById(
            visibleCheck.handler,
            schemaForm.getValues(),
            rules,
            preview
          ) ? !visible : visible
        );
      }
    };
    const updateDisabledState = () => {
      if (disableCheck?.handler) {
        setIsDisabled(
          evaluateRuleById(
            disableCheck.handler,
            schemaForm.getValues(),
            rules,
            preview
          ) ? !disabled : disabled
        );
      }
    };
    const subscription = schemaForm.watch(() => {
      updateVisibility();
      updateDisabledState();
    });
    updateVisibility();
    updateDisabledState();
    return () => subscription.unsubscribe();
  }, [
    schemaForm,
    visibleCheck,
    disableCheck,
    rules,
    preview,
    visible,
    disabled
  ]);
  return { isVisible, isDisabled };
};
export {
  useFieldVisibilityAndDisability
};
