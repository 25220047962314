import { DataTable } from '@elliemae/ds-data-table';
import { useLoanDataFieldSelector } from '@frontend/pricing-search';
import { useMemo } from 'react';
import { columns } from './columns';
import { generateRows } from './helpers';
import { StyledGridWrapper } from './styled';

export const LoanFieldChangesView = () => {
  const loanFieldChangesRaw = useLoanDataFieldSelector(
    'additionalLockInfo.lockCompareResults',
    {},
  ) as any;
  const loanFieldChangesArray = useMemo(
    () => Object.values(loanFieldChangesRaw).flat(),
    [loanFieldChangesRaw],
  );

  const parsedData = useMemo(
    () => generateRows(loanFieldChangesArray),
    [loanFieldChangesRaw],
  );

  return (
    <StyledGridWrapper>
      <DataTable
        colsLayoutStyle="auto"
        columns={columns}
        data={parsedData}
        noResultsMessage="No Field Changes"
        height={loanFieldChangesArray.length === 0 && 200}
      />
    </StyledGridWrapper>
  );
};
