import { bool, func } from 'prop-types';
import { useDispatch } from 'react-redux';
import { formatDate } from '../../../../common/services/helpers';
import api from '../../../../common/utils/api';
import { formatNumberWithCommas } from '../../../../common/utils/shared';
import { openGlobalErrorModalAction } from '../../../../data/globalErrorModal/actionCreators';
import {
  useRateSelectorLien,
  useRateSelectorLoan,
} from '../../../../data/useSelectors';
import { RateAlertModal } from './RateAlertModal';

export const RateAlert = ({ isOpen, handleCancel }) => {
  const dispatch = useDispatch();
  const borrowers = useRateSelectorLoan('borrowers', []) as any[];
  const property = useRateSelectorLoan('property', {});
  const { firstName, lastName } = borrowers[0] || {};
  const email = useRateSelectorLoan('originatorEmail', '') as string;
  const rate = useRateSelectorLien('rate', 0) as number;
  const price = (useRateSelectorLien('price', 0) as number)?.toFixed(3);
  const loanId = useRateSelectorLoan('id', '') as string;
  const amount = `$${formatNumberWithCommas(useRateSelectorLien('amount', 0))}`;
  const qualType = `${
    useRateSelectorLoan('qualificationTypeId', 1) === 1 ? '1st' : '2nd'
  }`;
  const { programName, userLender } = useRateSelectorLien(
    ['programName', 'userLender'],
    '',
  ) as any;
  const purpose = useRateSelectorLoan('purpose', '');
  const lockDays = useRateSelectorLien('lockDays', 0);
  const rateDate =
    formatDate(useRateSelectorLien('rateDate', 0), 'Calendar') || '';
  const programNameValue = programName ?? '';
  const userLenderValue = userLender ?? '';
  const line1Col1 = `${amount} ${qualType} ${purpose}`;
  const { type, usage } = (property || '') as any;
  const line2Col1 = `${type} ${' '} ${usage}`;
  const line2Col2 = `${lockDays} ${'-day Pricing a/o '} ${rateDate}`;
  const options = [
    {
      dsId: '1',
      type: 'option',
      value: 'id_1',
      label: `${userLenderValue} ${programNameValue}`,
    },
    {
      dsId: '2',
      type: 'option',
      value: 'id_2',
      label: 'any similar program',
    },
  ];

  const handleRateAlertCreate = async (payload) => {
    const response = await api.createRateAlert({ payload, loanId });
    if (response && response.summary) {
      let errorDetail = response.code;

      if (response.code && response.code === 'INVALID_ACCESS_TOKEN')
        errorDetail =
          'Your EPPS session has expired. Please close EPPS and log back in.';
      else {
        errorDetail = JSON.parse(response.summary);
        if (errorDetail.errors) errorDetail = errorDetail.errors[0]?.details;
        else errorDetail = errorDetail.details;
      }

      dispatch(
        openGlobalErrorModalAction({
          errorMessage: errorDetail,
        }) as any,
      );
    } else handleCancel(false);
  };

  return (
    <RateAlertModal
      isModalOpen={isOpen}
      borrower={`${firstName} ${lastName}`}
      rate={rate}
      price={price}
      loanInfoLine1Col1={line1Col1}
      loanInfoLine2Col1={line2Col1}
      loanInfoLine2Col2={line2Col2}
      options={options}
      email={email}
      handleCancel={handleCancel}
      handleCreate={handleRateAlertCreate}
    />
  );
};

RateAlert.defaultProps = {
  isOpen: false,
};

RateAlert.propTypes = {
  isOpen: bool,
  handleCancel: func.isRequired,
};
