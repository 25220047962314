import styled from 'styled-components';

export const StyledAllRatesToolbar = styled.div`
  #toolbar-all-rates-view > div:first-child {
    margin-bottom: 0px;
  }
  #toolbar-all-rates-view
    > div:first-child
    > div:first-child
    > div:first-child
    > div {
    margin-bottom: 0px;
  }
  #toolbar-all-rates-view {
    margin-bottom: 16px;
  }
`;
