import { get } from 'lodash';
import moment from 'moment';
import Common from '../../common/services/Common';
import { epcService } from '../../common/services/epcService';
import getRelockData, {
  convertEPPSAdjustmentsToLNCT,
  createAdjustments,
  getRatesData,
} from '../../common/services/lockConfirmServices';
import {
  deleteAdjustments,
  getLoanCatcherAdjustments,
} from '../../common/services/lockSummaryServices';
import Rights from '../../common/services/Rights';
import Session, {
  IS_BUYSIDE,
  IS_SELLSIDE,
  ORIGIN_LOCKID,
  ORIGIN_SOURCE,
  ORIGIN_TRANSACTION_TYPE,
  USER_TYPE,
} from '../../common/services/Session';
import browserHistory from '../../common/utils/browserHistory';
import { truncateText } from '../../common/utils/helpers';
import { PATH_LOGIN } from '../../route/constants';
import { openGlobalErrorModalAction } from '../globalErrorModal/actionCreators';
import { setStatusFail } from '../lockSummary/actions';
import { closeLoader, openLoader } from '../screenLoader/actions';
import {
  getRatesDataAction,
  getRatesDataSuccessAction,
  resetGridDataAction,
  resetRatesDataAction,
  setAllRelockDataSuccessAction,
  setRelockDataSuccessAction,
} from './actions';
import { getUpdatedSummary } from './helpers';
import { getAppConfigValue } from '@elliemae/pui-app-sdk';

export const resetGridData = (gridName) => (dispatch) =>
  dispatch(resetGridDataAction(gridName));

export const resetRatesData = () => (dispatch?: any) =>
  dispatch?.(resetRatesDataAction);

export const getRatesAction =
  (date, gridName, isCustom = false) =>
  async (dispatch, getState) => {
    dispatch(openLoader('Fetching Data ..'));
    dispatch(resetGridData(gridName));
    try {
      const loanData = get(getState(), 'EPPS.loanInfo') || {};
      const ratesData = await getRatesData(
        loanData,
        date,
        isCustom,
        dispatch,
        getRatesDataAction,
      );
      if (ratesData) {
        const payload = {
          gridName,
          ratesData,
        };
        dispatch(getRatesDataSuccessAction(payload));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      openGlobalErrorModalAction(dispatch);
    } finally {
      dispatch(closeLoader());
    }
  };

export const getRelockDataAction = () => async (dispatch, getState) => {
  dispatch(openLoader('Fetching Data ..'));
  try {
    const loanData = get(getState(), 'EPPS.loanInfo') || {};
    const lockEventsList =
      get(getState(), 'EPPS.originalLoanLockRequest') || {};

    const currentDate = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    const currentDateObj = {
      requestedStatus: '',
      requestedDate: currentDate,
      timeRequested: '',
    };
    const eventList = lockEventsList.lockRequests;
    const cloneEventList = [...eventList];
    if (cloneEventList) {
      cloneEventList.push(currentDateObj);
    }
    const user = Session.get(USER_TYPE);
    const relockData = await getRelockData(
      loanData,
      dispatch,
      setRelockDataSuccessAction,
      cloneEventList,
    );
    if (relockData && relockData.code !== undefined) {
      if (user && user.userType === 'StandAlone') {
        browserHistory.push(PATH_LOGIN);
      } else {
        openGlobalErrorModalAction(dispatch);
      }
    } else {
      const relockDataWithEvent = {
        eventList: cloneEventList,
        relockData,
      };
      dispatch(setAllRelockDataSuccessAction(relockDataWithEvent));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    openGlobalErrorModalAction(dispatch);
  } finally {
    dispatch(closeLoader());
  }
};

export const cancelLockAction = (comments) => async (dispatch) => {
  dispatch(openLoader('Cancelling Lock'));
  try {
    const transactionId = await epcService.createTransaction({
      request: {
        type: 'CANCELLOCK',
        options: {
          comments,
        },
      },
    });
    if (transactionId) {
      const getEpcTransaction = await Common.getEpcTransaction(transactionId);
      if (getEpcTransaction.status === 'completed') {
        Common.closeApplication();
      } else if (
        getEpcTransaction.summary === 'Conflict' ||
        getEpcTransaction.summary === 'Bad Request'
      ) {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: truncateText(getEpcTransaction.details, 300),
          }),
        );
      } else if (
        getEpcTransaction.status === 'failed' &&
        getEpcTransaction.code === 'EBS-912'
      ) {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: getEpcTransaction.details,
          }),
        );
      } else if (
        ['dev', 'qa', 'int'].some((envPartial) =>
          getAppConfigValue<string>('activeEnv').includes(envPartial),
        )
      ) {
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: getEpcTransaction.summary,
            title: 'Transaction Failed',
          }),
        );
      }
    } else throw new Error();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: () => Common.closeApplication(),
      }),
    );
    dispatch(closeLoader());
  }
};

export const denyLockRequestAction = (comments) => async (dispatch) => {
  dispatch(openLoader('Denying Lock Request'));
  try {
    const lockId = Session.get(ORIGIN_LOCKID);
    const transactionId = await epcService.createTransaction({
      request: {
        type: 'DENYLOCK',
        options: {
          comments,
          ...(lockId ? { lockId } : {}),
        },
      },
    });
    if (transactionId) {
      const getEpcTransaction = await Common.getEpcTransaction(transactionId);
      if (getEpcTransaction.status === 'completed') {
        Common.closeApplication();
      } else if (
        getEpcTransaction.summary === 'Conflict' ||
        getEpcTransaction.summary === 'Bad Request'
      ) {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: truncateText(getEpcTransaction.details, 300),
          }),
        );
      } else if (
        getEpcTransaction.status === 'failed' &&
        getEpcTransaction.code === 'EBS-912'
      ) {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: getEpcTransaction.details,
          }),
        );
      } else if (['dev', 'qa', 'int'].includes(ENV_NAME)) {
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: getEpcTransaction.summary,
            title: 'Transaction Failed',
          }),
        );
      }
    } else throw new Error();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: () => Common.closeApplication(),
      }),
    );
  } finally {
    dispatch(closeLoader());
  }
};

export const denyActiveLockAction =
  (comments) => async (dispatch, getState) => {
    dispatch(openLoader('Denying Lock'));
    try {
      const state = getState();
      const lockRequests = get(
        state,
        'EPPS.originalLoanInformation.lockRequests',
        [],
      );
      const { id: activeLockId } = lockRequests.find(
        ({ requestedStatus }) => requestedStatus === 'Locked',
      );
      const transactionId = activeLockId
        ? await epcService.createTransaction({
            request: {
              type: 'DENYLOCK',
              options: {
                comments,
                lockId: activeLockId,
              },
            },
          })
        : null;
      if (transactionId) {
        const getEpcTransaction = await Common.getEpcTransaction(transactionId);
        if (getEpcTransaction.status === 'completed') {
          Common.closeApplication();
        } else if (
          getEpcTransaction.summary === 'Conflict' ||
          getEpcTransaction.summary === 'Bad Request'
        ) {
          dispatch(
            setStatusFail({
              error: true,
              code: getEpcTransaction.code,
              message: truncateText(getEpcTransaction.details, 300),
            }),
          );
        } else if (
          getEpcTransaction.status === 'failed' &&
          getEpcTransaction.code === 'EBS-912'
        ) {
          dispatch(
            setStatusFail({
              error: true,
              code: getEpcTransaction.code,
              message: getEpcTransaction.details,
            }),
          );
        } else if (['dev', 'qa', 'int'].includes(ENV_NAME)) {
          dispatch(
            openGlobalErrorModalAction({
              errorMessage: getEpcTransaction.summary,
              title: 'Transaction Failed',
            }),
          );
        }
      } else throw new Error();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      dispatch(
        openGlobalErrorModalAction({
          onCloseCallback: () => Common.closeApplication(),
        }),
      );
    } finally {
      dispatch(closeLoader());
    }
  };

export const updateAndConfirmAction =
  (setOpenModal) => async (dispatch, getState) => {
    dispatch(openLoader('Lock and confirm...'));
    try {
      const state = getState();
      const loanData = get(state, 'EPPS.loanInfo') || {};

      const adjustmentsForLoanCatcherSystem = convertEPPSAdjustmentsToLNCT(
        Object.values(state?.epps?.origin?.buySideAdjustments ?? {}).flat(1),
      );
      const existingAdjustmentsForLoanCatcherSystem =
        await getLoanCatcherAdjustments(loanData?.loanInformation);
      const adjustmentUuidsToCreate = Object.values(
        adjustmentsForLoanCatcherSystem,
      )
        .flat(1)
        .map((i: any) => i.uuid);
      const adjustmentsToDelete =
        existingAdjustmentsForLoanCatcherSystem.filter(
          (adjustment) => !adjustmentUuidsToCreate.includes(adjustment.uuid),
        );
      await deleteAdjustments(loanData.loanInformation, adjustmentsToDelete);

      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(adjustmentsForLoanCatcherSystem)) {
        adjustmentsForLoanCatcherSystem[key] = adjustmentsForLoanCatcherSystem[
          key
        ].filter((i) => !i.status);
      }
      await createAdjustments(loanData, adjustmentsForLoanCatcherSystem);
      const selectedPrice = get(state, 'epps.lockSummary.selectedPrice');
      const hedgeCheckbox = get(state, 'epps.lockSummary.isLoanHedged');
      let summarydetails;
      if (selectedPrice === 'currentPrice') {
        summarydetails = get(state, 'epps.lockSummary.currentRateSelector');
      } else {
        summarydetails = get(state, 'epps.lockSummary.rateSelector');
      }
      if (summarydetails?.loan.hedged !== hedgeCheckbox) {
        summarydetails = await getUpdatedSummary(
          selectedPrice,
          loanData,
          dispatch,
        );
      }
      const buysideAdjustments = Object.values(
        state.epps.origin.buySideAdjustments,
      ).flat();

      const originSource = Session.get(ORIGIN_SOURCE) || '';
      let lockId = Session.get(ORIGIN_LOCKID);
      const originTransactionType = Session.get(ORIGIN_TRANSACTION_TYPE);
      if (
        (originTransactionType?.toLowerCase() === 'validatelock' ||
          originTransactionType?.toLowerCase() === 'getsellsidepricing') &&
        !lockId
      ) {
        lockId = state?.EPPS?.loanInfo?.lockId;
      }
      const isBuyside = Session.get(IS_BUYSIDE);
      const isSellside = Session.get(IS_SELLSIDE);
      let action = '';
      if (isBuyside) {
        action = 'BuySide';
      } else if (isSellside) {
        action = 'SellSide';
      }
      const transactionId = await epcService.createTransaction({
        request: {
          type:
            originTransactionType === 'validatelock'
              ? 'RELOCK'
              : 'UPDATE_CONFIRM',
          options: {
            actionType: action,
            summarydetails,
            buysideAdjustments,
            ...(lockId ? { lockId } : {}),
            loanElements: [],
            customFields: [],
            sourceApplicationForm: originSource,
          },
        },
      });

      if (transactionId) {
        const transaction = await Common.getEpcTransaction(transactionId);
        if (transaction.status === 'completed') {
          if (Rights.accessSellside) setOpenModal(true);
          else Common.closeApplication();
        } else if (transaction.summary === 'Conflict') {
          dispatch(
            setStatusFail({
              error: true,
              code: transaction.code,
              message: truncateText(transaction.details, 300),
            }),
          );
        } else if (
          transaction.status === 'failed' &&
          transaction.code === 'EBS-912'
        ) {
          dispatch(
            setStatusFail({
              error: true,
              code: transaction.code,
              message: transaction.details,
            }),
          );
        } else if (['dev', 'qa', 'int'].includes(ENV_NAME)) {
          dispatch(
            openGlobalErrorModalAction({
              errorMessage: transaction.summary,
              title: 'Transaction Failed',
            }),
          );
        }
      } else {
        throw new Error('EPC Transaction ID not found');
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      dispatch(
        openGlobalErrorModalAction({
          onCloseCallback: () => Common.closeApplication(),
        }),
      );
    } finally {
      dispatch(closeLoader());
    }
  };

export default getRelockDataAction;
