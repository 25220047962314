import { v4 as uuidv4 } from 'uuid';
import validate from '../../../../common/utils/validator';
import { validators } from './constants';

export const checkCardsType = (type) => {
  const isAdjustment = [
    'LockExtensionAdjustment',
    'ReLockFeeAdjustment',
    'CustomPriceAdjustment',
  ].includes(type);
  return isAdjustment ? 'adjustments' : 'concessions';
};

export const validateRequiredInputs = (rows, type) => {
  const validator = validators[type];
  const { hasError, errorDetails } = validate({ [type]: rows }, [validator]);
  return { hasError, errors: errorDetails[type] };
};

export const parseDecimals = (valueToParse, decimals) => {
  let toNumber;

  if (valueToParse || valueToParse === 0) {
    toNumber = Number(valueToParse);
  }

  if (typeof toNumber === 'number' && !Number.isNaN(toNumber)) {
    return toNumber.toFixed(decimals);
  }

  return valueToParse;
};

export const normalizeCardsData = (cards) => {
  const result = cards.map((card) => ({
    ...card,
    price: parseFloat(card.price),
    rate: parseFloat(card.rate),
    id: card.id || uuidv4(),
  }));
  return result;
};

export const normalizeAdjustmentsData = (adjs) => {
  const result = adjs.map((adj) => ({
    ...adj,
    price: Number(adj.price).toFixed(3),
    rate: Number(adj.rate).toFixed(3),
  }));
  return result;
};
