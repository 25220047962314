import api from '../../common/utils/api';

class LoginService {
  async refreshJWT(username, token) {
    const data = await api.refreshJWT(
      { customToken: { value: token }, tokenType: 'Bearer' },
      username,
    );
    return data;
  }

  async login(username, password) {
    const body = { EppsUserPassword: password };
    const data = await api.login({ body: JSON.stringify(body) }, username);
    return data;
  }

  async validateCredentials(username, password) {
    const body = { EppsUserPassword: password };
    const data = await api.validateCredentials(
      { body: JSON.stringify(body) },
      username,
    );
    return data;
  }
}

// TODO(ngen): Exporting an instance of a service is interesting here
export default new LoginService();
