var EppsCustomFieldDecimalTypes = /* @__PURE__ */ ((EppsCustomFieldDecimalTypes2) => {
  EppsCustomFieldDecimalTypes2["DECIMAL"] = "DECIMAL";
  EppsCustomFieldDecimalTypes2["DECIMAL_1"] = "DECIMAL_1";
  EppsCustomFieldDecimalTypes2["DECIMAL_2"] = "DECIMAL_2";
  EppsCustomFieldDecimalTypes2["DECIMAL_3"] = "DECIMAL_3";
  EppsCustomFieldDecimalTypes2["DECIMAL_4"] = "DECIMAL_4";
  EppsCustomFieldDecimalTypes2["DECIMAL_5"] = "DECIMAL_5";
  EppsCustomFieldDecimalTypes2["DECIMAL_6"] = "DECIMAL_6";
  EppsCustomFieldDecimalTypes2["DECIMAL_7"] = "DECIMAL_7";
  EppsCustomFieldDecimalTypes2["DECIMAL_8"] = "DECIMAL_8";
  EppsCustomFieldDecimalTypes2["DECIMAL_9"] = "DECIMAL_9";
  EppsCustomFieldDecimalTypes2["DECIMAL_10"] = "DECIMAL_10";
  return EppsCustomFieldDecimalTypes2;
})(EppsCustomFieldDecimalTypes || {});
var EppsCustomFieldTypes = /* @__PURE__ */ ((EppsCustomFieldTypes2) => {
  EppsCustomFieldTypes2["DATE"] = "DATE";
  EppsCustomFieldTypes2["DROPDOWN"] = "DROPDOWN";
  EppsCustomFieldTypes2["DROPDOWNLIST"] = "DROPDOWNLIST";
  EppsCustomFieldTypes2["YN"] = "YN";
  EppsCustomFieldTypes2["X"] = "X";
  EppsCustomFieldTypes2["STRING"] = "STRING";
  EppsCustomFieldTypes2["INTEGER"] = "INTEGER";
  EppsCustomFieldTypes2["MONTHDAY"] = "MONTHDAY";
  EppsCustomFieldTypes2["PHONE"] = "PHONE";
  EppsCustomFieldTypes2["SSN"] = "SSN";
  EppsCustomFieldTypes2["STATE"] = "STATE";
  EppsCustomFieldTypes2["ZIPCODE"] = "ZIPCODE";
  return EppsCustomFieldTypes2;
})(EppsCustomFieldTypes || {});
export {
  EppsCustomFieldDecimalTypes,
  EppsCustomFieldTypes
};
