import { SchemaSearchForm } from '@frontend/pricing-search';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getStateCounties,
  getZipLocations,
} from '../../common/services/searchFormServices';
import { usePricingSearchConfiguration } from '../../data/useSelectors';
import { lookupsActions } from '../../store/lookups';
import {
  StyledEppsContainerBody,
  StyledEppsContainerHeader,
  StyledEppsFormWrapper,
  StyledEppsParentViewSearchForm,
  StyledEppsWrapper,
} from '../global/styles/styled';
import { ErrorBanner } from './components/ErrorBanner';
import { FooterForm } from './components/FooterForm';
import { HeaderForm } from './components/HeaderForm';

export const SearchForm = ({ schemaForm }) => {
  const customSchema = usePricingSearchConfiguration();
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(lookupsActions.getGeocodesDataAction({ dispatch } as any));
    dispatch(lookupsActions.getLookupsDataAction({ dispatch } as any));
  }, []);

  return (
    <StyledEppsParentViewSearchForm>
      <StyledEppsContainerBody>
        <StyledEppsWrapper>
          <StyledEppsContainerHeader>
            <ErrorBanner />
          </StyledEppsContainerHeader>
          <HeaderForm />
          <StyledEppsFormWrapper>
            <SchemaSearchForm
              schema={customSchema}
              schemaForm={schemaForm}
              preview={false}
              getStateCounties={getStateCounties}
              getZipLocations={getZipLocations}
            />
          </StyledEppsFormWrapper>
        </StyledEppsWrapper>
      </StyledEppsContainerBody>
      <FooterForm schemaForm={schemaForm} />
    </StyledEppsParentViewSearchForm>
  );
};
