import { currencyWithDecimals } from '../../../../../common/utils/shared';
import { StyledDangerSpan } from './styled';

export const dispCurrency = (c) => {
  if (c === null || c === undefined || Number.isNaN(c)) return '--';
  const result = Math.round(c);
  if (result < 0) {
    return (
      <StyledDangerSpan>{`-${currencyWithDecimals(
        Math.abs(result),
      )}`}</StyledDangerSpan>
    );
  }
  return `$${result}`;
};

export const dispDiscount = (discountPoints) => {
  if (
    discountPoints === null ||
    discountPoints === undefined ||
    Number.isNaN(discountPoints)
  )
    return '--';
  const result = discountPoints ? discountPoints.toFixed(3) : '';
  if (result < 0) {
    return <StyledDangerSpan>{result}</StyledDangerSpan>;
  }
  return result;
};
