import moment from 'moment';

export const CARDS_TITLES = {
  LockExtensionAdjustment: 'Lock Extensions',
  ReLockFeeAdjustment: 'Re-Lock Fees',
  CustomPriceAdjustment: 'Custom Adjustments',
  CorporatePriceConcession: 'Corporate Price Concessions',
  BranchPriceConcession: 'Branch Price Concessions',
};

export const CARDS_TEMPLATES = {
  adjustments: {
    description: '',
    price: '',
    persist: true,
  },
  concessions: {
    title: '',
    approvalDate: moment().format('L'),
    price: '',
    approvedBy: '',
    approvedReason: '',
    persist: true,
  },
};

export const CARDS_LIMIT = {
  adjustments: 10,
  concessions: 5,
};

const adjustmentsValidator = {
  fieldName: 'adjustments',
  path: 'adjustments',
  customValidator: (
    _,
    rows: {
      price: string;
      description: string;
      rate: string;
      adjustmentType: string;
    }[] = [],
  ) => {
    const validation: {
      hasError: boolean;
      messages: { price: string; description: string; rate: string }[];
    } = { hasError: false, messages: [] };
    const errorMessages = {
      required: 'Required',
      atleastOne: 'One of Rate or Price is required',
      customAdjustmentRange: 'Between -25 and 25',
      priceRange: 'Between -9.999 and 9.999',
    };

    rows.forEach((row, index) => {
      const rowError = { price: '', description: '', rate: '' };
      const { price, description, rate, adjustmentType } = row;
      const priceValue = parseFloat(price);
      const rateValue = parseFloat(rate);
      const isCustomAdjustment = adjustmentType === 'CustomPriceAdjustment';
      // if there is no value in name
      if (!(description || '').trim()) {
        validation.hasError = true;
        rowError.description = errorMessages.required;
      }
      // validation for custom adjustment
      if (isCustomAdjustment) {
        if (Number.isNaN(rateValue) && Number.isNaN(priceValue)) {
          rowError.rate = errorMessages.atleastOne;
          rowError.price = errorMessages.atleastOne;
        }
        if (priceValue < -25 || priceValue > 25) {
          rowError.price = errorMessages.customAdjustmentRange;
        }
        if (rateValue < -25 || rateValue > 25) {
          rowError.rate = errorMessages.customAdjustmentRange;
        }
      }
      // validation for Re-Lock Fees, Corporate Price Concessions, Branch Price Concessions
      if (!isCustomAdjustment) {
        if (Number.isNaN(priceValue)) {
          rowError.price = errorMessages.required;
        } else if (priceValue < -9.999 || priceValue > 9.999) {
          rowError.price = errorMessages.priceRange;
        } else if (priceValue === 0) {
          rowError.price = errorMessages.required;
        }
      }

      if (rowError.price === '' && rowError.rate === '') {
        rowError.rate = '';
        rowError.price = '';
      } else validation.hasError = true;

      validation.messages[index] = rowError;
    });

    return validation;
  },
};

const concessionsValidator = {
  fieldName: 'concessions',
  path: 'concessions',
  customValidator: (
    _,
    rows: {
      price: string;
      approvalDate: string;
    }[] = [],
  ) => {
    const validation: {
      hasError: boolean;
      messages: { price: string; approvalDate: string }[];
    } = { hasError: false, messages: [] };

    rows.forEach((row, index) => {
      const rowError = { price: '', approvalDate: '' };
      const { price, approvalDate } = row;
      const priceValue = parseFloat(price);

      if (Number.isNaN(priceValue)) {
        validation.hasError = true;
        rowError.price = 'Required';
      }
      if (priceValue < -9.999 || priceValue > 9.999) {
        validation.hasError = true;
        rowError.price = 'Between -9.999 - 9.999';
      }

      if (priceValue === 0) {
        validation.hasError = true;
        rowError.price = 'Required';
      }
      if (!(approvalDate || '').trim()) {
        validation.hasError = true;
        rowError.approvalDate = 'Required';
      }
      validation.messages[index] = rowError;
    });

    return validation;
  },
};

export const validators = {
  adjustments: adjustmentsValidator,
  concessions: concessionsValidator,
};
