import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Controller } from "react-hook-form";
import { DSHeader } from "@elliemae/ds-header";
import { BUTTON_VARIANT, DSButton } from "@elliemae/ds-button";
import { Delete, WarningTriangle } from "@elliemae/ds-icons";
import { Grid, GridItem } from "@elliemae/ds-grid";
import { BANNER_TYPES, DSBanner } from "@elliemae/ds-banner";
import { v4 as uuidv4 } from "uuid";
import React, { useState } from "react";
import { Textbox } from "./textbox";
import { Combobox } from "./combobox";
import { Checkbox } from "./checkbox";
import { Date } from "./date";
const Otherrealestateowned = ({
  schemaForm,
  fields
}) => {
  const fieldComponentMap = {
    textbox: Textbox,
    combobox: Combobox,
    checkbox: Checkbox,
    date: Date
  };
  const [counter, setCounter] = useState(schemaForm.getValues("otherRealEstate")?.length || 0);
  const addProperty = () => {
    const defaultProperty = {
      id: uuidv4(),
      monthlyPayment: "0",
      mtgBalance: "0",
      occupancyRate: 0,
      rentalIncome: null,
      use: null,
      value: "0",
      isPropertyCommercial: false
    };
    setCounter(counter + 1);
    const updatedValues = schemaForm.getValues("otherRealEstate") || [];
    updatedValues.push(defaultProperty);
    schemaForm.setValue("otherRealEstate", updatedValues);
  };
  const removeProperty = (propertyIndex) => {
    setCounter(counter - 1);
    const updatedValues = schemaForm.getValues("otherRealEstate")?.filter((_, index) => index !== propertyIndex) || [];
    schemaForm.unregister(`otherRealEstate[${propertyIndex}]`);
    schemaForm.setValue("otherRealEstate", updatedValues);
    schemaForm.trigger("otherRealEstate");
  };
  const renderField = (field, index) => {
    const {
      type,
      ui,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      component_id,
      id,
      label,
      required,
      helptext,
      options,
      selectMultiple
    } = field;
    const modelPath = field?.modelPath ?? field?.encompass_ids?.[0]?.modelPath;
    const key = type === "enum" ? "combobox" : type === "boolean" ? "checkbox" : type === "string" && ui.widget === "datepicker" ? "date" : "textbox";
    const Component = key ? fieldComponentMap[key] : null;
    const pathValue = modelPath.replace("[0]", `[${index}]`);
    if (!Component) return null;
    ui.col_size = 2;
    return /* @__PURE__ */ jsx(
      Controller,
      {
        name: pathValue,
        render: ({ field: renderedField }) => /* @__PURE__ */ jsx(
          Component,
          {
            renderedField,
            schemaForm,
            rules: null,
            id,
            name: component_id,
            label,
            required,
            pathValue,
            feedbackMessage: helptext,
            type,
            ui,
            options,
            isMulti: selectMultiple,
            visible: true,
            disabled: false,
            visibleCheck: null,
            disableCheck: null,
            preview: true,
            rest: null
          }
        )
      },
      `${component_id}-${index}`
    );
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      Grid,
      {
        span: 12,
        alignItems: "center",
        style: { display: "flex", alignItems: "center", marginBottom: 8 },
        children: [
          /* @__PURE__ */ jsx(Grid, { span: 11, style: { flex: 1 }, children: /* @__PURE__ */ jsx(
            DSHeader,
            {
              text: "Other Real Estate Owned",
              fontSize: 12,
              fontWeight: "bold",
              lineHeight: 1,
              color: void 0,
              fontFamily: void 0,
              height: void 0,
              level: void 0,
              width: void 0
            }
          ) }),
          /* @__PURE__ */ jsx(Grid, { span: 1, style: { flexShrink: 0 }, children: /* @__PURE__ */ jsx(
            DSButton,
            {
              id: "realEstate",
              buttonType: "secondary",
              labelText: "Add Real Estate",
              size: "m",
              variant: BUTTON_VARIANT.DEFAULT,
              onClick: addProperty,
              disabled: counter === 4
            }
          ) })
        ]
      }
    ),
    counter === 0 && /* @__PURE__ */ jsxs(
      Grid,
      {
        span: 12,
        style: {
          marginTop: "16px",
          marginBottom: "60px",
          textAlign: "center"
        },
        children: [
          /* @__PURE__ */ jsx(
            WarningTriangle,
            {
              size: "m",
              color: ["brand-primary", "600"],
              style: { margin: "0 auto" }
            }
          ),
          /* @__PURE__ */ jsx("p", { children: "No other properties have been added yet." })
        ]
      }
    ),
    counter > 0 && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Grid, { span: 12, children: /* @__PURE__ */ jsx(
        DSBanner,
        {
          body: "Any amounts added to the section will need to be manually added to your loan origination system.",
          isOpen: true,
          label: "Other Real Estate Owned Amounts",
          type: BANNER_TYPES.WARNING,
          showCloseButton: false
        }
      ) }),
      Array.from({ length: counter }).map((_, index) => /* @__PURE__ */ jsxs(
        React.Fragment,
        {
          children: [
            fields.map((field) => renderField(field, index)),
            /* @__PURE__ */ jsx(GridItem, { alignItems: "center", children: /* @__PURE__ */ jsx(
              DSButton,
              {
                "aria-label": "Delete Real Estate",
                buttonType: "text",
                id: "deleteRealEstate",
                leftIcon: /* @__PURE__ */ jsx(Delete, {}),
                size: "l",
                variant: BUTTON_VARIANT.DEFAULT,
                onClick: () => removeProperty(index),
                style: { alignItems: "center", marginTop: 8 }
              }
            ) })
          ]
        },
        schemaForm.getValues("otherRealEstate")?.[index]?.id
      ))
    ] })
  ] });
};
export {
  Otherrealestateowned
};
