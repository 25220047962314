import { ModalFooter, ModalHeader } from '@elliemae/ds-modal-slide';
import { DSSeparator } from '@elliemae/ds-separator';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Session, {
  ALL_SUMMARY_PARAMS,
  IS_VALIDATE,
} from '../../../../common/services/Session';
import { isEmpty } from '../../../../common/utils/shared';
import { getRateSelectorAction } from '../../../../data/lockSummary/actionCreators';
import { closeLoader, openLoader } from '../../../../data/screenLoader/actions';
import {
  useLoanInformationSelector,
  useRateSelector,
} from '../../../../data/useSelectors';
import { ModalSlide } from '../../../components/ModalSlide';
import { CommentsSection } from '../../../LockSummary/components/CommentsSection';
import { PricingDetailsModal } from '../../../LockSummary/components/PricingDetailsModal';
import { getTableDataWithIDs } from '../../../LockSummary/components/PricingDetailsModal/helpers';
import { LockConfirmWrapperStyled } from '../../styled';
import { priceUpdate } from '../../utils';
import { BuysidePriceSection } from '../BuysidePriceSection';
import PricingDropdown from '../BuysidePriceSection/components/PricingDropdown';
import { PriceAnalyzerView } from '../PriceAnalyzer';
import { SummaryHeader } from '../SummaryHeader';
import { Validate } from '../Validate';
import { Banner } from '../Validate/components/Banner';

export const LockConfirm = ({ setIsCompareRatesView }) => {
  const dispatch = useDispatch();
  const loanInfo = useLoanInformationSelector();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPriceDetailsModalOpen, setIsPriceDetailsModalOpen] = useState(false);
  const [tableData, setTableData] = useState({
    rawPrice: [],
    adjustedPrice: [],
  });
  const defaultSelectData = useRef({});
  const isValidate = Session.get(IS_VALIDATE);
  const noNewProduct = isEmpty(Session.get(ALL_SUMMARY_PARAMS));
  const { priceData } = useRateSelector() as any;

  useEffect(() => {
    if (priceData) {
      setTableData(getTableDataWithIDs(priceData) as any);
    }
  }, [priceData]);

  const handleApplyChange = (data) => {
    defaultSelectData.current = data;
  };

  const showPriceAnalyzerModal = useCallback(() => {
    setIsModalOpen((prevState) => !prevState);
  }, []);

  const showPriceDetailsModal = useCallback(() => {
    setIsPriceDetailsModalOpen((prevState) => !prevState);
  }, []);

  const applyNewPriceConfirmAnalyzer = () => {
    dispatch(openLoader('Loading'));
    setIsModalOpen((prevState) => !prevState);
    priceUpdate(defaultSelectData.current, loanInfo);
    dispatch(getRateSelectorAction() as any);
    defaultSelectData.current = {};
    dispatch(closeLoader());
  };

  const onCompareRates = () => {
    setIsModalOpen(false);
    setIsCompareRatesView(true);
  };

  return (
    <>
      {isValidate && noNewProduct && <Banner />}
      <LockConfirmWrapperStyled>
        <SummaryHeader />
        <DSSeparator
          style={{ margin: '8px 0 24px' }}
          dashed
          orientation="horizontal"
          position="initial"
        />
        {isValidate && noNewProduct ? (
          <Validate
            buySidePriceSection={
              <BuysidePriceSection
                pricingDropdownSection={
                  <PricingDropdown
                    priceAnalyzerModal={showPriceAnalyzerModal}
                    priceDetailsModal={showPriceDetailsModal}
                  />
                }
              />
            }
          />
        ) : (
          <BuysidePriceSection
            pricingDropdownSection={
              <PricingDropdown
                priceAnalyzerModal={showPriceAnalyzerModal}
                priceDetailsModal={showPriceDetailsModal}
              />
            }
          />
        )}
        <ModalSlide
          maxWidth="650px"
          isOpen={isModalOpen}
          header={
            <ModalHeader
              title="Price Analyzer"
              data-testid="LockConfirmWrapper-all-components"
              onClose={showPriceAnalyzerModal}
            />
          }
          footer={
            <ModalFooter
              onConfirm={applyNewPriceConfirmAnalyzer}
              onReject={showPriceAnalyzerModal}
              confirmLabel="Apply New Price"
            />
          }
        >
          <PriceAnalyzerView
            onCompareRates={onCompareRates}
            onApplyChange={handleApplyChange}
          />
        </ModalSlide>
        <PricingDetailsModal
          togglePricingDetails={showPriceDetailsModal}
          isOpenPricingDetails={isPriceDetailsModalOpen}
          tableData={tableData}
        />
        <CommentsSection />
      </LockConfirmWrapperStyled>
    </>
  );
};

LockConfirm.propTypes = {
  setIsCompareRatesView: PropTypes.func.isRequired,
};
