export interface BuySideAdjustments {
  CustomPriceAdjustment: any[];
  ReLockFeeAdjustment: any[];
  LockExtensionAdjustment: any[];
  CorporatePriceConcession: any[];
  BranchPriceConcession: any[];
}

export interface InitialState {
  loanData: Record<string, any>;
  originalLoanData: Record<string, any>;
  isFirstLoad: boolean;
  lockedProgram: Record<string, any>;
  buySideAdjustments: BuySideAdjustments;
}

export const INITIAL_STATE: InitialState = {
  loanData: {},
  originalLoanData: {},
  isFirstLoad: true,
  lockedProgram: {},
  buySideAdjustments: {
    CustomPriceAdjustment: [],
    ReLockFeeAdjustment: [],
    LockExtensionAdjustment: [],
    CorporatePriceConcession: [],
    BranchPriceConcession: [],
  },
};
