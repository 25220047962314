import React, { useState, useEffect, memo } from 'react';
import { string, bool, arrayOf, shape, func } from 'prop-types';
import { DSComboBox2, DSFormItemLayout } from '@elliemae/ds-form';

export const DatesDropdown = memo<any>(
  ({
    dropdownDisabled,
    dropdownOptions,
    dropdownLabel,
    dropdownValue,
    onChange,
  }) => {
    const [value, setValue] = useState(dropdownValue);

    useEffect(() => {
      if (dropdownValue) {
        onChange(dropdownValue);
      }
    }, [dropdownValue]);

    const changeHandler = (newValue) => {
      setValue(newValue);
      onChange(newValue);
    };

    return (
      <DSFormItemLayout
        options={dropdownOptions}
        floatingLabel
        inputComponent={DSComboBox2}
        labelText={dropdownLabel}
        required={false}
        disabled={dropdownDisabled}
        value={value}
        onChange={changeHandler}
        maxOptions={dropdownOptions?.length || 5}
        placeholder="Select a date"
        isNonClearable
      />
    );
  },
);

(DatesDropdown as any).propTypes = {
  dropdownLabel: string.isRequired,
  dropdownOptions: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
      hasSeparator: bool,
    }),
  ),
  dropdownValue: string,
  dropdownDisabled: bool,
  onChange: func.isRequired,
};

(DatesDropdown as any).defaultProps = {
  dropdownValue: '',
  dropdownOptions: [],
  dropdownDisabled: false,
};
