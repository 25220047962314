import { currency } from '../../../../../common/utils/shared';

export const columnsBorrowersGrid = [
  {
    property: 'FullName',
    label: 'Borrower Name',
    visible: true,
  },
  {
    property: 'creditScore',
    label: 'CREDIT SCORE',
    visible: true,
    header: {
      customRenderer: () => 'CREDIT SCORE',
    },
    customRenderer: ({ metaData: { rowData } }) => {
      return rowData.creditScore;
    },
  },
  {
    property: 'liquidAssets',
    label: 'LIQUID ASSETS',
    visible: true,
    header: {
      customRenderer: () => 'LIQUID ASSETS',
    },
    customRenderer: ({ metaData: { rowData } }) => {
      return currency(rowData.liquidAssets);
    },
  },
  {
    property: 'retirementAssets',
    label: 'Retirement Assets',
    visible: true,
    header: {
      customRenderer: () => 'RETIREMENT ASSETS',
    },
    customRenderer: ({ metaData: { rowData } }) => {
      return currency(rowData.retirementAssets);
    },
  },
  {
    property: 'annualIncome',
    label: 'ANNUAL INCOME',
    visible: true,
    header: {
      customRenderer: () => 'ANNUAL INCOME',
    },
    customRenderer: ({ metaData: { rowData } }) => {
      return currency(rowData.annualIncome);
    },
  },
  {
    property: 'monthlyDebt',
    label: 'MONTHLY DEBT',
    visible: true,
    header: {
      customRenderer: () => 'MONTHLY DEBT',
    },
    customRenderer: ({ metaData: { rowData } }) => {
      return currency(rowData.monthlyDebt);
    },
  },
];
