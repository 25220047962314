import Common from '../../../../../common/services/Common';
import Rights from '../../../../../common/services/Rights';
import Session, {
  APPLICATION_NAME,
} from '../../../../../common/services/Session';

export const hasViewHoldBackRights = () => {
  const roles = Common.getUserRoles() || [];
  return Array.isArray(roles) && !roles.some(({ roleId }) => roleId === '12');
};

export const handleIsSecondMortgageAmount = ({ customDependencies }) => {
  const { initialSecondMortgageAmount } = customDependencies;
  return !!initialSecondMortgageAmount;
};

export const isHeloc = ({ customDependencies }) => {
  const { isHELOC } = customDependencies;
  return !isHELOC;
};

export const isRefinanceType = ({ customDependencies }) => {
  const { isRefinance } = customDependencies;
  return !isRefinance;
};

export const isCashOutRefinance = ({ customDependencies }) => {
  const { loanPurpose, isHELOC } = customDependencies;
  return loanPurpose !== 3 || isHELOC;
};

export const isHideWholesaleChannelFields = ({ customDependencies }) => {
  return (
    customDependencies.channel === 'Banked - Wholesale' &&
    Session.get(APPLICATION_NAME) === 'TPO'
  );
};

export const isHideCommitmentAndDeliveryTpe = ({ customDependencies }) => {
  const { channel } = customDependencies;
  const isCorrespondent = channel === 'Correspondent';
  const isTPO = Session.get(APPLICATION_NAME) === 'TPO';

  if (!isCorrespondent) {
    return true;
  }

  if (!Rights.viewDeliveryAndCommitment) {
    return true;
  }

  return channel === 'Banked - Wholesale' && isTPO;
};
