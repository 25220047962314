import { BANNER_TYPES, DSBanner } from '@elliemae/ds-banner';
import { useDispatch } from 'react-redux';
import { searchFormActions } from '../../../../store/searchForm';
import { useSearchFormSelector } from '../../../components/Form/utils/customHooks';

export const ErrorBanner = () => {
  const dispatch = useDispatch();
  const bannerIsOpen = (useSearchFormSelector('errorForm.bannerIsOpen') as any)
    ?.value;
  let bannerBody = 'Please fix any fields that are marked below.';
  const errorFilters = (useSearchFormSelector('errorForm.errorFilters') as any)
    ?.value;
  const errorFields = (useSearchFormSelector('errorForm.errorFields') as any)
    ?.value;
  if (errorFilters.length > 0 && errorFields.length === 0) {
    bannerBody = errorFilters?.[0]?.details;
  }

  return (
    <DSBanner
      body={bannerBody}
      isOpen={!!bannerIsOpen}
      onClose={
        (() => dispatch(searchFormActions.setBannerIsOpen(false))) as any
      }
      label="Errors Found"
      type={BANNER_TYPES.DANGER}
    />
  );
};
