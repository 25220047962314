import { useCallback } from "react";
import { isNull } from "lodash";
import { useLookup, parseCompensationValue, inRange } from "./utils";
const useTextboxFieldUpdateLogic = () => {
  return useCallback(
    (renderedField, schemaForm, id) => {
      switch (renderedField.name) {
        case "loanInformation.ltv":
          return (e) => {
            renderedField.onChange(e);
          };
        case "loanInformation.term":
          return (e) => {
            const value = parseFloat(e.target.value);
            const productType = schemaForm.getValues("productType");
            if (value && !inRange(value, 1, 360)) {
              schemaForm.setError(id, {
                type: "manual",
                message: "Custom Term must be an integer value from 1 to 360"
              });
            } else if (isNaN(value) && (productType === void 0 || productType?.length === 0)) {
              schemaForm.setError(id, {
                type: "manual",
                message: "Either enter a Custom Term or choose a Loan Term"
              });
            } else {
              schemaForm.clearErrors(id);
            }
            renderedField.onChange(e);
          };
        case "loanInformation.target":
          return (e) => {
            const targetLabel = schemaForm.getValues(
              "loanInformation.targetLabel"
            );
            const targetValue = parseFloat(e.target.value);
            if (targetLabel) {
              if (isNull(targetValue))
                schemaForm.setError(id, {
                  type: "manual",
                  message: "Required field"
                });
              else if (targetLabel === "Rate" && !inRange(targetValue, 0, 20)) {
                schemaForm.setError(id, {
                  type: "manual",
                  message: "Between 0% - 20%"
                });
              } else if (targetLabel === "Price" && !inRange(targetValue, 80, 120)) {
                schemaForm.setError(id, {
                  type: "manual",
                  message: "Between 80 - 120"
                });
              } else {
                schemaForm.clearErrors(id);
              }
            }
            renderedField.onChange(e);
          };
        case "loanInformation.averageRepresentativeCreditScore":
          return (e) => {
            const num = Number(e.target.value);
            const noCreditScores = schemaForm.getValues(
              "borrowerFinancialHistory.noCreditScores"
            );
            if ((num < 300 || num > 850) && (!noCreditScores || noCreditScores === void 0) && e.target.value) {
              schemaForm.setError(id, {
                type: "manual",
                message: "Between 300 and 850"
              });
            } else {
              schemaForm.clearErrors(id);
            }
            renderedField.onChange(e);
          };
        case "borrowers[0].borrowerFinanancial.creditScore":
          return (e) => {
            const num = Number(e.target.value);
            const noCreditScores = schemaForm.getValues(
              "borrowerFinancialHistory.noCreditScores"
            );
            if ((num < 300 || num > 850) && (!noCreditScores || noCreditScores === void 0) && e.target.value) {
              schemaForm.setError(id, {
                type: "manual",
                message: "Between 300 and 850"
              });
            } else {
              schemaForm.clearErrors(id);
            }
            renderedField.onChange(e);
          };
        case "borrowers[0].giftFundsDollar":
          return (e) => {
            const giftFundsPercent = Math.round(
              Math.abs(e.target.value) / schemaForm.getValues("property.value") * 1e5
            ) / 1e3;
            schemaForm.setValue(
              "borrowers[0].giftFundsPercent",
              giftFundsPercent
            );
            schemaForm.setValue(
              "loanInformation.giftForDownPayment",
              e.target.value
            );
            renderedField.onChange(e);
          };
        case "borrowers[0].giftFundsPercent":
          return (e) => {
            const giftFundsDollar = Math.round(
              e.target.value * (schemaForm.getValues("property.value") / 100)
            );
            schemaForm.setValue(
              "borrowers[0].giftFundsDollar",
              giftFundsDollar
            );
            schemaForm.setValue(
              "loanInformation.giftForDownPayment",
              giftFundsDollar
            );
            renderedField.onChange(e);
          };
        case "borrowers[0].sellerDollar":
          return (e) => {
            const sellerPercent = Math.round(
              Math.abs(e.target.value) / schemaForm.getValues("property.value") * 1e5
            ) / 1e3;
            schemaForm.setValue("borrowers[0].sellerPercent", sellerPercent);
            schemaForm.setValue(
              "loanInformation.sellerConsessionForDownPayment",
              e.target.value
            );
            renderedField.onChange(e);
          };
        case "borrowers[0].sellerPercent":
          return (e) => {
            const sellerDollar = Math.round(
              e.target.value * (schemaForm.getValues("property.value") / 100)
            );
            schemaForm.setValue("borrowers[0].sellerDollar", sellerDollar);
            schemaForm.setValue(
              "loanInformation.sellerConsessionForDownPayment",
              sellerDollar
            );
            renderedField.onChange(e);
          };
        default:
          return renderedField.onChange;
      }
    },
    []
  );
};
const useCheckboxFieldUpdateLogic = () => {
  return useCallback(
    (renderedField, schemaForm) => {
      switch (renderedField.name) {
        default:
          return () => renderedField.onChange(!renderedField.value);
      }
    },
    []
  );
};
const useSingleComboboxFieldUpdateLogic = () => {
  const parseValue = (value) => {
    if (!Array.isArray(value)) {
      if (value?.value && !isNaN(Number(value.value))) {
        return parseInt(value.value, 10);
      }
      return value?.value ?? null;
    }
    return value.map(
      (option) => !isNaN(Number(option.value)) ? parseInt(option.value, 10) : option.value
    );
  };
  return useCallback(
    (renderedField, schemaForm, id) => (value) => {
      switch (renderedField.name) {
        case "loanInformation.targetLabel":
          schemaForm.setValue(
            "loanInformation.target",
            !Array.isArray(value) && value?.value === "Low" ? 100 : null
          );
          return renderedField.onChange(parseValue(value));
        case "compensation.model":
          schemaForm.setValue(
            "compensation.model",
            parseCompensationValue(
              !Array.isArray(value) ? value?.value : null
            )
          );
          return renderedField.onChange(parseValue(value));
        default:
          return renderedField.onChange(parseValue(value));
      }
    },
    []
  );
};
const useLoanOfficerFieldUpdateLogic = () => {
  return useCallback(
    (renderedField, schemaForm, id, setLoanOfficer) => (value) => {
      switch (renderedField.name) {
        default:
          setLoanOfficer(value);
          const parsedValue = ["", void 0].includes(value?.value) ? null : value?.value;
          const parsedLogin = ["", void 0].includes(value?.login) ? null : value?.login;
          schemaForm.setValue("loanInformation.loanOfficerId", parsedValue);
          schemaForm.setValue("eppsUserName", parsedLogin);
          return renderedField.onChange(parsedLogin);
      }
    },
    []
  );
};
const useMultiComboboxFieldUpdateLogic = () => {
  const lookupsLoanTerms = useLookup("loanTerms", null);
  const parseValues = (value) => {
    if (Array.isArray(value)) {
      return value.map(
        (option) => !isNaN(Number(option.value)) ? parseInt(option.value, 10) : option.value
      );
    }
    return value;
  };
  return useCallback(
    (renderedField, schemaForm, id) => (value) => {
      switch (renderedField.name) {
        case "standardProducts":
          const govtProducts = /* @__PURE__ */ new Set(["2", "3", "4"]);
          const selectedGovtProducts = (Array.isArray(value) ? value : []).filter((item) => govtProducts.has(item.value));
          if (selectedGovtProducts.length > 1) {
            schemaForm.setError(id, {
              type: "manual",
              message: "Only one of FHA, VA or USDA can be selected."
            });
          } else if (Array.isArray(value) && value.length === 0) {
            schemaForm.setError(id, {
              type: "manual",
              message: "Required field"
            });
          } else {
            schemaForm.clearErrors(id);
          }
          return renderedField.onChange(parseValues(value));
        default:
          return renderedField.onChange(parseValues(value));
      }
    },
    []
  );
};
export {
  useCheckboxFieldUpdateLogic,
  useLoanOfficerFieldUpdateLogic,
  useMultiComboboxFieldUpdateLogic,
  useSingleComboboxFieldUpdateLogic,
  useTextboxFieldUpdateLogic
};
