/* eslint-disable @typescript-eslint/default-param-last */
import { OPEN_GLOBAL_ERROR_MODAL, CLOSE_GLOBAL_ERROR_MODAL } from './actions';

const INITIAL_STATE = {
  id: 0,
  isOpen: false,
  title: '',
  errorMessage: '',
  showClose: true,
  confirmLabel: 'Ok',
  onClose: () => {},
  onConfirm: () => {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_GLOBAL_ERROR_MODAL:
      return {
        ...state,
        ...action.payload,
      };
    case CLOSE_GLOBAL_ERROR_MODAL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
