import { Grid } from '@elliemae/ds-grid';
import styled from 'styled-components';

export default styled.div`
  width: 100%;
  > div {
    > div {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export const StyledBestExRowSelected = styled.div`
  ${({ gridRow }) => (gridRow ? 'height: 32px;' : 'height: 36px;')};
  max-height: 36px;
  width: calc(100% + 1.23076rem);
  margin-left: -0.61538rem;
  margin-right: -0.61538rem;
  padding-left: 0.61538rem;
  padding-right: 0.61538rem;
  display: flex;
  align-items: center;
  ${({ targetRow }) => (targetRow ? 'background: #EBF6FF;' : '')}
`;

export const StyledBestExProgramLinks = styled.p`
  ${({ isBest }) => (isBest ? 'font-weight: bold;' : '')}
  color: #3989ca;
  cursor: pointer;
  text-decoration: underline;
`;

export const StyledTableTitle = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.brand[200]};
  border-top: 2px solid ${({ theme }) => theme.colors.brand[400]};
  align-items: center;
  font-weight: bold;
`;

export const StyledProductNotesList = styled.div`
  ul {
    padding-right: 20px;
  }
`;
