/* eslint-disable no-unsafe-optional-chaining */
import { getAppConfigValue } from '@elliemae/pui-app-sdk';
import {
  getLoanPurposeType,
  getPropertyType,
  getPropertyUsageType,
} from '@frontend/epc';
import { parseNumber } from '@frontend/utils';
import { get, isEmpty, round, toNumber } from 'lodash';
import { getBuySideQualification } from '../../common/Qualification/QualificationActions';
import { rateSelectorBuySide } from '../../common/mocks/Buyside';
import { getClientSettingsData } from '../../common/services/ClientSettingsServices';
import Common from '../../common/services/Common';
import Session, {
  ALL_SUMMARY_PARAMS,
  ORIGIN_SOURCE,
  USER_TYPE,
} from '../../common/services/Session';
import { epcService } from '../../common/services/epcService';
import {
  getEncompassSettings,
  rateSelector,
} from '../../common/services/lockSummaryServices';
import browserHistory from '../../common/utils/browserHistory';
import createAction from '../../common/utils/createAction';
import { truncateText } from '../../common/utils/helpers';
import { closeLoader, openLoader } from '../screenLoader/actions';
import { PATH_LOGIN, PATH_SEARCH_RESULTS } from '../../route/constants';
import { openGlobalErrorModalAction } from '../globalErrorModal/actionCreators';
import { setRedirectTabAction } from '../landing/actions';
import { setLockedProgramData } from '../origin/actions';
import {
  SELECT_PRICE,
  selectProduct,
  setClientSettingsDataAction,
  setCompareDataAction,
  setCurrentRateSelectorAction,
  setDiffElementsAction,
  setRateSelectorAction,
  setSettings,
  setStatusFail,
} from './actions';
import {
  checkPricingStatus,
  findBestProgram,
  getAdjustmentCompare,
  getSnapshotProgramInfo,
  parseOriginAdjustments,
  parseRateAdjustmentsValidate,
} from './helpers';

export const getRateSelector = async (
  dispatch,
  loanData,
  buysideAdjustments?,
) => {
  const user = Session.get(USER_TYPE);
  const results = await rateSelector(dispatch, loanData, buysideAdjustments);

  if (results && results.code !== undefined) {
    if (user && user.userType === 'StandAlone') {
      browserHistory.push(PATH_LOGIN);
    } else if (
      results.errors &&
      results.errors.find((o) => o.code === 'PS031') &&
      results.summary &&
      results.summary.includes('EPPS90010')
    ) {
      const summaryResponse = JSON.parse(results.summary);
      dispatch(
        openGlobalErrorModalAction({
          errorMessage: summaryResponse.summary,
          onCloseCallback: () => {
            dispatch(setRedirectTabAction(PATH_SEARCH_RESULTS));
          },
        }),
      );
    } else if (
      results.errors &&
      results.errors.find((o) => o.code === 'PS095')
    ) {
      dispatch(
        openGlobalErrorModalAction({
          errorMessage:
            'Your ICE PPE session has expired. Please close ICE PPE and log back in.',
          onCloseCallback: () => Common.closeApplication(),
        }),
      );
    } else {
      dispatch(
        openGlobalErrorModalAction({
          onCloseCallback: () => Common.closeApplication(),
        }),
      );
    }
  }
  return results;
};

export const getRateSelectorAction = () => async (dispatch, state) => {
  try {
    const loanData = get(state(), 'EPPS.loanInfo') || {};
    // Sometimes, loanInfo.loanId isn't populated, so we have to find it from other objects.
    const loanId =
      get(state(), 'EPPS.loanInfo.loanInformation.loanId') ||
      get(state(), 'epps.lockSummary.loanId');
    loanData.loanInformation = loanData.loanInformation || {};
    loanData.loanInformation.loanId = loanId;
    const buysideAdjustments =
      get(state(), 'epps.origin.buySideAdjustments') || {};
    const results = await getRateSelector(
      dispatch,
      loanData,
      buysideAdjustments,
    );
    dispatch(setRateSelectorAction(results));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: () => Common.closeApplication(),
      }),
    );
  }
};

export const getClientSettingSelector = () => async (dispatch) => {
  try {
    const clientSettingsData = await getClientSettingsData();
    dispatch(setClientSettingsDataAction(clientSettingsData));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: () => Common.closeApplication(),
      }),
    );
  }
};

export const getValidateDataAction = () => async (dispatch, state) => {
  const loanData = get(state(), 'EPPS.loanInfo') || {};
  const originalLoanInfo = get(state(), 'EPPS.originalLoanInformation') || {};
  const { relockRequest, additionalLockInfo } = loanData;
  const { specificRateDataID, specificProgramID } = relockRequest;
  const { lockRequests } = originalLoanInfo;
  const snapshotProgramName = getSnapshotProgramInfo(lockRequests);
  const loanFieldComparisson = Object.values(
    additionalLockInfo?.lockCompareResults || {},
  ).flat() as any[];
  const rateChange = loanFieldComparisson.find(({ id }) => id === 'rate');
  const { proposed: proposedRate } = rateChange || {};
  const changedNoteRate = !!proposedRate;
  try {
    dispatch(openLoader('Loading'));
    const pricing = await getBuySideQualification(
      'historical',
      loanData,
      dispatch,
    );
    const {
      program: selectedProgram,
      rateDataID: newRateDataID,
      status,
    } = checkPricingStatus(
      specificProgramID,
      specificRateDataID,
      proposedRate,
      pricing,
      changedNoteRate,
    );
    dispatch(
      setLockedProgramData({
        programName: selectedProgram?.program || snapshotProgramName,
        programID: specificProgramID,
        notes: selectedProgram?.notes?.replace(/\{.*?\}/g, '') || '',
        status,
        modalOpen: true,
      }),
    );

    if (status === 'eligible') {
      const newLoanData = {
        ...loanData,
        relockRequest: { ...relockRequest, specificRateDataID: newRateDataID },
      };
      const debugBuyside = getAppConfigValue<boolean>(
        'partner-ui.debugBuyside',
      );
      const summary = debugBuyside
        ? rateSelectorBuySide
        : await getRateSelector(dispatch, newLoanData, null);
      dispatch(setRateSelectorAction(summary));

      if (!debugBuyside) {
        dispatch(selectProduct(selectedProgram));
      }
      if (
        loanFieldComparisson.length > 0 ||
        newRateDataID !== specificRateDataID
      ) {
        const originAdjustments = parseOriginAdjustments(originalLoanInfo);
        const adjustmentsToAdd = originAdjustments.filter(
          (adj) =>
            adj.adjustmentType === 'ReLockFeeAdjustment' ||
            adj.adjustmentType === 'CustomPriceAdjustment' ||
            adj.adjustmentType === 'CorporatePriceConcession' ||
            adj.adjustmentType === 'BranchPriceConcession',
        );
        const proposedAdjustments =
          summary.lien.adjustments.concat(adjustmentsToAdd);
        const compareResult = getAdjustmentCompare(
          originAdjustments,
          parseRateAdjustmentsValidate(
            proposedAdjustments,
            originAdjustments.some(
              ({ description }) => description === 'L.O. Compensation',
            ),
          ),
        );
        dispatch(setCompareDataAction(compareResult));
      }
      dispatch(closeLoader());
    } else {
      dispatch(setRedirectTabAction(PATH_SEARCH_RESULTS));
    }
  } catch {
    dispatch(closeLoader());
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: () => Common.closeApplication(),
      }),
    );
  }
};

export const getBuysideDataAction = () => async (dispatch, state) => {
  const loanData = get(state(), 'EPPS.loanInfo', {});
  const lockRequests = get(
    state(),
    'EPPS.originalLoanInformation.lockRequests',
    [],
  );

  const snapshotProgramName = getSnapshotProgramInfo(lockRequests);
  const { relockRequest, loanInformation, additionalLockInfo } = loanData || {};
  const { target } = loanInformation || {};
  const { specificRateDataID, specificProgramID } = relockRequest || {};
  const rate = target <= 20 ? target : null;
  const loanFieldComparisson = Object.values(
    additionalLockInfo?.lockCompareResults || {},
  ).flat() as any[];
  const rateChange = loanFieldComparisson.find(({ id }) => id === 'rate');
  const { proposed: proposedRate } = rateChange || {};
  const changedNoteRate = !!proposedRate;
  try {
    dispatch(openLoader('Loading'));
    const historicalPricing = await getBuySideQualification(
      'historical',
      loanData,
      dispatch,
    );

    const { program: historicalProgram, status: historicalStatus } =
      checkPricingStatus(
        specificProgramID,
        specificRateDataID,
        proposedRate || rate,
        historicalPricing,
        changedNoteRate,
      );
    dispatch(
      setLockedProgramData({
        programName: historicalProgram?.program || snapshotProgramName,
        programID: specificProgramID,
        notes: historicalProgram?.notes?.replace(/\{.*?\}/g, '') || '',
        status: historicalStatus,
        modalOpen: true,
      }),
    );

    if (historicalStatus === 'eligible') {
      dispatch(selectProduct(historicalProgram));
      const historicalSummary = getAppConfigValue<boolean>(
        'partner-ui.debugBuyside',
      )
        ? rateSelectorBuySide
        : await getRateSelector(
            dispatch,
            loanData,
            state()?.EPPS?.originalLoanInformation?.rateLock
              ?.buySideAdjustments,
          );
      dispatch(setRateSelectorAction(historicalSummary));

      const { price: historicalPrice, qualifiedRate: originalRate } =
        historicalSummary?.lien || {};

      const {
        program: currentProgram,
        rateDataID: newRateDataID,
        status: currentStatus,
      } = checkPricingStatus(
        specificProgramID,
        specificRateDataID,
        Number(originalRate),
        historicalPricing,
        false,
      );

      if (currentStatus === 'eligible') {
        const { price: currentPrice } =
          currentProgram?.rateDetails?.find(
            ({ rateDataID }) => rateDataID === newRateDataID,
          ) || {};
        const priceDifference =
          typeof currentPrice === 'number' &&
          historicalPrice.toFixed(3) !== (100 - currentPrice).toFixed(3);
        if (priceDifference) {
          const newLoanData = {
            ...loanData,
            relockRequest: {
              ...relockRequest,
              effectiveDate: '',
              specificRateDataID: newRateDataID,
            },
          };
          const currentSummary = await getRateSelector(
            dispatch,
            newLoanData,
            null,
          );
          dispatch(setCurrentRateSelectorAction(currentSummary));
          const compareResult = getAdjustmentCompare(
            historicalSummary?.lien?.adjustments,
            currentSummary?.lien?.adjustments,
          );
          dispatch(setCompareDataAction(compareResult));
        }
      }
      dispatch(closeLoader());
    } else {
      dispatch(setRedirectTabAction(PATH_SEARCH_RESULTS));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('getBuysideDataAction.error', err);
    dispatch(closeLoader());
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: () => Common.closeApplication(),
      }),
    );
  }
};

export const getSettingAction = () => async (dispatch) => {
  try {
    dispatch(openLoader('Loading'));
    const settings = await getEncompassSettings();
    if (settings && !settings.code)
      dispatch(setSettings(settings.adjustmentsSettings));
  } catch {
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: () => Common.closeApplication(),
      }),
    );
  } finally {
    dispatch(closeLoader());
  }
};

export const saveLoanAction = (rateLockContract) => async (dispatch, state) => {
  dispatch(openLoader('Saving Encompass Elements'));
  try {
    const getSummarydetails = get(state(), 'epps.lockSummary.rateSelector', {});
    const getEncompassElements = get(
      state(),
      'eppsData.lookups.data.encompassElements',
      [],
    );
    const originalLoanInfo = get(state(), 'epps.origin.originalLoanData', {});
    const validSummary = !!getEncompassElements.find(
      (element) => element.key === 'SummaryQuote',
    );
    const summaryDetails = validSummary ? getSummarydetails : {};
    const getCustomFields = get(
      state(),
      'EPPS.loanInfo.lockRequestAdditionalFields',
      [],
    );
    const getOriginSource = Session.get(ORIGIN_SOURCE) || '';
    const customFields: any[] = [];

    if (!isEmpty(getCustomFields)) {
      getCustomFields.map((item) => {
        return customFields.push({
          fieldId: item.fieldId,
          value: item.value,
        });
      });
    }

    const transactionId = await epcService.createTransaction({
      request: {
        type: 'SUBMIT_LOAN',
        options: {
          actionType: 'SubmitRates',
          summaryDetails,
          loanElements: [],
          customFields,
          sourceApplicationForm: getOriginSource,
        },
      },
    });

    if (transactionId) {
      const getEpcTransaction = await Common.getEpcTransaction(transactionId);
      if (getEpcTransaction.status === 'completed') {
        await Common.refreshGetOrigin();
        const diffELents = await Common.getUpdatedfield({
          loan: { ...originalLoanInfo },
          rateLockInput: {
            lenderFeeWaiverOption: rateLockContract.lenderFeeWaiver,
            noClosingCostOption: rateLockContract.noClosingCost,
          },
          sourceApplicationForm: getOriginSource,
        });
        if (!isEmpty(diffELents)) {
          dispatch(setDiffElementsAction(diffELents));
        } else Common.closeApplication();
      } else if (
        getEpcTransaction.summary === 'Conflict' ||
        getEpcTransaction.summary === 'Bad Request'
      ) {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: truncateText(getEpcTransaction.details, 300),
          }),
        );
      } else if (
        getEpcTransaction.status === 'failed' &&
        getEpcTransaction.code === 'EBS-912'
      ) {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: getEpcTransaction.details,
          }),
        );
      } else if (
        ['dev', 'qa', 'int'].some((envPartial) =>
          getAppConfigValue<string>('activeEnv').includes(envPartial),
        )
      ) {
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: getEpcTransaction.summary,
            title: 'Transaction Failed',
          }),
        );
      }
    } else throw new Error();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: () => Common.closeApplication(),
      }),
    );
  } finally {
    dispatch(closeLoader());
  }
};

export const floatPricingAction =
  (rateLockInput) => async (dispatch, state) => {
    dispatch(openLoader('Floating Pricing'));
    try {
      const summaryDetails = get(state(), 'epps.lockSummary.rateSelector', {});
      const originalLoanInfo = get(state(), 'epps.origin.originalLoanData', {});
      const getCustomFields = get(
        state(),
        'EPPS.loanInfo.lockRequestAdditionalFields',
        [],
      );

      const pricingOriginSource = Session.get(ORIGIN_SOURCE) || '';
      const customFields: any[] = [];

      if (!isEmpty(getCustomFields)) {
        getCustomFields.map((item) => {
          return customFields.push({
            fieldId: item.fieldId,
            value: item.value,
          });
        });
      }

      const options = buildEpcLoanObject({
        summaryDetails,
        pricingOriginSource,
      });

      const transactionId = await epcService.createTransaction({
        request: {
          type: 'FLOAT',
          options: {
            actionType: 'SubmitRates',
            sourceApplicationForm: pricingOriginSource,
            loan: options?.loan,
            summaryDetails,
            customFields,
            loanElements: [],
          },
        },
      });

      if (transactionId) {
        await epcService.updatePollTransaction({
          transactionID: transactionId,
          options,
        });

        // TODO(ngen): we had a boolean "true" passed here, but the method signature didn't have a parameter
        await Common.refreshGetOrigin();
        const diffELents = await Common.getUpdatedfield({
          loan: { ...originalLoanInfo },
          rateLockInput,
          sourceApplicationForm: pricingOriginSource,
        });
        if (!isEmpty(diffELents)) {
          dispatch(setDiffElementsAction(diffELents));
        }
        Common.closeApplication();
      } else throw new Error('Missing transaction ID');
    } catch (err) {
      console.error('Unhandled floatPricing error', err);
      dispatch(
        openGlobalErrorModalAction({
          onCloseCallback: () => Common.closeApplication(),
        }),
      );
    } finally {
      dispatch(closeLoader());
    }
  };

export const requestLockAction =
  (EPCActionType, actionType, rateLockContract) => async (dispatch, state) => {
    dispatch(openLoader('Requesting Lock'));
    try {
      const summaryDetails = get(state(), 'epps.lockSummary.rateSelector', {});
      const sellSideBestPrice =
        summaryDetails?.loan?.autolocked?.sellSideBestPrice;
      const originalLoanInfo = get(state(), 'epps.origin.originalLoanData', {});
      const getCustomFields = get(
        state(),
        'EPPS.loanInfo.lockRequestAdditionalFields',
        [],
      );
      const isLockRequestUnderOnrp = get(
        state(),
        'lockDeskStatus.isLockRequestUnderOnrp',
        {},
      );
      const onrpFields = get(state(), 'EPPS.loanInfo.loanInformation', []);
      const { loanChannel, firstMortgageAmount } = onrpFields;
      const getOriginSource = Session.get(ORIGIN_SOURCE) || '';
      const customFields: any[] = [];

      if (!isEmpty(getCustomFields)) {
        getCustomFields.map((item) => {
          return customFields.push({
            fieldId: item.fieldId,
            value: item.value,
          });
        });
      }

      let sellSideBestSummary;
      if (sellSideBestPrice) {
        const { loanPrograms, loanId } = get(state(), 'EPPS.loanPrograms', {});
        const loanData = get(state(), 'EPPS.loanInfo', {});
        const { relockRequest } = loanData;
        const { rate: selectedRate, loanTerm: selectedTerm } =
          summaryDetails?.lien ?? {};

        const { bestProgramID, bestRateID } = findBestProgram(
          loanPrograms,
          selectedRate,
          selectedTerm,
        );
        Session.set(ALL_SUMMARY_PARAMS, {
          loanId,
          programId: bestProgramID,
          rateDataId: bestRateID,
          effectiveDate: relockRequest?.effectiveDate,
        });
        sellSideBestSummary = await getRateSelector(loanData, dispatch);
      }
      const buysideAdjustments = Object.values(
        get(state(), 'epps.origin.buySideAdjustments') || {},
      ).flat(1);

      const transactionId = await epcService.createTransaction({
        request: {
          type: EPCActionType,
          options: {
            actionType,
            summaryDetails,
            customFields,
            buysideAdjustments,
            loanElements: [],
            sourceApplicationForm: getOriginSource,
            ...(sellSideBestSummary
              ? { sellSideDetails: sellSideBestSummary }
              : {}),
          },
        },
      });

      if (transactionId) {
        const getEpcTransaction = await Common.getEpcTransaction(transactionId);
        if (getEpcTransaction.status === 'completed') {
          if (isLockRequestUnderOnrp) {
            await Common.getOnrpAmount(
              loanChannel === 0 ? 1 : loanChannel,
              firstMortgageAmount,
            );
          }
          await Common.refreshGetOrigin();
          const diffELents = await Common.getUpdatedfield({
            loan: { ...originalLoanInfo },
            rateLockInput: {
              lenderFeeWaiverOption: rateLockContract.lenderFeeWaiver,
              noClosingCostOption: rateLockContract.noClosingCost,
            },
            sourceApplicationForm: getOriginSource,
          });
          if (!isEmpty(diffELents)) {
            dispatch(setDiffElementsAction(diffELents));
          } else Common.closeApplication();
        } else if (
          getEpcTransaction.summary === 'Conflict' ||
          getEpcTransaction.summary === 'Bad Request'
        ) {
          dispatch(
            setStatusFail({
              error: true,
              code: getEpcTransaction.code,
              message: truncateText(getEpcTransaction.details, 300),
            }),
          );
        } else if (
          getEpcTransaction.status === 'failed' &&
          getEpcTransaction.code === 'EBS-912'
        ) {
          dispatch(
            setStatusFail({
              error: true,
              code: getEpcTransaction.code,
              message: getEpcTransaction.details,
            }),
          );
        } else if (
          ['dev', 'qa', 'int'].some((envPartial) =>
            getAppConfigValue<string>('activeEnv').includes(envPartial),
          )
        ) {
          dispatch(
            openGlobalErrorModalAction({
              errorMessage: getEpcTransaction.summary,
              title: 'Transaction Failed',
            }),
          );
        }
      } else throw new Error();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      dispatch(
        openGlobalErrorModalAction({
          onCloseCallback: () => Common.closeApplication(),
        }),
      );
    } finally {
      dispatch(closeLoader());
    }
  };

export const selectPriceAction = (newSelection) => {
  return createAction(SELECT_PRICE)(newSelection);
};

const buildEpcLoanObject = (opts) => {
  const { summaryDetails, pricingOriginSource } = opts;
  const userRoles = Common.getUserRoles();
  const isFromSecondaryLock =
    pricingOriginSource.toLowerCase() === 'secondarylock';
  const loanPurposeType = getLoanPurposeType(
    summaryDetails?.loan?.purposeId,
    summaryDetails?.loan?.gseRefinancePurposeType,
  );

  // TODO(ngen): baseMarginRate was defined here, but never defined inside getBasePricingValues. Would always be undefined.
  const { baseRate, basePrice, srp } = getBasePricingValues(
    summaryDetails,
    isFromSecondaryLock,
  );

  const { requestPrepayPenalty, requestPenaltyTerm } =
    getPrepaymentPenaltyTerm(summaryDetails);

  const propTypeId = summaryDetails?.loan?.property?.typeId ?? 1;
  const propertyType = getPropertyType(propTypeId);
  const {
    subjectPropertyCondotelIndicator,
    subjectPropertyNonWarrantableProjectIndicator,
  } = getIsCondotel(summaryDetails, propertyType);

  const mortgageType = summaryDetails?.loan?.mortgageType;
  const qualificationTypeId = summaryDetails?.loan?.qualificationTypeId;

  const { requestMarginRate, requestPriceRateRequested } =
    getCalculatedAdjustmentValues(summaryDetails);

  let gfe2010FeeType = 'DiscountPoint';
  let gfe2010FeeIndex = 1;
  if (summaryDetails?.lien?.commission >= 0) {
    gfe2010FeeType = 'LenderCompensationCredit';
    gfe2010FeeIndex = 2;
  }

  const usageId = summaryDetails?.loan?.property?.usageId;

  const commision = summaryDetails?.lien?.commission;
  const shouldSetFundingValues =
    summaryDetails?.lien?.va ||
    summaryDetails?.lien?.fha ||
    summaryDetails?.lien?.usda;

  const propertyValueParams = {
    propertyAppraisedValueAmount:
      summaryDetails?.loan?.property?.appraisedValueAmount ??
      summaryDetails?.loan?.property?.estimatedValueAmount ??
      summaryDetails?.loan?.property?.purchasePriceAmount,
    propertyEstimatedValueAmount:
      summaryDetails?.loan?.property?.estimatedValueAmount ??
      summaryDetails?.loan?.property?.appraisedValueAmount ??
      summaryDetails?.loan?.property?.purchasePriceAmount,
    purchasePriceAmount: summaryDetails?.loan?.property?.purchasePriceAmount,
  };

  const epcLoan = {
    loan: {
      basePrice,
      baseRate,
      baseLoanAmount: summaryDetails?.loan?.baseLoanAmount,
      baseMarginRate: undefined,
      borrowerRequestedLoanAmount: summaryDetails?.loan?.baseLoanAmount,
      correspondentCommitmentType: summaryDetails?.loan?.commitmentType,
      correspondentDeliveryType: summaryDetails?.loan?.deliveryType,
      closingCost: {
        gfe2010: {
          gfe2010Fees: [
            {
              gfe2010FeeParentType: 'Section800LOCompensation',
              gfe2010FeeType: 'LenderCompensationCredit',
              gfe2010FeeIndex: 2,
              rate: commision >= 0 ? Math.abs(commision) : 0,
            },
            {
              gfe2010FeeParentType: 'Section800LOCompensation',
              gfe2010FeeType: 'DiscountPoint',
              gfe2010FeeIndex: 1,
              rate: commision >= 0 ? 0 : Math.abs(commision),
            },
          ],
        },
      },
      firstSubordinateLienAmount:
        summaryDetails?.lien?.firstSubordinateLienAmount,

      helocTeaserRate: summaryDetails?.lien?.introRate?.toString(),
      loanProductData: {
        armDisclosureType: summaryDetails?.lien?.armIndex
          ? summaryDetails?.lien?.maventIndexCode?.toString()
          : null,
        balloonLoanMaturityTermMonthsCount:
          summaryDetails?.lien?.loanTermMonths ?? 0,
        indexCurrentValuePercent: summaryDetails?.lien?.armIndexValue,
        indexDate: summaryDetails?.lien?.indexRetrievedDate
          ? new Date(summaryDetails?.lien?.indexRetrievedDate)
              .toISOString()
              .split('T')[0]
          : null,
        indexMarginPercent: summaryDetails?.lien?.armMargin,
        lienPriorityType:
          qualificationTypeId === 2 ? 'SecondLien' : 'FirstLien',
        subsequentRateAdjustmentMonthsCount: summaryDetails?.lien?.armFixedTerm,
        rateAdjustmentDurationMonthsCount: summaryDetails?.lien?.armAdjPeriod,
        rateAdjustmentPercent: summaryDetails?.lien?.armFirstCap,
        rateAdjustmentSubsequentCapPercent:
          summaryDetails?.lien?.armPeriodicCap,
        rateAdjustmentLifetimeCapPercent: summaryDetails?.lien?.armLifetimeCap,
      },
      lockdays: summaryDetails?.lien?.userLockDays,
      loanAmortizationTermMonths: summaryDetails?.loan?.amortizationTermMonths,
      loanAmortizationType: summaryDetails?.loan?.amortizationType,
      loanProgramName: summaryDetails?.lien?.userProgramName,
      mortgageType,
      noClosingCostOption: summaryDetails?.loan?.noClosingCost,
      property: {
        financedNumberOfUnits: getFinancedNumberOfUnits(summaryDetails),
        loanPurposeType,
      },
      ...propertyValueParams,
      rateLock: {
        ...propertyValueParams,
        borrowerRequestedLoanAmount: summaryDetails?.loan?.baseLoanAmount,
        baseLoanAmount: summaryDetails?.loan?.baseLoanAmount,
        borrLenderPaid: getCompensationModel(
          summaryDetails?.loan?.compensationModel,
        ),
        buySidePriceRate: isFromSecondaryLock
          ? summaryDetails?.lien?.price
          : null,
        firstTimeHomebuyersIndicator:
          summaryDetails?.loan?.borrowers?.[0]?.finance?.firstTimeHomeBuyers,
        floatDateUpdatedOn: new Date().toLocaleString('en-US', {
          timeZone: 'America/Los_Angeles',
        }),
        fundingAmount: shouldSetFundingValues
          ? summaryDetails?.loan?.feeAmount
          : null,
        initialAdvanceAmount: getInitialAdvAmount(
          mortgageType,
          qualificationTypeId,
        ),
        mipPaidInCash: shouldSetFundingValues
          ? summaryDetails?.loan?.feeAmountPaidInCash
          : null,
        planCode: summaryDetails?.lien?.programCode,
        pricingUpdated: new Date().toLocaleString('en-US', {
          timeZone: 'America/Los_Angeles',
        }),
        propertyUsageType: getPropertyUsageType(usageId),
        lenderFeeWaiverOption: summaryDetails?.loan?.lenderFeeWaiver,
        loanDocumentationType: getDocumentationType(summaryDetails),
        lockField: shouldSetFundingValues, // fundingAmount and mipPaidInCash will not populate unless this is true
        lockRequestAdjustments: getEpcRateLockAdjustments(
          summaryDetails,
          userRoles,
        ),
        requestImpoundWaived: summaryDetails?.lien?.waiveEscrow
          ? 'Waived'
          : 'Not Waived',
        requestMarginRate,
        requestPenaltyTerm,
        requestPrepayPenalty,
        requestPriceRate: basePrice,
        requestPriceRateRequested,
        requestProgramNotes: summaryDetails?.lien?.note?.join(','),
        requestRate: baseRate,
        requestRateSheetId: isFromSecondaryLock
          ? null
          : summaryDetails?.lien?.rateSheetId,
        requestStartingAdjPoint:
          100 - parseNumber(summaryDetails?.lien?.startingAdjustedPrice, 0),
        requestStartingAdjRate: isFromSecondaryLock
          ? null
          : summaryDetails?.lien?.startingAdjustedRate,
        requestUnDiscountedRate: summaryDetails?.lien?.undiscountedRate,
        secondSubordinateAmount: summaryDetails?.lien?.secondSubordinateAmount,
        subjectPropertyCondotelIndicator,
        subjectPropertyNonWarrantableProjectIndicator,
      },
      requestedInterestRatePercent: summaryDetails?.lien?.qualifiedRate,
      regulationZ: {
        lenderPaidMortgageInsuranceIndicator:
          summaryDetails?.loan?.lenderPaidMI?.toString(),
      },
      srp,
      twelveMonthMortgageRentalHistoryIndicator:
        summaryDetails?.loan?.borrowers?.[0]?.finance
          ?.demonstrateHousingPaymentHistory,
      unDiscountedRate: summaryDetails?.lien?.undiscountedRate,
    },
  };

  return epcLoan;
};

const getCompensationModel = (compModelEnum) => {
  const compConfig = {
    1: 'Borrower Paid',
    2: 'Lender Paid',
    3: 'Exempt Paid',
  };

  return compConfig?.[compModelEnum];
};

const getDocumentationType = (summaryDetails) => {
  const docId = summaryDetails?.lien?.documentationId;

  const docIdConfig = {
    1: 'FullDocumentation',
    2: 'NoVerificationOfStatedIncome',
    3: 'NoVerificationOfStatedIncomeOrAssets',
    4: 'NoRatio',
    5: 'NoDepositVerification',
    7: 'NoDocumentation',
    9: 'Reduced',
    10: 'LimitedDocumentation',
    11: 'NoVerificationOfStatedAssets',
  };

  return docIdConfig[docId ?? 1]; // default is full documentation
};

const getFinancedNumberOfUnits = (summaryDetails) => {
  const propTypeId = summaryDetails?.loan?.property?.typeId ?? 1;

  return (propTypeId <= 4 ? propTypeId : 1)?.toString();
};

const getInitialAdvAmount = (
  summaryDetails,
  mortgageType,
  qualificationTypeId?,
) => {
  if (
    mortgageType === 'HELOC' &&
    (qualificationTypeId === 1 || qualificationTypeId === 2)
  ) {
    return summaryDetails?.loan?.initialAdvanceAmount;
  }

  return null;
};

const getIsCondotel = (summaryDetails, propertyType) => {
  const propTypeWhitelist = [
    'Condominium',
    'HighRiseCondominium',
    'DetachedCondo',
  ];

  let subjectPropertyCondotelIndicator = false;
  let subjectPropertyNonWarrantableProjectIndicator = false;

  if (propTypeWhitelist.includes(propertyType)) {
    subjectPropertyCondotelIndicator = summaryDetails?.lien?.condotel;
    subjectPropertyNonWarrantableProjectIndicator =
      summaryDetails?.lien?.non_warrantable_condo;
  }

  return {
    subjectPropertyCondotelIndicator,
    subjectPropertyNonWarrantableProjectIndicator,
  };
};

const getPrepaymentPenaltyTerm = (summaryDetails) => {
  const ppp = toNumber(summaryDetails?.lien?.ppp);

  const ppeEnumConfig = {
    0: '',
    1: '1 Year',
    2: '2 Years',
    3: '3 Years',
    4: '4 Years',
    5: '5 Years',
  };

  const invalidText = 'NOT_VALID';
  const requestPenaltyTerm = ppeEnumConfig[ppp] ?? invalidText;
  let requestPrepayPenalty;
  if (requestPenaltyTerm !== invalidText) {
    requestPrepayPenalty = ppp === 0 ? 'N' : 'Y';
  }

  return { requestPenaltyTerm, requestPrepayPenalty };
};

const getBasePricingValues = (summaryDetails, isFromSecondaryLock) => {
  const parseBase = (key, adjs) =>
    adjs?.reduce((sum: number, curr: number) => sum + parseFloat(curr[key]), 0);

  const baseTypeAdjs = summaryDetails?.lien?.adjustments?.filter(
    (adj) => adj.adjustmentType === 'Base',
  );
  const baseRate = parseBase('rate', baseTypeAdjs);
  let basePrice = parseBase('price', baseTypeAdjs);
  let baseMargin = parseBase('margin', baseTypeAdjs);
  let srp;

  const srpPriceTotal = parseBase(
    'price',
    summaryDetails?.lien?.adjustments?.filter(
      (adj) => adj.adjustmentType === 'SRP',
    ),
  );

  if (!isFromSecondaryLock) {
    basePrice += srpPriceTotal;
  } else {
    srp = round(srpPriceTotal, 3);
  }

  if (!baseMargin) {
    baseMargin = summaryDetails?.lien?.armMargin;
  }

  const allHoldbackValues = summaryDetails?.lien?.adjustments
    ?.filter((adj) => adj.adjustmentType === 'Holdback')
    // eslint-disable-next-line no-return-assign, no-param-reassign
    ?.reduce((sum, curr) => (sum += curr.price), 0);
  basePrice += allHoldbackValues;

  return {
    basePrice: round(basePrice, 3),
    baseRate: round(baseRate, 3),
    baseMargin: round(baseMargin, 3),
    srp,
  };
};

const getCalculatedAdjustmentValues = (summaryDetails) => {
  const baseAdjustment = summaryDetails?.lien?.adjustments?.find(
    (adj) => adj.adjustmentType === 'Base',
  );

  const finalAdjustment = summaryDetails?.lien?.adjustments?.find(
    (adj) => adj.adjustmentType === 'Final',
  );

  let requestMarginRate = null;
  let requestMarginTotalAdjustment: number | null = null;
  if (summaryDetails?.lien?.arm) {
    requestMarginRate = baseAdjustment?.margin;
    requestMarginTotalAdjustment =
      finalAdjustment?.margin - baseAdjustment?.margin;
  }

  return {
    requestMarginRate,
    requestMarginTotalAdjustment,
    requestPriceTotalAdjustment: finalAdjustment?.price - baseAdjustment?.price,
    requestPriceRateRequested: finalAdjustment?.price,
  };
};

const getEpcRateLockAdjustments = (summaryDetails, userRoles) => {
  const { adjustments } = summaryDetails?.lien ?? {};
  const userRoleRoles = userRoles.map(({ role }) => role);
  const canViewCompensation =
    userRoleRoles.includes('View Compensation') &&
    !userRoleRoles.includes('View Profit Margin');

  const potentialAdjustmentsToSend = adjustments.filter(
    (adj) =>
      ['Adjustment', 'Profit', 'Holdback'].includes(adj.adjustmentType) &&
      (!canViewCompensation || adj.description !== 'L.0. Compensation'),
  );

  const epcAdjustments: Array<{
    adjustmentType: string;
    description: string;
    priceAdjustmentType: string;
    priceAdjustmentTypeIndex: number;
    rate: number;
    rateLockAdjustmentType: string;
  }> = [];
  let priceAdjIndex = 0;
  let marginAdjIndex = 0;
  let rateAdjIndex = 0;
  let profitMarginIndex = 0;
  for (let i = 0; i < potentialAdjustmentsToSend?.length; i += 1) {
    const adj = potentialAdjustmentsToSend[i];

    if (adj.adjustmentType === 'Adjustment') {
      if (adj.price !== null && adj.price !== undefined) {
        epcAdjustments.push({
          adjustmentType: adj.adjustmentType,
          description: adj.description,
          priceAdjustmentType: 'BasePrice',
          priceAdjustmentTypeIndex: (priceAdjIndex += 1),
          rate: adj.price,
          rateLockAdjustmentType: 'Request',
        });
      }

      if (adj.rate !== null && adj.rate !== undefined) {
        epcAdjustments.push({
          adjustmentType: adj.adjustmentType,
          description: adj.description,
          priceAdjustmentType: 'BaseRate',
          priceAdjustmentTypeIndex: (rateAdjIndex += 1),
          rate: adj.rate,
          rateLockAdjustmentType: 'Request',
        });
      }

      if (adj.margin !== null && adj.margin !== undefined) {
        epcAdjustments.push({
          adjustmentType: adj.adjustmentType,
          description: adj.description,
          priceAdjustmentType: 'BaseMargin',
          priceAdjustmentTypeIndex: (marginAdjIndex += 1),
          rate: adj.margin,
          rateLockAdjustmentType: 'Request',
        });
      }
    } else if (
      adj.adjustmentType === 'Profit' &&
      adj.price !== null &&
      adj.price !== undefined
    ) {
      epcAdjustments.push({
        adjustmentType: adj.adjustmentType,
        description: adj.description,
        priceAdjustmentType: 'ProfitMargin',
        priceAdjustmentTypeIndex: (profitMarginIndex += 1),
        rate: adj.price,
        rateLockAdjustmentType: 'Request',
      });
    } else if (
      adj.adjustmentType === 'Holdback' &&
      adj.price !== null &&
      adj.price !== undefined
    ) {
      if (adj.description === 'L.O. Compensation') {
        epcAdjustments.push({
          adjustmentType: 'Adjustment',
          description: adj.description,
          priceAdjustmentType: 'BasePrice',
          priceAdjustmentTypeIndex: (priceAdjIndex += 1),
          rate: adj.price,
          rateLockAdjustmentType: 'Request',
        });
      } else {
        epcAdjustments.push({
          adjustmentType: 'Adjustment',
          description: adj.description,
          priceAdjustmentType: 'ProfitMargin',
          priceAdjustmentTypeIndex: (profitMarginIndex += 1),
          rate:
            adj.rate !== null && adj.rate !== undefined ? adj.rate : adj.price,
          rateLockAdjustmentType: 'Request',
        });
      }
    }
  }

  return epcAdjustments;
};
