import React from 'react';
import PropTypes from 'prop-types';
import { DSButton, BUTTON_VARIANT } from '@elliemae/ds-button';
import { DSTooltipV2 } from '@elliemae/ds-tooltip';

export const QuoteInsuranceButtonDisabled = ({ tooltip, label }) => (
  <DSTooltipV2
    title={
      <div style={{ textAlign: 'left' }}>
        <div>Loan ineligible to quote Mortgage Insurance</div>
        <ul>
          {tooltip.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
      </div>
    }
    triggerComponent={
      <DSButton
        buttonType="secondary"
        labelText={label}
        variant={BUTTON_VARIANT.DISABLED}
        aria-disabled="true"
        style={{ marginRight: '8px' }}
      />
    }
  />
);

QuoteInsuranceButtonDisabled.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.arrayOf(PropTypes.string).isRequired,
};
