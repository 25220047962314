import { get, pick } from 'lodash';

export const selectLoanData =
  () =>
  ({ EPPS: { loanInfo = {} } }) =>
    loanInfo;

export const selectLoanDataField =
  (fieldName, defaultValue = null) =>
  ({ EPPS: { loanInfo = {} } }) => {
    return get(loanInfo, fieldName, defaultValue);
  };

export const selectLoanInformationField =
  (nameOrPath, defaultValue = null) =>
  ({ EPPS: { loanInfo: { loanInformation = {} } = {} } = {} }) => {
    if (Array.isArray(nameOrPath)) {
      return pick(loanInformation, nameOrPath);
    }
    if (typeof nameOrPath === 'string') {
      return get(loanInformation, nameOrPath, defaultValue);
    }
    return loanInformation;
  };

export const selectSearchFormFields =
  (nameOrPath, defaultValue = null) =>
  ({
    epps: {
      searchForm: { fields = {} },
    },
  }) => {
    if (Array.isArray(nameOrPath)) {
      return pick(fields, nameOrPath);
    }
    if (typeof nameOrPath === 'string') {
      return get(fields, nameOrPath, defaultValue);
    }
    return fields;
  };

export const selectSummaryDetailsField =
  (nameOrPath?, defaultValue = null) =>
  ({ EPPS: { summaryDetails = {} } = {} }) => {
    if (Array.isArray(nameOrPath)) {
      return pick(summaryDetails, nameOrPath);
    }
    if (typeof nameOrPath === 'string') {
      return get(summaryDetails, nameOrPath, defaultValue);
    }
    return summaryDetails;
  };

export const selectborrowerFinancialHistory =
  (nameOrPath, defaultValue = null) =>
  ({ EPPS: { loanInfo: { borrowerFinancialHistory = {} } = {} } = {} }) => {
    if (Array.isArray(nameOrPath)) {
      return pick(borrowerFinancialHistory, nameOrPath);
    }
    if (typeof nameOrPath === 'string') {
      return get(borrowerFinancialHistory, nameOrPath, defaultValue);
    }
    return borrowerFinancialHistory;
  };

export const selectRateSelectorLoan =
  (nameOrPath, defaultValue = null) =>
  ({
    epps: {
      lockSummary: {
        rateSelector: { loan = {} },
      },
    },
  }) => {
    if (Array.isArray(nameOrPath)) {
      return pick(loan, nameOrPath);
    }
    if (typeof nameOrPath === 'string') {
      return get(loan, nameOrPath, defaultValue);
    }
    return loan;
  };

export const selectRateSelectorLien =
  (nameOrPath, defaultValue = null) =>
  ({
    epps: {
      lockSummary: {
        rateSelector: { lien = {} },
      },
    },
  }) => {
    if (Array.isArray(nameOrPath)) {
      return pick(lien, nameOrPath);
    }
    if (typeof nameOrPath === 'string') {
      return get(lien, nameOrPath, defaultValue);
    }
    return lien;
  };

export const selectOriginalLoanInformation =
  (nameOrPath, defaultValue = null) =>
  ({ EPPS: { originalLoanInformation = {} } = {} }) => {
    if (Array.isArray(nameOrPath)) {
      return pick(originalLoanInformation, nameOrPath);
    }
    if (typeof nameOrPath === 'string') {
      return get(originalLoanInformation, nameOrPath, defaultValue);
    }
    return originalLoanInformation;
  };

export const selectLoanInfo =
  (nameOrPath, defaultValue = null) =>
  ({ EPPS: { loanInfo = {} } = {} }) => {
    if (Array.isArray(nameOrPath)) {
      return pick(loanInfo, nameOrPath);
    }
    if (typeof nameOrPath === 'string') {
      return get(loanInfo, nameOrPath, defaultValue);
    }
    return loanInfo;
  };

export const selectSearchForm =
  (nameOrPath, defaultValue = null) =>
  ({ epps: { searchForm } }) => {
    if (Array.isArray(nameOrPath)) {
      return pick(searchForm.fields, nameOrPath);
    }
    if (typeof nameOrPath === 'string') {
      return get(searchForm.fields, nameOrPath, defaultValue);
    }
    return searchForm;
  };
