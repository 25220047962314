/* eslint-disable @typescript-eslint/default-param-last */

export const truncateText = (text = '', length) => {
  return text.length > length ? `${text.slice(0, length - 3)}...` : text;
};

export const labelItalicTextFixer = (message) => (
  // This function fixes a dimsum defect that removes 1px from the end of the text (for italic letters).
  <span>
    {message}
    <span style={{ width: '2px', display: 'inline-block' }} />
  </span>
);
