import styled from 'styled-components';

export const StyledCardTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 24px 0px 24px;
`;
export const StyledTextTitle = styled.div`
  font-weight: bold;
`;

export const StyledEmptyCard = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledEmptyText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.title[400]};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

export const StyledCenteredText = styled.div`
  text-align: center;
`;
