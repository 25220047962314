import { WarningTriangle } from '@elliemae/ds-icons';
import { get } from 'lodash';
import { errorMessages } from '../../common/constants/Messages';
import Common from '../../common/services/Common';
import { closeLoader } from '../screenLoader/actions';
import { setCloseErrorModalAction, setOpenErrorModalAction } from './actions';

const closeGlobalErrorModalAction =
  (isRecall = false) =>
  (dispatch) => {
    dispatch(
      setCloseErrorModalAction({
        ...(!isRecall && { id: 0 }),
        isOpen: false,
        title: '',
        errorMessage: '',
        onClose: () => {},
      }),
    );
  };

export const openGlobalErrorModalAction =
  (opts: any = {}) =>
  (dispatch, state) => {
    try {
      throw new Error('Global Error');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(
        'Global error. There should have been an error above this one logged. If not, use the stack trace here to find the root cause',
        err,
      );
    }
    const {
      title = 'Error',
      errorMessage = '',
      reCallModal = false,
      onCloseCallback = () => {},
      reCallModalFunc = () => {},
      reCallModalFinalFunc = () => {},
      showClose = true,
      confirmLabel = 'Ok',
      icon = <WarningTriangle size="xxl" color={['danger', '900']} />,
    } = opts;

    const globalErrorModal = get(state(), 'globalErrorModal') || {};
    dispatch(
      setOpenErrorModalAction({
        isOpen: true,
        title,
        icon,
        id: (globalErrorModal.id as number) + 1,
        errorMessage: errorMessage || errorMessages.GENERAL_ERROR_MODAL,
        showClose,
        confirmLabel,
        onClose: () => {
          onCloseCallback();
          if (!reCallModal) dispatch(closeGlobalErrorModalAction());
          if (reCallModal && globalErrorModal.id >= 2) {
            reCallModalFinalFunc();
            dispatch(closeGlobalErrorModalAction());
          } else if (reCallModal && globalErrorModal.id <= 2) {
            reCallModalFunc();
            dispatch(closeGlobalErrorModalAction(true));
          }
        },
        onConfirm: () => {
          onCloseCallback();
          if (!reCallModal) {
            dispatch(closeGlobalErrorModalAction());
          }
          if (reCallModal && globalErrorModal.id >= 2) {
            reCallModalFinalFunc();
            dispatch(closeGlobalErrorModalAction());
          } else if (reCallModal && globalErrorModal.id <= 2) {
            reCallModalFunc();
            dispatch(closeGlobalErrorModalAction(true));
          }
        },
      }),
    );
  };

const openCloseAppErrorModal =
  ({ title, message }) =>
  (dispatch) => {
    dispatch(closeLoader());
    dispatch(
      openGlobalErrorModalAction({
        title,
        errorMessage: message,
        showClose: false,
        confirmLabel: 'Close ICE PPE',
        onCloseCallback: () => Common.closeApplication(),
      }),
    );
  };

export const openNoLoanAmountErrorModal = () =>
  openCloseAppErrorModal({
    title: 'Loan amount is $0.00',
    message:
      'Please return to the original application and enter a loan amount.',
  });

export const openGiftFundErrorModal = () =>
  openCloseAppErrorModal({
    title: "Gift Funds can't be a negative number",
    message:
      'Please return to your loan origination system and fix this issue, then relaunch ICE PPE.',
  });
