import { DSBackdrop } from '@elliemae/ds-backdrop';
import { DSCircularIndeterminateIndicator } from '@elliemae/ds-circular-progress-indicator';
import { DSTypography, TYPOGRAPHY_VARIANTS } from '@elliemae/ds-typography';
import { shape } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

const StyledCircularIndicator = styled(DSCircularIndeterminateIndicator)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const mapStateToProps = (state) => {
  return {
    opts: state.screenLoader,
  };
};

export const ScreenLoader = connect(mapStateToProps)(({ opts }: any) => {
  return (
    opts.show && (
      <>
        <DSBackdrop zIndex={2500} />
        <DSTypography variant={TYPOGRAPHY_VARIANTS.B1}>
          <b>
            <StyledCircularIndicator
              size="xl"
              text={opts.message && `${opts.message}...`}
              color="dark"
            />
          </b>
        </DSTypography>
      </>
    )
  );
});

ScreenLoader.propTypes = {
  opts: shape({}),
};

ScreenLoader.defaultProps = {
  opts: {},
};
