import React from 'react';
import { func } from 'prop-types';
import { DSButton } from '@elliemae/ds-button';

export const ProgramDetailsButton = ({ onClick }) => (
  <DSButton
    buttonType="secondary"
    labelText="Program Details"
    size="m"
    onClick={onClick}
  />
);

ProgramDetailsButton.propTypes = {
  onClick: func.isRequired,
};
