import { DataTable } from '@elliemae/ds-data-table';
import { ModalHeader } from '@elliemae/ds-modal-slide';
import { arrayOf, func, number, shape } from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLoanInformationField,
  useSummaryDetailsField,
} from '../../../../../../data/useSelectors';
import { getAdjustmentDetails } from '../../../../../AdjustmentDetails/AdjustmentDetailsAction';
import { ModalSlide } from '../../../../../components/ModalSlide';
import { StyledDataTableWrapper } from '../../../../../global/styles/styled';
import { GridPriceDetails } from '../../../GridPriceDetails/GridPriceDetails';
import { transformRateSelectorDataToSlide } from '../../../GridPriceDetails/helpers';
import { getAllRatesColumns } from './helpers';

export const AllRatesGrid = ({
  rates,
  onSelectRate,
  programId,
  targetRow,
  selectedProduct,
}) => {
  const dispatch = useDispatch();
  const lienPosition = useLoanInformationField('lienPosition', 1);
  const { loanId } = useSelector(({ EPPS }) => EPPS.loanPrograms || {});
  const {
    lien: { armIndexValue = 0, arm = false } = {
      arm: null,
      armIndexValue: null,
    },
    loan: { mortgageType = '' } = { mortgageType: null },
  } = useSummaryDetailsField() as any;
  const heloc = useMemo(() => mortgageType === 'HELOC', [mortgageType]);
  const [slide, setSlide] = useState({
    open: false,
    data: null,
    rowData: null,
  });

  const onClickPrice = (rowData) =>
    setSlide({
      rowData,
      open: false,
      data: null,
    });

  const { relockRequest } = useSelector(({ EPPS }) => EPPS.loanInfo || {});
  const columns = useMemo(
    () =>
      getAllRatesColumns({
        relockRequest,
        programId,
        targetRow,
        onClickPrice,
      }),
    [relockRequest, targetRow],
  );
  useEffect(() => {
    if (slide.rowData && !slide.open) {
      const { rateDataID: rateDataId } = slide.rowData;
      const searchParams = {
        loanId,
        programId,
        rateDataId,
        lienPosition,
      } as any;

      dispatch(getAdjustmentDetails(searchParams) as any).then((response) => {
        if (response && response.adjustments) {
          const dataSlide = transformRateSelectorDataToSlide(
            response.adjustments,
            armIndexValue,
          ) as any;
          dataSlide.showMarginTable = arm || heloc;
          dataSlide.bonaFideTestResults = response.bonaFideTestResults;
          setSlide({
            open: true,
            data: dataSlide,
            rowData: null,
          });
        }
      });
    }
  }, [slide]);

  const selection = selectedProduct && { [selectedProduct?.rateDataID]: true };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '85%',
        paddingTop: '8px',
      }}
    >
      <ModalSlide
        isOpen={slide.open}
        header={
          <ModalHeader
            title="Pricing Details"
            onClose={() => setSlide({ open: false, data: null, rowData: null })}
          />
        }
      >
        {slide.data && (
          <div style={{ padding: 16 }}>
            <GridPriceDetails isSlide data={slide.data} />
          </div>
        )}
      </ModalSlide>
      <StyledDataTableWrapper isAllRatesGrid>
        <DataTable
          columns={columns}
          data={rates}
          uniqueRowAccessor="rateDataID"
          textWrap="wrap-all"
          colsLayoutStyle="auto"
          selection={selection}
          noSelectionColumn
          onRowClick={onSelectRate}
        />
      </StyledDataTableWrapper>
    </div>
  );
};

AllRatesGrid.defaultProps = {
  targetRow: null,
  selectedProduct: null,
};

AllRatesGrid.propTypes = {
  rates: arrayOf(shape({})).isRequired,
  onSelectRate: func.isRequired,
  programId: number.isRequired,
  targetRow: shape({
    apr: number,
    bRatio: number,
    basePrice: number,
    bonaFideDiscountPointsFactor: number,
    bonaFideDiscountPointsTest: number,
    comp: number,
    compVal: number,
    delta: number,
    holdback: number,
    margin: number,
    pnI: number,
    price: number,
    priceVal: number,
    rate: number,
    rateDataID: number,
  }),
  selectedProduct: shape({
    apr: number,
    bRatio: number,
    basePrice: number,
    bonaFideDiscountPointsFactor: number,
    bonaFideDiscountPointsTest: number,
    comp: number,
    compVal: number,
    delta: number,
    holdback: number,
    margin: number,
    pnI: number,
    price: number,
    priceVal: number,
    rate: number,
    rateDataID: number,
  }),
};
