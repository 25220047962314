import styled from 'styled-components';

export const StyledLockUpdateBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  div.em-ds-labelValue__wrapper {
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-right: 0rem;
    font-size: 13px;
    width: 98%;
  }
  .em-ds-form-element-radio__label-text {
    font-size: 16px;
    font-weight: ${({ theme: { fontWeights = {} } }) =>
      `${fontWeights.semibold} `};
  }
  .em-ds-form-element-radio {
    padding-bottom: 0.2rem;
  }
  .em-ds-separator-wrapper {
    margin-top: 0rem;
    margin-left: 0rem;
    margin-bottom: 0.5rem;
  }
  .em-ds-labelValueGroup__wrapper--horizontal {
    width: 100%;
  }
  .relock-box-select-border {
    border: 1px solid
      ${({ theme: { colors = {} } }) => `${colors?.brand?.['600']} `};
  }
`;

export const StyledLockUpdateBoxDate = styled.div`
  div.em-ds-labelValue__wrapper {
    margin-bottom: 1rem;
    margin-right: 0;
    font-size: 14px;
    margin-left: 1rem;
    margin-top: ${({ duplicateBox }) => (duplicateBox ? '0rem' : '1rem')};
  }
`;

export const StyledLockStatusLabel = styled.div`
  div.em-ds-labelValue__wrapper span {
    font-size: 13px;
    width: 100%;
    margin-left: -1px;
  }
`;

export const StyledRateNotAvailable = styled.div`
  div.em-ds-labelValue__wrapper span {
    font-size: 16px;
    font-weight: 600;
    margin-left: -0.5%;
  }
`;

export const StyledWorseCaseText = styled.div`
  margin-left: ${({ theme }) => theme.space.s};
`;

export const StyledDetailsRibbonContainer = styled.div`
  width: 100%;
  text-align: left;
  .duKcTP {
    padding: 0rem;
  }
  .hkXKVj {
    font-weight: normal;
    padding: 0rem;
  }
  .eCzijK {
    padding: 0rem;
    border-bottom: none;
  }
  .jXgYPT {
    min-height: 0rem;
  }
  .xAcdV {
    padding: 0rem 0.5ren;
    position: inherit;
  }
`;

export const StyledDangerSpan = styled.div`
  color: ${({ theme }) => theme.colors.danger['900']};
  justify-content: right;
  width: 100%;
`;
