import { DataTable } from '@elliemae/ds-data-table';
import { arrayOf, shape } from 'prop-types';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Session, {
  ALL_SUMMARY_PARAMS,
  IS_BUYSIDE,
  IS_VALIDATE,
} from '../../../common/services/Session';
import { isEmpty } from '../../../common/utils/shared';
import { selectPriceAction } from '../../../data/lockSummary/actionCreators';
import {
  useCompareDataSelector,
  useRateSelectorLien,
} from '../../../data/useSelectors';
import { SlidePriceCards } from './SlidePriceCards';
import {
  defaultColumns,
  getBuysideColumns,
  getPricingChangedColumns,
  getValidateColumns,
} from './columns';
import { generateAdjustments } from './helpers';
import { loanBuySideAdjustmentsSelector } from './selectors';
import { useLocation } from 'react-router-dom';

export const PricingGrid = memo<any>(({ adjustmentRows }) => {
  const location = useLocation();
  const tabId = location.pathname;
  const [openSlide, setOpenSlide] = useState('');
  const dispatch = useDispatch();
  const selectPrice = (newPrice) => dispatch(selectPriceAction(newPrice));
  const { selectedPrice } = useSelector(
    ({ epps: { lockSummary } }) => lockSummary,
  );
  const adjustments = useRateSelectorLien('adjustments', []) as any[];
  const { comparissonArray, changeCounter: pricingChanges = 0 } =
    useCompareDataSelector() as any;

  const isSummaryTab = tabId === '/lock-summary';
  const pricingHasChanged =
    isSummaryTab &&
    pricingChanges !== 0 &&
    isEmpty(Session.get(ALL_SUMMARY_PARAMS));
  const loanVariations = useSelector(loanBuySideAdjustmentsSelector());
  const isBuyside = Session.get(IS_BUYSIDE);
  const isValidate = Session.get(IS_VALIDATE);
  const rowsBeforeEdit = useRef({});
  const memoRows = useMemo(
    () =>
      adjustmentRows.length
        ? adjustmentRows
        : generateAdjustments(
            isValidate && comparissonArray.length > 0
              ? comparissonArray
              : adjustments,
            loanVariations,
            isBuyside,
            isValidate && comparissonArray.length > 0
              ? comparissonArray
              : rowsBeforeEdit.current,
            isValidate,
            pricingHasChanged,
          ),
    [
      adjustments,
      comparissonArray,
      loanVariations,
      adjustmentRows,
      pricingHasChanged,
    ],
  );

  useEffect(() => {
    rowsBeforeEdit.current = memoRows;
  }, []);

  // TODO: Remove when dimsum allows dynamic columns
  const [key, setKey] = useState(0);
  const memoColumns = useMemo(() => {
    setKey((prev) => prev + 1);
    if (isBuyside) {
      if (pricingHasChanged) {
        if (isValidate) {
          return getValidateColumns({ onEditClick: setOpenSlide });
        }
        return getPricingChangedColumns({
          onEditClick: setOpenSlide,
          onSelectPrice: selectPrice,
          selectedPrice,
        });
      }
      return getBuysideColumns({ onEditClick: setOpenSlide });
    }
    return defaultColumns;
  }, [isBuyside, isValidate, pricingHasChanged, selectedPrice]);

  return (
    <>
      {openSlide && <SlidePriceCards open={openSlide} setOpen={setOpenSlide} />}
      <div
        style={{
          width: '100%',
          alignItems: 'center',
        }}
      >
        {(adjustments.length > 0 || adjustmentRows.length > 0) && (
          <DataTable
            data-testid="PricingGrid-all-components"
            columns={memoColumns || []}
            data={memoRows || []}
            colsLayoutStyle="auto"
            uniqueRowAccessor="id"
            selectable
            selection={
              memoRows.length && { [memoRows[memoRows.length - 1].id]: true }
            }
            noSelectionColumn
            textWrap="wrap-all"
            key={key}
            {...(pricingHasChanged
              ? {
                  getRowVariant: (row) => {
                    if (
                      row.original.price !== row.original.currentPrice ||
                      row.original.rate !== row.original.currentRate
                    ) {
                      return 'ds-secondary-row';
                    }
                    return 'ds-primary-row';
                  },
                }
              : {})}
          />
        )}
      </div>
    </>
  );
});

(PricingGrid as any).defaultProps = { adjustmentRows: [] };
(PricingGrid as any).propTypes = {
  adjustmentRows: arrayOf(shape({})),
};
