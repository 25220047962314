import { parseNumber } from '@frontend/utils';
import Session, { APPLICATION_NAME, IS_LO } from '../services/Session';
import api from './api';

const logs: Record<string, Date> = {};

const logEvent = (payload) => {
  const applicationName = Session.get(APPLICATION_NAME) || 'ENCW';
  let tparam1 = '';
  if (applicationName === 'ENC') tparam1 = 'SmartClient';
  else if (applicationName === 'ENCW') tparam1 = 'LOC';
  else if (applicationName === 'TPOC') tparam1 = 'TPOC';
  if (Session.get(IS_LO)) {
    api.logEvent({ tparam1, ...payload });
  } else {
    // eslint-disable-next-line no-console
    console.log({ tparam1, ...payload });
  }
};

export const startLog = (event: number) => {
  logs[event] = new Date();
};
export const stopLog = (event: number) => {
  if (logs[event]) {
    const endTime = new Date();
    const elapsedTime = parseNumber(
      // eslint-disable-next-line no-unsafe-optional-chaining
      endTime?.getTime?.() - logs[event]?.getTime?.(),
      null,
    ); // in ms
    const eventDefId = Number(event);
    delete logs[event];
    logEvent({ eventDefId, elapsedTime });
  }
};

export const logger = window?.emui?.logger || console;
