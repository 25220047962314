/* eslint-disable @typescript-eslint/default-param-last */
import { uniqBy } from 'lodash';
import {
  GET_RELOCK_DATA_SUCCESS_ALL,
  GET_RATES_DATA_SUCCESS,
  RESET_RATES_DATA,
  RESET_GRID_DATA,
  SET_VALIDATE_SUBMIT_BUTTON_STATUS,
} from './actions';

const INITIAL_STATE = {
  loanRelockData: null,
  ratesData: [], // contains sorted list of unique rates from all rate sheets - for compare rates view
  compareRatesGrid1: [],
  compareRatesGrid2: [],
  compareRatesGrid3: [],
};

const getUniqueRates = (
  current: Array<{ rate: number }> = [],
  rates: Array<{ rate: number }>,
) => uniqBy(current.concat(rates), 'rate').sort((a, b) => a.rate - b.rate);

const getRateSheetData = (ratesData, { ratesData: rates, gridName }) => {
  const rateSheet = rates.ratesBestEx || [];
  return {
    ratesData: getUniqueRates(ratesData, rateSheet),
    [gridName]: rateSheet,
  };
};

// remove rates data of the grid that was reset and merge the rates of the other two
const reduceRates = (gridName, state) => {
  const [grid1, grid2] = [
    'compareRatesGrid1',
    'compareRatesGrid2',
    'compareRatesGrid3',
  ].filter((name) => name !== gridName);
  return getUniqueRates(state[grid1], state[grid2]);
};

export default function LockConfirmReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  const { ratesData } = state;
  switch (type) {
    case GET_RELOCK_DATA_SUCCESS_ALL:
      return {
        ...state,
        loanRelockData: payload,
      };

    case GET_RATES_DATA_SUCCESS:
      return {
        ...state,
        ...getRateSheetData(ratesData, payload),
      };

    case RESET_RATES_DATA:
      return {
        ...state,
        ratesData: [],
        compareRatesGrid1: [],
        compareRatesGrid2: [],
        compareRatesGrid3: [],
      };

    case RESET_GRID_DATA:
      return {
        ...state,
        ratesData: reduceRates(payload, state),
        [payload]: [],
      };
    case SET_VALIDATE_SUBMIT_BUTTON_STATUS:
      return {
        ...state,
        validateSubmitButtonEnabled: action.payload,
      };
    default:
      return state;
  }
}
