import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { DSModal, MODAL_TYPE_V2 } from '@elliemae/ds-modal';
import { DSFormItemLayout, DSLargeInputText } from '@elliemae/ds-form';

export const CommentModal = ({ title, onConfirm, show, onHide }) => {
  const [comment, setComment] = useState('');

  return (
    <DSModal
      isOpen={show}
      modalTitle={title}
      modalType={MODAL_TYPE_V2.FORM}
      confirmLabel={title}
      onClose={onHide}
      onConfirm={() => {
        onConfirm(comment);
        onHide();
        setComment('');
      }}
      shouldCloseOnOverlayClick
      showClose
      version={2}
    >
      <DSFormItemLayout
        value={comment}
        maxLength={2000}
        characterLimitCounter={0}
        rows={6}
        labelText="Comments"
        onChange={(e) => setComment(e.target.value)}
        feedbackMessage="Optional"
        inputComponent={DSLargeInputText}
        data-testid="CommentModal-all-components"
      />
    </DSModal>
  );
};

CommentModal.propTypes = {
  title: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};
