import api from '../utils/api';
import Session, { IS_LO } from './Session';

const getClientSettings = async () => {
  let clientSettings = [];
  if (Session.get(IS_LO)) {
    clientSettings = await api.getWebhookLookups('clientsettings');
  }

  return clientSettings;
};

export const getClientSettingsData = async () => {
  try {
    const clientSettings = await getClientSettings();
    let settings = {};
    if (Array.isArray(clientSettings) && clientSettings.length) {
      const { options = {} } = clientSettings[0] || {};
      settings = options;
    }
    return settings;
  } catch (error) {
    return false;
  }
};
