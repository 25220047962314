import {
  useLoanDataFieldSelector,
  useUserData,
} from '@frontend/pricing-search';
import { useSelector } from 'react-redux';
import Session, {
  APPLICATION_NAME,
  IS_BUYSIDE,
  IS_LO,
} from '../../../common/services/Session';
import { useLoanInformationField } from '../../../data/useSelectors';
import { HeaderMenu } from './HeaderMenu';
import { OnrpLimitNotificationHeader } from './HeaderMenu/LockDeskStatus/components/OnrpLimitNotificationHeader';
import { PricingNotificationHeader } from './PricingNotificationHeader';
import { SmartClientHeader } from './SmartClientHeader';
import { WarningProgramStatus } from './WarningProgramStatus';

export const Header = ({ schemaForm }: { schemaForm: any }) => {
  const { loanId } = useSelector(({ EPPS }) => EPPS.loanPrograms || {});
  const { firstName = '', lastName = '', office = '' } = useUserData();
  const loanNumber = useLoanInformationField('loanNumber', '') as string;
  const loanIdLoanData = useLoanInformationField('loanId');
  const loanChannel = useLoanDataFieldSelector(
    'loanInformation.loanChannel',
  ) as unknown as number;
  const displayedLoanId = loanId || loanIdLoanData;
  const username = `${firstName} ${lastName}`;
  const isBuyside = Session.get(IS_BUYSIDE);
  const isValidChannel = [0, 1].includes(loanChannel);
  const isVisibleLockDesk = isValidChannel && !isBuyside;

  const applicationName = Session.get(IS_LO)
    ? Session.get(APPLICATION_NAME) || 'ENCW'
    : 'EPPS';

  return (
    <>
      {applicationName === 'ENC' ? (
        <SmartClientHeader
          loanId={displayedLoanId}
          username={username}
          office={office}
          loanNumber={loanNumber}
          schemaForm={schemaForm}
        />
      ) : (
        <HeaderMenu
          loanId={displayedLoanId}
          applicationName={applicationName}
          schemaForm={schemaForm}
        />
      )}
      <PricingNotificationHeader />
      {isVisibleLockDesk && <OnrpLimitNotificationHeader />}
      {isBuyside && <WarningProgramStatus />}
    </>
  );
};
