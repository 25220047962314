import { HelpCircleSmall, Property } from '@elliemae/ds-icons';
import { DSRibbon } from '@elliemae/ds-ribbon';
import { DSTooltipV2 } from '@elliemae/ds-tooltip';
import { calculateTotalLoanAmount, useLookup } from '@frontend/pricing-search';
import { get } from 'lodash';
import moment from 'moment';
import { memo, useMemo } from 'react';
import { currencyWithDecimals } from '../../../../../common/utils/shared';
import {
  useLoanDataSelector,
  useLoanInformationField,
  useLoanPropertiesSelector,
  useOriginalLoanInformationSelector,
} from '../../../../../data/useSelectors';
import { getLockStatusDetails, getLookupValue } from './helpers';
import { StyledRibbon } from './styled';

export const TopRibbon = () => {
  const loanData = useLoanDataSelector();
  const loanProperties = useLoanPropertiesSelector();
  const originalLoanInformation = useOriginalLoanInformationSelector();
  const firstMortgageAmount = useLoanInformationField(
    'firstMortgageAmount',
    0,
  ) as number;
  const financedAmount = useLoanInformationField('financedAmount', 0) as number;

  const totalLoanAmount = useMemo(
    () => calculateTotalLoanAmount(firstMortgageAmount, financedAmount),
    [firstMortgageAmount, financedAmount],
  );

  const { property, borrowers, loanInformation } = loanData as any;
  const { loanAmortizationType, purchasePriceAmount } = loanProperties as any;

  const {
    loanProgramName,
    applications: originalLoanInfoApplications,
    ltv,
    combinedLtv,
    hcltvHtltv,
    loanProductData = {},
    requestedInterestRatePercent,
  } = originalLoanInformation as any;
  const { lockStatusValue, lockIcon } = getLockStatusDetails();

  let ficoValue = get(borrowers, '[0].borrowerFinanancial.creditScore');
  if (!ficoValue) {
    ficoValue = '---';
  }

  const address = useMemo(() => {
    let addr = '';
    if (property?.streetAddress) {
      addr += property.streetAddress;
    }
    if (property?.city) {
      addr += ` ${property.city},`;
    }
    if (property?.state) {
      addr += ` ${property.state}`;
    }
    if (property?.zip) {
      addr += ` ${property.zip}`;
    }
    if (addr === '') {
      addr = 'No Subject Property';
    }

    return addr;
  }, [loanData]);

  const loanPurpose = get(loanInformation, 'loanPurpose');
  const estValue = get(property, 'value');

  const topRatioPercent = useMemo(() => {
    let ratio = get(originalLoanInfoApplications, '[0].topRatioPercent');
    if (ratio) {
      ratio = Number(ratio).toFixed(3);
    } else {
      ratio = '0.000';
    }

    return ratio;
  }, [originalLoanInfoApplications]);

  const bottomRatioPercent = useMemo(() => {
    let ratio = get(originalLoanInfoApplications, '[0].bottomRatioPercent');
    if (ratio) {
      ratio = Number(ratio).toFixed(3);
    } else {
      ratio = '0.000';
    }

    return ratio;
  }, [originalLoanInfoApplications]);

  const lienPosition = useMemo(() => {
    if (loanInformation?.lienPosition === 1) {
      return 'First';
    }
    if (loanInformation?.lienPosition === 2) {
      return 'Second';
    }
    return 'Lien Position';
  }, [loanInformation]);

  const InfoIcon = memo(() => (
    <span>
      Info{' '}
      <HelpCircleSmall
        size="s"
        color={['brand-primary', '600']}
        style={{ margin: '0 auto' }}
      />
    </span>
  ));

  return (
    <StyledRibbon>
      <DSRibbon.RibbonWrapper>
        <DSRibbon.RibbonRow alignLeft>
          <DSRibbon.RibbonCol>
            <DSTooltipV2
              title="Loan data to the right reflects current loan data in Encompass"
              zIndex={5000}
              triggerComponent={<DSRibbon.RibbonCard value={<InfoIcon />} />}
            />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard value={ficoValue} additionalLabel="FICO" />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              icon={<Property style={{ paddingTop: '4px' }} size="m" />}
              value={address}
              additionalLabel={[
                getLookupValue(
                  useLookup('unitTypes'),
                  get(property, 'unitTypeId'),
                  'Property Type',
                ),
                getLookupValue(
                  useLookup('propertyUse'),
                  get(property, 'use'),
                  'Occupancy',
                ),
              ]
                .filter(Boolean)
                .join(' | ')}
            />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              value={currencyWithDecimals(estValue, 2) || '$0.00'}
              additionalLabel="Est. Value"
            />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              value={`${
                requestedInterestRatePercent
                  ? requestedInterestRatePercent.toFixed(3)
                  : '0.000'
              }%`}
              additionalLabel="Note Rate"
            />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              icon={lockIcon}
              value={lockStatusValue}
              additionalLabel="Lock Status"
            />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              value={loanProgramName || 'No Loan Program'}
              additionalLabel={loanAmortizationType || 'Amortization'}
            />
          </DSRibbon.RibbonCol>
          {loanPurpose === 1 && (
            <DSRibbon.RibbonCol data-testid="TopRibbon-all-components">
              <DSRibbon.RibbonCard
                value={currencyWithDecimals(purchasePriceAmount, 2) || '$0.00'}
                additionalLabel="Purchase Price"
              />
            </DSRibbon.RibbonCol>
          )}
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              value={currencyWithDecimals(totalLoanAmount, 2) || '$0.00'}
              additionalLabel="Total Loan Amount"
            />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              value={`${topRatioPercent}/${bottomRatioPercent}`}
              additionalLabel="DTI"
            />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              value={`${ltv ? Number(ltv).toFixed(3) : '0.000'}/${
                combinedLtv ? Number(combinedLtv).toFixed(3) : '0.000'
              }/${hcltvHtltv ? Number(hcltvHtltv).toFixed(3) : '0.000'}`}
              additionalLabel="LTV / CLTV / HCLTV"
            />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              value={getLookupValue(
                useLookup('loanUsage'),
                get(loanInformation, 'loanPurpose'),
                'Loan Purpose',
              )}
              additionalLabel={lienPosition}
            />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              value={
                loanProductData.loanScheduledClosingDate
                  ? moment(loanProductData.loanScheduledClosingDate).format(
                      'MM/DD/YYYY',
                    )
                  : '--'
              }
              additionalLabel="Est Closing Date"
            />
          </DSRibbon.RibbonCol>
        </DSRibbon.RibbonRow>
      </DSRibbon.RibbonWrapper>
    </StyledRibbon>
  );
};
