import {
  setLockDeskStatusData,
  setLockDeskRequestLockButtonStatus,
} from './actions';

export const setLockDeskData = (lockDeskData) => (dispatch) => {
  if (lockDeskData) dispatch(setLockDeskStatusData(lockDeskData));
};

export const setRequestLockButtonStatus =
  (isDisable = false) =>
  (dispatch) => {
    dispatch(setLockDeskRequestLockButtonStatus(isDisable));
  };
