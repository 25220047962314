/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Grid } from '@elliemae/ds-grid';
import { getDefaultTheme } from '@elliemae/pui-theme';
import PropTypes from 'prop-types';
import { DSTypography, TYPOGRAPHY_VARIANTS } from '@elliemae/ds-typography';

const theme = getDefaultTheme();

const AdjustmentRowText = ({ text, isHeader }) => (
  <>
    {isHeader ? (
      <DSTypography variant={TYPOGRAPHY_VARIANTS.B1} color="neutral-500">
        <b>{text}</b>
      </DSTypography>
    ) : (
      text
    )}
  </>
);

AdjustmentRowText.propTypes = {
  text: PropTypes.string,
  isHeader: PropTypes.bool.isRequired,
};

AdjustmentRowText.defaultProps = {
  text: '',
};

const AdjustmentRowItem = ({
  text1,
  text2,
  isHeader = false,
  gridProps,
  index,
}: {
  text1: string;
  text2: string;
  isHeader: boolean;
  gridProps: Record<string, any>;
  index: number;
}) => {
  const textStyle = { minHeight: '1em' };
  return (
    <div key={text1 + text2 + index.toString()}>
      <Grid gutter="m" cols={['1fr', '1fr']} {...gridProps}>
        <Grid m="xxs" justifyContent="flex-start" style={textStyle}>
          <AdjustmentRowText text={text1} isHeader={isHeader} />
        </Grid>
        <Grid m="xxs" justifyContent="flex-end" style={textStyle}>
          <AdjustmentRowText text={text2} isHeader={isHeader} />
        </Grid>
      </Grid>
    </div>
  );
};

AdjustmentRowItem.propTypes = {
  text1: PropTypes.string,
  text2: PropTypes.string,
  isHeader: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  gridProps: PropTypes.object,
  index: PropTypes.number.isRequired,
};

AdjustmentRowItem.defaultProps = {
  isHeader: false,
  gridProps: {},
  text1: '',
  text2: '',
};

export const AdjustmentTable = (props) => {
  const { adjustments, typeLabel, totalAdjustments, total, base } = props;
  const border = '1px solid neutral-100';

  const formatNumber = (num) =>
    typeof num === 'number' ? num.toFixed(3) : num;
  return (
    <div>
      {[
        { text1: `Total ${typeLabel} Requested`, text2: formatNumber(total) },
        {
          isHeader: true,
          text1: `${typeLabel} Adjustment`,
          text2: 'Amount',
          gridProps: { bg: theme.colors.neutral[100], border },
        },
        ...adjustments.map((adj) => ({
          text1: adj.description?.toString(),
          text2: formatNumber(adj.amount),
          gridProps: { border },
        })),
        {
          text1: `Total ${typeLabel} Adjustments`,
          text2: formatNumber(totalAdjustments),
        },
        {
          isHeader: true,
          text1: `Base ${typeLabel}`,
          text2: formatNumber(base),
          gridProps: {
            bg: theme.colors.brand[100],
            borderTop: border,
          },
        },
        [],
      ].map((params, index) => AdjustmentRowItem({ ...params, index }))}
    </div>
  );
};

AdjustmentTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  adjustments: PropTypes.array.isRequired,
  typeLabel: PropTypes.string.isRequired,
  totalAdjustments: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  base: PropTypes.number.isRequired,
};
