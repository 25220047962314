import { DSLabelValue } from '@elliemae/ds-label-value';
import { useSelector } from 'react-redux';
import Session, { IS_BUYSIDE } from '../../../../../../common/services/Session';
import { getCompensationValues } from '../../../../../../common/utils/compensationFields';
import { isLOComp } from '../../../../helpers';
import {
  compensationModelSelector,
  compensationValuesSelector,
} from './selectors';

export const CompensationFields = () => {
  const compModel = useSelector(compensationModelSelector);
  const { comp, compVal } = useSelector(compensationValuesSelector);
  const [percentage, value] = getCompensationValues(compModel, comp, compVal);
  const isBuySide = Session.get(IS_BUYSIDE);
  const showComp = isLOComp() && !isBuySide;

  return (
    showComp && (
      <DSLabelValue.Group orientation="horizontal">
        <DSLabelValue>
          <DSLabelValue.Label>Compensation %</DSLabelValue.Label>
          <DSLabelValue.Value>{percentage}</DSLabelValue.Value>
        </DSLabelValue>
        <DSLabelValue>
          <DSLabelValue.Label>Compensation $</DSLabelValue.Label>
          <DSLabelValue.Value>{value}</DSLabelValue.Value>
        </DSLabelValue>
      </DSLabelValue.Group>
    )
  );
};
