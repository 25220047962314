import { object, string, mixed } from "yup";
const StringEnumObjectDto = object({
  displayText: string().required(),
  value: mixed((input) => input)
});
const isBrowser = () => typeof window !== "undefined";
function delay(ms) {
  return new Promise((res) => setTimeout(res, ms));
}
function parseNumber(x, defaultValue = NaN) {
  if (typeof x === "number" && !Number.isNaN(x)) {
    return x;
  }
  if (typeof x === "string") {
    const parsedString = x.replace(/[^0-9.-]/gi, "");
    const parsedNumber = parsedString.length === 0 ? defaultValue : Number(parsedString);
    return Number.isNaN(parsedNumber) ? defaultValue : parsedNumber;
  }
  return defaultValue;
}
function compareItems(a, b, whitelist) {
  const mapper = (item) => (whitelist || Object.keys(item)).map(
    (key) => getStringIdentifier(item, key)
  );
  const mappedA = mapper(a);
  const mappedB = mapper(b);
  return mappedA.length === mappedB.length && mappedA.every((value, index) => compareItem(value, mappedB[index]));
}
function getStringIdentifier(item, key) {
  const value = item?.[key];
  let stringValue;
  if (typeof value === "undefined" || value === null) {
    return `${key}:null`;
  }
  if (typeof value === "string") {
    stringValue = value;
  } else if (typeof value === "number") {
    stringValue = value.toString();
  } else if (typeof value === "boolean") {
    stringValue = value.toString();
  } else if (value instanceof Date) {
    stringValue = value.toISOString();
  } else if (Array.isArray(value)) {
    if (!value.length) {
      stringValue = "null";
    } else {
      stringValue = value.map((val, index) => getStringIdentifier(value, index)).join(",");
    }
  } else if (typeof value === "object") {
    try {
      stringValue = JSON.stringify(value);
    } catch (err) {
      console.error(err);
      console.log("key", key);
      console.log("value", value);
      stringValue = "null";
    }
  } else {
    throw new Error(`Unsupported type ${value}`);
  }
  return `${key}:${stringValue}`;
}
function compareItem(a, b) {
  if (Array.isArray(a) || Array.isArray(b)) {
    if (Array.isArray(a) && !a.length && (!b || Array.isArray(b) && !b.length) || Array.isArray(b) && !b.length && (!a || Array.isArray(a) && !a.length)) {
      return true;
    }
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((value, index) => compareItem(value, b[index]));
  }
  if (typeof a === "object") {
    return typeof b === "object" && compareItem(
      Object.keys(a).map((key) => key + a[key]),
      Object.keys(b).map((key) => key + b[key])
    );
  }
  if (typeof a === "undefined" || a === null) {
    return typeof b === "undefined" || b === null;
  }
  return a === b;
}
function randomUUID() {
  return (window ?? globalThis)?.crypto?.randomUUID();
}
function waitForCondition(fn, options = {}) {
  const startTime = (/* @__PURE__ */ new Date()).getTime();
  return new Promise((res, rej) => {
    const intervalID = setInterval(() => {
      const result = fn();
      if (result) {
        clearInterval(intervalID);
        return res(true);
      }
      const timeout = options.timeout || 15e3;
      if (startTime + timeout < (/* @__PURE__ */ new Date()).getTime()) {
        clearInterval(intervalID);
        return rej(new Error(`timeout. Task took longer than ${timeout}ms`));
      }
    }, options.timerInterval || 100);
  });
}
function pause(time) {
  return new Promise((res) => {
    setTimeout(() => {
      res(true);
    }, time);
  });
}
const convertMSToDateString = (miliseconds, locale = "en-US", options = {}) => {
  if (!miliseconds) {
    console.log("Invalid input");
    return miliseconds;
  }
  return `${new Date(
    typeof miliseconds === "string" ? parseInt(miliseconds, 10) : miliseconds
  ).toLocaleString(locale, options)}`;
};
export {
  StringEnumObjectDto,
  compareItem,
  compareItems,
  convertMSToDateString,
  delay,
  getStringIdentifier,
  isBrowser,
  parseNumber,
  pause,
  randomUUID,
  waitForCondition
};
