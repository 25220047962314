import React from 'react';
import { DSLabelValue } from '@elliemae/ds-label-value';
import { GridContainer, GridItem } from '@elliemae/ds-grid';

import { string, arrayOf, shape } from 'prop-types';
import StyledSummaryWrapper from './styled';

export const SummaryView = ({ summaryDetails = [] }) => {
  return summaryDetails.length ? (
    <StyledSummaryWrapper data-testid="SummaryView-all-components">
      <GridContainer cols={2}>
        {summaryDetails.map(
          ({ title, value }) =>
            value && (
              <GridItem span={1} key={title} data-testid="summary-field">
                <DSLabelValue>
                  <DSLabelValue.Label>{title}</DSLabelValue.Label>
                  <DSLabelValue.Value size="s">{value}</DSLabelValue.Value>
                </DSLabelValue>
              </GridItem>
            ),
        )}
      </GridContainer>
    </StyledSummaryWrapper>
  ) : null;
};

SummaryView.defaultProps = {
  summaryDetails: [],
};

SummaryView.propTypes = {
  summaryDetails: arrayOf(
    shape({
      title: string,
      value: string,
    }),
  ),
};
