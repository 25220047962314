import { BANNER_TYPES, DSBanner } from '@elliemae/ds-banner';
import { useLoanDataFieldSelector } from '@frontend/pricing-search';
import { useEffect, useMemo, useState } from 'react';
import { useCompareDataSelector } from '../../../../../../data/useSelectors';

export const Banner = () => {
  const [isBannerShown, setIsBannerShown] = useState<boolean>(false);
  const [bannerMessage, setBannerMessage] = useState<string>();
  const [bannerType, setBannerType] = useState<string>();
  const { changeCounter: pricingChangesCount = 0 } =
    useCompareDataSelector() as any;
  const loanFieldChanges = useLoanDataFieldSelector(
    'additionalLockInfo.lockCompareResults',
    {},
  ) as any;
  const loanFieldChangesCount = useMemo(
    () => Object.values(loanFieldChanges).flat().length,
    [loanFieldChanges],
  );

  useEffect(() => {
    if (pricingChangesCount > 0) {
      setIsBannerShown(true);
      setBannerMessage('Pricing has changed, the loan is still eligible.');
      setBannerType(BANNER_TYPES.WARNING);
    } else if (pricingChangesCount === 0 && loanFieldChangesCount === 0) {
      setIsBannerShown(true);
      setBannerMessage('Pricing and Loan Values have not changed.');
      setBannerType(BANNER_TYPES.SUCCESS);
    } else if (pricingChangesCount === 0 && loanFieldChangesCount !== 0) {
      setIsBannerShown(true);
      setBannerMessage('Pricing has not changed, the loan is still eligible.');
      setBannerType(BANNER_TYPES.SUCCESS);
    } else {
      setIsBannerShown(false);
    }
  }, [pricingChangesCount, loanFieldChangesCount]);

  return isBannerShown ? (
    <DSBanner
      containerProps={{
        id: 'validate-banner',
        'data-testid': 'Banner-all-components',
        style: { overflow: 'visible' }, // ToDo while loading overflow is seeting hidden so was not showing he banner. Need to check why this is happening
      }}
      label={bannerMessage}
      type={bannerType}
      showCloseButton={false}
    />
  ) : null;
};
