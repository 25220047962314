import { string } from 'prop-types';
import Session, { ORIGIN_SOURCE } from '../../../../../common/services/Session';
import { TITLE_TEXT } from '../utils/constants';
import { StyledNameView, StyledSlash, StyledTitle } from '../utils/styled';

export const HeaderTitle = ({ title }) => {
  const isSecondary = Session.get(ORIGIN_SOURCE) === 'secondarylock';

  return (
    <StyledTitle marginLeft={isSecondary}>
      {TITLE_TEXT}{' '}
      <StyledSlash data-testid="HeaderTitle-all-components" margin />{' '}
      <StyledNameView>{title}</StyledNameView>
    </StyledTitle>
  );
};

HeaderTitle.defaultProps = {
  title: '',
};

HeaderTitle.propTypes = {
  title: string,
};
