import Rights from '../../common/services/Rights';
import { currencyWithDecimals } from '../../common/utils/shared';
import { StyledDangerSpan } from './styled';

export const dispPrice = (p, priceVal) => {
  const result = (100 - p).toFixed(3);
  if (Number.isNaN(priceVal)) return 'n/a';
  const priceValResult = Math.round(priceVal);
  if (priceValResult < 0) {
    return <StyledDangerSpan>{result}</StyledDangerSpan>;
  }
  return result;
};

export const dispDiscount = (discountPoints) => {
  const result = discountPoints ? discountPoints.toFixed(3) : '';
  if (result < 0) {
    return <StyledDangerSpan>{result}</StyledDangerSpan>;
  }
  return result;
};

export const dispComp = (p) => {
  if (Number.isNaN(p)) return 'n/a';
  return p.toFixed(3);
};

export const dispApr = (p) => {
  if (p === null || p === undefined || Number.isNaN(p)) return '--';
  if (p === false) return '0.000%';
  return `${p.toFixed(3)}`;
};

export const dispRate = (p, priceVal) => {
  if (p === null || p === undefined || Number.isNaN(p)) return '--';
  if (p === false) return '0.000%';
  if (Number.isNaN(priceVal)) return 'n/a';
  const result = Math.round(priceVal);
  if (result < 0) {
    return `* ${p.toFixed(3)}`;
  }
  return `${p.toFixed(3)}`;
};

export const dispCurrency = (c) => {
  if (Number.isNaN(c)) return 'n/a';
  const result = Math.round(c);
  if (result < 0) {
    return <StyledDangerSpan>{`-$${Math.abs(result)}`}</StyledDangerSpan>;
  }
  return `$${result}`;
};

export const dispCurrencyMask = (c) => {
  if (Number.isNaN(c)) return 'n/a';
  const result = Math.round(c);
  if (result < 0) {
    return (
      <StyledDangerSpan>{`(${currencyWithDecimals(
        Math.abs(result),
      )})`}</StyledDangerSpan>
    );
  }
  return `${currencyWithDecimals(result)}`;
};

const getTargetRateOrPriceValue = (loanData) => {
  const loanInfo = loanData.loanInformation;
  const target = loanInfo.target !== undefined ? loanInfo.target : null;
  return Number.isNaN(parseFloat(target)) ? null : parseFloat(target);
};

const getTargetRateOrPriceRadioGroupLabel = (loanData) => {
  const target = getTargetRateOrPriceValue(loanData);
  let label: string | null = null;

  if (!target) return null;
  if (target >= 80 && target <= 120) label = 'Price';
  else if (target >= 0 && target <= 20) label = 'Rate';
  return label;
};

export const getTarget = (loanData) => {
  const targetType = getTargetRateOrPriceRadioGroupLabel(loanData);
  const target = getTargetRateOrPriceValue(loanData) as number;
  let t;
  let bTargetPrice = true;
  if (targetType === 'Rate') {
    t = target;
    bTargetPrice = false;
  } else {
    t = 100 - target;
    bTargetPrice = true;
  }
  return { isTargetPrice: bTargetPrice, value: t };
};

export const applyFilter = (filters) => (p) =>
  (filters.terms === undefined ||
    filters.terms.length === 0 ||
    filters.terms.indexOf(p.programTypeID) > -1) &&
  (filters.standardProducts === undefined ||
    filters.standardProducts.length === 0 ||
    filters.standardProducts.indexOf(p.productTypeID) > -1) &&
  (filters.specialProducts === undefined ||
    filters.specialProducts.length === 0 ||
    filters.specialProducts.indexOf(p.flagFilter) > -1) &&
  (filters.investors === undefined ||
    filters.investors.length === 0 ||
    (p.customLenderID
      ? filters.investors.indexOf(p.customLenderID) > -1
      : filters.investors.indexOf(p.lenderID) > -1)) &&
  (filters.show === undefined || filters.show === 'eligible'
    ? p.deleted === 0
    : p.deleted !== 0);

export const searchUpdater =
  (
    { loanData = {}, quickSearch, dispatch, updateLoanInfo, qualifyPrograms },
    dropDownProductTypes: any[] = [],
  ) =>
  (searchData) => {
    if (loanData) {
      const { productType = [] } = searchData;
      const arrayHasHELOC = dropDownProductTypes
        .filter((elem) => productType.includes(elem.value))
        .some((elem) => elem.heloc);
      const search = {
        ...quickSearch,
        loanType: arrayHasHELOC ? 'HELOC' : 'Conventional',
        productType:
          searchData.productType && searchData.productType.length
            ? searchData.productType
            : quickSearch.productType,
        specialProducts:
          searchData.specialProducts && searchData.specialProducts.length
            ? searchData.specialProducts
            : quickSearch.specialProducts,
        loanInformation: {
          ...quickSearch.loanInformation,
          helocInitialDraw: arrayHasHELOC
            ? quickSearch.loanInformation.helocInitialDraw
            : null,
          target: searchData.target || quickSearch.loanInformation.target,
          targetLabel:
            searchData.targetLabel || quickSearch.loanInformation.targetLabel,
          loanPurpose:
            searchData.loanPurpose !== undefined &&
            searchData.loanPurpose !== null
              ? searchData.loanPurpose
              : quickSearch.loanInformation.loanPurpose,
        },
        productOptions: searchData.productOptions
          ? searchData.productOptions
          : quickSearch.productOptions,
        lockDays:
          searchData.lockDays !== undefined && searchData.lockDays !== null
            ? [searchData.lockDays].flat()
            : (quickSearch.lockDays || []).flat(),
        standardProducts:
          searchData.standardProducts && searchData.standardProducts.length
            ? searchData.standardProducts
            : quickSearch.standardProducts,
      };
      dispatch(updateLoanInfo(search));
      qualifyPrograms(search);
    }
  };

export const checkLockProgramStatus = (
  relockRequest,
  programId,
  rateDetails,
) => {
  const { specificProgramID, specificRateDataID, effectiveDate } =
    relockRequest;
  if (specificProgramID && specificRateDataID && effectiveDate) {
    if (specificProgramID !== 0 && specificRateDataID !== 0) {
      return (
        specificProgramID === programId &&
        rateDetails.some((rate) => specificRateDataID === rate.rateDataID)
      );
    }
  }
  return false;
};

export const parseWord = (word = '') => {
  return word.toLocaleLowerCase().replace(/[ ,]/g, '');
};

export const checkLockRateStatus = (relockRequest, programId, rateDataId) => {
  const { specificProgramID, specificRateDataID, effectiveDate } =
    relockRequest;
  if (specificProgramID && specificRateDataID && effectiveDate) {
    if (specificProgramID !== 0 && specificRateDataID !== 0) {
      return (
        specificProgramID === programId && specificRateDataID === rateDataId
      );
    }
  }
  return false;
};

export const arrayHasHELOC = (dropDownLoanTerms, loanTerms) =>
  dropDownLoanTerms
    .filter((elem) => loanTerms.includes(elem.value))
    .some((elem) => elem.heloc);

export const isLOComp = () => {
  if (Rights.viewLOComp) {
    return true;
  }
  return false;
};
