import { DataTable } from '@elliemae/ds-data-table';
import { isEmpty } from '../../../../../../../../common/utils/shared';
import { useLockDeskStatusActionsSelector } from '../../../../../../../../data/useSelectors';
import { timeDayInRange } from '../../../utils/helpers';

import {
  getHeaderData,
  getHiddenColumn,
  getLockDeskColumn,
  getOnrpColumn,
  normalizeLockDeskData,
} from './utils/helpers';

export const LockDeskDataTable = () => {
  const { lockDeskData } = useLockDeskStatusActionsSelector();
  const { timeToClose, timeInRange } = isEmpty(lockDeskData)
    ? {}
    : timeDayInRange(lockDeskData);

  const lockData =
    normalizeLockDeskData(lockDeskData?.lockDeskTimingsPerDay) || [];
  const hiddenColumns = getHiddenColumn(lockData);
  const columns = [
    {
      Header: 'Day',
      accessor: 'day',
      width: hiddenColumns.length > 0 ? 150 : 80,
    },
    getLockDeskColumn(timeToClose, timeInRange),
    getOnrpColumn(timeToClose, timeInRange),
  ];
  return (
    <div>
      <p style={{ marginTop: '0px', marginBottom: '20px' }}>
        {getHeaderData(timeToClose, timeInRange)}
      </p>
      <DataTable
        data-testid="LockDeskGrid-all-components"
        hiddenColumns={hiddenColumns}
        columns={columns}
        data={lockData}
        autoHeight
        textWrap="wrap"
      />
    </div>
  );
};
