/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';

const key = 'lookups';

export const initialState = {
  data: {},
  success: false,
  errorMessage: '',
};

const slice = createSlice({
  name: key,
  initialState,
  reducers: {
    setLookupsSuccess: (state, { payload }) => {
      state.data = { ...state.data, ...payload };
      state.success = true;
      state.errorMessage = '';
    },
    setLookupsError: (state) => {
      state.data = {};
      state.success = false;
      state.errorMessage = 'ERROR';
    },
  },
});

export const lookupsActions = {
  ...slice.actions,
  getLookupsDataAction: createAction(`${key}/getLookupsDataAction`),
  getGeocodesDataAction: createAction(`${key}/getGeocodesDataAction`),
};

export default slice.reducer;
