import React from 'react';
import PropTypes from 'prop-types';
import {
  DSDialog,
  DSDialogBody,
  DSDialogSeparator,
  DSDialogDefaultLayout,
  DSDialogHeader,
  DSDialogTitle,
  DSDialogAddon,
} from '@elliemae/ds-dialog';
import { DSButtonV2 } from '@elliemae/ds-button';
import { Grid } from '@elliemae/ds-grid';
import { Close } from '@elliemae/ds-icons';
import { LockDeskDataTable } from './LockDeskDataTable';

export const LockDeskModal = ({ isModalOpen, handleClose }) => {
  return (
    <Grid justifyItems="center" alignItems="center" height="100%">
      <DSDialog isOpen={isModalOpen} centered style={{ width: '400px' }}>
        <DSDialogHeader>
          <DSDialogTitle>Lock Desk Hours</DSDialogTitle>
          <DSDialogAddon>
            <DSButtonV2
              buttonType="icon"
              data-testid="close-dialog-button"
              onClick={handleClose}
              aria-label="close dialog"
            >
              <Close color={['neutral', '900']} title="close" />
            </DSButtonV2>
          </DSDialogAddon>
        </DSDialogHeader>
        <DSDialogSeparator />
        <DSDialogBody>
          <DSDialogDefaultLayout>
            <LockDeskDataTable />
          </DSDialogDefaultLayout>
        </DSDialogBody>
        <DSDialogSeparator />
      </DSDialog>
    </Grid>
  );
};

LockDeskModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isModalOpen: PropTypes.func.isRequired,
};
