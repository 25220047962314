import { DSButton } from '@elliemae/ds-button';
import { ChevronLeft } from '@elliemae/ds-icons';
import {
  DSPageHeaderV2,
  PageHeaderSummary,
  PageHeaderTitle,
} from '@elliemae/ds-page-header';
import { DSToolbar } from '@elliemae/ds-toolbar';
import { func, number, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { setAllRatesRowData } from '../../../../../../common/Qualification/QualificationActions';

const BackArrow = () => {
  const dispatch = useDispatch();
  return (
    <DSButton
      buttonType="link"
      icon={<ChevronLeft />}
      onClick={() => dispatch(setAllRatesRowData(null))}
    />
  );
};

const ToolBar = ({ onClickProgramDetails }) => (
  <DSToolbar withDepth={false}>
    <DSButton
      buttonType="secondary"
      labelText="Program Details"
      onClick={onClickProgramDetails}
    />
  </DSToolbar>
);

export const AllRatesHeader = ({ onClickProgramDetails, name, rate }) => {
  return (
    <>
      <DSPageHeaderV2
        backarrow={<BackArrow />}
        breadcrumbs={<div style={{ paddingTop: `0px` }}> Search Results</div>}
        pageTitle={<PageHeaderTitle>{name}</PageHeaderTitle>}
        summary={<PageHeaderSummary label="Rates" value={rate} />}
        toolbar={<ToolBar onClickProgramDetails={onClickProgramDetails} />}
        withBottomSeparator={false}
      />
      <div
        style={{
          margin: '8px 0 9.5px 0',
          display: `flex`,
          width: `100%`,
          borderBottom: `1px solid #a9b1be`,
        }}
      />
    </>
  );
};

AllRatesHeader.propTypes = {
  onClickProgramDetails: func.isRequired,
  name: string.isRequired,
  rate: number.isRequired,
};

ToolBar.propTypes = {
  onClickProgramDetails: func.isRequired,
};
