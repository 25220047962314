import { DSButtonV2 } from '@elliemae/ds-button';
import {
  DSDialog,
  DSDialogBody,
  DSDialogDefaultLayout,
  DSDialogFooter,
  DSDialogHeader,
  DSDialogSeparator,
  DSDialogTitle,
} from '@elliemae/ds-dialog';
import { Grid } from '@elliemae/ds-grid';
import { DSTypography, TYPOGRAPHY_VARIANTS } from '@elliemae/ds-typography';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import Common from '../../common/services/Common';

export const LoanFieldValidationModal = ({ lockValidationStatus }) => {
  const onClick = () => Common.closeApplication();

  const isOpen = useMemo(
    () => typeof lockValidationStatus === 'string',
    [lockValidationStatus],
  );

  return (
    <DSDialog isOpen={isOpen} centered style={{ minWidth: '400px' }}>
      <DSDialogHeader>
        <DSDialogTitle>Loan Price Validation</DSDialogTitle>
      </DSDialogHeader>
      <DSDialogSeparator />
      <DSDialogBody>
        <DSDialogDefaultLayout>
          <Grid>
            <p style={{ marginTop: 0 }}>
              We are not able to process your lock extension at this time.
              Please request a Lock Update and then try your extension request
              again.
            </p>

            <DSTypography variant={TYPOGRAPHY_VARIANTS.B1} color="neutral-500">
              <b>Lock Validation Status: {lockValidationStatus}</b>
            </DSTypography>
          </Grid>
        </DSDialogDefaultLayout>
      </DSDialogBody>
      <DSDialogSeparator />
      <DSDialogFooter>
        <DSButtonV2 onClick={onClick}>Close ICE PPE</DSButtonV2>
      </DSDialogFooter>
    </DSDialog>
  );
};

LoanFieldValidationModal.propTypes = {
  lockValidationStatus: PropTypes.string.isRequired,
};
