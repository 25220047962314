import { DSButtonV2 } from '@elliemae/ds-button';
import {
  DSDialog,
  DSDialogBody,
  DSDialogDefaultLayout,
  DSDialogFooter,
  DSDialogHeader,
  DSDialogSeparator,
  DSDialogTitle,
} from '@elliemae/ds-dialog';
import { DSCheckbox, DSInputMask, MASK_TYPES } from '@elliemae/ds-form';
import { DSInputText } from '@elliemae/ds-form-input-text';
import { DSFormLayoutBlockItem } from '@elliemae/ds-form-layout-blocks';
import { DSSingleComboBox } from '@elliemae/ds-form-single-combobox';
import { Grid } from '@elliemae/ds-grid';
import { arrayOf, bool, func, number, string } from 'prop-types';
import { useEffect, useState } from 'react';
import Session, {
  APPLICATION_NAME,
  ORIGIN_SOURCE,
} from '../../../../common/services/Session';
import {
  handleDaysChange,
  handleEmailChange,
  handlePriceChange,
  handleRateChange,
} from './helpers';
import {
  StyledCard1,
  StyledCard2,
  StyledCheckBox,
  StyledDiv,
  StyledDivInner,
  StyledTitle,
} from './styled';

export const RateAlertModal = ({
  isModalOpen,
  borrower,
  rate,
  price,
  loanInfoLine1Col1,
  loanInfoLine2Col1,
  loanInfoLine2Col2,
  options,
  email,
  handleCancel,
  handleCreate,
}) => {
  const [disableCreate, setDisableCreate] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any[] | any>([]);
  const [rateValue, setRateValue] = useState(rate);
  const [priceValue, setPriceValue] = useState(price);
  const [emailValue, setEmailValue] = useState();
  const [daysValue, setDaysValue] = useState('15');
  const [primaryEmail, setPrimaryEmail] = useState(true);
  const [secondaryEmail, setSecondaryEmail] = useState(false);
  const [notify, setNotify] = useState(false);
  const [comboOptions, setComboOptions] = useState([]);
  const [validator, setValidator] = useState<any>({
    daysError: false,
    emailError: false,
    rateError: [undefined, null, ''].includes(rate),
    priceError: [undefined, null, ''].includes(price),
    rateErrorMsg: '',
    priceErrorMsg: '',
    daysErrorMsg: '',
    emailErrorMsg: '',
  });
  const alertText = `${'Alert me at my default address of '} ${email}`;

  useEffect(() => {
    setRateValue(rate);
    setPriceValue(price);
    if (options?.length) {
      const mappingData = options.map((data) => ({
        ...data,
      }));
      setComboOptions(mappingData);
      setSelectedValue(mappingData[0]);
    }
  }, [rate, price]);

  const priceChange = (e) => {
    setPriceValue(e.target.value);
    handleValidations('price', e.target.value);
  };

  const rateChange = (e) => {
    setRateValue(e.target.value);
    handleValidations('rate', e.target.value);
  };

  const daysChange = (e) => {
    setDaysValue(e.target.value.toString());
    handleValidations('days', e.target.value);
  };

  const emailChange = (e) => {
    setEmailValue(e);
    handleValidations('email', e);
  };

  const handleValidations = (type?: any, value?: any) => {
    let priceValidation = { hasError: false, errorMessage: '' };
    let rateValidation = { hasError: false, errorMessage: '' };
    let daysValidation = { hasError: false, errorMessage: '' };
    let emailValidation = { hasError: false, errorMessage: '' };

    switch (type) {
      case 'price': {
        priceValidation = handlePriceChange(value);
        break;
      }
      case 'rate': {
        rateValidation = handleRateChange(value);
        break;
      }
      case 'days': {
        daysValidation = handleDaysChange(value);
        break;
      }
      case 'email': {
        emailValidation = handleEmailChange(value);
        break;
      }
      case 'all': {
        priceValidation = handlePriceChange(priceValue);
        rateValidation = handleRateChange(rateValue);
        daysValidation = handleDaysChange(daysValue);
        emailValidation = handleEmailChange(emailValue);
        break;
      }
      default:
        break;
    }

    setValidator({
      priceError: priceValidation.hasError,
      rateError: rateValidation.hasError,
      daysError: daysValidation.hasError,
      emailError: emailValidation.hasError,
      priceErrorMsg: priceValidation.errorMessage,
      rateErrorMsg: rateValidation.errorMessage,
      daysErrorMsg: daysValidation.errorMessage,
      emailErrorMsg: emailValidation.errorMessage,
    });

    if (
      priceValidation.hasError ||
      rateValidation.hasError ||
      daysValidation.hasError ||
      emailValidation.hasError
    ) {
      setDisableCreate(true);
      return true;
    }

    setDisableCreate(false);
    return false;
  };

  const handleCreateEvent = () => {
    if (!handleValidations('all')) {
      handleCreate({
        sourceApplicationName: Session.get(APPLICATION_NAME) || 'ENCW',
        sourceApplicationFormName: Session.get(ORIGIN_SOURCE) || 'lockrequest',
        eppsUserName: '',
        facadeUserId: '',
        program: selectedValue.label,
        rate: rateValue.toString().replace('%', ''),
        price: priceValue,
        usePrimaryEmail: primaryEmail,
        altEmail: emailValue,
        setAltEmailAsDefault: secondaryEmail,
        expireInDays: daysValue,
        notifyIfExpires: notify,
        status: 0,
      });
    }
  };

  return (
    <Grid>
      <DSDialog
        isOpen={isModalOpen}
        onClickOutside={handleCancel}
        centered
        style={{ width: '800px' }}
      >
        <DSDialogHeader>
          <DSDialogTitle>Create Rate Alert</DSDialogTitle>
        </DSDialogHeader>
        <DSDialogSeparator />
        <DSDialogBody>
          <DSDialogDefaultLayout>
            <DSDialogSeparator />
            <StyledTitle>
              Loan Information <br />
              <br />
              <StyledDiv>
                <StyledCard1>Borrower {borrower}</StyledCard1>
                <StyledCard2>{options[0].label}</StyledCard2>
              </StyledDiv>
              <StyledDiv>
                <StyledCard1>{loanInfoLine1Col1}</StyledCard1>
                <StyledCard2>{`${rate.toFixed(
                  3,
                )} % ${' @'} ${price}`}</StyledCard2>
              </StyledDiv>
              <StyledDiv>
                <StyledCard1>{loanInfoLine2Col1}</StyledCard1>
                <StyledCard2>{loanInfoLine2Col2}</StyledCard2>
              </StyledDiv>
            </StyledTitle>
            <StyledTitle>
              <br />
              Alert Settings <br />
              <br />
              <StyledDiv>
                <StyledDivInner>
                  <Grid
                    cols={['1.8fr', '1.2fr', '1.2fr']}
                    gutter="xxs"
                    border="1px none white"
                  >
                    <DSFormLayoutBlockItem
                      label="Alert me when"
                      inputID="programs"
                      style={{ border: '1px none yellow', height: '10px' }}
                    >
                      <DSSingleComboBox
                        name="programs"
                        id="programs"
                        allOptions={comboOptions}
                        onChange={(item) => {
                          setSelectedValue(item);
                        }}
                        selectedValue={selectedValue}
                        required
                      />
                    </DSFormLayoutBlockItem>

                    <DSFormLayoutBlockItem
                      label="prices at or better than the rate of"
                      inputID="rateAt"
                      hasError={validator.rateError}
                      validationMessage={validator.rateErrorMsg}
                      style={{ border: '1px none yellow', height: '10px' }}
                    >
                      <DSInputMask
                        placeholder="0%"
                        mask={(num) =>
                          MASK_TYPES.NUMBER({
                            prefix: '',
                            integerLimit: 2,
                            allowDecimal: true,
                            allowNegative: false,
                            decimalLimit: 3,
                            suffix: '%',
                          })(num)
                        }
                        name="rateAt"
                        value={rateValue.toString()}
                        onBlur={rateChange}
                      />
                    </DSFormLayoutBlockItem>
                    <DSFormLayoutBlockItem
                      label="and the price of"
                      inputID="priceAt"
                      hasError={validator.priceError}
                      validationMessage={validator.priceErrorMsg}
                      style={{ border: '1px none yellow', height: '10px' }}
                    >
                      <DSInputMask
                        mask={(num) =>
                          MASK_TYPES.NUMBER({
                            prefix: '',
                            integerLimit: 3,
                            allowDecimal: true,
                            allowNegative: true,
                            decimalLimit: 3,
                          })(num)
                        }
                        name="priceAt"
                        value={priceValue.toString()}
                        placeholder=""
                        onBlur={priceChange}
                      />
                    </DSFormLayoutBlockItem>
                  </Grid>
                </StyledDivInner>
                <br />
                <Grid>
                  <DSCheckbox
                    labelText={alertText}
                    checked={primaryEmail}
                    name="primaryEmail"
                    id="primaryEmail"
                    onChange={
                      ((value) => setPrimaryEmail(value.target.checked)) as any
                    }
                  />
                </Grid>
                <Grid
                  cols={['1fr', '1fr']}
                  gutter="xs"
                  alignItems="center"
                  style={{ border: '1px none pink' }}
                >
                  <DSFormLayoutBlockItem
                    label="Send Alert Email Address"
                    inputID="email"
                    hasError={validator.emailError}
                    validationMessage={validator.emailErrorMsg}
                    style={{ height: '44px' }}
                  >
                    <DSInputText
                      name="email"
                      value={emailValue}
                      placeholder=""
                      onValueChange={emailChange}
                    />
                  </DSFormLayoutBlockItem>
                  <StyledCheckBox>
                    <DSCheckbox
                      labelText="Make this my default email address"
                      checked={secondaryEmail}
                      name="altEmail"
                      id="altEmail"
                      onChange={
                        ((value) =>
                          setSecondaryEmail(value.target.checked)) as any
                      }
                    />
                  </StyledCheckBox>
                </Grid>
              </StyledDiv>
            </StyledTitle>
            <StyledTitle>
              Alert Expiration <br />
              <StyledDiv>
                <Grid
                  cols={['1fr', '1fr']}
                  gutter="xs"
                  alignItems="center"
                  border="1px none white"
                >
                  <DSFormLayoutBlockItem
                    label="Alert Expiration in (days)"
                    inputID="days"
                    hasError={validator.daysError}
                    validationMessage={validator.daysErrorMsg}
                    style={{ border: '1px none yellow', height: '45px' }}
                  >
                    <DSInputMask
                      mask={(num) =>
                        MASK_TYPES.NUMBER({
                          prefix: '',
                          integerLimit: 2,
                          allowDecimal: false,
                          allowNegative: false,
                        })(num)
                      }
                      name="days"
                      placeholder=""
                      value={daysValue.toString()}
                      onBlur={daysChange}
                    />
                  </DSFormLayoutBlockItem>
                  <StyledCheckBox>
                    <DSCheckbox
                      labelText="Notify me if this alert expires"
                      checked={notify}
                      name="notify"
                      id="notify"
                      onChange={
                        ((value) => setNotify(value.target.checked)) as any
                      }
                    />
                  </StyledCheckBox>
                </Grid>
              </StyledDiv>
            </StyledTitle>

            <DSDialogSeparator />
          </DSDialogDefaultLayout>
        </DSDialogBody>
        <DSDialogSeparator />
        <DSDialogFooter>
          <DSButtonV2 buttonType="outline" onClick={handleCancel}>
            Cancel
          </DSButtonV2>
          <DSButtonV2
            disabled={disableCreate}
            onClick={() => handleCreateEvent()}
          >
            Create
          </DSButtonV2>
        </DSDialogFooter>
      </DSDialog>
    </Grid>
  );
};

RateAlertModal.defaultProps = {
  isModalOpen: false,
  borrower: '',
  rate: 0,
  price: '',
  loanInfoLine1Col1: '',
  loanInfoLine2Col1: '',
  loanInfoLine2Col2: '',
  email: '',
  options: [],
};

RateAlertModal.propTypes = {
  isModalOpen: bool,
  borrower: string,
  rate: number,
  price: string,
  loanInfoLine1Col1: string,
  loanInfoLine2Col1: string,
  loanInfoLine2Col2: string,
  options: arrayOf() as any,
  email: string,
  handleCancel: func.isRequired,
  handleCreate: func.isRequired,
};
