/* eslint-disable @typescript-eslint/default-param-last */
import getSavedState from '../utils/getSavedState';

const INITIAL_STATE = getSavedState('EPPS.loanPrograms', {});

export default function rates(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'GET_RATES_SUCCESS':
      return action.payload;
    case 'DATA_RECIEVED_SUCCESS':
      return action.payload;
    case 'GET_FILTERS_SUCCESS':
      return action.payload;
    case 'GET_RATES_FAIL':
      return action.payload;
    case 'UPDATE_LOAN_ID':
      return action.payload;
    default:
      return state;
  }
}
