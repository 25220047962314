import { v4 as uuidv4 } from 'uuid';
import {
  useRateSelectorLien,
  useRateSelectorLoan,
} from '../../../../../data/useSelectors';

export const useBorrowersSectionData = () => {
  const borrowers = useRateSelectorLoan('borrowers', []) as any[];

  const {
    finance: {
      demonstrateHousingPaymentHistory: monthHistory,
      firstTimeHomeBuyers: timeHomebuyer,
      bankruptcy,
      bankruptcyId,
      noticeOfDefaultForeClosure,
      noticeOfDefaultForeClosureId,
    } = {},
  } = borrowers[0] || {};

  const borrowerTable = borrowers.map((borrower) => {
    const firstNameVal = borrower.firstName || '';
    const lastNameVal = borrower.lastName || '';
    const parsedBorrower = {
      ...borrower,
      FullName: `${firstNameVal} ${lastNameVal}`,
    };
    if (!parsedBorrower.id) parsedBorrower.id = uuidv4();
    return parsedBorrower;
  });

  return {
    borrowers: borrowerTable,
    sectionFields: [
      {
        label: 'Representative Credit Score',
        value: useRateSelectorLien('representativeScore'),
        key: 'RepresentativeCreditScore',
      },
      {
        label: 'Mortgage History',
        value: useRateSelectorLien('lates', 'None'),
        key: 'mortgageHistory',
      },
      {
        label: 'NOD',
        value:
          noticeOfDefaultForeClosureId === 1
            ? 'Never'
            : noticeOfDefaultForeClosure,
        key: 'nod',
      },
      {
        label: 'Bankruptcy',
        value: bankruptcyId === 1 ? 'Never' : bankruptcy,
        key: 'bankruptcy',
      },
      {
        label: '12-Month History',
        value: monthHistory ? 'Yes' : 'No',
        key: 'twelveMonthHistory',
      },
      {
        label: 'First Time Homebuyer',
        value: timeHomebuyer ? 'Yes' : 'No',
        key: 'firstTimeHomebuyer',
      },
    ],
  };
};
